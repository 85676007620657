import * as React from "react";

function BroadcastIcon(props) {
  return (
    <svg
      id="Group_21406"
      data-name="Group 21406"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 30 30"
      {...props}
    >
      <rect
        id="Rectangle_5602"
        data-name="Rectangle 5602"
        width="30"
        height="30"
        stroke="none"
      />
      <g
        id="Group_25292"
        data-name="Group 25292"
        transform="translate(-5728 965)"
      >
        <path
          id="podcasts_FILL0_wght400_GRAD0_opsz48"
          d="M84.03-858a13.2,13.2,0,0,1-2.941-4.129A12.133,12.133,0,0,1,80-867.246a12.217,12.217,0,0,1,1.024-4.974A12.868,12.868,0,0,1,83.8-876.27,13.143,13.143,0,0,1,87.93-879,12.871,12.871,0,0,1,93-880a12.871,12.871,0,0,1,5.07,1,13.143,13.143,0,0,1,4.128,2.726,12.868,12.868,0,0,1,2.779,4.049A12.217,12.217,0,0,1,106-867.246a12.133,12.133,0,0,1-1.089,5.117A13.2,13.2,0,0,1,101.97-858l-1.4-1.371a10.978,10.978,0,0,0,2.551-3.507,10.3,10.3,0,0,0,.926-4.368,10.358,10.358,0,0,0-3.217-7.684A10.766,10.766,0,0,0,93-878.087a10.766,10.766,0,0,0-7.833,3.157,10.358,10.358,0,0,0-3.218,7.684,10.3,10.3,0,0,0,.926,4.368,10.978,10.978,0,0,0,2.551,3.507Zm3.673-3.6a8.022,8.022,0,0,1-1.82-2.5,7.284,7.284,0,0,1-.683-3.141,7.309,7.309,0,0,1,2.275-5.42A7.6,7.6,0,0,1,93-874.9a7.6,7.6,0,0,1,5.525,2.232,7.309,7.309,0,0,1,2.275,5.42,7.284,7.284,0,0,1-.683,3.141,8.023,8.023,0,0,1-1.82,2.5l-1.4-1.371a5.826,5.826,0,0,0,1.43-1.881,5.457,5.457,0,0,0,.52-2.391,5.482,5.482,0,0,0-1.706-4.065A5.7,5.7,0,0,0,93-872.986a5.7,5.7,0,0,0-4.144,1.674,5.482,5.482,0,0,0-1.706,4.065,5.457,5.457,0,0,0,.52,2.391,5.827,5.827,0,0,0,1.43,1.881Z"
          transform="translate(5650 -81)"
        />
        <ellipse
          id="Ellipse_1928"
          data-name="Ellipse 1928"
          cx="2.627"
          cy="2.609"
          rx="2.627"
          ry="2.609"
          transform="translate(5740.376 -950.884)"
        />
      </g>
    </svg>
  );
}

const MemoBroadcastIcon = React.memo(BroadcastIcon);

export default MemoBroadcastIcon;
