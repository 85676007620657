import * as React from "react";

function DisclaimerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 26 26"
      {...props}
    >
      <g id="noun-disclaimer-5835152" transform="translate(-2.893 -8.01)">
        <path
          id="Path_100850"
          data-name="Path 100850"
          d="M11.134,27.313a34.693,34.693,0,0,0,6.876.7,34.693,34.693,0,0,0,6.876-.694,3.088,3.088,0,0,0,2.425-2.432,34.427,34.427,0,0,0,0-13.777,3.088,3.088,0,0,0-2.425-2.4,34.543,34.543,0,0,0-13.777,0,3.088,3.088,0,0,0-2.4,2.429,34.427,34.427,0,0,0,0,13.777A3.088,3.088,0,0,0,11.134,27.313ZM9.946,11.373a1.838,1.838,0,0,1,1.438-1.441,33.28,33.28,0,0,1,13.277,0A1.838,1.838,0,0,1,26.1,11.373a33.2,33.2,0,0,1,0,13.277,1.838,1.838,0,0,1-1.438,1.441,33.318,33.318,0,0,1-13.277,0A1.838,1.838,0,0,1,9.946,24.65a33.2,33.2,0,0,1-.013-13.28Z"
          transform="translate(-5.12)"
          fill="#fff"
        />
        <circle
          id="Ellipse_4425"
          data-name="Ellipse 4425"
          cx="1"
          cy="1"
          r="1"
          transform="translate(11.89 21.261)"
          fill="#fff"
        />
        <path
          id="Path_100851"
          data-name="Path 100851"
          d="M39.234,31.592l.266-6.959a.761.761,0,0,0-1.5,0l.266,6.959a.491.491,0,0,0,.968,0Z"
          transform="translate(-25.86 -12.491)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
const MemoDisclaimerIcon = React.memo(DisclaimerIcon);
export default MemoDisclaimerIcon;
