import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gtmIdString,
  MAINTAINANCE_KEY,
  MAINTAINANCE_URL,
  REACT_APP_VAPID_ID,
} from "./constants/env";
import GlobalStyle from "./global_styles/style";
import { messaging } from "./push_firebase";
import { tokensState } from "./redux/actions/notificationAction/token";
import { routes } from "./routes";
import axios from "axios";
import TagManager from "react-gtm-module";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  checkIfImagesNeedsToBeChanged,
  fetchNewImages,
} from "./apis/staticImageGlobalizeApi";
import {
  convertToObj,
  globalImages,
  localImages,
  updatedImagesToShow,
} from "./assets/imagesPath/images";
import { PrivateRouteComponent } from "./routes/PrivateRoute";
import { publicRoute } from "./routes/PublicRoutes";
import PublicRoutesComp from "./routes/PublicRoutesComp";
import SuperParent from "./SuperParent";
import { langFunc, stopKeyboardShortcuts } from "./utils/utils";
import { getInvitationData } from "./redux/actions/InvitationAction";
import { userProfileData } from "./redux/actions/profileAction/userProfileAction";
import NewRt from "./pages/RoundTable/NewRt";
import { deviceInfoUpdatedSuccess } from "./redux/actions/authAction";
import { updateNavigateCheckLoading } from "./redux/actions/createEditRoundtable";
import { getMeetupEncryptionKeyToken } from "./redux/actions/meetupAction/meetupAction";
export let allWords = langFunc();

function App() {

  const tagManagerArgs = {
    gtmId: gtmIdString,
  };
  const navigate = useNavigate();
  useEffect(() => {
    // navigate("roundtable/list")
  }, []);
  const loc = useLocation();
  const [search_params] = useSearchParams();
  let current_user = localStorage.current_user
    ? JSON.parse(localStorage.current_user)
    : null;

  useEffect(() => {
    if (
      localStorage.getItem("current_user") &&
      !JSON.parse(localStorage?.current_user)?.["display_language"]
    ) {
      let data = JSON.parse(localStorage.getItem("current_user"));
      data.display_language = "en";
      localStorage.setItem("current_user", JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    if (search_params.get("df") == "t") {
      moengageEvent("View Page", "ALL", {
        URL: `${window.location.origin}/app?name=dulitfest`,
        "Custom Name": search_params.get("df") == "t" ? "dulitfest" : "",
      });
    }
  }, [search_params]);

  const dispatch = useDispatch();

  // GTM Initialization
  useEffect(() => {
    if (!location.href.includes("/roundtable?id=")) {
      dispatch(updateNavigateCheckLoading(false));
    }
    TagManager.initialize(tagManagerArgs);
    axios.interceptors.response.use(
      async (response) => {
        if (response?.status === 281) {
          navigate("/maintainance");
        }

        if (response?.status === 502) {
          const apiData = {
            url: MAINTAINANCE_URL,
            method: "GET",
            headers: {
              "x-api-key": MAINTAINANCE_KEY,
            },
          };

          try {
            const res = await axios(apiData);

            if (res?.status === 200) {
              return navigate("/maintainance");
            }

            return response;
          } catch (e) {
            return response;
          }
        }

        return response;
      },
      async (error) => {
        return Promise.reject(error);
      }
    );

    // making call to generate and get the encryption key for meetup redirection
    dispatch(getMeetupEncryptionKeyToken());
  }, []);

  let { logo } = globalImages;

  function showNotification(body) {
    let options = {
      body: body,
      icon: { logo },
      dir: "ltr",
    };
  }
  // kk new
  const langChangedApp = useSelector(
    (state) => state.languageChange.langChanged
  );

  useEffect(() => {
    if (langChangedApp == true) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [langChangedApp]);

  function firebase_actions() {
    //FOR PUSH NOTIFICATIONS
    messaging &&
      messaging
        ?.getToken({ vapidKey: REACT_APP_VAPID_ID })
        .then((currentToken) => {
          let current_user = localStorage?.current_user;
          current_user && (current_user = JSON.parse(current_user));
          let data = {};
          if (current_user?.access) {
            data = {
              access: current_user?.access,
              action: "save",
              token: currentToken,
            };
            if (
              currentToken &&
              localStorage?.current_user &&
              current_user?.access
            ) {
              let device_ids = current_user["device_ids"];
              if (device_ids?.length) {
                let temp = [];
                device_ids?.forEach((item) => {
                  temp.push(item["firebase_token"]);
                });
                if (!temp.includes(currentToken)) {
                  dispatch(tokensState(data));
                }
              }
            } else {
              dispatch(tokensState(data));
            }
          }
        })
        .catch();
  }

  useEffect(() => {
    allWords = langFunc();
  }, [localStorage.getItem("current_user"), langChangedApp]);

  // for fetching images

  const fetchImages = async () => {
    let existingImages = localStorage.getItem("global_images")
      ? JSON.parse(localStorage.getItem("global_images"))
      : null;

    updatedImagesToShow(existingImages ? existingImages : localImages);

    let res = {};
    if (localStorage?.current_user || localStorage?.anonymous_user) {
      // integrate api to fetch new version & flag
      res = await checkIfImagesNeedsToBeChanged();
      dispatch(deviceInfoUpdatedSuccess(true));
    }

    let data = res?.data?.data[0];
    // Function will not be called for anonymous user
    if (!localStorage?.current_user && localStorage?.anonymous_user) return;

    if (data?.is_invited) dispatch(getInvitationData());

    if (res?.status !== 200) {
      updatedImagesToShow(localImages);
      return;
    }

    // if !flag, updateImagesToShow(imagesPath) localstorage.removeItem('imagesPath') return
    if (res.data.file_updated * 1 === 0) {
      updatedImagesToShow(localImages);
      localStorage.getItem("global_images") &&
        localStorage.removeItem("global_images");
      return;
    }

    // if flag && existingImage?.version === newversion, return

    const newGlobalImagesVersion =
      res.data?.json_file_path &&
      res.data.json_file_path?.split("_")?.[1].split(".")?.[0];

    if (
      res?.data?.file_updated &&
      localStorage.getItem("global_images") &&
      JSON.parse(localStorage.getItem("global_images"))?.version ===
      newGlobalImagesVersion
    ) {
      return;
    }

    // fetch new json & convert array to oject
    const newJsonImagesResponse = await fetchNewImages(data?.json_file_path);
    const imagesData = newJsonImagesResponse?.data?.static_images;

    if (imagesData) {
      const newImages = convertToObj(imagesData);

      // set localstorage images path
      localStorage.setItem(
        "global_images",
        JSON.stringify({
          ...newImages,
          version: newGlobalImagesVersion,
        })
      );

      updatedImagesToShow(newImages);
    }
  };

  useEffect(() => {
    fetchImages();
    firebase_actions();
    stopKeyboardShortcuts();
  }, []);
  useEffect(() => {
    if (current_user?.username) {
      dispatch(userProfileData(current_user?.username));
    }
  }, []);
  useEffect(() => {
    if (
      !window.location.pathname.includes("roundtable") &&
      !window.location.pathname.includes("recording")
    ) {
      document.addEventListener(
        "play",
        function (e) {
          var audios = document.getElementsByTagName("audio");
          for (var i = 0, len = audios.length; i < len; i++) {
            if (audios[i] != e.target) {
              audios[i].pause();
            }
          }
        },
        true
      );
      document.addEventListener(
        "play",
        function (e) {
          var audios = document.getElementsByTagName("video");
          for (var i = 0, len = audios.length; i < len; i++) {
            if (audios[i] != e.target) {
              audios[i].pause();
            }
          }
        },
        true
      );
    }
    if (window.location.pathname === "/roundtable/join") {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    }
  }, []);

  const privateRoutes = routes.map(({ path, component: Component }, key) => (
    <Route
      element={
        <PrivateRouteComponent>
          <Component />
        </PrivateRouteComponent>
      }
      exact
      path={path}
      key={path}
    />
  ));

  const publicRoutes = publicRoute.map(({ path, component }, key) => (
    <Route
      exact
      path={path}
      element={<PublicRoutesComp component={component} path={path} />}
      key={path}
    />
  ));

  const appComponent = () => (
    <SuperParent>
      <GlobalStyle />
      <NewRt />
      <Routes>{[...privateRoutes, ...publicRoutes]}</Routes>
    </SuperParent>
  );

  return (
    <>
      {appComponent()}
    </>
  );
}

export default App;
