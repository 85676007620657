import { Helmet } from "react-helmet";
import {
  REACT_APP_BASE_URL_CLOUDFRONT,
  REACT_APP_DOMAIN_NAME
} from "../constants/env";

export const MetaTagsGenerator = ({ metaTags }) => {
  return (
    <Helmet>
      <title>{metaTags.title}</title>
      <meta name="title" content={metaTags.title} />
      <meta name="description" content={metaTags.description} />
      <meta name="keywords" content={metaTags.keywords} />
      <meta property="og:title" content={metaTags.title} />
      <meta
        property="og:image"
        content={
          metaTags.image ||
          `${REACT_APP_BASE_URL_CLOUDFRONT}/assets/images/MetaImage.png`
        }
      />
      <meta property="og:description" content={metaTags.description} />
      <meta property="og:type" content={REACT_APP_DOMAIN_NAME} />
      <meta name="twitter:title" content={metaTags.title} />
      <meta name="twitter:description" content={metaTags.description} />
    </Helmet>
  );
};
