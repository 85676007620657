import { Box, Button, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import "./styles.css";
import { allWords } from "../../App";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#F4F4F4 !important",
  },
  [`&.${tableCellClasses.body}`]: {
    // font: "normal normal 400 18px/21px Work Sans",
  },
  borderBottom: "0px !important",
  color: "#F4F4F4 !important",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#333333 !important",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AccordianContent = ({ rows }) => {
  return (
    <div
      className="accordian-content"
      data-test="src-components-ProfileEarnPointsAndBadges-index-0"
    >
      <ul data-test="src-components-ProfileEarnPointsAndBadges-index-1">
        <li data-test="src-components-ProfileEarnPointsAndBadges-index-2">
          {allWords.profilePoint.question}
          <p data-test="src-components-ProfileEarnPointsAndBadges-index-3">
            {allWords.profilePoint.answer}
          </p>
        </li>
        <li data-test="src-components-ProfileEarnPointsAndBadges-index-4">
           {allWords.profilePoint.question}  
          <p data-test="src-components-ProfileEarnPointsAndBadges-index-5">
            {allWords.profilePoint.answer}
          </p>
        </li>
      </ul>
      <TableContainer
        component={Paper}
        className="BudgeTable"
        style={{ backgroundColor: "#25252580" }}
      >
        <Table sx={{ minWidth: 400 }}>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow
                key={index}
                data-test="src-components-ProfileEarnPointsAndBadges-index-6"
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  data-test="src-components-ProfileEarnPointsAndBadges-index-7"
                >
                  {row.title}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  data-test="src-components-ProfileEarnPointsAndBadges-index-8"
                >
                  {row.tag}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const EarnPointBadges = () => {
  const [activeAccord, setActiveAccord] = useState(null);

  const handleAccordClick = (title) => {
    setActiveAccord(activeAccord === title ? null : title);
  };

  const accordianItems = [
    { title: allWords.profilePoint.points.title, content: <PointsAccordianContent /> },
    { title: allWords.profilePoint.levels.title, content: <LevelsAccordianContent /> },
    { title: allWords.profilePoint.badges.title, content: <BadgesAccordianContent /> },
  ];

  return (
    <div
      className="earn-point-wrapper"
      data-test="src-components-ProfileEarnPointsAndBadges-index-9"
    >
      <div
        className="wrapper"
        data-test="src-components-ProfileEarnPointsAndBadges-index-10"
      >
        {accordianItems.map(({ title, content }, index) => (
          <React.Fragment>
            <Box
              sx={{
                backgroundColor:
                  activeAccord === title ? "#66b984 !important" : "",
              }}
              className={`content ${activeAccord === title ? "active-accrodian" : ""}`}
              key={index}
              onClick={() => handleAccordClick(title)}
              data-test="src-components-ProfileEarnPointsAndBadges-index-11"
            >
              <Typography
                sx={{ color: activeAccord === title ? "#FFFFFF" : "#F4F4F4" }}
                data-test="src-components-ProfileEarnPointsAndBadges-index-12"
              >
                {title}
              </Typography>
              {activeAccord === title ? (
                <KeyboardArrowUpIcon
                  sx={{ marginLeft: "auto", marginRight: "16px" }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{ marginLeft: "auto", marginRight: "16px" }}
                />
              )}
            </Box>
            {activeAccord === title && content}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const PointsAccordianContent = () => {
  const [activePointTab, setActivePointTab] = useState("RoundTable");

  const tabs = [allWords.profile.tabs.RoundTable, allWords.profile.tabs.Latest, allWords.profile.tabs.MeetUp, allWords.profile.tabs.Others];

  return (
    <div
      className="accordian-content"
      data-test="src-components-ProfileEarnPointsAndBadges-index-13"
    >
      <ul data-test="src-components-ProfileEarnPointsAndBadges-index-14">
        <li data-test="src-components-ProfileEarnPointsAndBadges-index-15">
          {allWords.profilePoint.question}
          <p data-test="src-components-ProfileEarnPointsAndBadges-index-16">
            {allWords.profilePoint.answer}
          </p>
        </li>
        <li data-test="src-components-ProfileEarnPointsAndBadges-index-17">
          {allWords.profilePoint.question}
          <p data-test="src-components-ProfileEarnPointsAndBadges-index-18">
          {allWords.profilePoint.answer}
          </p>
        </li>
      </ul>
      <div
        className="points-tab"
        style={{ display: "flex", alignItems: "center" }}
        data-test="src-components-ProfileEarnPointsAndBadges-index-19"
      >
        {tabs.map((item, index) => (
          <Button
            variant="outlined"
            sx={{
              borderRadius: "15px !important",
              background: activePointTab === item ? "#E4E9F1" : "#11141C",
              color: activePointTab === item ? "#000000" : "#F4F4F4",
              marginBottom: "15px !important",
              marginRight: "25px !important",
              textTransform: "none !important",
              padding: "8px 14px !important",
              border: "1px solid #c3c3c3 !important",
            }}
            key={index}
            onClick={() => setActivePointTab(item)}
            data-test="src-components-ProfileEarnPointsAndBadges-index-20"
          >
            {item}
          </Button>
        ))}
      </div>
      {activePointTab === "RoundTable" && (
        <AccordianContent
          rows={[
            { title: allWords.profile.roundTable.CreateRoundTable, tag: "+10" },
            { title: allWords.profile.roundTable.AttendModerator, tag: "+40" },
            { title: allWords.profile.roundTable.AttendPanelist, tag: "+25" },
            { title: allWords.profile.roundTable.AttendAsInvitedAudience, tag: "+10" },
            { title: allWords.profile.roundTable.RandomAudience, tag: "+5" },
            { title: allWords.profile.roundTable.OwnerandModerator, tag: "+60" },
            { title: allWords.profile.roundTable.OwnerAndPanelist, tag: "+50" },
            { title: allWords.profile.roundTable.WatchTheRounTable, tag: "+5" },
            { title: allWords.profile.roundTable.AutoPlayRoundTable, tag: "+2" },
          ]}
        />
      )}
      {/* Add other tab contents here */}
    </div>
  );
};

const LevelsAccordianContent = () => {
  return (
    <AccordianContent
      rows={[
        { title: "Level 1", tag: "+10" },
        { title: "Level 2", tag: "+50" },
        { title: "Level 3", tag: "+100" },
        { title: "Level 4", tag: "+250" },
        { title: "Level 5", tag: "+500" },
        { title: "Level 6", tag: "+1000" },
        { title: "Level 7", tag: "+2500" },
        { title: "Level 8", tag: "+5000" },
        { title: "Level 9", tag: "+10000" },
        { title: "Level 10", tag: "+25000" },
        { title: "Level 11", tag: "+50000" },
        { title: "Level 12", tag: "+100000" },
        { title: "Level 13", tag: "+250000" },
        { title: "Level 14", tag: "+500000" },
        { title: "Level 15", tag: "+1000000" },
      ]}
    />
  );
};

const BadgesAccordianContent = () => {
  return (
    <AccordianContent
      rows={[
        { title: allWords.profile.roundTable.CreateRoundTable, tag: "Creator" },
        { title: allWords.profile.roundTable.AttendModerator, tag: "Moderator" },
        { title: allWords.profile.roundTable.AttendPanelist, tag: "Panelist" },
        { title: allWords.profile.roundTable.AttendAsInvitedAudience, tag: "Audience" },
        { title: allWords.profile.roundTable.WatchRoundTable, tag: "Viewer" },
      ]}
    />
  );
};

export default EarnPointBadges;
