import styled from "styled-components";
import { IconButton, Avatar } from "@mui/material";

export const PostDiv = styled.div`
  width: 100%;
  /* min-height: 400px; */
  border-radius: 0.5rem;
  /* border: 1px solid red; */
  margin-top: 1rem;
  padding: 0.2rem;
  cursor: pointer;
  color: #FFFFFF;
`;
export const Header = styled.div`
  width: 100%;
  padding: 0.2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.7rem;
`;
export const SubHeaderMain = styled.div`
  display: flex;
  align-items: center;
`;
export const UserNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* border: 1px solid pink; */
  opacity: 0.8;

  width: max-content;
  .dot_icon {
    opacity: 0.8;
    margin-top: 0.4rem;
    font-size: 12px;
  }
  p {
    opacity: 0.8;
  }
`;

export const UserAvatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 0;
`;

export const FollowButton = styled.button`
  min-height: 32px;
  border: none;
  border-radius: 0.3rem;
  text-align: center;
  font-family: normal normal normal 16px/19px Work Sans;
  letter-spacing: 0px;
  /* color: ${(props) => props.theme.color.black}; */
  color: #1a1a1a;
  // background-color: rgba(255, 255, 255, 0.2);
  background-color: white;
  cursor: pointer;
  /* opacity: 0.2; */
  /*  background-color: ${(props) => props.theme.color.white}; */
  font-family: normal normal normal 16px/19px Work Sans;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 600;
  :hover {
    /* background-color: ${(props) => props.theme.color.white}; */
    /* color: ${(props) => props.theme.color.primary}; */
    color: #000000;
  }
`;

export const MenuContainer = styled(IconButton)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px !important;
`;

export const Menu = styled(Avatar)`
  width: 30px !important;
  height: 30px !important;
`;
export const Body = styled.div`
  width: 100%;
`;

export const PostTitle = styled.p`
  line-height: 1.6;
  font-family: "Work Sans";
  font-size: ${(props) => props.theme.font.small};
  word-wrap: break-word;
  white-space: pre-wrap;
  margin: 0rem 1rem
`;
export const PostContent = styled.div`
  margin-top: 0.4rem;
`;

export const PostImgContainer = styled.img`
/* border: 0.1px solid red; */
/* min-height: 220px; */
/* height: 100%; */
width: auto;
max-width: 100%;
  cursor: pointer;
  max-height: 60vh;
  height: 100%;
  object-fit: cover;
  border: 1px solid rgba(162, 162, 163, 0.5);
`;
export const PostMultiImgContainer = styled.img`
 
  cursor: pointer;
  width: 98%;
  height: 205px;
  aspect-ratio: 1;
  object-fit: cover;
  border: 1px solid rgba(162, 162, 163, 0.5);
  /* border: 0.1px solid blue; */
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
`;

export const PostAudioContainer = styled.div`
  .img {
    width: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
    max-height: 300px;
  }
`;

export const PollingContainer = styled.div`
  width: 100%;
  height: 40px;
  margin-top: 1rem;
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : null};
  align-items: center;
  cursor: pointer;
  border: ${(props) => (props.border ? "2px solid lightblue" : null)};
  // background-color: ${(props) => (props.bgColor ? props.bgColor : null)};
  border-radius: 0.5rem;
`;

export const PollingInnerContainer = styled.div`
  width: ${(props) =>
    props.width
      ? props.width === "0"
        ? "0.3rem"
        : props.width + "%"
      : "0.3rem"};
  height: 40px;
  margin-top: 1px;
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : null};
  align-items: center;
  cursor: pointer;
  border: ${(props) => (props.border ? "2px solid lightblue" : null)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : null)};
  border-radius: 0.5rem;
  position: relative;
  left: 0px;
`;
