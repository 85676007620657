import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Grid, DialogActions } from "@mui/material";
import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import PhotoIcon from "../IconsComponents/PhotoIcon";
import CloseIcon from "@mui/icons-material/Close";
import EditImage from "../../components/Post/AddPostDialog/EditImage";
import UserListInput from "../../components/UserListInput/userListInput";
import UserProfile from "../../components/UserProfile";
import ImageContainer from '../../components/Post/AddPostDialog/ImageContainer';
import { allWords } from "../../App";

const useStyles = makeStyles({
  dialogPaper: {
    width: '100%',
    background: '#212121',
    borderRadius: '20px',
    opacity: '1',
    '@media (max-width: 768px)': {
      width: '90%', // Adjust width for smaller screens
      height: 'auto', // Allow height to adjust according to content
      maxHeight: '80vh', // Limit height to 80% of viewport height
    },
  },
  title: {
    textAlign: 'left',
    fontFamily: 'Work Sans',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: '1',
    '@media (max-width: 768px)': {
      width: '100%', // Make title full width for smaller screens
    },
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    opacity: '1',
    position: 'absolute',
    top: '12px', // Adjust the top position to center vertically
    right: '12px',
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    opacity: '1',
    position: 'absolute',
    top: '12px', // Adjust the top position to center vertically
    right: '12px', // Move the icon to the right
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between', // Items are evenly distributed
    marginTop: '1.2rem'
  },
  actionItem: {
    display: 'flex',
    alignItems: 'center',

  },
  actionImage: {
    width: '48px',
    height: '48px',
    background: '#373737', // Set background color for the circular background
    borderRadius: '50%', // Make the circular background round
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden', // Ensure the image does not exceed the bounds of the circle
    marginRight: '12px'
  },
  imageIcon: {
    width: '60%', // Adjust the size of the image
    height: 'auto', // Maintain aspect ratio
    color: '#FFFFFF', // Icon color
  },
  actionText: {
    textAlign: 'left',
    fontFamily: 'Work Sans',
    fontWeight: 'medium',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: '1',
  },
  input: {
    borderBottom: '1px solid #F4F4F4',
    width: '100%',
    height: '19px',
    textAlign: 'left',
    font: 'normal normal normal 16px/20px Work Sans',
    letterSpacing: '0px',
    color: '#F4F4F4',
    opacity: 0.5,
    '&:focus': {
      borderBottom: '1px solid #F4F4F4', // Adjust the focus style as needed
    },
  },
  customButton: {
    width: '108px',
    height: '32px',
    background: '#F4F4F4',
    textAlign: 'center',
    font: "normal normal medium 14px / 36px",
    letterSpacing: "0px",
    color: "#0E0817",
    borderRadius: '60px',
    opacity: '1',
    '@media (max-width: 768px)': {
      width: '100%', // Full width for smaller screens
    },
  },
  iconButton: {
    paddingRight: "2rem", // Default padding
    "@media (max-width: 600px)": {
      paddingRight: "1rem", // Adjust padding for smaller screens
    },
  },
});

const Button = styled.button`
  width: 200px;
  height: 50px;
  outline: none;
  border-radius: 5px;
  color: ${(props) => (props.primary ? props.theme.color.primary : "white")};
  background-color: ${(props) =>
    props.bgColor ? props.theme.color.secondary : "white"};
  border: 2px solid
    ${(props) =>
    props.primary ? props.theme.color.primary : props.theme.color.secondary};
`;

function AddMediaDialog({
  isOpen,
  handleShowCaseImageChange,
  editImage,
  setEditImage,
  imgArray,
  setImgArray,
  imgSrc,
  editImageRef,
  isShowCaption,
  isClose,
  textInput,
  setTextInput,
  textCount,
  setTextCount,
  inputRef,
  setImgSrc,
  createShowcase
}) {

  const classes = useStyles();
  const handleCrop = async () => {
    editImageRef.current?.setCroppedImage();
    setEditImage(false);
  };

  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: "0.8rem",
        width: 600,
        color: "#fff",
        background: "#242424",
      },
    }} open={isOpen}>
      <DialogTitle>
        {allWords.profileShowcase.addShowcase}
        <IconButton aria-label="close" onClick={isClose} style={{ position: "absolute", right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          className="main_container"
          style={{ maxWidth: 600 }}
          spacing={2}
        >
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              width: "100%",
              alignItems: "start",
            }}
          >
            {!editImage && (
              <>
                &emsp;
                <UserProfile
                  username={
                    JSON.parse(
                      localStorage.current_user ||
                      localStorage.anonymous_user
                    )?.username
                  }
                />
                {/* &emsp; */}
                <div
                  style={{
                    width: "26.4rem",
                    marginLeft: "0.5rem",
                    height: "9rem",
                    borderRadius: "8px",
                    padding: "10px",
                    marginBottom: "1rem",
                  }}
                >
                  <UserListInput
                    name="add dialog"
                    placeholder={allWords.th.placeholder}
                    className="post-area addDialogCSs"
                    refs={inputRef}
                    maxLength={textCount === 0 ? 300 : ""}
                    value={textInput}
                    listDirection="bottom"
                    onChange={(e) => {
                      const txt = e.target.value;
                      const urlRegex = /\bhttps?:\/\/\S+/gi;
                      let charCount;

                      const allLinks = txt.match(urlRegex);

                      if (allLinks) {
                        const allLinksSize = allLinks.join("").length;
                        charCount =
                          txt.length - allLinksSize + allLinks.length * 4;
                      } else {
                        charCount = txt.length;
                      }

                      if (charCount <= 300) {
                        setTextCount(charCount);
                      } else {
                        setTextCount(300);
                      }

                      if (charCount <= 300) {
                        setTextInput(e.target.value);
                      } else {
                        if (allLinks) {
                          const allLinksSize = allLinks.join("").length;
                          charCount =
                            charCount + allLinksSize - allLinks.length * 4;
                        } else {
                          charCount = 300;
                        }

                        setTextInput(txt.slice(0, charCount));
                      }

                      localStorage.setItem("input_text", e.target.value);
                    }}
                  />
                  <p
                    style={{ margin: 0, opacity: "0.6", textAlign: "end" }}
                  >
                    {textCount >= 0 && textCount <= 300
                      ? 300 - textCount
                      : 300}
                    /300
                  </p>
                </div>
              </>
            )}
          </div>

          {editImage
            ?
            <EditImage
              imageSrc={imgSrc}
              style={{
                width: "100%",
              }}
              setImgArray={setImgArray}
              ref={editImageRef}
              isObj={true}
            />
            :
            <>
              {imgArray && (
                <>
                  {imgArray.map((item, index) => {
                    return (
                      <>
                        <Grid
                          item
                          md={imgArray.length < 2 ? 12 : 6}
                          style={index < 2 ? { paddingTop: "0" } : {}}
                          key={item}
                        >
                          <ImageContainer
                            imageSrc={imgSrc}
                            setImageSrc={setImgSrc}
                            editImage={editImage}
                            setEditImage={setEditImage}
                            imgArray={imgArray}
                            setImgArray={setImgArray}
                            index={index}
                            imgSrc={item}
                            isShowCaption={isShowCaption}
                          />
                        </Grid>
                      </>
                    );
                  })}
                </>
              )}
            </>
          }

        </Grid>
      </DialogContent>
      <DialogActions>
        {!editImage && (
          <>
            <div>
              <IconButton
                variant="contained"
                component="label"
                style={{ paddingRight: "27rem" }}
              >
                <PhotoIcon />
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleShowCaseImageChange}
                />
              </IconButton>
            </div>
            <Button
              disabled={imgArray?.length === 0}
              className={classes.customButton}
              onClick={() => createShowcase({ type: 'media', media: imgArray, title: textInput })}
            >
              {allWords.th.create}
            </Button>
          </>
        )}
        
        {editImage && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              gap: "2rem",
              alignItems: "center",
            }}
          >
            <Button
              primary
              onClick={() => {
                setEditImage(false);
              }}
            >
              {allWords.misc.cancel}
            </Button>
            <Button bgColor onClick={handleCrop}>
              {allWords.misc.save}
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AddMediaDialog;
