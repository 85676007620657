// Third-party imports
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

// Local component imports
import AddMediaDialog from "./AddMediaDialog";
import AddLinkDialog from "./AddLinkDialog";
import { fetchShowcaseData } from "../../redux/actions/profileAction/showcasAction";

import { allWords } from "../../App";
import logger from "../../logger";
import { createShowcaseApi } from "../../apis/profileApi";
import createLinkImg from "../../assets/images/createLink.svg";
import createPostImg from "../../assets/images/createPost.svg";
import createMediaImg from "../../assets/images/createMedia.svg";

// Utility imports
import ToastHandler from "../../utils/ToastHandler";

// Style import
import "./style.css";

const useStyles = makeStyles({
  dialogPaper: {
    width: "100%",
    background: "#212121",
    borderRadius: "20px",
    opacity: "1",
    "@media (max-width: 768px)": {
      width: "90%", // Adjust width for smaller screens
      height: "auto", // Allow height to adjust according to content
      maxHeight: "80vh", // Limit height to 80% of viewport height
    },
  },
  title: {
    textAlign: "left",
    fontFamily: "Work Sans",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
    "@media (max-width: 768px)": {
      width: "100%", // Make title full width for smaller screens
    },
  },
  closeIcon: {
    width: "24px",
    height: "24px",
    opacity: "1",
    position: "absolute",
    top: "12px", // Adjust the top position to center vertically
    right: "12px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-between", // Items are evenly distributed
    marginTop: "1.2rem",
  },
  actionItem: {
    display: "flex",
    alignItems: "center",
  },
  actionImage: {
    width: "48px",
    height: "48px",
    background: "#373737", // Set background color for the circular background
    borderRadius: "50%", // Make the circular background round
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden", // Ensure the image does not exceed the bounds of the circle
    marginRight: "12px",
  },
  imageIcon: {
    width: "60%", // Adjust the size of the image
    height: "auto", // Maintain aspect ratio
    color: "#FFFFFF", // Icon color
  },
  actionText: {
    textAlign: "left",
    fontFamily: "Work Sans",
    fontWeight: "medium",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
  },
  input: {
    borderBottom: "1px solid #F4F4F4",
    width: "100%",
    height: "19px",
    textAlign: "left",
    font: "normal normal normal 16px/20px Work Sans",
    letterSpacing: "0px",
    color: "#F4F4F4",
    opacity: 0.5,
    "&:focus": {
      borderBottom: "1px solid #F4F4F4", // Adjust the focus style as needed
    },
  },
  customButton: {
    width: "108px",
    height: "32px",
    background: "#F4F4F4",
    textAlign: "center",
    font: "normal normal medium 14px / 36px",
    letterSpacing: "0px",
    color: "#0E0817",
    borderRadius: "60px",
    opacity: "1",
    "@media (max-width: 768px)": {
      width: "100%", // Full width for smaller screens
    },
  },
});

function AddShowcaseModal({ isOpen, onOpenChange, HighlightsUser = {} }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editImage, setEditImage] = useState(false);
  const [textCount, setTextCount] = useState(0);
  const [textInput, setTextInput] = useState("");
  const [imgArray, setImgArray] = useState([]);
  const [imgSrc, setImgSrc] = useState({
    index: null,
    url: null,
    file: null,
  });
  const [isMediaAddDialogOpen, setIsMediaAddDialogOpen] = useState(false);

  const [link, setLink] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [ogImage, setOGImage] = useState(null);
  const [isLinkAddDialogOpen, setIsLinkAddDialogOpen] = useState(false);
  const [isOgImageLoading, setIsOgImageLoading] = useState(false);

  const editImageRef = useRef();
  const inputRef = useRef();

  const [debouncedLink] = useDebounce(link, 1000);

  const handleShowCaseImageChange = (e) => {
    setIsMediaAddDialogOpen(true);
    const imageFile = e.target.files[0];
    if (imageFile) {
      for (let i = 0; i < e.target.files.length; i++) {
        let size = e.target.files[i].size;
        if (Math.round(size / 1024) > 1024 * 15) {
          ToastHandler("warn", "Upload an image less than 15 MB.");
          setIsMediaAddDialogOpen(false);
          e.target.value = null;
          return false;
        }
      }
    }

    if (!imageFile) {
      ToastHandler("warn", "Please select image.");
      setImgKey((prevkey) => prevkey + 1);
      setIsMediaAddDialogOpen(false);
      return false;
    }
    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      ToastHandler("warn", allWords.misc.toastMsg.invalidImgFormat);
      setImgKey((prevkey) => prevkey + 1);

      return false;
    }
    const images = e.target.files;

    if (images.length <= 3) {
      if (imgArray.length === 3) {
        ToastHandler("warn", allWords.profileShowcase.only3ImageAllowed);
      } else {
        if (e.target.files) {
          const file = Array.from(e.target.files).map((file) => {
            return {
              file: file,
              url: URL.createObjectURL(file),
            };
          });

          setImgArray((prevImg) => prevImg.concat(file));
        } else {
          setImgArray([]);
        }
      }
    } else {
      ToastHandler("warn", allWords.profileShowcase.only3ImageAllowed);
    }

    e.target.value = null;
  };

  const fetchOGImage = async (url) => {
    try {
      setIsOgImageLoading(true);
      const response = await fetch(url);
      const html = await response.text();
      const ogImageRegex =
        /<meta[^>]*property="og:image"[^>]*content="([^"]*)"[^>]*>/i;
      const match = html.match(ogImageRegex);

      if (match && match.length >= 2) {
        const imageUrl = match[1];
        setOGImage(imageUrl);
      } else {
        setOGImage(null);
      }
      setIsOgImageLoading(false);
    } catch (error) {
      setIsOgImageLoading(false);
      setOGImage(null);
      logger.error(error);
    }
  };

  useEffect(() => {
    if (debouncedLink) {
      fetchOGImage(debouncedLink);
    }
  }, [debouncedLink]);

  const handleLinkChange = async (event) => {
    const newLink = event.target.value;

    const isValid = validateLink(newLink);
    setIsValid(isValid);
    setLink(newLink);
  };

  const validateLink = (link) => {
    // Regular expression to check if the link is a valid URL
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(link);
  };

  const handAddPostClick = (e) => {
    navigate("/showcase");
  };

  const handleClose = () => {
    onOpenChange(false);
    setIsMediaAddDialogOpen(false);
    setIsLinkAddDialogOpen(false);
    setLink("");
    setIsValid(false);
    setEditImage(false);
    setImgSrc({
      index: null,
      url: null,
      file: null,
    });
    setTextInput("");
    setTextCount(0);
    setImgArray([]);
    setOGImage(null);
  };

  const createShowcase = async ({ type, title, caption, link, media }) => {
    try {
      await createShowcaseApi({ type, title, caption, link, media });
      if (HighlightsUser?._id) {
        dispatch(fetchShowcaseData(HighlightsUser._id));
      }
      handleClose();
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle className={classes.title}>
          {allWords.profileShowcase.addShowcase}
          <IconButton onClick={handleClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className={classes.actionContainer}>
            <div className={classes.actionItem} style={{ cursor: "pointer" }}>
              <label
                htmlFor="imageInput"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div className={classes.actionImage}>
                  <img
                    src={createMediaImg}
                    alt="Create Media"
                    className={classes.imageIcon}
                  />
                </div>
                <Typography className={classes.actionText}>
                  Add Media
                </Typography>
              </label>
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                multiple
                hidden
                onChange={handleShowCaseImageChange}
              />
            </div>
            <div
              className={classes.actionItem}
              style={{ cursor: "pointer" }}
              onClick={handAddPostClick}
            >
              <label style={{ cursor: "pointer" }}>
                <div className={classes.actionImage}>
                  <img
                    src={createPostImg}
                    alt="Create Post"
                    className={classes.imageIcon}
                  />
                </div>
              </label>
              <Typography className={classes.actionText}>Add a Post</Typography>
            </div>
            <div
              className={classes.actionItem}
              onClick={() => setIsLinkAddDialogOpen(true)}
              style={{ cursor: "pointer" }}
            >
              <label style={{ cursor: "pointer" }}>
                <div className={classes.actionImage}>
                  {/* <PhotoIcon className={classes.imageIcon} /> */}
                  <img
                    src={createLinkImg}
                    alt="Create Link"
                    className={classes.imageIcon}
                  />
                </div>
              </label>
              <Typography className={classes.actionText}>Add a Link</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions />
        {isMediaAddDialogOpen && (
          <AddMediaDialog
            editImage={editImage}
            setEditImage={setEditImage}
            imgArray={imgArray}
            setImgArray={setImgArray}
            setImgSrc={setImgSrc}
            imgSrc={imgSrc}
            isOpen={isMediaAddDialogOpen}
            editImageRef={editImageRef}
            isShowCaption={false}
            isClose={handleClose}
            inputRef={inputRef}
            textInput={textInput}
            setTextInput={setTextInput}
            textCount={textCount}
            setTextCount={setTextCount}
            handleShowCaseImageChange={handleShowCaseImageChange}
            createShowcase={createShowcase}
          />
        )}
        {isLinkAddDialogOpen && (
          <AddLinkDialog
            isOpen={isLinkAddDialogOpen}
            onClose={handleClose}
            link={link}
            handleLinkChange={handleLinkChange}
            isValid={isValid}
            createShowcase={createShowcase}
            ogImage={ogImage}
            isOgImageLoading={isOgImageLoading}
          />
        )}
      </Dialog>
    </div>
  );
}

export default AddShowcaseModal;
