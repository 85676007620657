import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ToastHandler from "../../utils/ToastHandler";
import PreloginComp from "../../components/PreLoginComp";
import RoundTableIconActive from "../../assets/icons/RoundTable_icon_active.svg";

const NewRtEvent = {
  minimized: "minimized",
  maximize: "maximized",
  navigate: "navigate",
  toast: "toast",
  collapsed: "collapsed",
  expand: "expand",
  closed: "closed",
  loginRequired: "loginRequired",
};

export default function NewRt() {
  const navigate = useNavigate();

  const [rtId, setRtId] = useState();

  const pathname = window.location.pathname;

  const [minimized, setMinimized] = useState(false);

  const [openLogin, setOpenLogin] = useState(false);

  const [minimizedPosition, setMinimizedPosition] = useState({
    bottom: 0,
    right: 0,
  });

  const [minimizedDimention, setMinimizedDimentions] = useState({
    height: 300,
    width: 500,
  });

  const resetRtConstraints = () => {
    setMinimizedDimentions({
      height: 300,
      width: 500,
    });
    setMinimizedPosition({
      bottom: 0,
      right: 0,
    });
  };

  useEffect(() => {
    if (pathname !== "/roundtable/join") return;

    const joinRt = localStorage.getItem("join_rt");

    if (!joinRt) {
      navigate("/roundtable/all");
      return;
    }

    const { channelName, rt_id } = JSON.parse(joinRt || "{}");

    setRtId(channelName || rt_id);
  }, [pathname]);

  const iframeMessageHandler = (event) => {
    let data = event.data;
    if (data) {
      try {
        data = JSON.parse(data);
      } catch (err) {
        // console.error(err);
      }
    }

    switch (data.event) {
      case NewRtEvent.minimized:
        setMinimized(true);
        resetRtConstraints();
        navigate("/roundtable/all");
        break;
      case NewRtEvent.maximize:
        resetRtConstraints();
        navigate("/roundtable/join");
        setMinimized(false);
        break;
      case NewRtEvent.navigate:
        navigate(data.endpoint);
        break;
      case NewRtEvent.toast:
        ToastHandler(data.status, data.message);
        break;
      case NewRtEvent.collapsed:
        setMinimizedDimentions(data.dimentions);
        break;
      case NewRtEvent.expand:
        setMinimizedDimentions(data.dimentions);
        break;
      case NewRtEvent.closed:
        resetRtConstraints();
        setMinimized(false);
        break;
      case NewRtEvent.loginRequired:
        setOpenLogin(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("message", iframeMessageHandler);

    return () => {
      window.removeEventListener("message", iframeMessageHandler);
    };
  }, []);

  // console.log("NEW RT ====", rtId, pathname, minimized);

  return (
    <>
      {!rtId || (pathname !== "/roundtable/join" && !minimized) ? (
        <></>
      ) : (
        <div
          style={{
            position: "fixed",
            bottom: minimized ? minimizedPosition.bottom : 0,
            right: minimized ? minimizedPosition.right : 0,
            width: minimized ? minimizedDimention.width : "100%",
            height: minimized ? minimizedDimention.height : "100%",
            zIndex: "10",
            cursor: minimized ? "pointer" : "auto",
            padding: "0px",
          }}
          data-test="src-pages-RoundTable-NewRt-0"
        >
          <iframe
            id="live-rt-iframe"
            src={`${window.location.origin}/roundtable/v2?id=${rtId}`}
            style={{
              pointerEvents: "all",
              width: "100%",
              height: "100%",
            }}
            allowFullScreen={true}
            allow={"microphone *; camera *; display-capture *"}
          />
        </div>
      )}
      <PreloginComp
        modalOpen={openLogin}
        setModalOpen={setOpenLogin}
        icon={
          <img
            src={RoundTableIconActive}
            alt="this is image"
            width={40}
            height={40}
            data-test="src-pages-RoundTable-NewRt-1"
          />
        }
        title={"To interact in Roundtable, Login or sign up to Khul Ke"}
        description={""}
      />
    </>
  );
}
