import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  data: [],
  profileHeader: {
    followers: 0,
    following: 0,
    location: "",
    bio: "",
    name: "",
    user_name: "",
    dob: "",
    user_id: "",
    userType: "",
    privacy_settings: [],
  },
  profilePoints: {
    user_activities_badge: {},
    user_activities_level: {},
    user_activities_points: {},
  },
  profilePointDetail: {
    badges: [],
    level: 0,
    points: 0,
    next_level_points: 0,
  },
  mutualFriends: {
    mut_foll: 0,
  },
  profilePageUserData: {
    data: {},
    loading: true,
    error: false,
    block: false,
    notFound: false,
  },
};
const userProfilerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_START:
      return {
        ...state,
      };
    case actionTypes.USER_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case actionTypes.PROFILE_HEADER_SUCCESS:
      return {
        ...state,
        profileHeader: { ...action.payload },
      };

    case actionTypes.PROFILE_POINT_CALCULATION:
      return {
        ...state,
        profilePoints: { ...action.payload },
      };

    case actionTypes.PROFILE_POINTS_DATA:
      return {
        ...state,
        profilePointDetail: { ...action.payload },
      };
    case actionTypes.USER_PROFILE_RESPONSE_DATA:
      return {
        ...state,
        profilePageUserData: { data: { ...action.payload }, loading: false },
      };
    case actionTypes.UPDATE_PROFILE_RESPONSE_LOADING:
      return {
        ...state,
        profilePageUserData: {
          ...state.profilePageUserData,
          loading: action.payload,
        },
      };
    case actionTypes.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        profilePageUserData: {
          ...state.profilePageUserData,
          error: action.payload,
        },
      };
    case actionTypes.UPDATE_PROFILE_BLOCK:
      return {
        ...state,
        profilePageUserData: {
          ...state.profilePageUserData,
          block: action.payload,
        },
      };
    case actionTypes.UPDATE_PROFILE_NOT_FOUND:
      return {
        ...state,
        profilePageUserData: {
          ...state.profilePageUserData,
          notFound: action.payload,
        },
      };
    case actionTypes.PROFILE_MUTUAL_FRIENDS:
      return {
        ...state,
        mutualFriends: action.payload,
      };
    default:
      return state;
  }
};

export const getUserProfileData = (state) =>
  state.user_profile.profilePageUserData.data;

export const getIsProfileLoading = (state) =>
  state.user_profile.profilePageUserData.loading;

  export const getIsProfileError = (state) =>
  state.user_profile.profilePageUserData.error;

  export const getIsProfileBlock = (state) =>
  state.user_profile.profilePageUserData.block;

  export const getIsProfileNotFound = (state) =>
  state.user_profile.profilePageUserData.notFound;

export default userProfilerReducer;
