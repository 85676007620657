import { TOGGLE_SIDEBAR } from "../actionTypes";

export const MAKE_FULLSCREEN = "MAKE_FULLSCREEN";
export const MAKE_SMALLSCREEN = "MAKE_SMALLSCREEN";

export const toggleSiderBarFull = () => {
  return {
    type: MAKE_FULLSCREEN,
  };
};

export const toggleSiderBarSmall = () => {
  return {
    type: MAKE_SMALLSCREEN,
  };
};
