import {
  GET_EBKK_POSTS_START,
  GET_EBKK_POSTS_SUCCESS,
  ADD_SINGLE_EBKK_POST,
  GET_EBKK_POSTS_FAILURE,
  REMOVE_SINGLE_EBKK_POST,
  SET_NO_MORE_DATA,
  LIKE_DISLIKE_EBKK_POSTS
} from "../../actions/actionTypes";

// Initial state for the K3 posts
const initialState = {
  ebkkPosts: [],
  error: null,
  loading: false,
  noMoreData: false,
};

const ebkkPostsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EBKK_POSTS_START:
      return { ...state, loading: true };

    case GET_EBKK_POSTS_SUCCESS:
      return {
        ...state,
        ebkkPosts: [...state.ebkkPosts, ...action.payload],
        error: null,
        loading: false,
      };
    case GET_EBKK_POSTS_FAILURE:
      return {
        ...state,
        ebkkPosts: [],
        error: action.payload,
        loading: false,
      };
    case ADD_SINGLE_EBKK_POST:
      return {
        ...state,
        ebkkPosts: [action.payload, ...state.ebkkPosts],
        loading: false,
      };
    case REMOVE_SINGLE_EBKK_POST:
      return {
        ...state,
        ebkkPosts: state.ebkkPosts.filter(
          (post) => post._id !== action.payload
        ),
        loading: false,
      };
    case LIKE_DISLIKE_EBKK_POSTS:
      return {
        ...state,
        ebkkPosts: state.ebkkPosts.map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        }),
      };
    case SET_NO_MORE_DATA:
      return {
        ...state,
        noMoreData: true, // Set the flag to true
        loading: false,
      };
    default:
      return state;
  }
};

export default ebkkPostsReducer;