import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonStyle, CloseStyle, IconContainer, TitleStyle } from "./style";
import CloseIcon from "../../assets/icons/close_icon_white.svg";
import CreatePost from "../../assets/icons/create_post.svg";
import CreateSnipIt from "../../assets/icons/create_snipit.svg";
// import CreateExpertView from "../../assets/icons/create_expert_view.svg";
// import CreateView from "../../assets/icons/create_view.svg";
// import CreateKhabar from "../../assets/icons/create_khabar.svg";
import CreateRT from "../../assets/icons/create_rt.svg";
import CreateMeetup from "../../assets/icons/create_meetup.svg";
import { MEET_UP_BASE_URL, MOBILE_VIEW } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { createEditRoundtableInitialize } from "../../redux/actions/createEditRoundtable";
import { allWords } from "../../App";
import moment from "moment";
import { getMeetupEncryptedTokenForRedirection } from "../../redux/reducers/meetupReducer/meetupReducer";
import { getEncryptedToken } from "../../utils/utils";

const CreateContent = ({
  open,
  setCreateContent,
  setAddPost,
  setPostParent,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const encryptionkey = useSelector(getMeetupEncryptedTokenForRedirection);

  const getCurrentUser = () => {
    try {
      return JSON.parse(localStorage?.current_user ?? "{}");
    } catch (error) {
      return {};
    }
  };

  const currentUser = getCurrentUser();
  const isExpertBkk = Boolean(currentUser?.is_expert_bkk);
  const isBKK = Boolean(currentUser?.is_bkk === 1);
  const isK3 = Boolean(currentUser?.is_k3 === 1);

  const customPaperProps = {
    sx: { borderRadius: "20px" },
    style: { backgroundColor: "#212121", color: "#FFFFFF" },
  };

  const handleClose = () => {
    setCreateContent(false);
  };

  const handleAddPost = () => {
    setCreateContent(false);
    setPostParent("POST");
    setAddPost(true);
  };

  const handleAddScrolls = () => {
    setCreateContent(false);
    setPostParent("SCROLLS");
    setAddPost(true);
  };

  //todo const handleCreateExpertView = () => {
  //   setCreateContent(false);
  //   setPostParent("SKK");
  //   setAddPost(true);
  // };

  //todo const handleAddKhabar = () => {
  //   setCreateContent(false);
  //   setPostParent("K3");
  //   setAddPost(true);
  // };

  const handleCreateRT = () => {
    setCreateContent(false);

    dispatch(
      createEditRoundtableInitialize({
        rtType: allWords.rt.label2.toLowerCase(), // live recorded
        rtPlayType: allWords.createRT.videoBtn.toLowerCase(), // video audio
        rtNature: allWords.createRT.optPub.toLowerCase(), // public, private, confidential
        rtTopic: "",
        rtDescription: "",
        dateValue: moment(new Date()).add(30, "minutes"),
        timeValue: moment(new Date()).add(30, "minutes"),
        urlRtId: null,
        wipRtId: null,
        durationHr: {
          label: `0 ${allWords.misc.livert.h}`,
          value: "0",
        },
        durationMin: {
          label: `30 ${allWords.misc.livert.m}`,
          value: "30",
        },
        durationSec: {
          label: `0 ${allWords.misc.livert.m}`,
          value: "0",
        },
        totalDur: "",
        schedule: true,
        rtImage: "",
        rtImageUrl: "",
        rtImgDel: false,
        logo1: "",
        logo2: "",
        logo3: "",
        intro: "",
        outro: "",
        logo1Del: false,
        logo2Del: false,
        logo3Del: false,
        introDel: false,
        outroDel: false,
        recording: "",
        owner: "",
        moderator: "",
        moderatorIntroduction: "",
        panelists: [],
        rtDoc: "",
        invitesList: {},
        user_data: [],
        phoneList: [],
        emailList: [],
        created_at: "",
        m_type: false,
        backdropFlag: false,
        inviteFollower: false,
        inviteFollowing: false,
        user_id: [],
        emails: [],
        phones: [],
        anonymous: false,
        rtThumbnailPreview: null,
        logo1Preview: null,
        logo2Preview: null,
        logo3Preview: null,
        introPreview: null,
        outroPreview: null,
        recordingPreview: null,
        docPreview: null,
        isDocPdf: false,
        arrayId: [],
        broadcastStreaming: false,
      })
    );

    navigate("/roundtable/create");
  };

  //todo const handleCreateView = () => {
  //   setCreateContent(false);
  //   setPostParent("BKK");
  //   setAddPost(true);
  // };

  const handleCreateMeetup = () => {
    const accessToken=localStorage.access
    const encryptedAccessToken = getEncryptedToken(accessToken, encryptionkey);
    window.open(`${MEET_UP_BASE_URL}?token=${encryptedAccessToken}`);
  };

  //todo const buttonConfigurations = [
  //   { onClick: handleAddPost, src: CreatePost, label: "Create a Post" },
  //   { onClick: handleAddSnipIt, src: CreateSnipIt, label: "Create a Snip-It" },
  //   isExpertBkk && {
  //     onClick: handleCreateExpertView,
  //     src: CreateExpertView,
  //     label: "Create an Expert View",
  //   },
  //   isBKK && { onClick: handleCreateView, src: CreateView, label: "Create a View" },
  //   isK3 && { onClick: handleAddKhabar, src: CreateKhabar, label: "Create a Khabar" },
  //   { onClick: handleCreateRT, src: CreateRT, label: "Create a RoundTable" },
  //   {
  //     onClick: handleCreateMeetup,
  //     src: CreateMeetup,
  //     label: "Schedule a Meetup",
  //   },
  // ].filter(Boolean);

  const buttonConfigurations = [
    {
      onClick: handleAddPost,
      src: CreatePost,
      label: allWords.createNew.createPost,
    },
    {
      onClick: handleAddScrolls,
      src: CreateSnipIt,
      label: allWords.createNew.createScrolls,
    },
    {
      onClick: handleCreateRT,
      src: CreateRT,
      label: allWords.createNew.createRT,
    },
    {
      onClick: handleCreateMeetup,
      src: CreateMeetup,
      label: allWords.createNew.scheduleMeetup,
    },
  ].filter(Boolean);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      PaperProps={customPaperProps}
      data-test="src-components-CreateContent-index-0"
    >
      <DialogTitle>
        <span
          style={TitleStyle}
          data-test="src-components-CreateContent-index-1"
        >
          Create
        </span>
        <Button
          onClick={handleClose}
          style={CloseStyle}
          data-test="src-components-CreateContent-index-2"
        >
          <img
            src={CloseIcon}
            data-test="src-components-CreateContent-index-3"
            alt="this is image"
          ></img>
        </Button>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          rowSpacing={MOBILE_VIEW ? 3 : 5}
          data-test="src-components-CreateContent-index-4"
        >
          {buttonConfigurations.map((config, index) => (
            <Grid
              item
              xs={MOBILE_VIEW ? 12 : 4}
              key={index}
              data-test="src-components-CreateContent-index-5"
            >
              <Button
                onClick={config.onClick}
                style={ButtonStyle}
                data-test="src-components-CreateContent-index-6"
              >
                <IconContainer>
                  <img
                    src={config.src}
                    width="25px"
                    height="25px"
                    alt={config.label}
                    data-test="src-components-CreateContent-index-7"
                  />
                </IconContainer>
                &nbsp;
                {config.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateContent;
