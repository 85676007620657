import * as actionTypes from "../../actions/actionTypes";

const initialState = { meetupEncryptionKey: "", isError: false };

const meetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MEETUP_TOKEN_KEY_GENERATION_START:
      return {
        ...state,
        isError: false,
      };
    case actionTypes.MEETUP_TOKEN_KEY_GENERATION_SUCCESS:

      return {
        meetupEncryptionKey: action.payload?.data?.data?.public_key,
        isError: false,
      };
    case actionTypes.MEETUP_TOKEN_KEY_GENERATION_FAILURE: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
};

//Selector For Meetup Token
export const getMeetupEncryptedTokenForRedirection = (state) => state.meetupInfo.meetupEncryptionKey;

export default meetupReducer;
