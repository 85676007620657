import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allWords } from "../../App";
import {
  INDIAN_UNION_TERRITORIES,
  INDIAN_STATES,
} from "../../constants/constant";
import { components } from "react-select";
import { useDebounce } from "use-debounce";
import logger from "../../logger";
import {
  setDialogStatus,
  setPostParentType,
  setSharLink,
} from "../../redux/actions/PostSuccessAction";
import { addPostScrollsData } from "../../redux/actions/postAction";
import { getProfileScrollsPosts } from "../../redux/actions/scrollsAction";
import { getVisitorSearch } from "../../redux/actions/visitorSearchAction";
import ToastHandler from "../../utils/ToastHandler";
import { truncate } from "lodash";
import UserProfile from "../UserProfile";
import { withStyles } from "@mui/styles";
import VideoSvg from "../../assets/icons/Video (1).svg";
import CreateSnipIt from "../../assets/icons/create_snipit.svg";
import Public_Opinion from "../../assets/icons/public_opinion.svg";
import News from "../../assets/icons/news.svg";
import Masti from "../../assets/icons/masti.svg";
import Showcase from "../../assets/icons/showcase.svg";
import DIY from "../../assets/icons/diy.svg";
import Bhakti from "../../assets/icons/bhakti.svg";
import Politics from "../../assets/icons/politics.svg";
import Sports from "../../assets/icons/sports.svg";
import RoundTable from "../../assets/icons/roundTable.svg";
import Leadership from "../../assets/icons/leadership.svg";
import Governance from "../../assets/icons/governance.svg";
import Science from "../../assets/icons/sci&tech.svg";
import GridViewIcon from "../../assets/icons/scroll_cat_menu.svg";
import ArrowForward from "../../assets/icons/scroll_cat_forward.svg";
import RadioCheck from "../../assets/icons/check_green.svg";
import VideoContainer from "./VideoContainer";
import {
  DialogMiddleText,
  StepOneContainer,
  SvgIcon,
  UploadButton,
  UploadText,
  StepTwoContainer,
  FormPartWrapper,
  FormContainer,
  InputWrapper,
  StyledUserTagIcon,
  SkkInputContainer,
  PContainer,
  StyledHashIcon,
  StyledCreatableSelect,
  SkkStyledHashInput,
  StyledLocationIcon,
  StyledSelect,
  HashInputWrapper,
  HashtagList,
  SkkRemoveButton,
  SkkHashtagItem,
  StyledPhoneIcon,
  VideoSelectionContainer,
  StyledDialogTitle,
  Hr,
  PostButton,
  VidErrorDiv,
  ScrollCatContainer,
  ScrollCatWrapper,
  StyledContainer,
  StyledContentWrapper,
  ScrollCatWrapperRight,
  ScrollCatRadioBtn,
  FormPartContainer,
  StyledDialogContainer,
  Asterisk,
} from "./style";
import CancelIcon from "@mui/icons-material/Cancel";
import { MOBILE_VIEW } from "../../constants/env";
import UserListInput from "../UserListInput/userListInput";

const IconButtonCustom = withStyles({
  root: {
    width: "60px",
    height: "26px",
    marginLeft: "-.6em",
  },
})(IconButton);

const BackspaceIcon = withStyles({
  root: {
    width: "64px",
    height: "26px",
    color: "#000",
  },
})(ArrowBackIcon);

const SkkBackspaceIcon = withStyles({
  root: {
    width: "64px",
    height: "26px",
    color: "#fff",
  },
})(ArrowBackIcon);

const FormatOptionLabelSkk = (props) => {
  return (
    <components.Option {...props}>
      <div
        className="d-flex"
        style={{ cursor: "pointer", backgroundColor: "#1A171F" }}
      >
        <div>
          <UserProfile username={props.data.label} className="avatar" />
        </div>
        <div className="px-3" style={{ width: "80%" }}>
          <h6 style={{ color: "#E4E9F1" }}>{props.data.value}</h6>
          <p style={{ color: "#E4E9F1", fontSize: "0.8rem" }}>
            @{props.data.label}
          </p>
        </div>
      </div>
    </components.Option>
  );
};

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    color: "#a3a3a3 !important",
  }),
  control: (provided, state) => ({
    ...provided,
    // Remove border and box-shadow when the control is focused
    minHeight: "0px !important",
    backgroundColor: "#1A171F",
    border: state.isFocused ? "#63779C" : "#A2A2A24D",
    outline: state.isFocused ? "#63779C" : provided.outline,
    "&:hover": {
      borderColor: state.isFocused ? "none" : provided.borderColor,
    },
    input: {
      color: "#fff !important",
      width: "100%",
      fontSize: "12px",
      padding: "0px !important",
    },
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: ".8rem",
    border: "transparent !important",
    maxWidth: "max-content",
    borderRadius: "6px",
    padding: "5px 5px",
    color: "#E4E9F1",
    backgroundColor: "#A2A2A280",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#1A171F",
    "&:hover": {
      backgroundColor: "#1A171F",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    borderColor: "10px",
    backgroundColor: "#000",
  }),
  menu: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: "90%",
    zIndex: 99999,
    width: "100%",
    backgroundColor: state.menuIsOpen ? "#A2A2A280" : "#222224",
  }),
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>@{children}</components.SingleValue>
);

const VideoPostDialog = ({ open, onClose, parentType = "" }) => {
  const dispatch = useDispatch();
  const visitorSearchData = useSelector((state) => state.visitorSearch.data);
  const mediaType = window.location.pathname;
  const [step, setStep] = useState(1);
  const [videoFile, setVideoFile] = useState(null);
  const [startVideoTime, setStartVideoTime] = useState(0);
  const [endVideoTime, setEndVideoTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [selectedNewUserOption, setSelectedNewUserOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [hashtags, setHashtags] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [phoneInputValue, setPhoneInputValue] = useState(null);
  const [videoError, setVideoError] = useState("");
  const inputRef = useRef("");
  const [userInputValue, setUserInputValue] = useState("");
  const [debouncedUserInputValue] = useDebounce(userInputValue, 300);
  const [editVideo, setEditVideo] = useState(false);
  const [scrollsCat, setScrollsCat] = useState(false);
  const [textCount, setTextCount] = useState(0);
  const [isMouseHovered, setIsMouseHovered] = useState(false);
  const [hashInputValue, setHashInputValue] = useState("");
  const [selectedScrollsCat, setSelectedScrollsCat] = useState(null);
  const [fakeDiv, setFakeDiv] = useState(true);

  const storedValueString = sessionStorage.getItem("storedVideoProperty");
  const storedVideoProperty = storedValueString
    ? JSON.parse(storedValueString)
    : null;

  const fileInputRef = useRef(null);

  const getCurrentUser = () => {
    try {
      return JSON.parse(localStorage?.current_user ?? "{}");
    } catch (error) {
      return {};
    }
  };

  const currentUser = getCurrentUser();
  const isExpertBkk = Boolean(currentUser?.is_expert_bkk);
  const isK3 = Boolean(currentUser?.is_k3 === 1);
  const isBKK = Boolean(currentUser?.is_bkk === 1);

  //create image array
  const scrollsCatImage = [
    Public_Opinion,
    Sports,
    News,
    RoundTable,
    Masti,
    Leadership,
    Showcase,
    Governance,
    DIY,
    Science,
    Bhakti,
    CreateSnipIt,
    Politics,
  ];

  const handleBack = () => {
    if (scrollsCat) {
      if (selectedScrollsCat) {
        setSelectedScrollsCat(null);
      }
      setScrollsCat(false);
      return;
    }
    if (step > 1) {
      setStep(step - 1);
    }
    setVideoFilePath("");
    setFakeDiv(true);
    setEditVideo(false);
    setSelectedScrollsCat(null);
    sessionStorage.removeItem("storedVideoProperty");
    sessionStorage.removeItem("storedBeforRender");
  };

  const handleVideoChange = (e) => {
    const videoFile = e.target.files[0];
    window.URL = window.URL || window.webkitURL;

    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      const vDuration = video.duration;

      setVideoDuration(vDuration);
      sessionStorage.setItem(
        "storedVideoProperty",
        JSON.stringify({
          _start: 0,
          _end: vDuration,
          _duration: vDuration,
        })
      );
      if (vDuration) {
        if (vDuration > 180) {
          setVideoError(allWords.misc.toastMsg.videoLength3min);
        }
      }
      window.URL.revokeObjectURL(video.src);
    };
    video.src = URL.createObjectURL(e.target.files[0]);

    if (videoFile) {
      let size = e.target.files[0].size;
      if (Math.round(size / 1024) > 512000) {
        ToastHandler("warn", "Upload a video file less than 500 MB.");
        return false;
      }
    }
    if (!videoFile) {
      ToastHandler("warn", "Please select video.");
      return false;
    }
    if (!videoFile.name.match(/\.(mp4|MP4)$/)) {
      ToastHandler("warn", allWords.misc.toastMsg.invalidVideoFormat);
      return false;
    }

    if (e.target.files) {
      setVideoFile(e.target.files[0]);
      setVideoFilePath(URL.createObjectURL(e.target.files[0]));
      if (URL.createObjectURL(e.target.files[0]) && step === 1) {
        setStep(2);
      }
    }
    e.target.value = null;
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const scrollsTagCat = [
    "PUBLIC_OPINION",
    "SPORTS",
    "NEWS",
    "ROUNDTABLE_SNIPPETS",
    "MASTI",
    "LEADERSHIP_SPEAKS",
    "SHOWCASE",
    "GOVERNANCE",
    "DO_IT_YOURSELF",
    "SCIENCE_AND_TECHNOLOGY",
    "BHAKTI",
    "OTHERS",
    "POLITICS",
  ];

  function getCategoryPayload(category) {
    switch (category) {
      case "PUBLIC_OPINION":
        return isExpertBkk ? "EXPERT_BKK_POST" : "BKK_POST";
      case "NEWS":
        return "K3_POST";
      default:
        return "SNIPPET";
    }
  }

  // Function to create the message object
  const createMessageObject = (otherParams) => {
    const str1 = otherParams?.textInput.replace(/"/g, '\\"');
    const str2 = otherParams?.textInput.replace(/(?:\r\n|\r|\n)/g, " <br />");
    let postType = getCategoryPayload(scrollsTagCat[selectedScrollsCat.index]);
    return {
      type: postType,
      media_type: "VIDEO",
      text: otherParams?.textInput.includes("\n") ? str2 : str1,
      caption: [""],
      tags: [""],
      hash_tags: otherParams.hashtags.length ? otherParams.hashtags : [],
      version: "v1",
      with_username:
        otherParams.selectedUserOption && !selectedNewUserOption
          ? [otherParams?.selectedUserOption?._id || null]
          : [],
      location: otherParams.selectedStateOption
        ? [{ state: otherParams?.selectedStateOption?.value || null }]
        : [],
      non_existing_user: selectedNewUserOption
        ? selectedNewUserOption?.label
        : "",
      contact_no: phoneInputValue ? phoneInputValue : "",
      file_type: ["VIDEO"],
      start_duration: [
        otherParams.startVideoTime || otherParams.endVideoTime
          ? otherParams.startVideoTime
          : null,
      ],
      end_duration: [otherParams.endVideoTime || null],
      duration: [otherParams.videoDuration || null],
      trim: ["true"],
      is_snap: ["false"],
      is_recorded: ["false"],
      category: scrollsTagCat[selectedScrollsCat.index],
    };
  };

  const handlePostSubmit = async () => {
    // Main code
    localStorage.removeItem("text_data");
    const formData = new FormData();

    const otherParams = {
      hashtags,
      selectedUserOption,
      selectedNewUserOption,
      selectedStateOption,
      startVideoTime: storedVideoProperty?._start || startVideoTime,
      endVideoTime: storedVideoProperty?._end || endVideoTime,
      videoDuration: storedVideoProperty?._duration || videoDuration,
      textInput,
    };
    const firstEntry = scrollsTagCat[selectedScrollsCat?.index];

    if (!videoFilePath) {
      return ToastHandler("warn", "Enter video for scrolls.");
    }
    if (videoError) {
      return ToastHandler("warn", "Please trim your video.");
    }
    if (!selectedScrollsCat) {
      return ToastHandler("warn", "Please select scroll category.");
    } else if ((videoFilePath && textInput) || videoFilePath) {
      if (storedVideoProperty?._duration  > 180) {
        return setVideoError(allWords.misc.toastMsg.videoLength3min);
      }
      if (phoneInputValue && phoneInputValue.length < 10) {
        return ToastHandler("warn", "Enter a valid phone number");
      }

      let messageObject;
      messageObject = createMessageObject(otherParams);
      if (messageObject) {
        formData.append("message", JSON.stringify(messageObject));
      }
    }
    formData.append("video", videoFile);

    dispatch(
      addPostScrollsData(formData, parentType, ({ err, data }) => {
        if (err) {
          return logger.info(err);
        }
        const postId = data?.[0]?.post_id;

        if (firstEntry === "OTHERS") {
          dispatch(
            setSharLink(`${window.location.origin}/scrolls/post_${postId}`)
          );
        } else {
          dispatch(
            setSharLink(
              `${
                window.location.origin
              }/scrolls/${firstEntry?.toLowerCase()}/post_${postId}`
            )
          );
        }

        dispatch(setDialogStatus(true));
        dispatch(setPostParentType(parentType));
      })
    );

    setVideoDuration(0);
    setVideoFile(null);
    setVideoFilePath(null);
    setEndVideoTime(0);
    setStep(1);
    onClose();
    sessionStorage.removeItem("storedVideoProperty");
    sessionStorage.removeItem("storedBeforRender");
    if (["SCROLLS"].includes(parentType)) {
      ToastHandler("info", allWords.misc.toastMsg.postUploadingWait);
    }
  };

  useEffect(() => {
    if (step === 2) {
      if (videoDuration <= 180) {
        setVideoError("");
      }
    }
  }, [videoDuration]);

  const handlePhoneInputChange = (e) => {
    const value = e.target.value;
    const onlyDigits = value.replace(/[^0-9]/g, ""); // Remove all non-digit characters
    if (onlyDigits.length <= 10) {
      // Limit length to 10
      setPhoneInputValue(onlyDigits);
    }
  };

  const handleHashInputChange = (e) => {
    const value = e.target.value;
    setHashInputValue(value);
  };

  const handleHashKeyDown = (e) => {
    if (e.key === "Enter" && hashInputValue) {
      const newWord = hashInputValue.startsWith("#") ? trimDoubleHash(hashInputValue) : `#${hashInputValue}`;
      if (hashtags.length >= 3) {
        // Show a toast error message if the user tries to enter more than 3 hashtags
        ToastHandler("warn", "You can only enter up to 3 hashtags");
      } else {
        setHashtags((prev) => [...prev, newWord]);
        setHashInputValue("");
      }
    }
  };

  const trimDoubleHash = (value) => {
    // To remove leading hastags
    let index=0
    for(let i=0; i<value.length; i++) {
      if(value[i] != "#") {
        index = i;
        break;
      };
    }
    return "#" + value.slice(index);
  }
  
  const removeHashtag = (index) => {
    setHashtags((prev) => prev.filter((_, i) => i !== index));
  };

  const statesOptions = [...INDIAN_STATES, ...INDIAN_UNION_TERRITORIES].map(
    (location) => ({
      value: location,
      label: location,
    })
  );

  const handleSelectChange = (newValue, actionMeta) => {
    setSelectedUserOption(newValue);
    if (actionMeta.action === "create-option") {
      setSelectedNewUserOption(newValue);
    }
  };

  const handleUserInputChange = (newValue) => {
    setUserInputValue(newValue);
    setUserTags([]);
  };

  const handleBlur = () => {
    if (userInputValue && !selectedUserOption) {
      // If there's a typed value and no selected option, consider it as the input
      const newOption = { label: userInputValue, value: userInputValue };
      setSelectedUserOption(newOption);
      setSelectedNewUserOption(newOption);
    }
  };

  const handleStateSelectChange = (value) => {
    setSelectedStateOption(value);
  };

  useEffect(() => {
    if (visitorSearchData) {
      let temp_usertags = [];
      visitorSearchData.data.map((item) => {
        let temp_dict = {};
        temp_dict["_id"] = item._id;
        temp_dict["value"] = item.name || item.username;
        temp_dict["label"] = item.username || item.name;
        temp_usertags.push(temp_dict);
      });
      setUserTags(temp_usertags);
    }
  }, [visitorSearchData]);

  const getUserTags = (values) => {
    let data = { search_term: values };
    dispatch(getVisitorSearch(data));
  };

  useEffect(() => {
    if (debouncedUserInputValue) {
      getUserTags(debouncedUserInputValue);
    }
  }, [debouncedUserInputValue]);

  useEffect(() => {
    setIsMouseHovered(true);
  }, []);

  const onChangeInputHandler = (e) => {
    const txt = e.target.value;
    const urlRegex = /\bhttps?:\/\/\S+/gi;
    let charCount;

    const allLinks = txt.match(urlRegex);

    if (allLinks) {
      const allLinksSize = allLinks.join("").length;
      charCount = txt.length - allLinksSize + allLinks.length * 4;
    } else {
      charCount = txt.length;
    }

    if (charCount <= 300) {
      setTextCount(charCount);
    } else {
      setTextCount(300);
    }

    if (charCount <= 300) {
      setTextInput(e.target.value);
    } else {
      if (allLinks) {
        const allLinksSize = allLinks.join("").length;
        charCount = charCount + allLinksSize - allLinks.length * 4;
      } else {
        charCount = 300;
      }
      setTextInput(txt.slice(0, charCount));
    }

    // setValue(value + 1);

    if (localStorage.getItem("text_data")) {
      localStorage.setItem("text_data", e.target.value);
    }
  };

  const onScrollCat = () => {
    setScrollsCat(true);
  };

  const onCloseScrollCat = () => {
    setFakeDiv(true);
    if (!selectedScrollsCat) {
      return ToastHandler("warn", allWords.scrolls.catNotSelected);
    }
    if (editVideo) {
      setEditVideo(true);
      setFakeDiv(false);
    }
    setScrollsCat(false);
  };

  const selectScrollCatHandler = (index, value) => {
    setSelectedScrollsCat({ index, value });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          style: {
            background: "transparent",
            opacity: 1,
            position: "absolute",
            maxHeight: "calc(100% - 50px)",
            overflowY: "visible",
            borderRadius: "20px",
            flexDirection: "row-reverse",
            boxShadow: "none",
            margin: "0!important",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: `${MOBILE_VIEW ? "8px" : "-54px"}`,
            top: `${MOBILE_VIEW ? "-1px" : "-5px"}`,
            padding: `${MOBILE_VIEW ? "0px !important" : "10px !important"}`,
            paddingTop: "5px !important",
            background: "transparent",
            display: "inline-block",
            height: "3rem",
          }}
        >
          <CancelIcon
            style={{
              color: "#fff",
              height: "2.5rem ",
              width: "2.5rem ",
            }}
          />
        </IconButton>
        <StyledDialogContainer>
          <StyledDialogTitle
            style={{ justifyContent: step > 1 ? "space-between" : "center" }}
          >
            {step > 1 && (
              <IconButtonCustom onClick={handleBack} fontSize="large">
                <SkkBackspaceIcon />
              </IconButtonCustom>
            )}
            <DialogMiddleText>
              {scrollsCat
                ? allWords.scrolls.categories
                : step === 1
                  ? allWords.scrolls.add
                  : allWords.scrolls.create}
            </DialogMiddleText>
            {step === 2 && (
              <PostButton
                onClick={scrollsCat ? onCloseScrollCat : handlePostSubmit}
                color="primary"
                style={{
                  backgroundColor:
                    (videoFilePath && selectedScrollsCat) ||
                    (scrollsCat && selectedScrollsCat)
                      ? "#fff"
                      : "#868686",
                }}
              >
                {scrollsCat
                  ? allWords.scrolls.DoneBtn
                  : allWords.scrolls.PostBtn}
              </PostButton>
            )}
          </StyledDialogTitle>
          <DialogContent
            style={{
              padding: "0px",
              borderRadius: "20px",
              // ,height:"400px"
            }}
          >
            {step === 1 && (
              <StepOneContainer>
                <SvgIcon>
                  <img src={VideoSvg} alt="Video Icon" />
                </SvgIcon>
                <UploadText>{allWords.scrolls.uploadDes}</UploadText>
                <React.Fragment>
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: "none" }}
                    accept="video/*"
                    onChange={handleVideoChange}
                  />
                  <UploadButton onClick={handleUploadButtonClick}>
                    {allWords.scrolls.uploadBtn}
                  </UploadButton>
                </React.Fragment>
              </StepOneContainer>
            )}
            {step === 2 && videoFilePath && !scrollsCat && (
              <StepTwoContainer>
                <VideoSelectionContainer>
                  <VideoContainer
                    setVideoDuration={setVideoDuration}
                    setStartVideoTime={setStartVideoTime}
                    setEndVideoTime={setEndVideoTime}
                    controls
                    editVideo={editVideo}
                    src={videoFilePath}
                    videoFilePath={videoFilePath}
                    setVideoFilePath={setVideoFilePath}
                    setEditVideo={setEditVideo}
                    removeVideo={step}
                    setStep={setStep}
                    videoDuration={videoDuration}
                    videoFile={videoFile}
                    endVideoTime={endVideoTime}
                    parentType={parentType}
                    setLengthError={setVideoError}
                    setFakeDiv={setFakeDiv}
                    setSelectedScrollsCat={setSelectedScrollsCat}
                    scrollsCat={scrollsCat}
                  />
                  {fakeDiv && (
                    <div style={{ width: "100%", height: "145px" }}>
                      {videoError && <VidErrorDiv>{videoError}</VidErrorDiv>}
                    </div>
                  )}
                </VideoSelectionContainer>
                <FormPartContainer>
                  <FormPartWrapper>
                    <SkkInputContainer>
                      <UserListInput
                        name="add dialog"
                        placeholder={allWords.scrolls.description}
                        className="post-area addDialogScroll"
                        refs={inputRef}
                        maxLength={textCount === 0 ? 300 : ""}
                        value={textInput}
                        listDirection="bottom"
                        onChange={(e) => onChangeInputHandler(e)}
                      />
                      <PContainer>
                        {textCount >= 0 && textCount <= 300
                          ? 300 - textCount
                          : 300}
                        /300
                      </PContainer>
                    </SkkInputContainer>{" "}
                    <Hr />
                    <FormContainer>
                      <StyledContainer onClick={onScrollCat}>
                        <StyledContentWrapper>
                          <img src={GridViewIcon} alt="_icon" loading="lazy" />
                          <h5>
                            {allWords.scrolls.categories}
                            <Asterisk>*</Asterisk>
                          </h5>
                        </StyledContentWrapper>
                        <StyledContentWrapper>
                          <p>
                            {truncate(selectedScrollsCat?.value, {
                              length: 20,
                            })}
                          </p>
                          <img src={ArrowForward} alt="_icon" loading="lazy" />
                        </StyledContentWrapper>
                      </StyledContainer>
                      {selectedScrollsCat?.index === 0 && (
                        <React.Fragment>
                          <InputWrapper>
                            <StyledUserTagIcon />
                            <StyledCreatableSelect
                              styles={customStyles}
                              menuPlacement="top"
                              closeMenuOnSelect={true}
                              backspaceRemovesValue={true}
                              isClearable={true}
                              options={userTags}
                              isSearchable={true}
                              placeholder={allWords.scrolls.tagUsers}
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null,
                                Option: FormatOptionLabelSkk,
                                SingleValue,
                              }}
                              onInputChange={handleUserInputChange}
                              onChange={handleSelectChange}
                              onBlur={handleBlur}
                              value={
                                selectedUserOption ? selectedUserOption : ""
                              }
                            />
                          </InputWrapper>
                          <HashInputWrapper>
                            <StyledPhoneIcon />
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="addPhontWraper"
                              onMouseEnter={() => setIsMouseHovered(true)}
                            >
                              {isMouseHovered ? (
                                <SkkStyledHashInput
                                  type="text"
                                  styles={customStyles}
                                  value={phoneInputValue}
                                  onChange={handlePhoneInputChange}
                                  placeholder={allWords.scrolls.addPhoneNo}
                                  onKeyDown={(e) =>
                                    e.key === "Enter" &&
                                    setIsMouseHovered(false)
                                  }
                                  onBlur={() =>
                                    phoneInputValue && setIsMouseHovered(false)
                                  }
                                />
                              ) : (
                                <div className="parent-input-for-soch">
                                  <p>{phoneInputValue}</p>
                                </div>
                              )}
                            </div>
                          </HashInputWrapper>
                          <InputWrapper>
                            <StyledLocationIcon />
                            <StyledSelect
                              styles={customStyles}
                              menuPlacement="top"
                              isClearable={true}
                              placeholder={allWords.scrolls.addLocation}
                              options={statesOptions}
                              onChange={handleStateSelectChange}
                              value={
                                selectedStateOption ? selectedStateOption : ""
                              }
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null,
                              }}
                            />
                          </InputWrapper>
                          <HashInputWrapper>
                            <StyledHashIcon />
                            <SkkStyledHashInput
                              type="text"
                              value={hashInputValue}
                              onChange={handleHashInputChange}
                              onKeyDown={handleHashKeyDown}
                              placeholder={allWords.scrolls.addHashtags}
                            />
                          </HashInputWrapper>
                          <HashtagList>
                            <HashInputWrapper>
                              {hashtags?.map((hashtag, index) => (
                                <SkkHashtagItem key={index}>
                                  {truncate(hashtag, { length: 10 })}
                                  <SkkRemoveButton
                                    onClick={() => removeHashtag(index)}
                                  />
                                </SkkHashtagItem>
                              ))}
                            </HashInputWrapper>
                          </HashtagList>
                        </React.Fragment>
                      )}
                    </FormContainer>
                  </FormPartWrapper>
                </FormPartContainer>
              </StepTwoContainer>
            )}
            {scrollsCat && (
              <ScrollCatContainer>
                {allWords.scrolls?.scrollsCat?.map((el, index) => {
                  // Add a condition to skip rendering for public and news
                  if (
                    (index === 0 && !isBKK && !isExpertBkk) ||
                    (index === 2 && !isK3)
                  ) {
                    return null; // Skip rendering
                  }
                  return (
                    <ScrollCatWrapper
                      key={index}
                      style={{
                        borderColor:
                          selectedScrollsCat?.index === index
                            ? "#fff"
                            : "#817e8680",
                      }}
                    >
                      <ScrollCatWrapperRight>
                        <img
                          src={scrollsCatImage[index]}
                          alt="scroll_cat_icons"
                          loading="lazy"
                        />
                        <div>
                          <h4>{el?.title}</h4>
                          <h6>{el?.subTitle}</h6>
                        </div>
                      </ScrollCatWrapperRight>
                      <ScrollCatRadioBtn
                        onClick={() => selectScrollCatHandler(index, el.title)}
                      >
                        {selectedScrollsCat?.index === index ? (
                          <img
                            src={RadioCheck}
                            alt="checked_icon"
                            loading="lazy"
                          />
                        ) : (
                          <span></span>
                        )}
                      </ScrollCatRadioBtn>
                    </ScrollCatWrapper>
                  );
                })}
              </ScrollCatContainer>
            )}
          </DialogContent>
        </StyledDialogContainer>
      </Dialog>
    </React.Fragment>
  );
};

export default VideoPostDialog;
