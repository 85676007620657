import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { REACT_APP_BASE_URL_FOR_USER } from "../../constants/env";
import { auto_login_continue, moengageEvent } from "../../utils/utils";
import Spinner from "../Spinner";
import MemoSuggesstion from "../SuggestionCard";
import { CardContainer, SuggestionTitle } from "../SuggestionCard/style";
import { allWords } from "../../App";

export default function SuggestedToYou({
  is_icon_sidebar,
  is_profile_section,
}) {
  const {
    loading,
    error,
    interest: suggested,
  } = useSelector((state) => state.suggestion);

  const [data_error, setDataError] = useState(false);
  const [curated, setCurated] = useState([]);

  useEffect(() => {
    if (error) {
      setDataError(true);
    } else if (suggested) {
      setCurated(
        localStorage.current_user && !localStorage.anonymous_user
          ? suggested["data"]
          : suggested?.["data"]?.[0]
      );
    }
  }, [suggested, error]);

  //Follow users
  const follow_unfollow_driver = async (handle, type) => {
    if (!handle || !type) {
      return;
    }
    let data = JSON.stringify({ handle, type });
    let config = {
      method: "post",
      url: `${REACT_APP_BASE_URL_FOR_USER}/follow-friends/`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async (res) => {
        if (res.status === 200) {
          moengageEvent(type === "follow" ? "Follow" : "UnFollow", "User", {
            IdOth: "",
            UsernameOth: handle,
          });

          let temp_solution = [...curated];
          let toset = true;
          if (type === "unfollow") {
            toset = false;
          }
          for (let i = 0; i < temp_solution.length; i++) {
            if (temp_solution[i]["username"] === handle) {
              temp_solution[i]["is_following"] = toset;
              setCurated(temp_solution);
              return;
            }
          }
        }
      })
      .catch(async (err) => {
        const res = err.response;
        if (!res) return;
        if (res.status === 401) {
          return await auto_login_continue(() =>
            follow_unfollow_driver(handle, type)
          );
        }
      });
  };

  const handleRemoveFriend = (friend) => {
    const index = curated.findIndex(
      (profile) => profile.username === friend.username
    );
    if (index !== -1) {
      let temp_solution = [...curated];
      temp_solution.splice(index, 1);
      setCurated(temp_solution);
    }
  };

  return (
    <>
      {data_error ? (
        <div
          className="container text-center mt-5"
          data-test="src-components-LeftSideBar-SuggestedToYou-0"
        >
          <p
            className="warn-text"
            data-test="src-components-LeftSideBar-SuggestedToYou-1"
          >
            {allWords.th.suggested.error}
          </p>
        </div>
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : curated !== allWords.th.suggested.noUser ? (
            <MemoSuggesstion
              render_points={curated}
              btn_fucntion={follow_unfollow_driver}
              type={"follow"}
              need_badge={false}
              is_icon_sidebar={is_icon_sidebar}
              is_profile_section={is_profile_section}
              handleRemoveFriend={handleRemoveFriend}
            />
          ) : (
            <CardContainer>
              <SuggestionTitle className="text-center">
                <small className="alert-text">
                  {allWords.th.suggestion.noUser}
                </small>
              </SuggestionTitle>
            </CardContainer>
          )}
          <br />
        </>
      )}
    </>
  );
}
