import clsx from "clsx";
import React, { useEffect, useState, useRef, useCallback } from "react";

// Components
import WithoutImage from "../../assets/images/WithoutImage.svg";
import BottomLeftComponent from "./BottomLeftComponent";
import RTDialogs from "./RTDialogs";
import VideoThumbnail from "./VideoThumbnail";

// Style
import "./style.css";

// Utils
import AspectRatio from "@mui/joy/AspectRatio";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allWords } from "../../App";
import { PLATFORM_DETAILS } from "../../pages/AccountSettings/BroadCast/BroadCastUtils/Configs";
import { rtActionData } from "../../redux/actions/roundtableAction/rtAction";
import ToastHandler from "../../utils/ToastHandler";
import {
  POPULART_CATEGORIES,
  moengageEvent,
  navigateToNewPage,
} from "../../utils/utils";
import { getRTSingleData } from "../../redux/actions/roundtableAction/single_roundtable";

export default function RTImg(props) {
  const {
    cover_img,
    webp_url,
    past,
    title,
    live,
    upcoming,
    rt_nature,
    rt_type,
    rec_end_flag,
    rec_owner_flag,
    rec_start_flag,
    timestamp,
    media_recording,
    owner_details,
    rt_id,
    muteFlag,
    setMuteFlag,
    videoElem,
    rt_details,
    listingDisplay,
    popular,
    moderator,
    speakers,
    navigate,
    reminder_status,
    reject,
    accept,
    has_accepted,
    acr,
    was_invited,
    request_status,
    join_count,
    is_cancelled,
    time,
    pauseVid,
    ext,
    id,
    broadcast_destination,
    awesomeDisplay,
    videoUrl,
    duration,
    mouseHovered = false,
    showBottomDuration,
    disableJoin = false,
    clickEffect = true,
    endPoint,
  } = props;

  const rtAction = useSelector((state) => state?.rtActionRed?.data);

  // Local State
  const [c_img, setCImg] = useState(cover_img);
  const [hoverImg, setHoverImg] = useState(false);
  const [notification_text, setNotificationText] = useState("");
  const [notify_box, setNotifyBox] = useState(false);
  const [set_reminder_status, setReminderStatus] = useState(reminder_status);
  const [set_request_access, setRequestAccess] = useState(request_status);
  const [modalOpen, setModalOpen] = useState(false);
  const [titleType, setTitleType] = useState("");
  const [btnFlag, setBtnFlag] = useState(false);
  const [btnData, setBtnData] = useState({
    type: "",
    msg: "",
  });
  const [joinFlg, setJoinFlg] = useState(false);
  const [elemHeight, setElemHeight] = useState(200);

  const rtImgRef = useRef(null);
  const dispatch = useDispatch();

  const agora_join = () => {
    localStorage.removeItem("join_rt");
    localStorage.setItem(
      "join_rt",
      JSON.stringify({
        rt_id: rt_id,
        channelName: rt_id,
      })
    );

    if (localStorage.join_rt) {
      navigateToNewPage("/roundtable/join");
    }
  };

  useEffect(() => {
    const updateElemHeight = () => {
      const height = rtImgRef.current.offsetHeight;
      setElemHeight(height);
    };
    window.addEventListener("resize", updateElemHeight);
    updateElemHeight();
    return () => {
      window.removeEventListener("resize", updateElemHeight);
    };
  }, []);

  useEffect(() => {
    if (joinFlg) {
      if (rtAction && rtAction?.data?.status === 200) {
        agora_join();
        moengageEvent("Join", "RoundTable", {
          RoundTableID: rt_id,
          Name: title,
          "K Type": rt_type,
          "K SubType": rt_nature,
          "Audience Interaction": 0,
        });
      } else {
        if (rtAction && rtAction?.data?.status === 253) {
          if (
            rtAction.data.message !==
            "You're not allowed to join this RoundTable."
          ) {
            if (
              rtAction.data.message ===
              "This round table has been ended by Owner"
            ) {
              setNotifyBox(true);
              setNotificationText("This RoundTable has been ended by Owner");
            } else if (live) {
              setNotifyBox(true);
              setNotificationText(rtAction.data.message); //Replace with a dialog popup
            }
          }
        }
      }
    }
  }, [rtAction]);

  const joinRT = useCallback(async () => {
    dispatch(rtActionData({ rt_id: rt_id, action: "JOIN" }));
    setJoinFlg(true);
  }, [dispatch, rt_id]);

  const playIncrement1 = useCallback(() => {
    navigateToNewPage(`/roundtable/recorded/${rt_id}`);
    // window.location.reload();
  }, [navigateToNewPage, rt_id]);

  function imgFunction() {
    const acceptableRTNature = ["public", "trial", "secret"];
    if (!clickEffect || window.location.href.includes(POPULART_CATEGORIES)) {
      return navigate(`/roundtables/${endPoint}`);
    }

    if (disableJoin) return;

    if (
      (live || upcoming) &&
      (acceptableRTNature.includes(rt_nature) ||
        (rt_nature === "private" &&
          was_invited &&
          (request_status || set_request_access)))
    ) {
      joinRT();
    }
    if (past || (!live && !upcoming)) {
      playIncrement1();
    }
    if (btnFlag === false) {
      if (rt_nature === "public" && upcoming) {
        if (reject === false) {
          if (
            reminder_status ||
            set_reminder_status ||
            accept === true ||
            has_accepted ||
            acr
          ) {
            setBtnFlag(false);
            ToastHandler("info", allWords.rt.upcomingRtInfoToast);
          } else {
            if (!localStorage.current_user && localStorage.anonymous_user) {
              setModalOpen(true);
              setTitleType("set_reminder");
              return;
            }

            setBtnFlag(true);
            setBtnData({
              type: "reminder_set",
              msg: allWords.misc.livert.startRT,
            });
          }
        } else {
          if (!localStorage.current_user && localStorage.anonymous_user) {
            setModalOpen(true);
            setTitleType("set_reminder");
            return;
          }

          setBtnFlag(true);
          setBtnData({
            type: "reminder_set",
            msg: allWords.misc.livert.startRT,
          });
        }
      }
      if (rt_nature === "secret" && upcoming) {
        if (reminder_status || set_reminder_status) {
          setBtnFlag(false);
          ToastHandler("info", allWords.rt.upcomingRtInfoToast);
        } else {
          setBtnFlag(true);
          setBtnData({
            type: "reminder_set",
            msg: allWords.misc.livert.startRT,
          });
        }
      }
      if (rt_nature === "private") {
        if (upcoming && was_invited) {
          if (reject === false) {
            if (
              reminder_status ||
              set_reminder_status ||
              accept === true ||
              has_accepted ||
              acr
            ) {
              setBtnFlag(false);
              ToastHandler("info", allWords.rt.upcomingRtInfoToast);
            } else {
              if (!localStorage.current_user && localStorage.anonymous_user) {
                setModalOpen(true);
                setTitleType("set_reminder");
                return;
              }

              setBtnFlag(true);
              setBtnData({
                type: "reminder_set",
                msg: allWords.misc.livert.startRT,
              });
            }
          } else {
            if (!localStorage.current_user && localStorage.anonymous_user) {
              setModalOpen(true);
              setTitleType("set_reminder");
              return;
            }

            setBtnFlag(true);
            setBtnData({
              type: "reminder_set",
              msg: allWords.misc.livert.startRT,
            });
          }
        }
        if (listingDisplay) {
          if ((upcoming || live) && !was_invited) {
            if (request_status || set_request_access) {
              setBtnFlag(false);
              ToastHandler("info", allWords.createRT.reqSentAlready);
            } else {
              if (!localStorage.current_user && localStorage.anonymous_user) {
                setModalOpen(true);
                setTitleType("request_access");
                return;
              }

              setBtnFlag(true);
              setBtnData({
                type: "request_access",
                msg: allWords.createRT.pvtRTpopUp,
              });
            }
          }
        }
      }
    }
  }

  return (
    <div
      className="rt_img_div"
      id={id}
      onClick={(e) => {
        if (!e.target.id.includes("button")) {
          imgFunction();
        }
      }}
      ref={rtImgRef}
      data-test="src-components-RTListingCard-RTImg-0"
    >
      {(media_recording || videoUrl) && (
        <>
          <VideoThumbnail
            media_recording={media_recording}
            owner_details={owner_details}
            rt_id={rt_id}
            muteFlag={muteFlag}
            setMuteFlag={setMuteFlag}
            videoElem={videoElem}
            rt_details={rt_details}
            c_img={c_img ? c_img : cover_img}
            setCImg={setCImg}
            title={title}
            videoUrl={videoUrl}
            upcoming={upcoming}
            pauseVid={pauseVid}
            parentHeight={elemHeight}
          />
        </>
      )}
      {cover_img ? (
        <Box
          className={
            upcoming
              ? "RT_Created_Success_Upcoming_Thumb_Img"
              : "RT_Created_Success_Thumb_Img"
          }
          sx={{
            width: "100%",
            borderRadius: "10px",
            p: 0,
            // "&:hover": { borderRadius: "0px" },
          }}
          data-test="src-components-RTListingCard-RTImg-1"
        >
          <AspectRatio
            variant="plain"
            objectFit="contain"
            sx={{
              borderRadius: "10px",
              "&:hover": { borderRadius: "0px" },
            }}
          >
            <img
              alt="rt_img"
              src={hoverImg && webp_url!="" ? webp_url :(c_img ? c_img : webp_url ? webp_url : cover_img)}
              onMouseEnter={() => setHoverImg(true)}
              onMouseLeave={() => setHoverImg(false)}
              onError={() => {
                setCImg(undefined);
              }}
              data-test="src-components-RTListingCard-RTImg-2"
            />
          </AspectRatio>
        </Box>
      ) : (
        <Box
          className="RT_Created_Success_Img"
          sx={{
            width: "100%",
            borderRadius: "10px",
            "&:hover": { borderRadius: "0px" },
          }}
          data-test="src-components-RTListingCard-RTImg-3"
        >
          <AspectRatio
            objectFit="contain"
            sx={{
              borderRadius: "10px",
              // "&:hover": { borderRadius: "0px" },
            }}
          >
            <div data-test="src-components-RTListingCard-RTImg-4">
              <img
                alt="this is image" 
                src={WithoutImage}
                data-test="src-components-RTListingCard-RTImg-5"
              />
              <div
                className="rt_img_p"
                data-test="src-components-RTListingCard-RTImg-6"
              >
                <p data-test="src-components-RTListingCard-RTImg-7">{title}</p>
              </div>
            </div>
          </AspectRatio>
        </Box>
      )}
      {/* @TODO: Notifiction Integration */}
      {/* {mouseHovered && <NotificationsIcon
        sx={{
          fill: `#${true ? "fd5c0d" : "ffffff"} !important`,
          position: "absolute",
          left: "12px",
          top: "12px",
        }}
      />} */}
      {(live ||
        upcoming ||
        (rt_type !== undefined &&
          rt_type !== "" &&
          rt_type !== "RECORDING_BASED")) && (
        <div
          className={clsx("top_right", {
            ["hideTR"]: rt_type === "RECORDING_BASED" && past,
          })}
          style={{
            padding: live ? "5px 15px" : "",
            right:
              muteFlag === true && (media_recording || videoUrl)
                ? awesomeDisplay
                  ? "5vw"
                  : "30px"
                : "",
          }}
          hidden={
            (rt_type === "RECORDING_BASED" && past) ||
            (past && is_cancelled === 0 && join_count > 0) ||
            (past &&
              is_cancelled === undefined &&
              (join_count === undefined || join_count > 0))
              ? true
              : false
          }
          data-test="src-components-RTListingCard-RTImg-8"
        >
          {live && (
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
              data-test="src-components-RTListingCard-RTImg-9"
            >
              <lottie-player
                src="https://assets7.lottiefiles.com/packages/lf20_ua3gryvm.json"
                background="transparent"
                speed="0"
                style={{ width: "48px", height: "16px" }}
              />
              {broadcast_destination &&
                owner_details?.username ===
                  JSON.parse(localStorage.getItem("current_user"))?.username &&
                broadcast_destination.map(
                  (item) => PLATFORM_DETAILS[item].smallIcon
                )}
            </div>
          )}
          {upcoming && (
            <>
              <span
                style={{
                  color: "#54B798",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                data-test="src-components-RTListingCard-RTImg-10"
              >
                {allWords.rt.label3}
              </span>
            </>
          )}

          {rt_type !== "RECORDING_BASED" && (
            <>
              {past && (join_count === 0 || is_cancelled === 1) && (
                <>
                  <span
                    style={{
                      color: "#ed4d29",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    data-test="src-components-RTListingCard-RTImg-11"
                  >
                    {allWords.misc.cancelled}
                  </span>
                </>
              )}
            </>
          )}
        </div>
      )}
      {showBottomDuration ? (
        <BottomLeftComponent
          rt_id={rt_id}
          rt_nature={rt_nature}
          rt_type={rt_type}
          upcoming={upcoming}
          live={live}
          rec_owner_flag={rec_owner_flag}
          rec_end_flag={rec_end_flag}
          rec_start_flag={rec_start_flag}
          timestamp={timestamp}
          past={past}
          popular={popular}
          media_recording={media_recording}
          time={time}
          muteFlag={muteFlag}
          ext={ext}
          duration={duration}
        />
      ) : null}
      <RTDialogs
        notify_box={notify_box}
        setNotifyBox={setNotifyBox}
        notification_text={notification_text}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        titleType={titleType}
        setTitleType={setTitleType}
        setReminderStatus={setReminderStatus}
        rt_id={rt_id}
        btnFlag={btnFlag}
        setBtnFlag={setBtnFlag}
        btnData={btnData}
        setRequestAccess={setRequestAccess}
        title={title}
        r_type={rt_type}
        rt_nature={rt_nature}
      />
    </div>
  );
}
