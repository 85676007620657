import * as actionTypes from "../../actions/actionTypes";
const userInitialState = {
  deviceInfoUpdated: false,
};
const deviceInfoReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actionTypes.DEVICE_INFO_SUCCESS:
      return {
        deviceInfoUpdated: action.payload
      };
    default:
      return state;
  }
};

export const isDeviceInfoUpdated = (state) => state.deviceInfo.deviceInfoUpdated;

export default deviceInfoReducer;
