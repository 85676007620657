import { lazy } from "react";

export const publicRoute = [
  {
    path: "/app",
    component: lazy(() => import("../pages/UniversalAppPage/index")),
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/auth/Login2")),
  },
  {
    path: "/redirect",
    component: lazy(() => import("../pages/auth/Login2/PreRedirection")),
  },
  {
    path: "/login/password",
    component: lazy(() => import("../pages/auth/Login2/Loginp")),
  },
  {
    path: "/login/otp",
    component: lazy(() => import("../pages/auth/Login2/Logino")),
  },
  {
    path: "/signup",
    component: lazy(() => import("../pages/auth/Signup2/Signup21")),
  },
  {
    path: "/signup/otp",
    component: lazy(() => import("../pages/auth/Signup2/Signup22")),
  },
  {
    path: "/verify_fp",
    component: lazy(() => import("../pages/auth/VerifyFP")),
  },
  {
    path: "/roundtable/*",
    component: lazy(() => import("../routes/RoundTableLayout")),
  },
  {
    path: "/embed/roundtable/*",
    component: lazy(() => import("../routes/RoundTableLayout")),
  },
  {
    path: "/forgot_password",
    component: lazy(() => import("../pages/auth/ForgotPassword")),
  },
  {
    path: "/post/:id",
    component: lazy(() => import("../pages/SinglePost")),
  },
  {
    path: "/search",
    component: lazy(() => import("../pages/Search")),
  },
  {
    path: "/rt/search",
    component: lazy(() => import("../pages/SearchRountable")),
  },
  {
    path: "/welcome",
    component: lazy(() => import("../pages/Welcome/Welcome3.jsx")),
  },
  {
    path: "/recording",
    component: lazy(() => import("../pages/RecordRT")),
  },
  {
    path: "/maintainance",
    component: lazy(() => import("../pages/Maintainance")),
  },
  {
    path: "/password_reset",
    component: lazy(() => import("../pages/auth/ResetPassword")),
  },
  {
    path: "/townhall",
    component: lazy(() => import("../pages/Townhall")),
  },
  {
    path: "/organization/*",
    component: lazy(() => import("../pages/Organization")),
  },
  {
    path: "/roundtable/*",
    component: lazy(() => import("../pages/Maidan")),
  },
  {
    path: "/home",
    component: lazy(() => import("../pages/Home")),
  },

  {
    path: "/profile/*",
    component: lazy(() => import("./ProfileLayout")),
  },
  {
    path: "/scrolls/*",
    component: lazy(() => import("../pages/ScrollsPage")),
  },
  {
    path: "/embed/scrolls/*",
    component: lazy(() => import("../pages/ScrollsPage/EmbedScrollsPage")),
  },
  {
    path: "/tags/:hastag",
    component: lazy(() => import("../pages/HashTagsVideos")),
  },
  {
    path: "/locations/:name",
    component: lazy(() => import("../pages/LocationVideos")),
  },
  {
    path: "/showcase",
    component: lazy(() => import("../pages/Showcase")),
  },
  {
    path: "/showcaselist",
    component: lazy(() => import("../pages/ShowcaseList")),
  },
  {
    path: "/preview-rt",
    component: lazy(
      () => import("../components/CreateEditRoundtable/PreviewComponent")
    ),
  },
  {
    path: "/VideosOnMap",
    component: lazy(() => import("../pages/VideosOnMap/ScrollsMap.js")),
  },
  {
    path: "/roundtable/all",
    component: lazy(() => import("../components/RoundTableHome")),
  },
  {
    path: "/roundtables/:endpoint",
    component: lazy(
      () => import("../components/RoundTableHome/AllRoundTablesList")
    ),
  },
  {
    path: "/signup/:customSignup",
    component: lazy(() => import("../pages/Customsignup")),
  },
  {
    path: "/embed/preview/:id/:type/:label",
    component: lazy(() => import("../pages/EmbedPreview")),
  },
  {
    path: "/",
    component: lazy(() => import("../pages/Home")),
  },
  {
    path: "*",
    component: lazy(() => import("../pages/Error/pageNotFound")),
  },
];
