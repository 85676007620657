import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { REACT_APP_VAPID_ID } from "../constants/env";
import { messaging } from "../push_firebase";
import FallBackSpinner from "../components/FallBackSpinner";
import { REACT_APP_BASE_URL_FOR_USER, STATIC_TOKEN } from "../constants/env";
import { useSelector } from "react-redux";
import { isDeviceInfoUpdated } from "../redux/reducers/authReducer/deviceInfoUpdate";
import { checkIfImagesNeedsToBeChanged as updateDeviceInfo } from "../apis/staticImageGlobalizeApi";

export default function PublicRoutesComp({ component: Component, path }) {
  const [loggingAnonymousUser, setLoggingAnnonymousUser] = useState(true);
  const isDeviceInfoAvailable = useSelector(isDeviceInfoUpdated);
  const fetchAnonymousUser = async () => {
    const ip_address =
      Math.floor(Math.random() * 255) +
      1 +
      "." +
      Math.floor(Math.random() * 255) +
      "." +
      Math.floor(Math.random() * 255) +
      "." +
      Math.floor(Math.random() * 255);

    const deviceId = Math.floor(1000000 + Math.random() * 9000000);
    let firebaseToken = "default";
    try {
      firebaseToken = await messaging?.getToken({ vapidKey: REACT_APP_VAPID_ID });
    } catch (e) {
      console.log(e);
    }

    const config = {
      url: `${REACT_APP_BASE_URL_FOR_USER}/v1/anonymous-user-entry`,
      method: "GET",
      params: {
        "deviceinfo[device_name]": navigator.userAgent,
        "deviceinfo[platform]": navigator.platform,
        "deviceinfo[platform_version]": "v1",
        "deviceinfo[app_version]": "1",
        "deviceinfo[ip_address]": ip_address,
        "device-id": deviceId,
        "device-type": "web",
        "firebase-token": firebaseToken,
      },
      headers: { Authorization: STATIC_TOKEN },
    };

    const response = await axios(config);

    if (response.status === 200) {
      const data = response.data.data[0];
      localStorage.setItem(
        "anonymous_user",
        JSON.stringify({
          anonymous: true,
          ...data,
        })
      );
      if (!isDeviceInfoAvailable) {
        updateDeviceInfo();
      }

      return setLoggingAnnonymousUser(false);
    }
  };

  useEffect(() => {
    const commonPathsNoAuthRequired = [
      "/login/password",
      "/login/otp",
      "/signup",
      "/signup/otp",
      "/roundtable/disclaimer",
      "/welcome",
      "/recording",
      "/maintainance",
      "/forgot_password",
      "/feedback",
      "/faq",
      "/community-guidelines",
      "/support",
      "/privacy-policy",
      "/sitemap",
      "/disclaimers",
      "/terms-conditions",
    ];

    const isCommonPath = commonPathsNoAuthRequired.find(
      (elem) => elem === path
    );

    if (isCommonPath) {
      return setLoggingAnnonymousUser(false);
    }

    const current_user = localStorage.getItem("current_user");
    const anonymous_user = localStorage.getItem("anonymous_user");

    if (current_user || anonymous_user) return setLoggingAnnonymousUser(false);

    if (!current_user) fetchAnonymousUser();
  }, []);

  if (loggingAnonymousUser) return <FallBackSpinner />;

  return <Component />;
}
