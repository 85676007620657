import * as React from "react";

function TermsAndConditionsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 27 27"
      {...props}
    >
      <g id="noun-policy-5017525" transform="translate(-4 -7.264)">
        <path
          id="Path_100845"
          data-name="Path 100845"
          d="M23.669,20.828l1.474.69a.41.41,0,0,0,.347,0l1.474-.69a2.846,2.846,0,0,0,1.757-2.519V15.187a.41.41,0,0,0-.408-.41,4.617,4.617,0,0,1-2.453-.7c-.211-.129-.512-.4-.766-.207a4.74,4.74,0,0,1-2.775.909.41.41,0,0,0-.408.41v3.122a2.846,2.846,0,0,0,1.757,2.519Zm-.938-5.248a5.381,5.381,0,0,0,2.585-.88l.1.064a5.386,5.386,0,0,0,2.486.816v2.729a2.039,2.039,0,0,1-1.285,1.776l-1.3.609-1.3-.609a2.039,2.039,0,0,1-1.285-1.776Z"
          transform="translate(-10.569 -3.857)"
        />
        <path
          id="Path_100846"
          data-name="Path 100846"
          d="M25.955,22.264a.413.413,0,0,0,.669.153l2.6-2.508a.41.41,0,1,0-.569-.59l-2.148,2.072-.314-.852a.41.41,0,1,0-.769.284Z"
          transform="translate(-12.626 -7.046)"
        />
        <path
          id="Path_100847"
          data-name="Path 100847"
          d="M24.194,7.264H9.81A2.889,2.889,0,0,0,6.925,10.15V23.455H4.41a.41.41,0,0,0-.41.41,3.683,3.683,0,0,0,3.678,3.679H19.3a2.956,2.956,0,0,0,2.952-2.952V12h4.293a.41.41,0,0,0,.41-.41V10.025A2.764,2.764,0,0,0,24.194,7.264ZM7.678,26.724a2.863,2.863,0,0,1-2.829-2.449h11.5a3.064,3.064,0,0,0,.913,2.449Zm13.755-2.132a2.133,2.133,0,0,1-4.265,0v-.726a.41.41,0,0,0-.41-.41H7.744V10.15A2.068,2.068,0,0,1,9.81,8.084H22.233a2.752,2.752,0,0,0-.8,1.941V24.591Zm4.7-13.408H22.253V10.025a1.941,1.941,0,0,1,3.883,0Z"
          transform="translate(0 0)"
        />
        <path
          id="Path_100848"
          data-name="Path 100848"
          d="M27.769,36.247H19.311a.41.41,0,1,0,0,.82h8.458a.41.41,0,1,0,0-.82Z"
          transform="translate(-8.793 -17.103)"
        />
        <path
          id="Path_100849"
          data-name="Path 100849"
          d="M27.769,40.554H19.311a.41.41,0,1,0,0,.82h8.458a.41.41,0,1,0,0-.82Z"
          transform="translate(-8.793 -19.644)"
        />
      </g>
    </svg>
  );
}

const MemoTermsAndConditionsIcon= React.memo(TermsAndConditionsIcon);

export default MemoTermsAndConditionsIcon;
