import React, { useState } from "react";
import geoLocation from "../../assets/icons/geoLocation.svg";
import styled, { css } from "styled-components";
import { SearchOutlined } from "@mui/icons-material";
import { ClickAwayListener } from "@mui/material";
import { allWords } from "../../App";
import { useNavigate, useLocation } from "react-router-dom";
import LatLonData from "../../pages/VideosOnMap/LatLonData.json";
import { toast } from "react-toastify";

const SearchContainer = styled.div`
  width: 95%;
  margin: auto;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid #38353d;
  padding: 0.8rem;
  border-radius: 10px;
  background-color: #252525;
  margin-top: ${(props) => (props.label == "maidan" ? "0rem" : "0rem")};
`;
const CustomSearchInput = styled.input`
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 0.8rem;
  font-weight: medium;
  color: #f4f4f4;
  background-color: transparent;
`;

const GeoSearchButton = styled.button`
  top: 26px;
  left: 1201px;
  width: 245px;
  height: 28px;
  /* UI Properties */
  background: #ed4d28 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
`;

const GeoSearchText = styled.span`
  top: 32px;
  left: 1233px;
  width: 102px;
  height: 16px;
  text-align: left;
  font-family: Work Sans;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0px;
  color: #f4f4f4;
  opacity: 1;
`;

const SuggestionList = styled.ul`
  top: 50px;
  z-index: 10001;
  left: 13px;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-height: 150px; /* Set the maximum height to limit the number of visible suggestions */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: black;
  border-right: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
  border-left: 1px solid rgb(204, 204, 204);
  border-image: initial;
  border-top: none;
  color: white;
  border-radius: 0px 0px 10px 10px;
  padding: 0px;
  margin: 0px;
  position: absolute;
  list-style-type: none;

  ${(props) =>
    props.page === "search" &&
    css`
      top: 146px;
      z-index: 10001;
      left: 283px;
      display: flex;
      flex-direction: column;
      width: 51%;
    `}

  ${(props) =>
    props.page === "roundtable" &&
    css`
      top: 118px;
      z-index: 10001;
      left: 310px;
      display: flex;
      flex-direction: column;
      width: 64%;
    `}
`;

const SuggestionItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: black;
    border-top: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    border-left: 1px solid rgb(204, 204, 204);
  }
`;

const SearchInput = ({ ...props }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionOpen, setIssuggestionOpen] = useState(false);
  const [inputValue, setInputValue] = useState(props?.searchText ?? "");
  const navigate = useNavigate();
  const loc = useLocation();
  let suggestionList = true;

  if (props.page === "search" || props.page === "roundtable") {
    suggestionList = false;
  }

  if (props.value?.charAt(0) === "#" && !props.hasTyped) {
    props.value = props.value?.substring(1);
  }

  // Function to update query parameters
  const updateQueryParams = (selectedLocation) => {
    if (selectedLocation) {
      const { city, lat, lng } = selectedLocation;
      const newParams = new URLSearchParams();
      newParams.set("searchLocation", city);
      newParams.set("lat", lat);
      newParams.set("lng", lng);

      navigate(`/VideosOnMap?${newParams.toString()}`, { replace: true });
    } else {
      navigate(`/VideosOnMap`, { replace: true });
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    // Filter suggestions based on the current input value
    const filteredSuggestions = LatLonData.filter((item) =>
      item.city.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (selectedSuggestion) => {
    setInputValue(selectedSuggestion.city);
    updateQueryParams(selectedSuggestion);
    setSuggestions([]); // Clear suggestions after selection
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIssuggestionOpen(false);
      }}
    >
      <SearchContainer label={props.label}>
        <SearchOutlined
          style={{ fontSize: 25, padding: 2, opacity: "0.5", color: "#a2a2a2" }}
        />
        <CustomSearchInput
          {...props}
          placeholder={allWords.th.searPlaceholder}
          value={inputValue}
          onChange={handleInputChange}
          onClick={() => {
            setIssuggestionOpen(true)
             if (props.onClick) {
                props.onClick(); // Call the onClick function from props if it exists
            }
          }}
        />
        {window.location.pathname.includes('VideosOnMap') && suggestionList && isSuggestionOpen && suggestions.length > 0 && (
          <SuggestionList page={props.page}>
            {suggestions.map((suggestion, index) => (
              <SuggestionItem
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.city}
              </SuggestionItem>
            ))}
          </SuggestionList>
        )}
        <GeoSearchButton
          id="searchOnMap"
          onClick={() => {
            // Update query parameters based on selected location
            const selectedLocation = LatLonData.find(
              (item) => item.city.toLowerCase() === inputValue.toLowerCase()
            );
            updateQueryParams(selectedLocation);
          }}
        >
          <img
            src={geoLocation}
            style={{
              top: "32px",
              left: "1211px",
              width: "12px",
              height: "12px",
              marginRight: "4px",
              marginBottom: "3px",
            }}
          ></img>
          <GeoSearchText>Search on Map</GeoSearchText>
        </GeoSearchButton>
      </SearchContainer>
    </ClickAwayListener>
  );
};

export default SearchInput;
