import { makeStyles } from "@mui/styles";
import { CancelOutlined } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { allWords } from "../../../App";
import PollIcon from "../../../assets/icons/poll_icon.svg";
import { POST_API_BASE_URL, REACT_APP_USER_ONBOARDING_URL } from "../../../constants/env";
import {
  INDIAN_UNION_TERRITORIES,
  INDIAN_STATES,
} from "../../../constants/constant";
import logger from "../../../logger";
import { addPostData, getPostData } from "../../../redux/actions/postAction";
import {
  setSharLink,
  setDialogStatus,
  setPostParentType,
} from "../../../redux/actions/PostSuccessAction";
import ToastHandler from "../../../utils/ToastHandler";
import AudioIcon from "../../IconsComponents/AudioIcon";
import DocsIcon from "../../IconsComponents/DocsIcon";
import PhotoIcon from "../../IconsComponents/PhotoIcon";
import VideoIcon from "../../IconsComponents/VideoIcon";
import PollFormContainer from "../../PollFormContainer";
import PreloginComp from "../../PreLoginComp";
import UserListInput from "../../UserListInput/userListInput";
import UserProfile from "../../UserProfile";
import "../style.css";
import AudioContainer from "./AudioContainer";
import DocsContainer from "./DocsContainer";
import EditImage from "./EditImage";
import ImageContainer from "./ImageContainer";
import { device_info } from "../../../utils/utils";
import {getOrgId} from "../../../apis/profileApi";

import {
  AddPostBtn,
  IconContainer,
  MainDiv,
  VidErrorDiv,
  Container,
  InputWrapper,
  StyledSelect,
  StyledCreatableSelect,
  HashInputWrapper,
  StyledUserTagIcon,
  StyledLocationIcon,
  StyledHashIcon,
  StyledHashInput,
  HashtagList,
  HashtagItem,
  RemoveButton,
  ErrorMessage,
  StyledPhoneIcon,
} from "./style";
import VideoContainer from "./VideoContainer";
import { components } from "react-select";
import { useDebounce } from "use-debounce";
import { getVisitorSearch } from "../../../redux/actions/visitorSearchAction";
import { truncate } from "lodash";
import "./style.css";
import CheckIcon from '@mui/icons-material/Check';

const FormatOptionLabel = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex" style={{ cursor: "pointer" }}>
        <div>
          <UserProfile username={props.data.label} className="avatar" />
        </div>
        <div className="px-3" style={{ width: "80%" }}>
          <h6 style={{ color: "#fff" }}>{props.data.value}</h6>
          <p style={{ color: "#fff", fontSize: "0.8rem" }}>
            @{props.data.label}
          </p>
        </div>
      </div>
    </components.Option>
  );
};
const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#63779C !important",
    opacity: 1,
  }),
  control: (provided, state) => ({
    ...provided,
    // Remove border and box-shadow when the control is focused
    borderColor: "#7070704D",
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    outline: state.isFocused ? "#63779C" : provided.outline,
    "&:hover": {
      borderColor: "#7070704D",
    },
    background: "#1A171F",
    color: "#fff",
  }),
  singleValue: (base) => ({
    ...base,
    maxWidth: "max-content",
    border: "1px solid #D3D6DB",
    borderRadius: "6px",
    padding: "5px 5px",
    background: "#1A171F",
    color: "#fff",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#1A171F",
    color: "#fff",
  }),
  option: (provided) => ({
    ...provided,
    background: "#1A171F",
    "&:hover": {
      backgroundColor: "#0E0817",
    },
  }),
};
const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>@{children}</components.SingleValue>
);

const useStyles = makeStyles((isOrg) => ({
  paper: {
    backgroundColor: "#212121",
    color: "#fff",
    borderRadius: "1rem",
    height: isOrg ? "571px" : undefined,
    "@media (max-width: 375px)": {
      width: "97%",
      margin: 0,
    },
    "@media (min-width: 600px)": {
      width: "80%",
      maxWidth: "550px",
      margin: "0 auto",
    },
  },
}));

const Button = styled.button`
  width: 200px;
  height: 50px;
  outline: none;
  border-radius: 5px;
  color: ${(props) => (props.primary ? props.theme.color.primary : "white")};
  background-color: ${(props) =>
    props.bgColor ? props.theme.color.secondary : "white"};
  border: 2px solid
    ${(props) =>
      props.primary ? props.theme.color.primary : props.theme.color.secondary};
`;

const AddPostDialog = ({
  open,
  onClose = ()=>{},
  setAddPost,
  setDialogTitle,
  setDiscard,
  setDayDuration,
  setHourDuration,
  setMsgFlag,
  parentType = "",
  isOrg = false,
  orgName = "Everyone",
  orgId = "", 
  setOrgId = ()=>{},
}) => {
  const [state, setState] = useState(false);
  const [startAudioTime, setStartAudioTime] = useState(0);
  const [endAudioTime, setEndAudioTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [startVideoTime, setStartVideoTime] = useState(0);
  const [endVideoTime, setEndVideoTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);
  const [xlFile, setXlFile] = useState(null);
  const [wordFile, setWordFile] = useState(null);
  const [pptFile, setPptFile] = useState(null);
  const [editVideo, setEditVideo] = useState(false);
  const [editImage, setEditImage] = useState(false);
  const [imageCaption1, setImageCaption1] = useState("");
  const [imageCaption2, setImageCaption2] = useState("");
  const [imageCaption3, setImageCaption3] = useState("");
  const [imageCaption4, setImageCaption4] = useState("");
  const [imgArray, setImgArray] = useState([]);
  const [captionLength, setCaptionLength] = useState(0);
  const [imageSrc, setImageSrc] = useState({
    index: null,
    url: null,
    file: null,
  });

  const [disable, setDisable] = useState(false);
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [docsFilePath, setDocsFilePath] = useState(null);
  const [docsFile, setDocsFile] = useState(null);
  const [audioFilePath, setAduioFilePath] = useState(null);
  const [audioFile, setAduioFile] = useState(null);
  const [textInput, setTextInput] = useState("");

  const [value, setValue] = useState(0);
  const [caption, setCaption] = useState("");
  const [textCount, setTextCount] = useState(0);
  const dispatch = useDispatch();
  const allPostData = useSelector((state) => state.post.posts);
  const post_res = useSelector((state) => state.post.post_res);
  const addPostLoading = useSelector((state) => state.post.loading);
  const [pollState, setPollState] = useState(false);
  const editImageRef = useRef();
  const [pollData, setPollData] = useState({
    option: [],
    duration: "",
  });
  const [state_data, setStateData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [lengthError, setLengthError] = useState(null);

  const classes = useStyles(isOrg);
  const visitorSearchData = useSelector((state) => state.visitorSearch.data);

  const [userInputValue, setUserInputValue] = useState("");
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [selectedNewUserOption, setSelectedNewUserOption] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [debouncedUserInputValue] = useDebounce(userInputValue, 300);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [phoneInputValue, setPhoneInputValue] = useState(null);
  const [hashInputValue, setHashInputValue] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [isMouseHovered, setIsMouseHovered] = useState(false);

  const [neworgName, setnewOrgName] = useState(orgName);
  const [selectedOption, setSelectedOption] = useState("Option 0");
  const [showDropdown, setShowDropdown] = useState(false); 
  const [options, setOptions] = useState([]);
  const [newOrgId, setNewOrgId] = useState(null);

  const handleHashInputChange = (e) => {
    const value = e.target.value;
    setHashInputValue(value);
  };

  const handleDropdownChange = async (option) => {
    setSelectedOption(option);
    // Find the selected option object from the options array
    const selectedOptionObject = options.find(opt => opt.value === option);
    
    // If the selected option object is found, set the orgName to its label
    if (selectedOptionObject) {
      setnewOrgName(selectedOptionObject.label);
      console.log(selectedOptionObject.label)
      await getOrgId(selectedOptionObject.label).then((res)=>{
        setNewOrgId(res)
      })
    } else {
      // Default to "Everyone" if the selected option object is not found
      setnewOrgName("Everyone");
      setNewOrgId("")
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleHashKeyDown = (e) => {
    if (e.key === "Enter" && hashInputValue) {
      const newWord = hashInputValue.startsWith("#")
        ? hashInputValue
        : `#${hashInputValue}`;
      if (hashtags.length >= 3) {
        // Show a toast error message if the user tries to enter more than 3 hashtags
        ToastHandler("warn", "You can only enter up to 3 hashtags");
      } else {
        setHashtags((prev) => [...prev, newWord]);
        setHashInputValue("");
      }
    }
  };

  const handlePhoneInputChange = (e) => {
    const value = e.target.value;
    const onlyDigits = value.replace(/[^0-9]/g, ""); // Remove all non-digit characters
    if (onlyDigits.length <= 10) {
      // Limit length to 10
      setPhoneInputValue(onlyDigits);
    }
  };
  const removeHashtag = (index) => {
    setHashtags((prev) => prev.filter((_, i) => i !== index));
  };

  const statesOptions = [...INDIAN_STATES, ...INDIAN_UNION_TERRITORIES].map(
    (location) => ({
      value: location,
      label: location,
    })
  );

  const handleUserInputChange = (newValue) => {
    setUserInputValue(newValue);
  };

  const handleSelectChange = (newValue, actionMeta) => {
    setSelectedUserOption(newValue);
    if (actionMeta.action === "create-option") {
      setSelectedNewUserOption(newValue);
    }
  };

  const handleBlur = () => {
    if (userInputValue && !selectedUserOption) {
      // If there's a typed value and no selected option, consider it as the input
      const newOption = { label: userInputValue, value: userInputValue };
      setSelectedUserOption(newOption);
      setSelectedNewUserOption(newOption);
    }
  };

  const handleStateSelectChange = (value) => {
    setSelectedStateOption(value);
  };

  useEffect(() => {
    if (visitorSearchData) {
      let temp_usertags = [];
      visitorSearchData.data.map((item) => {
        let temp_dict = {};
        temp_dict["_id"] = item._id;
        temp_dict["value"] = item.name || item.username;
        temp_dict["label"] = item.username || item.name;
        temp_usertags.push(temp_dict);
      });
      setUserTags(temp_usertags);
    }
  }, [visitorSearchData]);

  const getUserTags = (values) => {
    let data = { search_term: values };
    dispatch(getVisitorSearch(data));
  };

  useEffect(() => {
    if (debouncedUserInputValue) {
      getUserTags(debouncedUserInputValue);
    }
  }, [debouncedUserInputValue]);
  useEffect(() => {
    setIsMouseHovered(true);
  }, []);
  const imageCaptionPropHandler = (index) => {
    if (index === 0) {
      return imageCaption1;
    }
    if (index === 1) {
      return imageCaption2;
    }
    if (index === 2) {
      return imageCaption3;
    }
    if (index === 3) {
      return imageCaption4;
    }
  };

  const handleVideoChange = (e) => {
    setLengthError(null);
    const videoFile = e.target.files[0];
    window.URL = window.URL || window.webkitURL;

    const video = document.createElement("video");
    video.preload = "metadata";
    if (videoFile) {
      let size = e.target.files[0].size;
      if (
        ["K3", "BKK", "SKK"].includes(parentType) &&
        video.duration < 180 &&
        Math.round(size / 1024) > 512000
      ) {
        ToastHandler("warn", "Upload a video file less than 500 MB.");
        setState(false);
        return false;
      }

      if (
        !["K3", "BKK", "SKK"].includes(parentType) &&
        Math.round(size / 1024) > 256000
      ) {
        ToastHandler("warn", "Upload a video file less than 250 MB.");
        setState(false);
        return false;
      }
    }
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      if (!["K3", "BKK", "SKK"].includes(parentType) && video.duration > 150) {
        return ToastHandler("warn", allWords.misc.maxvidoel);
      }

      if (["K3", "BKK", "SKK"].includes(parentType) && video.duration > 180) {
        setLengthError(allWords.misc.toastMsg.videoLength3min);
        return;
      }
    };
    video.src = URL.createObjectURL(e.target.files[0]);

    if (!videoFile) {
      ToastHandler("warn", "Please select video.");
      return false;
    }
    if (!videoFile.name.match(/\.(mp4|MP4)$/)) {
      ToastHandler("warn", allWords.misc.toastMsg.invalidVideoFormat);
      return false;
    }
    setAduioFilePath(null);
    setDocsFilePath(null);
    setImgArray([]);
    setPollState(false);

    if (e.target.files) {
      setVideoFile(e.target.files[0]);
      setVideoFilePath(URL.createObjectURL(e.target.files[0]));
    }

    setEditImage(false);
    setEditVideo(false);

    e.target.value = null;
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (!imageFile) {
      ToastHandler("warn", "Please select image.");
      return false;
    }
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG)$/)) {
      ToastHandler("warn", allWords.misc.toastMsg.invalidImgFormat);
      return false;
    }
    if (imageFile) {
      for (let i = 0; i < e.target.files.length; i++) {
        let size = e.target.files[i].size;
        if (Math.round(size / 1024) > 1024 * 15) {
          ToastHandler("warn", "Upload an image less than 15 MB.");
          setState(false);
          e.target.value = null;
          return false;
        }
      }
    }
    setVideoFilePath(null);
    setDocsFilePath(null);
    setAduioFilePath(null);
    setPollState(false);

    const images = e.target.files;
    if (images.length <= 4) {
      if (imgArray.length === 4) {
        ToastHandler("warn", allWords.misc.toastMsg.only4ImageAllowed);
      } else {
        if (e.target.files) {
          const file = Array.from(e.target.files).map((file) => {
            return {
              file: file,
              url: URL.createObjectURL(file),
            };
          });
          setImgArray((prevImg) => prevImg.concat(file));
        }
      }
    } else {
      ToastHandler("warn", allWords.misc.toastMsg.only4ImageAllowed);
    }

    setEditImage(false);

    e.target.value = null;
  };

  const handleDocsChange = (e) => {
    setDisable(false);
    setVideoFilePath(null);
    setAduioFilePath(null);
    setImgArray([]);
    setState(true);
    setPollState(false);
    const docfile = e.target.files[0];
    let filePath = docfile.name;

    // Allowing file type
    let allowedExtensions = /(\.pdf|\.xls|\.xlsx|\.doc|\.docx|\.ppt|\.pptx)$/i;
    if (!allowedExtensions.exec(filePath)) {
      ToastHandler(
        "warn",
        "Invalid file format. Please upload pdf, doc, ppt, xlx files."
      );
      setState(false);
      e.target.value = null;
      return false;
    }
    if (!docfile) {
      ToastHandler("warn", "Please select documents.");
      return false;
    }

    if (e.target.files[0]) {
      let size = e.target.files[0].size;

      if (Math.round(size / 1024) > 1024 * 15) {
        ToastHandler("warn", "Upload a file less than 15 MB.");
        setState(false);
        return false;
      }
      setDocsFilePath(URL.createObjectURL(e.target.files[0]));
    }
    if (docfile.name.match(/(\.pdf|\.PDF)$/i)) {
      setDocsFile(e.target.files[0]);
      setXlFile(null);
      setWordFile(null);
      setPptFile(null);
    } else if (docfile.name.match(/(\.xls|\.xlsx|\.XLS|\.XLSX)$/i)) {
      setXlFile(e.target.files[0]);
      setDocsFile(null);
      setWordFile(null);
      setPptFile(null);
    } else if (docfile.name.match(/(\.doc|\.docx|\.DOC|\.DOCX)$/i)) {
      setWordFile(e.target.files[0]);
      setDocsFile(null);
      setXlFile(null);
      setPptFile(null);
    } else if (docfile.name.match(/(\.ppt|\.pptx|\.PPT|\.PPTX)$/i)) {
      setPptFile(e.target.files[0]);
      setDocsFile(null);
      setWordFile(null);
      setXlFile(null);
    }

    e.target.value = null;
  };

  const handleAudioChange = (e) => {
    setImgArray([]);
    setDocsFilePath(null);
    setVideoFilePath(null);
    setPollState(false);

    const audiofile = e.target.files[0];

    if (!audiofile) {
      ToastHandler("warn", "Please select audio.");
      return false;
    }
    if (!audiofile.name.match(/\.(mp3)$/)) {
      ToastHandler("warn", allWords.misc.toastMsg.invalidAudioFormat);
      return false;
    }

    if (e.target.files) {
      setAduioFile(e.target.files[0]);
      setAduioFilePath(URL.createObjectURL(e.target.files[0]));
    }

    if (phoneInputValue && phoneInputValue.length < 10) {
      ToastHandler("warn", "Enter a valid phone number");
      return;
    }
    setEditImage(false);
    setEditVideo(false);

    e.target.value = null;
  };

  const handlePostSubmit = async () => {
    localStorage.removeItem("text_data");
    const formData = new FormData();

    const str1 = textInput.replace(/"/g, '\\"');
    const str2 = textInput.replace(/(?:\r\n|\r|\n)/g, " <br />");

    if (
      textInput.trim() === "" &&
      !imgArray.length > 0 &&
      !docsFilePath &&
      !audioFilePath &&
      !videoFilePath
    ) {
      ToastHandler("warn", allWords.misc.entersomeval);
      return;
    }

    // for snip it
    if (parentType === "SNIPPET" && !videoFilePath) {
      return ToastHandler("warn", "Enter video for Snip-It");
    }

    //K3
    if (parentType === "K3" && !videoFilePath) {
      return ToastHandler("warn", "Enter video for Khabri Ke Khabre");
    }
    //bol khulke
    if (parentType === "BKK" && !videoFilePath) {
      return ToastHandler("warn", "Enter video for Bol Khul Ke");
    }

    if (parentType === "SKK" && textInput.trim() === "" && !videoFilePath) {
      return ToastHandler("warn", allWords.misc.entersomeval);
    }

    if (parentType === "SKK" && !videoFilePath) {
      return ToastHandler("warn", "Enter video for Soch Khul Ke");
    }

    if (
      textInput &&
      !imgArray.length > 0 &&
      !docsFilePath &&
      !audioFilePath &&
      !videoFilePath
    ) {
      if (textInput.includes('"')) {
        formData.append("message", `{"type":"TEXT","text":"${str1}","organisationId": "${newOrgId ? newOrgId :  orgId}"}`);
      } else if (textInput.includes("\n")) {
        formData.append("message", `{"type":"TEXT","text":"${str2}","organisationId": "${newOrgId ? newOrgId :  orgId}"}`);
      } else {
        formData.append("message", `{"type":"TEXT","text":"${textInput}","organisationId": "${newOrgId ? newOrgId :  orgId}"}`);
      }
    } else if ((imgArray.length > 0 && textInput) || imgArray.length > 0) {
      if (imgArray.length === 1) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str1}","caption":["${imageCaption1}"],"tags":[""],"file_type":["IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str2}","caption":["${imageCaption1}"],"tags":[""],"file_type":["IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${textInput}","caption":["${imageCaption1}"],"tags":[""],"file_type":["IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        }

        formData.append("image", imgArray[0].file);
      }
      if (imgArray.length === 2) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str1}","caption":["${imageCaption1}","${imageCaption2}"],"tags":[""],"file_type":["IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str2}","caption":["${imageCaption1}","${imageCaption2}"],"tags":[""],"file_type":["IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${textInput}","caption":["${imageCaption1}","${imageCaption2}"],"tags":[""],"file_type":["IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        }

        for (let i in imgArray) {
          formData.append("image", imgArray[i].file);
        }
      }
      if (imgArray.length === 3) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str1}","caption":["${imageCaption1}","${imageCaption2}","${imageCaption3}"],"tags":[""],"file_type":["IMAGE","IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str2}","caption":["${imageCaption1}","${imageCaption2}","${imageCaption3}"],"tags":[""],"file_type":["IMAGE","IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${textInput}","caption":["${imageCaption1}","${imageCaption2}","${imageCaption3}"],"tags":[""],"file_type":["IMAGE","IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        }

        for (let i in imgArray) {
          formData.append("image", imgArray[i].file);
        }
      }
      if (imgArray.length === 4) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str1}","caption":["${imageCaption1}","${imageCaption2}","${imageCaption3}","${imageCaption4}"],"tags":[""],"file_type":["IMAGE","IMAGE","IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${str2}","caption":["${imageCaption1}","${imageCaption2}","${imageCaption3}","${imageCaption4}"],"tags":[""],"file_type":["IMAGE","IMAGE","IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"IMAGE","text":"${textInput}","caption":["${imageCaption1}","${imageCaption2}","${imageCaption3}","${imageCaption4}"],"tags":[""],"file_type":["IMAGE","IMAGE","IMAGE","IMAGE"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"],"usernames":"[]"}`
          );
        }

        for (let i in imgArray) {
          formData.append("image", imgArray[i].file);
        }
      }
    } else if ((videoFilePath && textInput) || videoFilePath) {
      if (!["K3", "BKK", "SKK"].includes(parentType) && videoDuration > 150) {
        return ToastHandler("warn", allWords.misc.toastMsg.maxvidoel);
      }
      if (["K3", "BKK", "SKK"].includes(parentType) && videoDuration > 180) {
        setVideoError(allWords.misc.toastMsg.videoLength3min);
        return;
      }

      if (textInput.includes('"')) {
        formData.append(
          "message",
          `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"VIDEO","text":"${str1}","caption":["${caption}"],"tags":[""],"file_type":["VIDEO"],"start_duration":["${
            startVideoTime || endVideoTime ? startVideoTime : null
          }"],"end_duration":["${
            endVideoTime ? endVideoTime : null
          }"],"duration":["${
            videoDuration ? videoDuration : null
          }"],"trim":["true"],"is_snap":["false"],"is_recorded":["false"]}`
        );
      } else if (textInput.includes("\n")) {
        formData.append(
          "message",
          `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"VIDEO","text":"${str2}","caption":["${caption}"],"tags":[""],"file_type":["VIDEO"],"start_duration":["${
            startVideoTime || endVideoTime ? startVideoTime : null
          }"],"end_duration":["${
            endVideoTime ? endVideoTime : null
          }"],"duration":["${
            videoDuration ? videoDuration : null
          }"],"trim":["true"],"is_snap":["false"],"is_recorded":["false"]}`
        );
      } else if (["BKK", "SKK"].includes(parentType)) {
        const messageObject = {
          type: parentType === "BKK" ? "BKK_POST" : "EXPERT_BKK_POST",
          media_type: "VIDEO",
          text: str1,
          caption: [caption],
          tags: [""],
          hash_tags: hashtags.length ? hashtags : [],
          version: "v1",
          with_username: selectedUserOption
            ? [selectedUserOption ? selectedUserOption._id : null]
            : [],
          location: selectedStateOption
            ? [
                {
                  state: selectedStateOption ? selectedStateOption.value : null,
                },
              ]
            : [],
          non_existing_user: selectedNewUserOption?.label || "",
          contact_no: phoneInputValue ? phoneInputValue : "",
          file_type: ["VIDEO"],
          start_duration: [
            startVideoTime || endVideoTime ? startVideoTime : null,
          ],
          end_duration: [endVideoTime ? endVideoTime : null],
          duration: [videoDuration ? videoDuration : null],
          trim: ["true"],
          is_snap: ["false"],
          is_recorded: ["false"],
        };

        formData.append("message", JSON.stringify(messageObject));
      } else if (["SNIPPET", "K3"].includes(parentType)) {
        formData.append(
          "message",
          `{"type":"${
            parentType === "SNIPPET"
              ? "SNIPPET"
              : parentType === "K3"
                ? "K3_POST"
                : "BKK_POST"
          }","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"VIDEO","text":"${str1}","caption":["${caption}"],"tags":[""],"file_type":["VIDEO"],"start_duration":["${
            startVideoTime || endVideoTime ? startVideoTime : null
          }"],"end_duration":["${
            endVideoTime ? endVideoTime : null
          }"],"duration":["${
            videoDuration ? videoDuration : null
          }"],"trim":["true"],"is_snap":["false"],"is_recorded":["false"]}`
        );
      } else {
        formData.append(
          "message",
          `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"VIDEO","text":"${textInput}","caption":["${caption}"],"tags":[""],"file_type":["VIDEO"],"start_duration":["${
            startVideoTime || endVideoTime ? startVideoTime : null
          }"],"end_duration":["${
            endVideoTime ? endVideoTime : null
          }"],"duration":["${
            videoDuration ? videoDuration : null
          }"],"trim":["true"],"is_snap":["false"],"is_recorded":["false"]}`
        );
      }
      formData.append("video", videoFile);
    } else if ((docsFilePath && textInput) || docsFilePath) {
      if (xlFile) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"XLS","text":"${str1}","caption":["${caption}"],"tags":[""],"file_type":["XLS"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"XLS","text":"${str2}","caption":["${caption}"],"tags":[""],"file_type":["XLS"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"XLS","text":"${textInput}","caption":["${caption}"],"tags":[""],"file_type":["XLS"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        }

        formData.append("doc", xlFile);
      } else if (docsFile) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"PDF","text":"${str1}","caption":["${caption}"],"tags":[""],"file_type":["PDF"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"PDF","text":"${str2}","caption":["${caption}"],"tags":[""],"file_type":["PDF"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"PDF","text":"${textInput}","caption":["${caption}"],"tags":[""],"file_type":["PDF"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        }

        formData.append("doc", docsFile);
      } else if (wordFile) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"DOC","text":"${str1}","caption":["${caption}"],"tags":[""],"file_type":["DOC"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"DOC","text":"${str2}","caption":["${caption}"],"tags":[""],"file_type":["DOC"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"DOC","text":"${textInput}","caption":["${caption}"],"tags":[""],"file_type":["DOC"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        }

        formData.append("doc", wordFile);
      } else if (pptFile) {
        if (textInput.includes('"')) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"PPTX","text":"${str1}","caption":["${caption}"],"tags":[""],"file_type":["PPTX"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else if (textInput.includes("\n")) {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"PPTX","text":"${str2}","caption":["${caption}"],"tags":[""],"file_type":["PPTX"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        } else {
          formData.append(
            "message",
            `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"PPTX","text":"${textInput}","caption":["${caption}"],"tags":[""],"file_type":["PPTX"],"start_duration":[null],"end_duration":[null],"duration":[null],"trim":[null],"is_snap":["false"],"is_recorded":["false"]}`
          );
        }

        formData.append("doc", pptFile);
      }
    } else if ((audioFilePath && textInput) || audioFilePath) {
      if (audioFile.size / 10 ** 6 > 250)
        return ToastHandler(
          "warn",
          "Upload a audio file less than 250 MB with maximum duration as 2min 30sec."
        );

      if (audioDuration > 150)
        return ToastHandler(
          "warn",
          allWords.misc.toastMsg.audioLengthValidation
        );

      if (textInput.includes('"')) {
        formData.append(
          "message",
          `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"AUDIO","text":"${str1}","caption":["${caption}"],"tags":[""],"file_type":["AUDIO"],"start_duration":["${
            startAudioTime || endAudioTime ? startAudioTime : null
          }"],"end_duration":["${
            endAudioTime ? endAudioTime : null
          }"],"duration":["${
            audioDuration ? audioDuration : null
          }"],"trim":["true"],"is_snap":["false"],"is_recorded":["false"]}`
        );
      } else if (textInput.includes("\n")) {
        formData.append(
          "message",
          `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"AUDIO","text":"${str2}","caption":["${caption}"],"tags":[""],"file_type":["AUDIO"],"start_duration":["${
            startAudioTime || endAudioTime ? startAudioTime : null
          }"],"end_duration":["${
            endAudioTime ? endAudioTime : null
          }"],"duration":["${
            audioDuration ? audioDuration : null
          }"],"trim":["true"],"is_snap":["false"],"is_recorded":["false"]}`
        );
      } else {
        formData.append(
          "message",
          `{"type":"POST","organisationId": "${newOrgId ? newOrgId :  orgId}","media_type":"AUDIO","text":"${textInput}","caption":["${caption}"],"tags":[""],"file_type":["AUDIO"],"start_duration":["${
            startAudioTime || endAudioTime ? startAudioTime : null
          }"],"end_duration":["${
            endAudioTime ? endAudioTime : null
          }"],"duration":["${
            audioDuration ? audioDuration : null
          }"],"trim":["true"],"is_snap":["false"],"is_recorded":["false"]}`
        );
      }

      formData.append("audio", audioFile);
    }

    dispatch(
      addPostData(formData, parentType, ({ err, data }) => {
        if (err) {
          return logger.info(err);
        }

        if (!["SNIPPET", "K3", "BKK", "SKK"].includes(parentType)) {
          setAddPost(false);
          return ToastHandler("sus", `${allWords.misc.created_post_success}`);
        }

        let parentTypeForLink = "";

        if (parentType === "SNIPPET") {
          parentTypeForLink = "snip-it";
        } else if (parentType === "BKK") {
          parentTypeForLink = "bol-khulke";
        } else if (parentType === "SKK") {
          parentTypeForLink = "soch-khulke";
        } else if (parentType === "K3") {
          parentTypeForLink = "k3";
        } else {
          parentTypeForLink = "posts";
        }

        const postId = data?.[0]?.post_id;
        dispatch(
          setSharLink(
            `${window.location.origin}/${parentTypeForLink}?${postId}`
          )
        );
        dispatch(setDialogStatus(true));
        dispatch(setPostParentType(parentType));
        setAddPost(false);
      })
    );

    if (["SNIPPET", "K3", "BKK", "SKK"].includes(parentType)) {
      setAddPost(false);
      ToastHandler("info", allWords.misc.toastMsg.postUploadingWait);
    }
  };

  useEffect(() => {
    setDialogTitle(allWords.misc.addPoste);
    if (post_res) {
      dispatch(getPostData(20));
      allPostData?.data?.old_post?.filter((val) => {
        if (val?.post_id === post_res[0]?.post_id) {
          setAddPost(true);
        }
      });
    }
  }, [post_res]);

  const inputRef = useRef("");

  function onCaptionChange(e, index) {
    setCaptionLength(e.target.value.length);
    if (index === 0) {
      setImageCaption1(e.target.value);
    }
    if (index === 1) {
      setImageCaption2(e.target.value);
    }
    if (index === 2) {
      setImageCaption3(e.target.value);
    }
    if (index === 3) {
      setImageCaption4(e.target.value);
    }
  }

  function onDeleteCaption(e, index) {
    if (index === 0) {
      setImageCaption1(imageCaption2);
      setImageCaption2(imageCaption3);
      setImageCaption3(imageCaption4);
      return setImageCaption4("");
    }
    if (index === 1) {
      setImageCaption1(imageCaption1);
      setImageCaption2(imageCaption3);
      setImageCaption3(imageCaption4);
      return setImageCaption4("");
    }
    if (index === 2) {
      setImageCaption1(imageCaption1);
      setImageCaption2(imageCaption2);
      setImageCaption3(imageCaption4);
      return setImageCaption4("");
    }
    if (index === 3) {
      setImageCaption1(imageCaption1);
      setImageCaption2(imageCaption2);
      setImageCaption3(imageCaption3);
      return setImageCaption4("");
    }

    if (imgArray.length < 1) {
      setImageCaption1("");
      setImageCaption2("");
      setImageCaption3("");
      setImageCaption4("");
    }
  }

  const handleCrop = () => {
    editImageRef.current?.setCroppedImage();
    setEditImage(false);
  };

  // poll toggle
  const handlePollToggle = async () => {
    setPollState(!pollState);
    setVideoFilePath(null);
    setDocsFilePath(null);
    setAduioFilePath(null);
    setImgArray([]);
  };

  const handlePollSubmit = () => {
    if (
      textInput === "" &&
      !imgArray.length > 0 &&
      !docsFilePath &&
      !audioFilePath &&
      !videoFilePath &&
      !localStorage.getItem("input_text") &&
      String(pollData.option[0]) === "undefined" &&
      String(pollData.option[1]) === "undefined"
    ) {
      ToastHandler("warn", allWords.misc.entersomeval);
      return;
    }

    if (textInput === "") {
      ToastHandler("warn", "Kindly provide the question for the Poll.");
      return;
    }

    if (state_data?.length === 2) {
      if (
        (state_data?.[0]?.["length"] !== 0 &&
          state_data?.[1]?.["length"] === 0) ||
        (state_data?.[0]?.["length"] === 0 &&
          state_data?.[1]?.["length"] !== 0) ||
        state_data?.[0]?.["length"] === 0 ||
        state_data?.[1]?.["length"] === 0
      ) {
        ToastHandler("warn", "Minimum 2 options should be there for Poll.");
      }
    } else {
      if (
        state_data?.[0]?.["length"] === 0 ||
        state_data?.[1]?.["length"] === 0 ||
        state_data?.[2]?.["length"] === 0 ||
        state_data?.[3]?.["length"] === 0
      ) {
        ToastHandler("warn", "Kindly fill the option or delete that.");
      }
    }

    if (
      Number(pollData.duration[0]) === 0 &&
      Number(pollData.duration[2]) === 0 &&
      Number(pollData.duration[4]) === 0
    ) {
      ToastHandler("warn", "Duration cannot be empty.");
      return;
    }

    let str = "";
    if (textInput.includes('"')) {
      str = textInput.replace(/"/g, '\\"');
    } else if (textInput.includes("\n")) {
      str = textInput.replace(/(?:\r\n|\r|\n)/g, " <br /> ");
    } else {
      str = textInput;
    }

    const data = new FormData();
    if (
      state_data?.[0]?.["length"] !== 0 &&
      state_data?.[1]?.["length"] !== 0 &&
      state_data?.[2]?.["length"] === undefined &&
      state_data?.[3]?.["length"] === undefined
    ) {
      data.append(
        "message",
        `{"question":"${str}","option":["${pollData.option[0]}","${
          pollData.option[1]
        }"],"start_date":"${String(
          moment.utc().format()
        )}","duration":"${String(pollData.duration)}"}`
      );
    } else if (
      state_data?.[0]?.["length"] !== 0 &&
      state_data?.[1]?.["length"] !== 0 &&
      state_data?.[2]?.["length"] !== 0 &&
      String(pollData.option[2]) !== "undefined" &&
      state_data?.[3]?.["length"] === undefined
    ) {
      data.append(
        "message",
        `{"question":"${str}","option":["${String(
          pollData.option[0]
        )}","${String(pollData.option[1])}","${String(
          pollData.option[2]
        )}"],"start_date":"${String(
          moment.utc().format()
        )}","duration":"${String(pollData.duration)}"}`
      );
    } else if (
      state_data?.[0]?.["length"] !== 0 &&
      state_data?.[1]?.["length"] !== 0 &&
      state_data?.[2]?.["length"] !== 0 &&
      String(pollData.option[2]) !== "undefined" &&
      state_data?.[3]?.["length"] !== 0 &&
      String(pollData.option[3]) !== "undefined"
    ) {
      data.append(
        "message",
        `{"question":"${str}","option":["${String(
          pollData.option[0]
        )}","${String(pollData.option[1])}","${String(
          pollData.option[2]
        )}","${String(pollData.option[3])}"],"start_date":"${String(
          moment.utc().format()
        )}","duration":"${String(pollData.duration)}"}`
      );
    }

    data.append("device_info", JSON.stringify(device_info));

    const config = {
      method: "post",
      url: `${POST_API_BASE_URL}/post/add-polling-post`,
      headers: {
        "device-type": "web",
        Authorization: `Bearer ${localStorage.access}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.removeItem("input_text");
          if (window.location.pathname === "/home") {
            setDayDuration(Number(pollData.duration[0]));
            setHourDuration(Number(pollData.duration[2]));
            //
          }
        }
        dispatch(getPostData(20));
        setAddPost(false);
      })
      .catch();
  };

  const getUserCustOrgDetails = () => {

    const config = {
      method: "get",
      url: `${REACT_APP_USER_ONBOARDING_URL}/user/v1/auth/user-custom-org-details/?limit=10&offset=0`,
      headers: {
        Authorization: `Bearer ${localStorage.access}`,
      }
    };
    const defaultOptions = [{ value: 'Option 0', label: 'Everyone' , username:"Everyone"}]

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          const optionsFromAPI = response?.data?.data?.map((item, index) => ({
            value: `Option ${index + 1}`,
            label: item.org,
            username: item.org_profile_name // If available in the response data
          }));
          setOptions([...defaultOptions,...optionsFromAPI])
        }
      })
      .catch();
  }

  useEffect(() => {
    setIsMouseHovered(true);
    getUserCustOrgDetails()
  }, []);
  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              {parentType === "SNIPPET"
                ? allWords.th.snipit
                : parentType === "K3"
                  ? allWords.K3.create
                  : parentType === "BKK"
                    ? allWords.bkk.create
                    : parentType === "SKK"
                      ? allWords.bkk.create
                      : allWords.th.post}
            </p>
            <div>
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.1, opacity: 0.8 }}
              >
                <IconButton
                  sx={{ color: "#fff !important", marginBottom: "8px" }}
                  onClick={() => {
                    setDiscard(true);
                    isOrg ? onClose() : ""
                    if (["SNIPPET", "K3", "BKK", "SKK"].includes(parentType)) {
                      setMsgFlag({ title: "", flag: false });
                    }
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </motion.div>
            </div>
          </div>
        </DialogTitle>
        {isOrg && (
        <div className="dropdown">
          <button className="dropbtn" onClick={toggleDropdown}>
            This post will be visible to{" "}
            <span style={{ color: "#F15B29" }}>{neworgName}</span>
          </button>
          {showDropdown && (
            <div className="dropdown-content">
              <p style={{marginLeft: "10px", width: "184px", height: "19px", textAlign: "left", font: "normal normal 600 16px / 38px Work Sans",
              letterSpacing: "0px", color: "#F4F4F4", opacity: "1"}}>Who can see this post?</p>
              <p style={{margin: "12px", width: "207px", height: "34px", textAlign: "left", font: "normal normal normal 14px / 18px Work Sans",
              letterSpacing: "0px", color: "#A2A2A2", opacity: "1"}}>Choose where you want your post to be visible.</p>
              <ul style={{ listStyleType: "none", padding: 0, marginLeft: ".7rem", marginTop: "12px" }}>
                {options.map(option => (
                  <li
                    key={option.label}
                    onClick={() => handleDropdownChange(option.value)} // Assuming handleDropdownChange is defined elsewhere
                    style={{ marginBottom: '1rem', cursor: 'pointer' }}
                  >
                    <UserProfile username={option.username} className="avatar" width={38} height={38} />
                    {`  ${option.label}`}
                    {selectedOption === option.value && (
                      <CheckIcon
                        style={{
                          color: 'rgb(241, 91, 41)',
                          position: 'fixed',
                          right: '39rem',
                          fontWeight: 'bold'
                        }}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
        <DialogContent
          style={{
            overflowY: "scroll",
            paddingBottom: lengthError ? "" : "0",
            paddingBottom: lengthError ? "" : "0",
          }}
        >
          <MainDiv>
            {editImage ? (
              <>
                <EditImage
                  imageSrc={imageSrc}
                  style={{
                    width: "100%",
                  }}
                  setImgArray={setImgArray}
                  ref={editImageRef}
                />
              </>
            ) : (
              <>
                <div className="input_container">
                  <UserProfile
                    borderRadius="50%"
                    height="46px"
                    width="46px"
                    username={
                      JSON.parse(
                        localStorage.current_user || localStorage.anonymous_user
                      )?.["username"]
                    }
                  />

                  <div
                    style={{
                      width: "26.4rem",
                      marginLeft: "0.2rem",
                      height: "9rem",
                      borderRadius: "8px",
                      padding: "1px",
                      marginBottom: "1rem",
                    }}
                  >
                    <UserListInput
                      isOrg={isOrg}
                      name="add dialog"
                      placeholder={
                        isOrg ? "Write what you feel about this...                               0/300" :
                        parentType === "K3"
                          ? allWords.K3.description
                          : parentType === "BKK"
                            ? allWords.bkk.description
                            : parentType === "SKK"
                              ? allWords.ekk.description
                              : allWords.th.post_placeholder
                      }
                      className="post-area addDialogCSs addPostDilogUserListInpt"
                      refs={inputRef}
                      maxLength={textCount === 0 ? 300 : ""}
                      value={textInput}
                      listDirection="bottom"
                      onChange={(e) => {
                        const txt = e.target.value;
                        const urlRegex = /\bhttps?:\/\/\S+/gi;
                        let charCount;

                        const allLinks = txt.match(urlRegex);

                        if (allLinks) {
                          const allLinksSize = allLinks.join("").length;
                          charCount =
                            txt.length - allLinksSize + allLinks.length * 4;
                        } else {
                          charCount = txt.length;
                        }

                        if (charCount <= 300) {
                          setTextCount(charCount);
                        } else {
                          setTextCount(300);
                        }

                        if (charCount <= 300) {
                          setTextInput(e.target.value);
                        }

                        setValue(value + 1);

                        if (localStorage.getItem("text_data")) {
                          localStorage.setItem("text_data", e.target.value);
                        }
                      }}
                    />
                    {!isOrg && <p style={{ margin: 0, opacity: "0.6", textAlign: "end" }}>
                      {textCount >= 0 && textCount <= 300
                        ? 0 + textCount
                        : 300}
                      /300 {allWords.th.characters}
                    </p>}
                  </div>
                </div>

                <Grid container spacing={2} className="main_container">
                  {videoFilePath && (
                    <Grid item md={12}>
                      <VideoContainer
                        setVideoDuration={setVideoDuration}
                        setStartVideoTime={setStartVideoTime}
                        setEndVideoTime={setEndVideoTime}
                        setCaption={setCaption}
                        showCaption
                        controls
                        editVideo={editVideo}
                        src={videoFilePath}
                        videoFilePath={videoFilePath}
                        setVideoFilePath={setVideoFilePath}
                        setEditVideo={setEditVideo}
                        setDialogTitle={setDialogTitle}
                        videoDuration={videoDuration}
                        videoFile={videoFile}
                        setState={setState}
                        endVideoTime={endVideoTime}
                        parentType={parentType}
                        setLengthError={setLengthError}
                      />
                      {lengthError && <VidErrorDiv>{lengthError}</VidErrorDiv>}
                      {["BKK", "SKK"].includes(parentType) && (
                        <Container>
                          <InputWrapper>
                            <StyledUserTagIcon />
                            <StyledCreatableSelect
                              styles={customStyles}
                              menuPlacement="top"
                              closeMenuOnSelect={true}
                              backspaceRemovesValue={true}
                              isClearable={true}
                              options={userTags}
                              isSearchable={true}
                              placeholder={allWords.bkk.tagUsers}
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null,
                                Option: FormatOptionLabel,
                                SingleValue,
                              }}
                              onInputChange={handleUserInputChange}
                              onChange={handleSelectChange}
                              onBlur={handleBlur}
                              value={
                                selectedUserOption ? selectedUserOption : ""
                              }
                            />
                          </InputWrapper>
                          <HashInputWrapper>
                            <StyledPhoneIcon />
                            <div
                              style={{ width: "100%", height: "100%" }}
                              onMouseEnter={() => setIsMouseHovered(true)}
                            >
                              {isMouseHovered ? (
                                <StyledHashInput
                                  type="text"
                                  value={phoneInputValue}
                                  onChange={handlePhoneInputChange}
                                  placeholder={allWords.bkk.addPhoneNo}
                                  onKeyDown={(e) =>
                                    e.key === "Enter" &&
                                    setIsMouseHovered(false)
                                  }
                                  onBlur={() =>
                                    phoneInputValue && setIsMouseHovered(false)
                                  }
                                />
                              ) : (
                                <div className="parent-input-for-soch">
                                  <p>{phoneInputValue}</p>
                                </div>
                              )}
                            </div>
                          </HashInputWrapper>
                          <InputWrapper>
                            <StyledLocationIcon />
                            <StyledSelect
                              styles={customStyles}
                              menuPlacement="top"
                              isClearable={true}
                              placeholder={allWords.bkk.addLocation}
                              options={statesOptions}
                              onChange={handleStateSelectChange}
                              value={
                                selectedStateOption ? selectedStateOption : ""
                              }
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null,
                              }}
                            />
                          </InputWrapper>
                          <HashInputWrapper>
                            <StyledHashIcon />
                            <StyledHashInput
                              type="text"
                              value={hashInputValue}
                              onChange={handleHashInputChange}
                              onKeyDown={handleHashKeyDown}
                              placeholder={allWords.bkk.addhasgtags}
                            />
                          </HashInputWrapper>
                          <HashtagList>
                            <HashInputWrapper style={{ width: "100%" }}>
                              <StyledHashIcon
                                style={{ visibility: "hidden" }}
                              />
                              {hashtags.map((hashtag, index) => (
                                <HashtagItem key={index}>
                                  {truncate(hashtag, { length: 10 })}
                                  <RemoveButton
                                    onClick={() => removeHashtag(index)}
                                  />
                                </HashtagItem>
                              ))}
                            </HashInputWrapper>
                          </HashtagList>
                        </Container>
                      )}
                    </Grid>
                  )}
                  {imgArray.length > 0 && (
                    <>
                      {imgArray.map((item, index) => {
                        return (
                          <>
                            <Grid
                              item
                              md={imgArray.length < 2 ? 12 : 6}
                              style={index < 2 ? { paddingTop: "0" } : {}}
                              key={item}
                            >
                              <ImageContainer
                                captionLength={captionLength}
                                onCaptionChange={(e) =>
                                  onCaptionChange(e, index)
                                }
                                onDeleteCaption={(e) =>
                                  onDeleteCaption(e, index)
                                }
                                imageCaption={imageCaptionPropHandler(index)}
                                setCaption={setCaption}
                                imageSrc={imageSrc}
                                setImageSrc={setImageSrc}
                                editImage={editImage}
                                setEditImage={setEditImage}
                                imgArray={imgArray}
                                setImgArray={setImgArray}
                                index={index}
                                imgSrc={item}
                              />
                            </Grid>
                          </>
                        );
                      })}
                    </>
                  )}
                  {docsFilePath && (
                    <div
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        width: "100%",
                      }}
                    >
                      <DocsContainer
                        showCaption
                        docsFilePath={docsFilePath}
                        handle
                        docsFile={docsFile?.name}
                        excelDoc={xlFile?.name}
                        pptDoc={pptFile?.name}
                        wordDoc={wordFile?.name}
                      />
                    </div>
                  )}
                  {audioFilePath && (
                    <>
                      {audioFilePath && (
                        <AudioContainer
                          setAudioDuration={setAudioDuration}
                          setStartAudioTime={setStartAudioTime}
                          setEndAudioTime={setEndAudioTime}
                          setCaption={setCaption}
                          showCaption
                          user
                          audioFilePath={audioFilePath}
                          setAduioFilePath={setAduioFilePath}
                          startAudioTime={startAudioTime}
                          endAudioTime={endAudioTime}
                          isPlaying
                          setState={setState}
                          audioFile={audioFile}
                        />
                      )}
                    </>
                  )}
                  {/* poll  view*/}
                  {pollState && (
                    <div style={{ marginTop: "1rem" }}>
                      <PollFormContainer
                        setPollData={setPollData}
                        setStateData={setStateData}
                      />
                    </div>
                  )}
                </Grid>
              </>
            )}
          </MainDiv>
        </DialogContent>

        <DialogActions>
          {editImage && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                gap: "2rem",
                alignItems: "center",
              }}
            >
              <Button
                primary
                onClick={() => {
                  setEditImage(false);
                  setDialogTitle(allWords.misc.addPoste);
                }}
              >
                {allWords.misc.cancel}
              </Button>
              <Button bgColor onClick={handleCrop}>
                {allWords.misc.save}
              </Button>
            </div>
          )}
          {!editImage && (
            <IconContainer>
              <div className="d-flex add_post_all_icons_div">
                {/* videos */}
                &emsp;
                <IconButton
                  variant="contained"
                  component="label"
                  className="add_post_video_icon"
                  // style={{ paddingLeft: "5px" }}
                >
                  <VideoIcon />
                  <input
                    type="file"
                    accept="video/*"
                    hidden
                    onChange={handleVideoChange}
                  />
                </IconButton>{" "}
                <>&emsp;</>
                {/* images */}
                <IconButton
                  variant="contained"
                  component="label"
                  hidden={["SNIPPET", "K3", "BKK", "SKK"].includes(parentType)}
                >
                  <PhotoIcon />
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    hidden
                    onChange={handleImageChange}
                  />
                </IconButton>
                <>&emsp;</>
                {/* docs */}
                <IconButton
                  variant="contained"
                  component="label"
                  hidden={["SNIPPET", "K3", "BKK", "SKK"].includes(parentType)}
                >
                  <DocsIcon />
                  <input
                    type="file"
                    accept=".doc,.docx,.pdf,.xlsx,.xls,.ppt,.pptx"
                    hidden
                    onChange={handleDocsChange}
                  />
                </IconButton>
                <>&emsp;</>
                {/* audio */}
                <IconButton
                  variant="contained"
                  component="label"
                  hidden={["SNIPPET", "K3", "BKK", "SKK"].includes(parentType)}
                >
                  <input
                    type="file"
                    accept="audio/*"
                    hidden
                    onChange={handleAudioChange}
                  />
                  <AudioIcon />
                </IconButton>
                <>&emsp;</>
                {/* poll icon */}
                <IconButton
                  variant="contained"
                  onClick={handlePollToggle}
                  hidden={["SNIPPET", "K3", "BKK", "SKK"].includes(parentType)}
                  className="add_post_poll_icon"
                >
                  <img
                    src={PollIcon}
                    style={{
                      width: "1.4rem",
                      height: "1.4rem",
                    }}
                    alt="poll-icon"
                  />
                </IconButton>
              </div>
              {addPostLoading ? (
                <>
                  <CircularProgress
                    style={{ color: "#66B984", width: 40, height: 40 }}
                  />
                </>
              ) : (
                <AddPostBtn
                  disabled={disable}
                  style={{ marginRight: "1.6rem" }}
                  type="submit"
                  onClick={() => {
                    if (
                      localStorage.anonymous_user &&
                      !localStorage.current_user
                    )
                      return setModalOpen(true);

                    if (pollState) {
                      handlePollSubmit();
                      setMsgFlag({ title: "Poll", flag: true });
                    } else {
                      handlePostSubmit();
                      if (
                        textInput.trim() === "" &&
                        !imgArray.length > 0 &&
                        !docsFilePath &&
                        !audioFilePath &&
                        !videoFilePath
                      ) {
                        setMsgFlag({ title: "", flag: false });
                      } else setMsgFlag({ title: "Post", flag: true });
                    }
                  }}
                >
                  {allWords.th.post}
                </AddPostBtn>
              )}
            </IconContainer>
          )}
        </DialogActions>
      </Dialog>
      <PreloginComp
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        icon={
          <AddCircleIcon
            color={"success"}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        }
        title={allWords.th.prelogMessagePost}
        description={""}
      />
    </>
  );
};

export default AddPostDialog;
