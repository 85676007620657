import * as React from "react";

function CommunityGuidlinesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 28 30"
      {...props}
    >
      <g id="noun-guidelines-5814860" transform="translate(-30.266 -107.36)">
        <path
          id="Path_100834"
          data-name="Path 100834"
          d="M188.765,129.715H174.182a2.044,2.044,0,0,1-2.042-2.042V109.4a2.044,2.044,0,0,1,2.042-2.042h14.583a2.044,2.044,0,0,1,2.042,2.042v18.271A2.044,2.044,0,0,1,188.765,129.715Zm-14.583-21.444a1.132,1.132,0,0,0-1.131,1.131v18.271a1.132,1.132,0,0,0,1.131,1.131h14.583a1.132,1.132,0,0,0,1.131-1.131V109.4a1.132,1.132,0,0,0-1.131-1.131Z"
          transform="translate(-141.874 0)"
          fill="#fff"
        />
        <path
          id="Path_100835"
          data-name="Path 100835"
          d="M391.744,226.681h-9.968a.455.455,0,1,1,0-.911h9.968a.455.455,0,0,1,0,.911Z"
          transform="translate(-345.099 -115.039)"
          fill="#fff"
        />
        <path
          id="Path_100836"
          data-name="Path 100836"
          d="M388.612,325.831h-6.837a.456.456,0,1,1,0-.911h6.837a.456.456,0,0,1,0,.911Z"
          transform="translate(-345.099 -211.367)"
          fill="#fff"
        />
        <path
          id="Path_100837"
          data-name="Path 100837"
          d="M391.744,435.341h-9.968a.456.456,0,1,1,0-.911h9.968a.456.456,0,0,1,0,.911Z"
          transform="translate(-345.099 -317.759)"
          fill="#fff"
        />
        <path
          id="Path_100838"
          data-name="Path 100838"
          d="M388.612,534.481h-6.837a.455.455,0,1,1,0-.911h6.837a.455.455,0,1,1,0,.911Z"
          transform="translate(-345.099 -414.077)"
          fill="#fff"
        />
        <path
          id="Path_100839"
          data-name="Path 100839"
          d="M391.744,643.991h-9.968a.455.455,0,1,1,0-.911h9.968a.455.455,0,1,1,0,.911Z"
          transform="translate(-345.099 -520.47)"
          fill="#fff"
        />
        <path
          id="Path_100840"
          data-name="Path 100840"
          d="M388.612,743.141h-6.837a.455.455,0,1,1,0-.911h6.837a.455.455,0,1,1,0,.911Z"
          transform="translate(-345.099 -616.797)"
          fill="#fff"
        />
        <path
          id="Path_100841"
          data-name="Path 100841"
          d="M237.877,253.705h0a.456.456,0,0,1-.322-.133l-.911-.911a.455.455,0,1,1,.644-.644l.589.589,1.369-1.37a.456.456,0,0,1,.644.644l-1.691,1.692A.456.456,0,0,1,237.877,253.705Z"
          transform="translate(-204.412 -139.651)"
          fill="#fff"
        />
        <path
          id="Path_100842"
          data-name="Path 100842"
          d="M238.158,660.1a.456.456,0,0,1-.322-.134l-.911-.911a.455.455,0,0,1,.644-.644l.589.589,1.369-1.37a.456.456,0,1,1,.644.644l-1.692,1.691A.456.456,0,0,1,238.158,660.1Z"
          transform="translate(-204.693 -534.472)"
          fill="#fff"
        />
        <path
          id="Path_100843"
          data-name="Path 100843"
          d="M244.771,449.131a.455.455,0,0,1-.321-.777l2.16-2.16a.455.455,0,1,1,.644.644L245.094,449A.454.454,0,0,1,244.771,449.131Z"
          transform="translate(-211.996 -329.058)"
          fill="#fff"
        />
        <path
          id="Path_100844"
          data-name="Path 100844"
          d="M246.93,449.131a.453.453,0,0,1-.322-.133l-2.159-2.16a.455.455,0,1,1,.644-.644l2.16,2.16a.455.455,0,0,1-.322.777Z"
          transform="translate(-211.994 -329.058)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
const MemoCommunityGuidlinesIcon = React.memo(CommunityGuidlinesIcon);
export default MemoCommunityGuidlinesIcon;
