import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UserProfile from "../UserProfile";

const IconContainer = styled.div`
  width: 100%;
  /* height: 50px; */
  margin-top: 0.563rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;

  @media (max-width: 480px) {
    flex-direction: row;
    justify-content: start;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    flex-direction: row;
    justify-content: flex-start;
  }
  @media (min-width: 768px) and (max-width: 968px) {
    flex-direction: row;
    justify-content: flex-start;
  }
  .nav_title {
    font-size: 20px;
    color: #87848b;
  }
  .icon_container {
    padding: 10px 0px 15px 15px;
    display: flex;
    // margin-right:10px;
    .selected_icon {
      color: ${(props) => props.theme.color.white};
    }
    .selected_title {
      font-weight: bold;
      color: ${(props) => props.theme.color.white};
      font-size: 20px;
    }

    .icon {
      width: 25px;
      height: 25px;
      margin-right: 1rem;
      color: ${(props) => props.theme.color.white};
    }
    :hover {
      border-radius: 10px;
      cursor: pointer;
      .nav_title {
        transition: 0.3s;
        :hover {
          transition: 0.3s;
        }
      }
    }
  }
  :hover {
    background-color: #18141F;
    border-radius: 10px;
    cursor: pointer;
    .nav_title {
      transition: 0.3s;
      :hover {
        transition: 0.3s;
      }
    }
  }
`;

export const Title = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.color.white};
  font-size: 1.125rem;
  font-family: "Work Sans";
  @media (max-width: 480px) {
    display: inline-flex;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 969px) {
    display: inline-flex;
  }
`;
export const Notification = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  background-color: ${(props) => props.theme.color.flamingo};
  color: ${(props) => props.theme.color.blackBackground};
  font-family: "Work Sans";
  font-size: 12px;
  align-self: center;
  margin-top: -5px;
`;

const ListItem = ({
  Icon,
  title,
  username,
  path,
  notifications,
  selected,
  iconColor,
  onClick,
  style,
  comp,
  className,
  txt,
  is_icon_sidebar,
}) => {
  return (
    <IconContainer className={className} onClick={onClick}>
      <div
        className="icon_container"
        style={
          is_icon_sidebar
            ? { padding: "10px 12px 15px 12px" }
            : { padding: "10px 0px 15px 16px" }
        }
        data-test="src-components-LeftSideBar-ListItem-0"
      >
        <Link
          to={path}
          style={{
            textDecoration: "none",
          }}
        >
          {Icon && !comp && (
            <>
              {txt !== "profile" ? (
                <img
                  alt="this is image"
                  src={Icon}
                  className={`icon  ${selected && "selected_icon"}  ${
                    iconColor && "iconColor"
                  }`}
                  style={{ borderRadius: txt === "profile" ? 8 : 0 }}
                  data-test="src-components-LeftSideBar-ListItem-1"
                />
              ) : (
                <span
                  style={{ paddingRight: "0.9rem" }}
                  data-test="src-components-LeftSideBar-ListItem-2"
                >
                  <UserProfile height={30} width={30} username={username} />
                </span>
              )}
            </>
          )}
        </Link>
        {comp && (
          <Link exact to={path}>
            <Icon
              className={`icon  ${selected && "selected_icon"}  ${
                iconColor && "iconColor"
              }`}
              style={style}
            />
          </Link>
        )}
        {is_icon_sidebar && (
          <Title
            to={path}
            className={`nav_title ${selected && "selected_title"}`}
            activeclassname="active"
          >
            {title}
          </Title>
        )}
      </div>
      {is_icon_sidebar
        ? notifications && (
            <Notification style={{ marginRight: "5px" }}>
              {notifications}
            </Notification>
          )
        : ""}
    </IconContainer>
  );
};

export default ListItem;
