import { getMeetupTokenKeyAPI } from "../../../apis/meetupApi";
import * as actionTypes from "../actionTypes";

export const getMeetupEncryptionKeyStart = () => {
  return {
    type: actionTypes.MEETUP_TOKEN_KEY_GENERATION_START,
  };
};

export const getMeetupEncryptionKeySuccess = (data) => {
  return {
    type: actionTypes.MEETUP_TOKEN_KEY_GENERATION_SUCCESS,
    payload: data,
  };
};

export const getMeetupEncryptionKeyFailure = () => {
  return {
    type: actionTypes.MEETUP_TOKEN_KEY_GENERATION_FAILURE,
  };
};

export const getMeetupEncryptionKeyToken = () => {
    return async (dispatch) => {
      try {
        dispatch(getMeetupEncryptionKeyStart());
        const response = await getMeetupTokenKeyAPI();
        dispatch(getMeetupEncryptionKeySuccess(response));
      } catch (err) {
        dispatch(getMeetupEncryptionKeyFailure());
      }
    }
  }
