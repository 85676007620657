import styled from "styled-components";

export const LeftSideBarDiv = styled.div`
  width: 100%;
  height: 100vh;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 0;
    ::-webkit-scrollbar {
      width: 0;     
    }
  }

  @media screen and (min-width: 1024px) {
    border-right: 1px solid #47424c;
    ::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  @media screen and (min-width: 1980px) {
    justify-content: flex-start;
  }
`;

export const Div = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Cross = styled.img`
  height: 40px;
  width: 30px;
  margin-top: 10px;

  @media only screen and (min-width: 900px) {
    display: none;
    visibility: hidden;
  }
`;
