const allWordsM = {
  // Townhall
  th: {
    // header
    townhall: "टाऊनहॉल",
    latest: "लेटेस्ट",
    news: "ANI न्यूज",
    expertKhulke: "सोच खुल के",
    timeline: "टाइमलाइन",
    meet: "मीटअप",
    snipit: "स्निप-इट",
    scrolls: "स्क्रोल्स",
    home: "होम",
    // left sidebar
    rt: "राउंडटेबल",
    yapp: "याप",
    noti: "नोटीफीकेशन",
    profile: "प्रोफाइल",
    post: "पोस्ट",
    newpost: "नवीन पोस्ट",
    create: "निर्मिती",
    k3: "डी 2 एम",
    bkk: "बोल खुल के",
    // left sidebar suggestions (<SuggestedToYou/>)
    suggested: {
      sugTitle: "तुमच्यासाठी सुचवलेलं",
      follow: "फॉलो करा",
      error: "काहीतरी चुकीचं झालं! नंतर पुन्हा प्रयत्न करा!",
      noUser: "समान आवड स्वारस्यांसह युजर सापडला नाही",
      noResult: "कोणतेही परिणाम सापडले नाहीत",
      pre: "फॉलो करण्यासाठी, खुल के मध्ये साइन इन किंवा साइन अप करा",
    },
    // left bar for anonymous
    loginCTA: "संवाद साधण्यासाठी लॉग इन करा",
    loginBtn: "लॉग इन",
    // popup menu
    profile: "प्रोफाइल",
    accSettings: "खाते सेटिंग्ज",
    logout: "लॉगआऊट",

    // create post box - new post component
    prelogMessagePost:
      "पोस्ट तयार करण्यासाठी, खुल के मध्ये लॉग इन किंवा साइन अप करा",
    placeholder: "काहीतरी लिहा...",
    post_placeholder: "आपल्या पोस्टवर येथे लिहा...",
    characters: "वर्ण",
    popupTitle: "नवीन पोस्ट",
    video: "व्हिडिओ",
    image: "फोटो",
    document: "दस्तऐवज",
    audio: "ऑडिओ",
    poll: "पोल",
    // polling
    btnText: "पर्याय जोडा",
    lenTitle: "मतदानाची लांबी",
    // discard popup
    disTitle: "पोस्ट काढुन टाका",
    disText: "आपल्याला हे पोस्ट काढुन टाकायचं आहे का?",
    disbtnOptionY: "काढुन टाका",
    disbtnOptionN: "रद्द करा",
    disbtnOption1: "हो, मला खात्री आहे",
    disbtnOption2: "नको, त्या ठेवा",
    exitPopupTitle: "बाहेर पडायचं का?",
    // posting image
    imgplcHolder: "शीर्षक जोडा",
    // right panel
    popularHeading: "लोकप्रिय शो",
    // search bar
    searPlaceholder: "शोधा",
    //Footer for anonymous <PreLoginFooter/>
    opt1: "सामान्य प्रश्न",
    opt2: "समुदाय मार्गदर्शक नियम",
    opt3: "समर्थन",
    opt4: "गोपनीयता धोरण",
    opt5: "अस्वीकरण",
    opt6: "अटी आणि धोरण",
    opt7: "टेक डाउन धोरण",
    // Complete your profile
    completeYourProfile: "चांगल्या शोधासाठी आपले प्रोफाइल पूर्ण करा",
    completeYourProfileText:
      "आपले प्रोफाइल पूर्ण करा आणि आमच्या जीवंत समुदायात सामील व्हा. आपल्या आवडीनुसार वाचकांसह सामायिक करा, समरस व्यक्तींसंग संपर्क साधा, आणि महत्वाच्या चर्चांना प्रेरित करा. चला, हा डिजिटल स्थान खरंतर आपला बनवा.",
    profComplete: "आपले प्रोफाइल पूर्ण झाले आहे",
  },
  // ---------------------------------------------------------------------------
  createNew: {
    createPost: "पोस्ट तयार करा",
    createScrolls: "स्क्रोल तयार करा",
    createRT: "एक गोलमेज तयार करा",
    scheduleMeetup: "मीटिंग शेड्यूल करा",
  },
  // Roundtable listing page
  rt: {
    notificationMessage: "पाठवलेल्या आमंत्रणांची स्थिती तपासा",
    rt: "राउंडटेबल",
    rtNewBtn: "नवीन",
    label1: "सर्व",
    label2: "लाइव",
    label3: "येणारे",
    label4: "माझे",
    categories: "कॅटेगरी",
    // rt type popup
    popupTitle: "राउंडटेबल प्रकार निवडा",
    opt1: "व्हिडिओ",
    opt2: "ऑडिओ",
    opt3: "रेकॉर्डेड",
    // on click of bell icon -> /roundtable/notifications
    notiTitle: "राउंडटेबल सूचना",

    // right panel tabs
    live: "लाइव",
    upcoming: "येणारे",
    noItem: "दाखवण्यास काहीही नाही",
    noRt: "दाखवण्यास कोणताही राउंडटेबल नाही",
    noRTFound: "गोलमेज आढळले नाही",
    scrolledBottom: "अधिक राउंडटेबल्स नाहीत, आपण शेवटाकडे पोहोचले आहात",
    upcomingRtInfoToast:
      "हा राउंडटेबल अद्याप चालू झाला नाही.राउंडटेबल सुरु झाल्यावर आपल्याला सूचित करण्यात येईल.",
    accepted_invite: "आपण आमंत्रण स्वीकारले आहे.",
    rejected_invite: "आपण आमंत्रण नाकारले आहे.",
    invite_follow: "तुम्ही फॉलो करीत असलेल्यांना आमंत्रण पाठवा",
    and: "आणि",
  },
  // path -> /notifications/interaction
  noti: {
    // tabs title
    interaction: "परस्परसंवाद",
    Net: "नेटवर्क",
    noNoti: "आपल्याकडे नवीन परस्परसंवाद नाहीत!",
    started_following: "आपल्याला फॉलो करण्यास सुरूवात केली",
  },
  // path => /search (search pages)
  search: {
    top: "शीर्ष",
    latest: "लेटेस्ट",
    videos: "व्हिडिओ",
    profile: "प्रोफाइल",
    news: "बातम्या",
    post: "पोस्ट्स",
    people: "लोक",
    rt: "राउंडटेबल",
    // fallback under roundtable tab
    noRT: "कोणताही राउंडटेबल दाखवण्यासाठी उपलब्ध नाही",
    trendingNews: "ट्रेंडिंग बातम्या",
    breakingNews: "ठळक बातम्या",
    shortVideos: "स्क्रोल व्हिडिओ",
    newUserReportMsg:
      "पोस्ट ब्लॉक करण्यासाठी, लॉग इन करा किंवा खुल के वर साइन अप करा",
    newUserMuteMsg:
      "पोस्ट म्यूट करण्यासाठी, लॉग इन करा किंवा खुल के वर साइन अप करा",
  },
  // login pages
  login: {
    headingleft: "एक मंच जिथे चर्चा महत्त्वाची आहे.",
    // form
    headingOfForm: "लॉगीन",
    lineAboveInput: "ईमेल/मोबाइल/युजर नेम टाका",
    placeHolder: "abcd@example.com",
    radioTitle: "सह लॉगीन",
    opt1: "OTP",
    opt2: "पासवर्ड",
    btn: "लॉगिन",
    filterCap: "फिल्टर",
    linkToSignup: "नवीन युजर? खाते तयार करा",
    privacyPolicyLine:
      "सुरू ठेवून, आपल्याला आमच्या गोपनीयता धोरण आणि सेवा अटी मान्य असाव्यात.",
    formBottomLine: "कोणत्याही संदर्भात,आपण आमच्याशी संपर्क साधू शकता",
    // password page
    titleAboveBar: "पासवर्ड प्रविष्ट करा",
    passPlaceholder: "आपला पासवर्ड येथे",
    passwordWarning:
      "हा खूप कमी मजबूत पासवर्ड आहे. कृपया मजबूत पासवर्ड सेट करा.",
    forgotpass: "पासवर्ड विसरलात?",
    btnPass: "लॉगिन",
    khulkeMail: "support@khulke.com",
  },

  profile: {
    bio: "बायो",
    Enterwbsiteplace: "वेबसाइट URL लिहा",
    perso4: "वैयक्तिक माहिती",
    fallback: "काहीतरी चुकीचं झालं! नंतर पुन्हा प्रयत्न करा.",
    pageTitle: "प्रोफाइल",
    tabopt1: "पोस्ट्स",
    tabopt2: "राउंडटेबल",
    tabopt3: "स्क्रोल्स",
    tabopt4: "सेव्ह्ड",
    // btns
    btnEdit: "प्रोफाइल एडीट करा",
    btnFollowers: "फॉलोवर्स",
    btnFollowing: "फॉलो करीत आहे",
    // noData
    noSnips: "तुम्ही अद्याप कोणतेही स्क्रोल्स तयार केलेले नाही.",
    noSave: "तुम्ही अद्याप कोणताही पोस्ट सेव्ह केलेली नाही.",
    noRt: "अद्याप कोणताही राउंडटेबल तयार किंवा त्यात सहभागी  झालेले नाहीत",
    noPost: "अद्याप कोणत्याही पोस्ट नाहीत",
    withUsername: "युजरनेमसह",
    notFound: "सापडले नाही! कृपया दुसरं युजरनेम ने प्रयत्न करा.",
    contentCardRight: {
      opt1: "लाइव",
      opt2: "येणारे",
      noRT: "दाखविण्यासाठी कोणतेही राउंडटेबल नाही",
    },
    female: "स्त्री",
    male: "पुरुष",
    other: "इतर",
    highlights: {
      cancel: "Cancel",
      delete: "Delete",
      edit: "Edit",
      removeHighlights: "Delete this Highlight?",
      removeHighlightInstruction:
        "You can add this to highlight again from the highlight section.",
      highlights: "हायलाइट्स",
      WriteTitle: "हायलाइटसाठी शीर्षक लिहा",
      addCaption: "मथळा जोडा",
      create: "तयार करा",
      enterTitle: "कृपया शीर्षक प्रविष्ट करा.",
      selectImage: "कृपया प्रतिमा निवडा.",
      imageLess: "5 MB पेक्षा कमी प्रतिमा अपलोड करा.",
      imagesAllowed: "फक्त 3 प्रतिमांना परवानगी आहे!",
      addHighlights: "हायलाइट्स जोडा",
    },
    roundTable: {
      CreateRoundTable: "गोलमेज तयार करा",
      AttendModerator: "नियंत्रक म्हणून उपस्थित रहा",
      AttendPanelist: 'पॅनलिस्ट म्हणून उपस्थित रहा',
      AttendAsInvitedAudience: "आमंत्रित प्रेक्षक म्हणून उपस्थित रहा",
      RandomAudience: "यादृच्छिक प्रेक्षक म्हणून उपस्थित रहा",
      OwnerandModerator: "मालक आणि नियंत्रक म्हणून उपस्थित रहा",
      OwnerAndPanelist: "मालक आणि पॅनेल सदस्य म्हणून उपस्थित रहा",
      WatchTheRounTable: "गोलमेज पहा",
      AutoPlayRoundTable: "ऑटो-प्ले राउंडटेबल",
      WatchRoundTable: "गोलमेज पहा"
    },
    tabs:{
      RoundTable: "गोल मेज",
      Latest: "नवीनतम",
      MeetUp: "भेटायला",
      Others: "इतर"
    }
  },
  profileShowcase: {
    showcase: "शोकेस",
    addShowcase: "शोकेस जोडा",
    only3ImageAllowed: "केवळ 3 इमेज ची परवानगी आहे!",
    only4ImageAllowed: "Only 4 images allowed!",
    save: "Save",
    remove: "Remove",
    cancel: "Cancel",
  },

  profilePoint: {
    khulKeboard: {
      title: "खुल के बोर्ड",
    },
    earnPointBadges: {
      title: "पॉइंट्स आणि बॅजेस कसे कमवावे",
    },
    levels: {
      title: "लेवल्स",
    },
    badges: {
      title: "बॅज",
    },
    points: {
      title: "गुण"
    },
    question: "गुणांचे महत्त्व काय?",
    answer: "गुण मिळविण्यासाठी वापरकर्त्याने उपस्थित केलेल्या गोलमेजांचा कालावधी 15 मिनिटांपेक्षा जास्त असणे आवश्यक आहे.",
    earnMorePoints: {
      title: "अधिक गुण मिळवा",
      createPost : 'पोस्ट तयार करा',
      createRoundTable : "गोलमेज तयार करा",
      createSnipIt : "स्निप-इट तयार करा",
      likePosts : "पोस्ट लाईक करा",
      Comment: "टिप्पणी"
    },
  },

  // "/notifications/network"
  notiMain: {
    noInteraction: "तुमच्याकडून कोणतेही नवीन इंटरॅक्शन्स नाहीत!",
    tab1: "इंटरॅक्शन",
    tab2: "नेटवर्क",
  },

  // accountPages -> AccountLeftSideBar/index
  // leftbar
  leftAccounts: {
    acc: "खाते",
    invite: "मित्रांना आमंत्रित करा",
    privacy: "गोपनीयता",
    pass: "पासवर्ड",
    mutedWord: "म्युट केलेले शब्द",
    MutedAcc: "म्युट केलेले खाते",
    blocked: "ब्लॉक केलेले खाते",
    feedback: "अभिप्राय",
    faq: "सामान्य प्रश्न",
    privacyPoli: "गोपनीयता धोरण",
    communiGuide: "समुदाय मार्गदर्शक",
    disclaim: "अस्वीकृती",
    support: "सहाय्य",
    TnC: "अटी आणि धोरण",
    logout: "लॉग आउट",
    takeDown: "टेक डाउन धोरण",
    broadcast: "प्रसारण",
    sitemap: "साइटमॅप",
  },
  // settings pages
  setting: {
    // "/account_settings"
    settingHeader: "सेटिंग्ज",
    usernameLegend: "यूजरनेम",
    EmailLegend: "ईमेल",
    mobileLegend: "मोबाइल",
    deactivateHeading: "आपले खाते निष्क्रिय करू इच्छिता का?",
    deactivateInfo:
      "तुम्ही 30 दिवसांत लॉग इन करून तुमचे खाते पुन्हा सक्रिय करू शकता. 30 दिवसांनंतर, खाते कोणत्याही पोस्ट्स, आणि मीडियासह काढून टाकले जाईल.",
    deactiveBtn: "खाते निष्क्रिय करा",
  },

  // create rt -> "/roundtable/create"
  createRT: {
    pgTitle: "राउंडटेबल",
    step1: "नवीन राउंडटेबल",
    step2: "वक्ते",
    step3: "वर्ग",
    step4: "आमंत्रण",
    create_your_rt: "तुमच्या स्वतःच्या राउंडटेबलचा निर्माण करा",
    maximum_2_gb: "अधिकतम व्हिडिओ आकार केवळ 2 जीबी असणे आवश्यक आहे",
    download: "डाउनलोड",

    type: "राउंडटेबलचा प्रकार",
    iBtn: {
      line1: "आपण ऑडिओ किंवा व्हिडिओ राउंडटेबल निवडू शकता",
      line2: "ऑडिओ - वक्त्यांचा आवाज सर्वांसाठी उपलब्ध असेल",
      line3: "व्हिडिओ - वक्त्यांचा आवाज आणि दृश्य सर्वांसाठी उपलब्ध असेल",
    },
    videoBtn: "व्हिडिओ",
    audioBtn: "ऑडिओ",

    // title
    addTitle: "शीर्षक जोडा",
    pvtRTpopUp:
      "खाजगी राउंडटेबलमध्ये सामील होण्यासाठी, राउंडटेबलच्या मालकाला विनंती पाठवा.आपल्याला विनंती पाठवायची आहे का?",
    reqSentAlready: "विनंती आधीच पाठवली गेली आहे!!!",
    addTitleIbtn: {
      firstLine: "शीर्षक युजर्स साठी जास्त आकर्षक असणे आवश्यक आहे.",
      secondLine:
        "विषय आपल्या चर्चेशी संबंधित असावा कारण शीर्षकात केवळ 50 अक्षर असू शकतात.",
    },
    addTitlePlaceholder: "शीर्षक लिहा",

    // description
    descTitle: "वर्णन",
    descIBtn: {
      one: "वर्णन विषये वक्त्यांच्या बद्दल सविस्तर असणे आवश्यक आहे ज्यामुळे वापरकर्त्यांना ही चर्चा विश्वास उत्त्पन्न करण्यास मदत करेल.",
      two: "सीमित मर्यादा 250 शब्दे आहे, म्हणजे तुम्ही लघु लेख देऊ शकता.",
    },
    // autoRecording
    recTitle: "ऑटो रेकॉर्डिंग",
    recIBtn: {
      one: "ऑटो रेकॉर्डिंग हे राउंडटेबल मॉडरेटर ने जॉईन केल्यानंतर ऑटोमॅटिक रेकॉर्डिंग सुरू करते.",
      two: "“ON” निवडून – राउंडटेबल मॉडरेटर राउंडटेबल जॉईन केल्यानंतर ऑटोमॅटिक रेकॉर्डिंग सुरू करते",
      three:
        "“OFF” निवडून – राउंडटेबल रेकॉर्डिंग राउंडटेबल मॉडरेटर/मालक लाइव मध्ये सुरू करत नाही तो पर्यंत सुरु होऊ शकणार नाही.",
    },

    natureOfRt: "राउंडटेबल बद्दल",
    optPub: "सार्वजनिक",
    optPriv: "खाजगी",
    optTrial: "ट्रायल ",
    optConfi: "गोपनीय",
    category: " Select Category",
    categoryOption: " Select Category",
    categoryData: [
      "Arts & culture",
      "Astrology",
      "Books",
      "Business",
      "ChatShows",
      "Crime",
      "Defense",
      "Education",
      "Entertainment",
      "Fashion",
      "Finance & Economy",
      "Fitness",
      "Food",
      "Games",
      "Government",
      "Health",
      "Legal",
      "Lifestyle",
      "News",
      "Pets & Animals",
      "Philosophy",
      "Poetry",
      "Politics",
      "Recipes",
      "Religion",
      "Science & Technology",
      "Society",
      "Spiritual",
      "Sports",
      "Theatre",
      "Travel",
      "Wellness",
    ],

    natureIBtn: {
      one: "तुम्हाला उपलब्ध असलेले 3 प्रकारचे राउंडटेबल आहेत:",
      two: "“सार्वजनिक” - हे राउंडटेबल सर्वांनाच दिसेल आणि त्यात राउंडटेबल दरम्यान कोणत्याही वेळेला सामील होऊ शकतो.",
      three:
        "“खाजगी” - हे राउंडटेबल सर्वांनाच दिसेल, पण त्यात सामील होण्यासाठी त्यांना विनंती करणे आणि मालका चा स्वीकार करणे आवश्यक आहे.",
      four: "“गोपनीय” - हे राउंडटेबल फक्त आमंत्रित व्यक्तींना दिसतो आणि केवळ ते त्यात सामील होऊ शकतात.",
    },

    natureRtBtn: {
      one: "तुम्हाला उपलब्ध असलेले 2 प्रकारचे राउंडटेबल आहेत:",
      two: "“सार्वजनिक” - हे राउंडटेबल सर्वांनाच दिसेल आणि त्या राउंडटेबल दरम्यान कोणत्याही वेळी सहभागी होऊ शकता.",
      three:
        "“खाजगी” - हे राउंडटेबल सर्वांनाच दिसेल, पण त्यात सामील होण्यासाठी विनंती करणे आणि मालका ने स्वीकार करणे आवश्यक आहे.",
    },

    keepAnonTitle: "मला निनावी ठेवा",
    keepAnonI:
      "हा गुप्त राउंडटेबल आहे ज्यात राउंडटेबलच्या मालकाचं नाव कोणाला ही दिसत नाही.",
  },
  // Create RT page 2
  moderatorLabel: "मॉडरेटर",
  panelistLabel: "पॅनलिस्ट",
  transferRights: "मालकी हक्क हस्तांतरित करा",
  addPanelistBtn: "पॅनलिस्ट जोडा",
  editPanelistBtn: "पॅनलिस्ट संपादित करा",
  nopanelistError: "कोणतेही पॅनलिस्ट जोडलेले नाहीत!",
  //Scrolls
  scrolls: {
    add: "स्क्रोल्स जोडा",
    create: "स्क्रोल्स तयार करा",
    uploadDes: "अपलोड करण्यासाठी व्हिडिओ फाइल जोडा.",
    uploadBtn: "डिव्हाईसमधून निवडा.",
    PostBtn: "पोस्ट",
    DoneBtn: "संपन्न",
    tagUsers: "प्रतिसाद युजर्स ला टॅग करा",
    addLocation: "लोकेशन जोडा",
    addHashtags: "हॅशटॅग जोडा",
    addPhoneNo: "प्रतिसाद देता येईल असा मोबाइल जोडा",

    //Scrolls Select Category
    categories: "कॅटेगरी",
    scrollsCat: [
      {
        title: "सार्वजनिक अभिप्राय",
        subTitle:
          "लघु व्हिडिओ क्लिप्सद्वारे सध्याच्या घटनांवर आपले मत व्यक्त करा।",
      },
      {
        title: "क्रीडा",
        subTitle: "आपल्या आवडत्या क्रीडा क्षणांना शेअर करून उत्सव साजरा करा!",
      },
      {
        title: "समाचार",
        subTitle:
          "नागरी पत्रकारितेचे स्वागत करा! आपला दृष्टिकोन महत्वाचा आहे, आणि आपली फुटेज म्हणजे पुढची मुख्य बातमी ठरू शकते.",
      },
      {
        title: "राउंडटेबल स्निप इट",
        subTitle:
          "आपले विचार संक्षेपित करा! संक्षेपित करून शेअर  करण्यासाठी ट्रिम फीचर वापरा.",
      },
      {
        title: "मस्ती",
        subTitle:
          "आपले मस्तीचे क्षण शेअर करा! आपल्या आनंददायी अनुभवांचे क्षण अपलोड करा आणि आनंद साजरा करा!",
      },
      {
        title: "नेतृत्व बोल",
        subTitle:
          "विविध क्षेत्रांमधील तज्ञांनी ज्ञान, अनुभव, शेअर केले आहे ते बघा. त्यांच्या कडून प्रेरणा घ्या आणि आपले कौशल्ये वाढवा.",
      },
      {
        title: "शोकेस - उत्पादन, प्रतिभा आणि संस्था",
        subTitle:
          "तुमच्या उत्पादन, प्रतिभा किंवा संस्थेच प्रदर्शन करा. जगाला आपल्या उत्कृष्टतेची अनुमती द्या!",
      },
      {
        title: "शासनप्रबंधन",
        subTitle:
          "कायदा, न्यायपालिका, सरकारी बिल्स आणि शासनाच्या विषयावरील पोस्ट पहा.माहिती मिळवत राहा अपडेट रहा.",
      },
      {
        title: "DIY - तुम्ही स्वतः करा",
        subTitle:
          "आपले सर्वात अलिकडचे DIY प्रकल्प आमच्याशी शेअर करा. आपल्या महत्त्वाच्या कामाला अपलोड करा आणि इतरांना प्रेरणा द्या.",
      },
      {
        title: "विज्ञान आणि तंत्रज्ञान",
        subTitle:
          "विज्ञान आणि तंत्रज्ञानाशी संबंधित व्हिडिओ पहा. प्रयोग आणि हॅक्स पासून सुविधा आणि आविष्कारांपर्यंत शिका आणि प्रेरित व्हा.",
      },

      {
        title: "भक्ती",
        subTitle: "आपली भक्ती साधना शेअर करा! आपले आध्यात्मिक क्षण अपलोड करा.",
      },
      {
        title: "इतर",
        subTitle:
          "विविध विषया संबंधी व्हीडीओ अनुभवा.आनंद घ्या, शिका आणि शेअर करा.",
      },
      {
        title: "राजकारण",
        subTitle: "आपले राजकीय दृष्टिकोन शेअर करा!",
      },
    ],
    //Toaster msg
    catNotSelected: "कृपया स्क्रोलचा टॅग निवडा.",
    shareScrolls: "आपल्या स्क्रोलला यशस्वीरीत्या शेअर केलं गेलं आहे.",
  },
  // k3 page
  K3: {
    create: "बनवा",
    description: "येथे बातम्यांची सूचना जोडा",
    btnadd: "D2M व्हिडिओ अपलोड करा",
  },
  // bkk page
  bkk: {
    create: "तयार करा",
    shareViews: "तुमचे मौल्यवान व्ह्यू येथे शेअर करा",
    description: "आपले मौल्यवान व्ह्यू येथे जोडा",
    btnadd: "व्ह्यू जोडा",
    tagUsers: "युजर्स टॅग करा",
    addLocation: "लोकेशन जोडा",
    addhasgtags: "हॅशटॅग्स जोडा",
    addVideoFile: "अपलोड करण्यासाठी व्हिडिओ फाइल जोडा",
    selectFromDevice: "डिव्हाईसमधून निवडा",
    addPhoneNo: "फोन नंबर जोडा",
    post: "पोस्ट",
    next: "पुढे",
  },
  // snippit page
  snip: {
    btnadd: "जोडा",
    dislike: "नापसंत",
    likes: "आवडल",
    interactions: "इंटरॅक्शन्स",
    share: "शेअर",
    shares: "शेअर करा",
    like: "आवड",
    block: "ब्लॉक",
    muteThisPerson: "या व्यक्तीला निःशब्द करा",
    reportPost: "पोस्टचा अहवाल द्या",
  },
  ekk: {
    description: "तुमचे व्ह्यू इथे लिहा",
    shareViews: "तुमचे व्ह्यू शेअर करा",
  },
  yapp: {
    writeMessageHere: "तुमचे संदेश येथे लिहा...",
    createKlub: "क्लब तयार करा",
    selectMembers: "सदस्य निवडा",
    newKlub: "नवीन क्लब",
    klubName: "क्लबचे नाव",
  },
  addpanelist: "जोडा",
  addedalready: "आधीच जोडले आहे",
  editpanelist: "संपादित करा",
  panelist: "पॅनलिस्ट",
  misc: {
    pollBefore: "आपण निवडले आहे",
    pollAfter:
      " एकदा तुम्ही पुष्टी केल्यानंतर, तुम्ही ही निवड पूर्ववत करू शकत नाही.",
    confirm: "पुष्टी",
    textError: {
      more_than_100: "आपण 100 शब्दांपेक्षा अधिक टाइप करू शकत नाही.",
      more_than_250: "आपण 250 शब्दांपेक्षा अधिक टाइप करू शकत नाही.",
    },
    invite: {
      notModPanInvite: "आपण मॉडरेटर किंवा पॅनलिस्ट नमूद करू शकत नाही.",
    },
    timer: {
      selectTime: "वेळ निवडा",
    },
    toastMsg: {
      only_two_poll: "तिथे केवळ दोन पोल असतील.",
      duration_lesser_than_RT: "राऊंडटेबल कालावधी शिवाय वेळ अधिक असू शकत नाही.",
      duration_empty: "कालावधी रिक्त असू नये.",
      fill_option: "कृपया पर्याय भरा किंवा डिलीट करा.",
      minimum_options: "पोलसाठी किमान 2 पर्याय असणे आवश्यक आहे.",
      provied_question: "पोलसाठी प्रश्न प्रदान करा.",
      file_size: "15 MB पेक्षा कमी फाईल अपलोड करा.",
      select_document: "कृपया दस्तऐवज निवडा.",
      invalid_file_format:
        "अवैध फाईल स्वरूप. कृपया pdf, doc, ppt, xlx फाईल अपलोड करा.",
      image_size: "15 MB पेक्षा कमी इमेज अपलोड करा.",
      select_image: "कृपया इमेज निवडा",
      only4ImageAllowed: "केवळ 4 इमेज ची परवानगी आहे!",
      audioLengthValidation: "ऑडिओसाठी जास्तीत जास्त कालावधी 2 मिनिट 30 सेकंद.",
      postUploadingWait:
        "फाईल अपलोड करण्याची प्रक्रिया अद्याप चालू आहे. अपलोड झाल्यावर सूचना देण्यात येईल.",
      invalidAudioFormat: "अवैध ऑडिओ फाईल स्वरूप. कृपया mp3 फाईल अपलोड करा.",
      max_video_duration:
        "जास्तीत जास्त कालावधी 15 सेकंदा पेक्षा जास्त असू नये.",

      select_video: "कृपया व्हिडिओ निवडा.",
      less_than_15mb_file: "15MB पेक्षा कमी फाईल अपलोड करा",
      invalidImgFormat:
        "अवैध फोटो स्वरूप. कृपया jpg, jpeg, png, gif फोटो अपलोड करा.",
      invalidVideoFormat: "अवैध व्हिडिओ फाईल स्वरूप. कृपया mp4 फाईल अपलोड करा.",
      invalidVidAudFormat:
        "अवैध व्हिडिओ फाईल स्वरूप. कृपया mp4/mp3 फाईल अपलोड करा.",
      multipleLoginError: "आपण विविध डिव्हाइसमधून सामील झाला आहात",
      sessionExpired: "सत्र समाप्त",
      videoLength3min:
        "आपल्या व्हिडिओचा कालावधी 3 मिनिटपेक्षा जास्त आहे. कृपया व्हिडिओ ट्रीम करून पोस्ट करा.",
    },
    image_less_size: "15 MBपेक्षा कमी फोटो अपलोड करा",
    addPoste: "पोस्ट जोडा",
    savechanges: "बदल सेव झाले आहेत!",
    updateuname: "युजर नेम अपडेट करा",
    changessaved: "तुमच्या बदलांना सेव केले आहे!",
    oldview: "दर्शक",
    allfollower: "सर्व फॉलोवर्स ",
    allfollowing: "सर्व फॉलो करणारे",
    minfo1:
      "मॉडरेटर राउंडटेबलसाठी अनिवार्य वक्ता आहे. मॉडरेटरच्या काही अटी आहे",
    minfor2:
      "तिने /त्याने आमंत्रण स्वीकार केल्याशिवाय राउंडटेबलमध्ये कोणीही सामील होऊ शकत नाही",
    minfor3:
      "तिने /त्याने राउंडटेबलमध्ये सामील न झाल्यास प्रारंभानंतर तो लॉक असेल",
    minfor4:
      "तिने /त्याने राउंडटेबलमध्ये सामील न झाल्यास  प्रारंभी च्या 10 मिनिटांनंतर राउंडटेबल रद्द होईल",
    minfor5: "एका राउंडटेबलमध्ये केवळ 1 मॉडरेटर आमंत्रित करण्यात येईल",
    minfor6: "मालक मॉडरेटर ला सहमती असल्यास मॉडरेटर सह मालक होऊ शकतो",
    minfor7: "मॉडरेटरसाठी 100 शब्दांचे वर्णन जोडू शकता",
    ttlcantEmpty: "शीर्षक रिक्त असू शकत नाही",
    minago: "मिनी आधी",
    owner: "मालक",
    vlist: "दर्शकांची यादी",
    notifywhenready:
      "हा राउंडटेबल सुरू झाला नाही. जेव्हा हा राउंडटेबल तयार होईल तेव्हा आम्ही तुम्हाला सूचित करू.",
    maxvidoel:
      "व्हिडिओसाठी मान्य वेळ 2 मिनिट 30 सेकंद आहे. कृपया व्हिडिओ ट्रीम करून पोस्ट करा.",
    unsave: "सेव्ह केले नाही",
    repply: "रीप्लाय",
    exploresnip: "स्निप-इट मध्ये शोधा",
    watchsnipit: "स्निप-इट मध्ये आणखी बघा",
    explorekhabar: "खबर खुल के मध्ये शोधा",
    watchkhabar: "खबर खुल के मध्ये आणखी पहा",
    explorebkk: "बोल खुल के मध्ये शोधा",
    watchbkk: "बोल खुल के मध्ये आणखी पहा",
    exploreSkk: "सोच खुल के मध्ये शोधा",
    watchSKk: "सोच खुल के मध्ये आणखी पहा",
    succcopied: "यशस्वीरीत्या कॉपी केले",
    entersomeval: "काहीतरी प्रविष्ट करा",
    readmore: "आणखी वाचा",
    readless: "कमी वाचा",
    stoprec: "रेकॉर्डिंग थांबवा",
    endrt: "राउंडटेबल समाप्त",
    muteda: " म्युट केलेले दर्शक",
    finalr: "अंतिम निकाल",
    uncirc: "सरक्युलेट करू नका",
    receivenotiq:
      "आपल्याला ही राउंडटेबलचे रेकॉर्डिंग उपलब्ध झाल्यावर त्याची महिती प्राप्त करायची आहे का?",
    greatline:
      "उत्कृष्ट, आम्ही आपल्याला रेकॉर्डिंग उपलब्ध झाल्यावर नोटीफिकेशन पाठवू .",
    recrte: "रेकॉर्ड केलेले राउंडटेबल",
    uploddw: "अपलोड करा",
    sche1: "राउंडटेबल कार्यक्रम शेड्युअल करण्याचे 2 पर्याय आहेत.",
    sche2: "“आता” - राउंडटेबल तयार झाला की आताच आपल्या राउंडटेबल ला सुरु करेल",
    sche3:
      "“उत्तरार्ध” - आपल्याला आपल्या राउंडटेबलसाठी सुरु करण्याची तारीख आणि वेळ निवडण्याची सुविधा मिळेल कमीत कमी 15 मिनिटांपर्यंत",
    recording: "रेकॉर्डिंग",
    recline1: "रेकॉर्ड केलेले व्हिडिओ हे जास्तीत जास्त 3 तासाचे असू शकता",
    recline2: "आउट्रो व्हिडिओसाठी रेशिओ 16: 9 असा असावा",
    recline3: "हे केवळ .mp4 आणि .mp3 आधारित आहे",
    addwm: "वाइल्डकार्ड केलेला संदेश जोडा",
    coowner: "सहमालक",
    past: "पूर्व",
    new: "नवीन",
    prelog: "राउंडटेबल बघण्यासाठी, कृपया खुलके लॉगिन किंवा साइन अप करा",
    avionceconfirm: "राउंडटेबलची पुष्टी केल्यानंतर उपलब्ध होईल",
    nocategoryinc: "कोणतीही कॅटेगरी समाविष्ट नाही!",
    notagsinc: "कोणतेही टॅग समाविष्ट नाहीत!",
    selectSche: "शेड्युल निवडा",
    uploadl: "लोगो अपलोड करा",
    now: "आता",
    later: "नंतर",
    dateTime: "तारीख आणि वेळ",
    duration: "कालावधी",
    addDescplaceholder: "वर्णन जोडा",
    recOn: "चालू",
    recOff: "बंद",
    restore: "युजर नेम पुनर्स्थापित करा",
    update: "अपडेट करा",
    mob: "मोबाइल नंबर प्रविष्ट करा",
    user: "युजरनेम",
    enterusname: "युजरनेम प्रविष्ट करा",
    mail: "ईमेल",
    mob: "मोबाइल",
    share: "शेअर करा",
    copy: "लिंक कॉपी करा",
    postit: "पोस्ट करा",
    attending: "उपस्थित",
    watch: "पाहा",
    liste: "ऐका",
    views: "दृश्य",
    rtDetails: "राउंडटेबल डीटेल्स",
    cawaited: "पुष्टीकरण प्रतीक्षा केली जात आहे",
    jnow: "आता सामील व्हा",
    sreminder: "सेट रिमाइंडर",
    rset: "रिमाइंडर सेट",
    has: "आहे",
    hassecond: "आहे",
    invitedu: "तुम्ही आमंत्रित आहात",
    somethingwrong: "काहीतरी चुकिचं झालं",
    char: "वर्ण",
    words: "शब्द",
    topic: "विषय",
    done: "संपलं",
    invitation_sent_successfully: "आमंत्रण यशस्वीरित्या पाठविले",
    you_can_edit_rt_manage_your_invitations_anytime:
      "आपण सर्वकाही कधीही एडीट करू शकता आणि आपले आमंत्रण कधीही व्यवस्थित करू शकता.",
    manage_invitations: "आमंत्रण व्यवस्थापित करा",
    done_for_now: "आत्ता पूर्ण",
    intro_video: "इंट्रो व्हिडिओ",
    outro_video: "आउट्रो व्हिडिओ",
    invite_audience: "दर्शकांना आमंत्रित करा",
    invite_your_audience: "आपल्या दर्शकांना आमंत्रित करा",
    advance_option: "अधिक पर्याय",
    advance_option_info:
      "हे आपल्या राउंडटेबलला अधिक आकर्षक बनविण्यासाठी मदत करेल.",
    multiple_logos: "अनेक लोगो",
    example_text: "उदा. विषय | स्पीकरचं नाव | मालकाचं नाव | हॅशटॅग",
    preview: "प्रीव्ह्यू",
    your_rt_has_update: "आपले राउंडटेबल अपडेट केले गेले आहे.",
    add_introduction_for_panelist: "पॅनलिस्टचा परिचय जोडा",
    add_introduction_for_moderator: "मॉडरेटर चा परिचय जोडा",
    update_your_rt: "आपले राउंडटेबल अपडेट करा",
    join: "सामील व्हा",
    INVITE_POP_DESC: "तुम्ही चालू ठेऊ इच्छिता की सर्व वगळू इच्छिता?",
    rt_live_time_left: "आपल्या लाइव राउंडटेबलसाठी समय शिल्लक आहे",
    mod_note_1:
      "सूचना: जर आपण आमंत्रणचा स्वीकार केला नाही तर, राउंडटेबल सुरु होणार नाही",
    mod_note_10_1:
      "सूचना: जर आपण राउंडटेबल निश्चित केलेल्या वेळेनुसार अनुपस्थित असल्यास, तर राउंडटेबल रद्द होईल.",
    mod_note_10_2: "मिनिट",
    rt_about_start_in: "आपले राउंडटेबल सुरू होणार आहे:",
    for_the_rt: "राउंडटेबलसाठी",
    you_have_been_invited: "आपल्याला आमंत्रित केले आहे",
    you_have_been_invited_1: "आपल्याला आमंत्रित केले आहे",
    you_have_been_invited_2: "",
    your_rt_is_live_with_time_left: "आपल्या राउंडटेबल ची वेळ शिल्लक आहे",

    more_inv_1: "आपल्याकडे",
    more_inv_2: "राउंडटेबल्सची अधिक आमंत्रणे आहेत",
    see_you_there: "तिथे भेटू!",
    continue: "सुरू ठेवा",
    continue_to_create: "तयार करण्यासाठी सुरू ठेवा",
    you_have_not_uploaded_image:
      "तुम्ही आपल्या राउंडटेबलला अधिक आकर्षित करू शकता असलेली चित्रे अपलोड करू शकता",
    skip_all: "सर्व वगळा",
    user_already_added: "युजर आधीच जोडला गेला आहे !!!",
    add_more: "अधिक जोडा",
    remove: "काढा",
    thumbnail: "थंबनेल",
    thumbnail_preview: "थंबनेल प्रीव्ह्यू",
    description_placeholder:
      "पिछला गोलमेज के विषय आ लिंक के बारे में विवरण जोड़ू",
    // Create RT page 3
    pg3: {
      uplImg: "फोटो अपलोड करा",
      Recommendation: "सिफारिश",
      l1: "राउंडटेबलची कवर चित्रे 16:9 रेशिओ",
      l2: "हे फक्त PNG, JPEG, JPG सपोर्ट करते",
      l3: "हे अधिकतम 15mb मर्यादेसह",

      logo: "लोगो अपलोड करा (अधिकतम 3)",
      ll1: "तुम्ही अधिकतम 3 लोगो अपलोड करू शकता",
      ll2: "लोगोसाठी कोणताही परिमाण स्वीकारला जातो",
      ll3: "लोगोसाठी कोणताही परिमाण स्वीकारला जातो",
      ll4: "हे अधिकतम 1mb मर्यादेसह",

      uplintro: {
        uplintro: "इंट्रो व्हिडिओ अपलोड करा",
        uploutro: "आउट्रो व्हिडिओ अपलोड करा",

        introl1:
          "इंट्रो व्हिडिओ 15 सेकंदांसाठी असू शकतो जो राउंडटेबलच्या सुरवातीला येतो",
        introl2: "इंट्रो व्हिडिओचा 16:9 रेशिओ आहे",
        introl3: "हे केवळ .mp4 सहाय्य करते",
        introl4: "हे अधिकतम 100mb मर्यादेसह",
        uplplace: "इंट्रो व्हिडिओ अपलोड करा",

        outro1:
          "आउट्रो व्हिडिओ राउंडटेबलच्या समाप्तीला येतो जो 15 सेकंदाचा असू शकतो",
        outro2: "आउट्रो व्हिडिओचा 16:9 रेशिओ आहे",
        outro3: "हे केवळ .mp4 सहाय्य करते",
        outro4: "हे अधिकतम 100mb मर्यादेसह",
      },
      attachdoc: "दस्तऐवज संलग्न करा",
      docplaceholder: "दस्तऐवज अपलोड करा",
      docRecommendation: "सिफारिश",
      docl1:
        "1. वापरकर्त्यांना अधिक समजेल अश्या हेतूने चर्चेच्या झलका देण्यासाठी एक कागदपत्र अपलोड करा",
      docl2: "हे केवळ doc , xls, ppt, pdf सहाय्य करते",
      docl3: "हे अधिकतम 15mb मर्यादेसह",

      category: "कॅटेगरी",
      catergoryplace: "शोधा आणि समाविष्ट करा",
      tags: "टॅग्ज",
      addbtn: "जोडा",
    },
    pg4: {
      invite: "आमंत्रण",
      iinvite:
        "तुम्ही आपल्या सर्व फॉलोवर्स ला, आपल्या फॉलोइंग्जमधील लोकांना किंवा अगोदरच्या राउंडटेबल्स मध्ये होते अश्यांना आमंत्रित करू शकता",
      viewer: "दर्शक",
      invitemore: "अधिक आमंत्रण करा",
      iinvite:
        "तुम्ही खुल के वर उपस्थित असलेले कोणतेही युजर त्यांच्या नावाचा शोध करून किंवा त्यांचा मोबाइल नंबर किंवा ईमेल आयडी प्रविष्ट करून आमंत्रित करू शकता",
      searchuser: "युजर शोधा",
      addMobile: "मोबाइल किंवा ईमेल आयडी जोडा आमंत्रणासाठी",
    },
    review: {
      title: "राउंडटेबल रीव्ह्यू",
      date: "तारीख",
      Starttm: "सुरुवातीची वेळ",
      Endttm: "शेवटची वेळ",
      Duration: "कालावधी",
      invalid: "अवैध तारीख",
      logos: "लोगो",
      Attach: "संलग्न",
      nodoc: "कोणतेही कागदपत्रे जोडलेली नाहीत!",
      categories: "कॅटेगरी",
      ppants: "सहभागी",

      discard: {
        dtitle: "राउंडटेबल काढून टाका",
        text: "तुम्हाला खात्री आहे की तुम्हाला ही माहिती काढून टाकायची आहे?",
        y: "हो, मला खात्री आहे",
        n: "नको, तेच ठेवा",
      },
    },
    livert: {
      mMODERATOR: "मॉडरेटर",
      mPANELIST: "पॅनलिस्ट",
      mWIDLCARD: "वाइल्डकार्ड",
      Audience: "दर्शक",
      admin: "व्यवस्थापक",
      moderator: "मॉडरेटर",
      owner: "मालक",
      panelists: "पॅनलिस्ट्स",
      panelist: "पॅनलिस्ट",
      audiences: "दर्शक",
      interac: "इंटरअक्शन",
      time: "वेळ",
      joinNow: "Join Now",
      watchNow: "Watch Now",
      live: "Live",
      upcomming: "Upcomming",
      leaveRtDesc: "तुम्हाला हा राउंडटेबल सोडण्याची खात्री आहे का?",
      rtStartSoon: "राउंडटेबल लवकरच सुरू होईल",
      modNotJoined:
        "मॉडरेटर अद्याप जॉईन झालेले नाहीत, क्रपया मॉडरेटरची वाट बघा",
      nodNotPres: "मॉडरेटर उपस्थित नाही, म्हणून हा राउंडटेबल रद्द केलेला आहे.",
      Nodata: "कोणताही डेटा सापडला नाही",
      msgplace: "तुम्हाला काय वाटतय त्या बद्दल लिहा...",
      send: "पाठवा",
      follow: "फॉलो",
      unfollow: "अनफॉलो",
      following: "फॉलो करत आहे",
      followers: "फॉलोवर्स",
      recc: "सूचना:सारख्या मॉडरेटरसह असलेले राउंडटेबल सुचवा",
      comment: "कमेंट",
      writeplace: "आपला संदेश लिहा...",
      leave: "राउंडटेबल सोडा",
      dis: "डिस्क्लेमर",
      end: "राउंडटेबल संपण्यात आहे",
      ext: "राउंडटेबलची वेळ वाढवली",
      extent: "विस्तार करा",
      extentTime: "वेळेचा विस्तार",
      sureendthis: "आपण हे राउंडटेबल संपावे या बद्दल  खात्रीशीर आहात का?",
      extendbymin: "कृपया खालील मिनिट निवडा आणि हा राउंडटेबल विस्तारित करा",
      min: "मिनिट",
      minutes: "मिनिट",
      tleft: "वेळ बाकी: ",
      over: "हे राउंडटेबल आधीच संपलं आहे.",
      rt: "राउंडटेबल",
      rec: "राउंडटेबलच रेकॉर्डिंग होत आहे",
      starredPost: "मॉडरेटरने आपली पोस्ट स्टार केली आहे.",
      unstarredPost: "मॉडरेटरने आपली पोस्ट अस्टार केली आहे.",
      del: "डिलीट",
      copy: "पोस्टची कॉपी करा",
      poll: "पोल",
      vote: "मतदान",
      fpoll: "पहिलं पोल",
      Participants: "सहभागी",
      mod: "मॉडरेटर",
      raisehand: "राउंडटेबलमध्ये चॅटसाठी सहभागी व्हा",
      rhp: "मॉडरेटरचे लक्ष वेधून घेण्यासाठी संदेश पाठवा.",
      wques: "तुमचा प्रश्न येथे लिहा...",
      mini: "राउंडटेबल मिनीमाइज करा",
      disclaimer: "डिस्क्लेमर",
      endRt: "राउंडटेबल संपलं",
      full: "फुलस्क्रीन",
      notifyMe: "मला सूचित करा",
      noopt: "कोणतेही पर्याय नाहीत",
      intro: "परिचय",
      editp: "पॅनलिस्ट एडीट करा",
      padd: "कृपया मॉडरेटर जोडा",
      pro: "सुरू करा",
      dis: "रद्द करा",
      rtcreated: "राउंडटेबल यशस्वीरीत्या तयार केला गेला",
      rt_updated: "राउंडटेबल यशस्वीरित्या अपडेट केला गेला",
      aw: "उत्तम",
      nolog: "कोणतेही लोगो समाविष्ट केलेले नाही!",
      novid: "कोणतेही व्हिडिओ समाविष्ट केलेले नाही!",
      nodoc: "कोणतेही कागदपत्र समाविष्ट केलेले नाही!",
      invitecount: "आमंत्रितांची संख्या",
      c: "लिंक कॉपी करा",
      p: "पोस्ट करा",
      you: "तुम्ही",
      circ: "सरक्युलेट",
      circed: "सरक्युलेटेड",
      quote: "कोट",
      srec: "रेकॉर्डिंग सुरू करा",
      endrec: "रेकॉर्डिंग समाप्त करा",
      muted: "म्युट दर्शक",
      nomuted: "म्युट सहभागी नाहीत!",
      pchat: "पॅनलिस्ट चॅट",
      am: "a m.",
      pm: "p m .",
      addopt: "पर्याय जोडा",
      option: "पर्याय",
      polldur: "पोल कालावधी",
      d: "दिवस",
      h: "तास",
      m: "मिनिट",
      fewago: "काही सेकंदांपूर्वी",
      view: "व्ह्यू",
      wm: "वाइल्डकार्ड संदेश",
      wildc: "वाइल्डकार्ड",
      writqu: "आपण काय विचार करत आहात ते लिहा...",
      reply: "प्रतिसाद द्या",
      repliedto: "उत्तर दिले",
      past: "पूर्व",
      nothingToShow: "दाखविण्यास काही नाही!",
      userAlreadyAdded: "हा युजर आधीच जोडला गेला आहे!",
      uploadRecRT: "रेकॉर्डिंग शेअर करा",
      enterValidTitle: "कृपया इमेज अपलोड करण्यापूर्वी शीर्षक प्रविष्ट करा.",
      enterRecordedRT: "कृपया रेकॉर्ड केलेली ऑडिओ / व्हिडिओ फाईल अपलोड करा.",
      startRT:
        "आम्ही तुम्हाला राउंडटेबल सुरु होण्याची आठवण करून द्यावी असे तुम्हाला वाटते का ?",
    },
    reqacc: "एक्सेस साठी विनंती",
    skip: "वगळा",
    next: "पुढचा",
    created_post: "तुमचं पोस्ट तयार केलं गेलं आहे.",
    created_post_success: "तुमची पोस्ट यशस्वीरित्या पोस्ट केली गेली आहे.",
    created_snip_success: "तुमचं स्निप-इट यशस्वीरित्या पोस्ट केलं गेलं आहे!",
    created_khabar_success:
      "तुमचा D2M व्हिडिओ यशस्वीरित्या पोस्ट केला गेला आहे!",
    created_bol_success: "तुमचा व्ह्यू यशस्वीरित्या पोस्ट केलं गेलं आहे!",
    deleted_post: "ही पोस्ट डिलीट केली गेली आहे",
    deleted_post_success: "पोस्ट यशस्वीरीत्या डिलीट झाली आहे",
    deleted_snip_success: "स्निप-इट यशस्वीरीत्या डिलीट झाल आहे",
    deleted_khabar_success: "D2M यशस्वीरीत्या डिलीट झाल आहे।",
    deleted_Bol_success: "व्ह्यू यशस्वीरीत्या डिलीट झाले आहे।",
    deleted_rountable_success: "राउंडटेबल यशस्वीरीत्या डिलीट झाले",
    raised_hand: "सहभागी व्हा",
    liked_post: "तुमची पोस्ट आवडली",
    disliked_post: "तुमची पोस्ट आवडली नाही",
    circulated_post: "तुमची पोस्ट सरक्युलेट करा",
    quoted_post: "तुमचं पोस्ट कोट करा",
    commented_post: "तुमच्या पोस्टवर टिप्पणी केली",
    mention_post: "तुमचा उल्लेख केला",
    replied_post: "तुमच्या पोस्टवर प्रतिसाद दिला",
    write: "लिहा",
    create: "तयार करा",
    close: "बंद करा",
    file: "फाईल",
    btn_cancel: "रद्द करा",
    allow: "परवानगी द्या",
    reject: "नाकारा",
    text_wilduser: "वाईल्डकार्ड पॅनलिस्ट म्हणून जोडा",
    DOB: "जन्म तारीख",
    website: "वेबसाइट",
    fullname: "पूर्ण नाव",
    difficulty: "आपण काही अडचण अनुभवत आहात, कृपया पुन्हा प्रयत्न करा",
    for_any_support_you_can_reach_us_at:
      "कोणत्याही मदतीसाठी आपण आम्हाला संपर्क साधू शकता",
    add: "जोडा",
    goback: "मागे जा",
    backlisting: "लिस्टिंगवर परत जा",
    yes: "हो",
    no: "नाही",
    bycont: "सुरु ठेवा,मी स्वीकारतो",
    termsof: "वापराच्या अटी",
    privacy: "गोपनीयता धोरण",
    upload: "आता अपलोड करा",
    reqs: "विनंती पाठवली",
    sht: "टाऊनहॉलवर शेअर करा",
    rsh: "आत्ताच शोधलेले",
    avionceconfirm: "राउंडटेबल ची पुष्टी झाल्यावर ते उपलब्ध होईल",
    nomoreinvitee:
      "कोणतेही आमंत्रित व्यक्ती दाखविले नाहीत,तुम्ही तळाला पोहचला आहात",
    norest: "कोणतेही निकाल आढळले नाहीत",
    beawp: "वाईल्डकार्ड पॅनलिस्ट बना",
    bewp2:
      "वाईल्डकार्ड पॅनलिस्ट बना. मॉडरेटरला तुम्हाला वाईल्डकार्ड पॅनलिस्ट म्हणून जोडण्याची इच्छा आहे",
    ipr: "मागील राउंडटेबल द्वारे आमंत्रित करा",
    rtNameDate: "राउंडटेबलचं नाव आणि दिनांक",
    attendees: "उपस्थितीत",
    searchByRt: "राउंडटेबलच्या नावानुसार शोधा",
    searchByMod: "मॉडरेटरच्या नावानुसार शोधा",
    searchByPan: "पॅनलिस्टच्या नावानुसार शोधा",
    searchByCategory: "कॅटेगरी नुसार शोधा",
    searchByTags: "टॅग्जनुसार शोधा",
    rmfromwild: "वाईल्डकार्डमधून काढा",
    nopast: "मागील राउंडटेबल नाहीत",
    advanceSearch: "अधिक शोध घ्या",
    wsomethin: "काहीतरी लिहा...",
    save: "सेव्ह करा ",
    rotate: "फोटो रोटेट करा",
    maintainar: "आकार रेशिओ ठेवा",
    customar: "कस्टम पैलू रेशिओ",
    ays: "आपल्याला खात्री आहे का?",
    yas: "हो, मला खात्री आहे",
    news: "बातम्या",
    showallrep: "सर्व उत्तरे दाखवा",
    recom: "सारखे सुचवलेले राउंडटेबल",
    recomOwn: "सारख्या मालकाचे राउंडटेबल सुचवा",
    recomMod: "सारख्या मॉडरेटरचे राउंडटेबल सुचवा",
    confDel: "डिलीट करण्याची पुष्टी करा",
    proceed: "पुढे जा",
    conf: "तुम्हाला खात्री आहे की तुम्ही हे राउंडटेबल डिलीट करू इच्छिता?",
    confdiscard: "तुम्हाला खात्री आहे की तुम्ही हे राउंडटेबल रद्द करू इच्छिता",
    stover: "वेळ आधीच झाली आहे.",
    kindlywait: " कृपया सुरु करण्याची वेळ अपडेट करा.",
    edit: "एडीट करा",
    leaveQ: "हो,शांतपणे बाहेर पडा",
    nostay: "नाही,मी थांबतो",
    inviteFollow: "तुमच्या फॉलोवर्स ला आमंत्रित करा",
    yhs: "तुम्ही निवडलेले आहे",
    oyc: "तुम्ही एकदा पुष्टी केल्यानंतर,तुम्ही हे निवडलेले रद्द करू शकता.",
    wyltr:
      "तुम्हाला ही सूचना मिळाल्यावर आनंद होईल का,जेव्हा आम्ही या राउंडटेबलची नोंदणी करु?",
    cont: "अभिनंदन!",
    ycanytomerestore: "तुम्ही कधीही मागील युजर नेम पुन्हा स्थापित करू शकता.",
    days: "दिवस",
    savedraft: "ड्राफ्टमध्ये साठवा",
    editaudio: "ऑडिओ एडीट करा",
    cancel: "रद्द करा",
    cancelled: "रद्द केले गेले",
    sendforRaisehand: "मोडरेटरचे लक्ष वेधण्यासाठी संदेश पाठवा.",
    viewm: "संदेश पहा",
    dismiss: "रद्द करा",
    nouserRaised: "कोणत्याही युजर ने चर्चेत सहभाग घेतलेला नाही",
    raisehanWmessage: "युजर ने केवळ सहभाग दाखवला आहे परंतु संदेश नाही",
    removeuser:
      "तुम्हाला ह्या युजर ला सहभाग घेणाऱ्या यादीतून काढून टाकायचं आहे?",
    star: "स्टार करा",
    unstar: "अस्टार करा",
    showthispoll: "हा पोल पहा",
    joinreq: "सामील होण्याची विनंती",
    youdonthavemsg: "तुमच्याकडे संदेश नाहीत.",
    accept: "स्वीकारा",
    nonoti: "कोणतेही नवीन नोटीफिकेशन नाही!",
    youarenotflw: "तुम्ही कोणाला फॉलो करत नाहीत",
    youhavenofollowers: "तुमच्याकडे फॉलोवर्स नाहीत",
    listofRT: "तुमच्या राउंडटेबलची यादी",
    pindiscussion: "लाइव राउंडटेबल चर्चेत सहभागी व्हा",
    comingrt: '"येणारे" - चांगली राउंडटेबल्स',
    sevenday: "7 दिवस",
    thirtyday: "30 दिवस",
    continueonBrowser:
      "ब्राउझरवर सुरु ठेवा किंवा अ‍ॅपमध्ये पुनर्निर्देशित करा!",
    openinApp: "अ‍ॅपमध्ये उघडा",
    stayinbroweser: "ब्राउझरमध्ये ठेवा",
    email: "ईमेल",
    update: "अपडेट करा",

    deactiveModal: {
      deactivateAcc: "आपले खाते निष्क्रिय करा",
      desuccess: "तुमचे खाते यशस्वीरीत्या निष्क्रिय केले गेले आहे.",
      areyousure: "तुमचे खाते निष्क्रिय करण्याबाबत तुम्ही खात्रीशीर आहात का?",
      yes: "होय",
      no: "नाही",
      note: "तुमच्या खात्याला निष्क्रिय करण्यापूर्वीच्या सूचना",
      p1: "तुम्ही आपले खाते 30 दिवसांत लॉगिन करून पुन्हा चालू करू शकता. 30 दिवसांनंतर, खाते डिलीट केले जाईल, त्यामुळे सर्व पोस्ट, आणि मीडिया हटविले जाईल.",
      p2: "काही अधिक माहिती",
      p3: "तुम्ही तुमचे खाते चुकून डिलीट केले तर तुम्ही पुनर्स्थापन करू शकता, खाते निष्क्रिय करण्यापूर्वी 30 दिवसाच्या आत हे होऊ शकते.",
      p4: "काही माहिती तरीही google किंवा bing सारख्या सर्च इंजिन मध्ये उपलब्ध असू शकते.",
      p5: "अधिक माहिती",
      p6a: "आपण फक्त बदलू इच्छित असल्यास",
      p6b: ", तुम्हाला तुमचे खाते निष्क्रिय करण्याची गरज नाही — ते संपादित करा",
      p7a: "जर तुम्हाला फक्त आपले",
      p7b: "प्रोफाइल नाव",
      p7c: "बदलायचं असेल, तर तुम्हाला आपले खाते निष्क्रिय करण्याची गरज नाही — तो आपल्यात सुधारणा करा",
      p8a: "जर तुम्हाला फक्त आपली",
      p8b: "प्रोफाइल चित्र",
      p8c: "बदलायचं असेल, तर तुम्हाला आपले खाते निष्क्रिय करण्याची गरज नाही — तो आपल्यात सुधारणा करा",
      p9a: "जर तुम्हाला फक्त आपला",
      p9b: "पासवर्ड",
      p9c: "बदलायचं असेल, तर तुम्हाला आपले खाते निष्क्रिय करण्याची गरज नाही — तो आपल्यात सुधारणा करा",
      p10: "जर तुम्हाला तुमची खुल के डेटा डाउनलोड करायची असेल, तर तुम्हाला तुमचे खाते निष्क्रिय करण्यापूर्वी विनंती आणि डाउनलोड प्रक्रिया दोन्हांकडे पूर्ण करायचं आहे. तुमच्या डेटा डाउनलोड करण्याच्या लिंक निष्क्रिय केलेल्या खात्यांना पाठविलेल्या जाऊ शकत नाहीत.",
      settings: "सेटिंग्ज",
    },

    pages: {
      mute: "म्यूट",
      beaw: "वाइल्डकार्ड पॅनलिस्ट बना",
      beawild:
        "वाइल्डकार्ड पॅनलिस्ट बना. मॉडरेटर तुम्हाला वाइल्डकार्ड पॅनलिस्ट बनवू इच्छितो",
      removeme: "मला वाइल्डकार्ड पासून काढा",
      removewild: " वाइल्डकार्ड काढा",
      unmute: "अनम्युट",
      belowhavehands: "खालील वापरकर्त्यांनी सहभाग दाखवला आहे",
      addwild: "वाइल्डकार्ड जोडा",
      waitingforscreen: "स्क्रीन शेअर करण्यासाठी वाट पाहत आहे",
      screenshared: "तुमची स्क्रीन शेअर केली जात आहे",
      loading: "लोड होत आहे...",
      facingDiffi: "तुम्ही काही अडचण अनुभवत आहात. कृपया पुन्हा प्रयत्न करा.",
      consentsent: "वाइल्डकार्ड सहमती अगोदर दिली गेली आहे.",
      cantaddw:
        "युजर ऑफलाइन आहे,त्यांना वाइल्डकार्ड म्हणून जोडले जाऊ शकत नाही.",
      fivespeaker:
        "पाच पॅनलिस्ट आहेत. म्हणजे तुम्ही आणखी कोणताही वाइल्डकार्ड पॅनलिस्ट जोडू शकत नाही",
      logintoview: "प्रोफाइल पहा, खुल के लॉगिन किंवा साइन अप करा",
      camerataken:
        "तुमचे कॅमेरा वर्तमानात इतर ठिकाणी वापरला जात आहे.आपला कॅमेरा प्रवेश बंद करा आणि इतर अ‍ॅप बंद करून खुल के वापरा",
      cantgetcam: "तुमचा कॅमेरा आणि मायक्रोफोन ट्रॅक मिळवू शकत नाही",
      distitle: "डिस्क्लेमर",
      disclaimer:
        'The information, views, opinions, and arguments (collectively "Views") showcased and presented by the panelist, or the interviewees solely belong to the individual who has expressed it and Loktantra Mediatech Private Limited("Company") does not agree, condone, endorse, or subscribe to the views expressed in the duration of the interview and makes no representation of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any view or opinion expressed by any panelist or the interviewee.The content showcased here is for informative purposes only and should not be construed for anything other than this.Any malice or hurting of sentiments is unintentional.Under no circumstance shall the Company have any liability for the Views of any panelist or interviewee, nor shall the Company have any liability for any loss or damage of any kind incurred because of Views expressed by the panelist. Your use of this platform is solely at your own risk.',

      blocked: {
        Unblock: "अनब्लॉक करा",
        nouserblock: "कोणतेही युजर ब्लॉक नाहीत!",
      },

      pagenotfound: "ओहो...हे पृष्ठ सापडत नाही.",
      gotohome: "मुख्यपृष्ठावर जा",
      nomute: "कोणतेही युजर म्यूट केलेले नाहीत!",
      other: "इतर",
      page: "पृष्ठ",
      of: "ची",
      gender: "लिंग",
      male: "पुरुष",
      female: "स्त्री",
      interest: "आवड",
      selectprivateinfo:
        "आपल्या खात्याची माहिती पृष्ठावर दाखविण्यासाठी माहिती निवडा.",
      privacySettings: "गोपनीयता सेटिंग्ज",
      update: "अपडेट",
      acc: "खाते",
      dob: "जन्मतारीख",
      loc: "स्थान",
      int: "आवड",
      lang: "भाषा",
      site: "वेबसाइट",
      usernotfound: "युजर चे युजरनेम सापडले नाही! कृपया दुसरे युजरनेम वापरा.",
      makecoowner: "सहमालक बनवा",
      l1: "पॅनलिस्ट हे राउंडटेबलसाठी उपस्थित असलेले वक्ते आहेत.",
      l2: "न्यून आणि जास्तीत जास्त ५ पॅनलिस्ट जोडता येऊ शकतात.",
      l2a: "एका एकच्या राउंडटेबलमध्ये किमान शून्य आणि जास्तीत जास्त १ पॅनलिस्ट जोडता येऊ शकतो.",
      l3: "प्रत्येक पॅनलिस्टसाठी तुम्ही १०० शब्दाचे वर्णन देऊ शकता.",
      back2rt: "राउंडटेबलला परत जा",
      thread: "थ्रेड",
      prelog: "हे केवळ रजीस्टर युजर्स साठी उपलब्ध असेल.",
      redir: "पुनर्निर्देशित करीत आहे",
      dis: "काढा",
      proupdated: "आपले प्रोफाइल अपडेट केले आहे",
      // forgot password page
      fortitle: "पासवर्ड विसरलात?",
      for1: "आपल्या खुल के खात्याशी संबंधित फोन नंबर किंवा ईमेल प्रविष्ट करा",
      for2: "तुमच्या खुल के खात्या साठी",
      rempass: "पासवर्ड लक्षात ठेवा?",
      login: {
        loginl: "आपल्या खात्यात लॉगिन करा",
        logintitleleft: "बातचीतिच्या जगात प्रवेश करा",
        login: "लॉगिन",
        newuser: "नवीन युजर नेम?",
        cra: "खाते तयार करा",
        infotext: " कोणत्याही मदतीसाठी, आपण आमच्याशी संपर्क साधू शकता",
        intro: "खुल के - सामाजिक संचार प्लेटफॉर्म",
        bycont: "सुरू ठेवताना ,सहमती असावी",
        pp21: "एक स्थान जेथे बातचीत महत्त्वाची असते.",
        pp22: "ओटीपी प्रविष्ट करा",
        pp23: "ओटीपी पाठवला",
        pp24: "ओटीपी पुन्हा पाठवा",
        pp25: "ओटीपी मिळाला नाही? पुन्हा पाठवा",
        pp26: "सेकंद",
        pp27: "पासवर्ड प्रविष्ट करा",
        pp28: "परत जा",
        pp29: "OTP मिळवा",
        restpass: "तुमचा पासवर्ड पुन्हा सेट करा",
        enternewpass: "नवीन पासवर्ड प्रविष्ट करा",
        retypepass: "तुमचा पासवर्ड पुन्हा टाइप करा.",
        conto: "सूर ठेवा",
        warning:
          "तुम्ही आधीपासूनच आमच्याशी रजिस्टर आहात. कृपया OTP प्रविष्ट करून लॉगीन करा.",
      },
      signup: {
        siuptitle: "साइन अप करा",
        sup: "साइन अप करा",
        enteradd: "ईमेल आयडी प्रविष्ट करा",
        usephone: "फोन वापरा",
        ph1: "फोन नंबर प्रविष्ट करा.",
        usemail: "इमेल आयडी वापरा",
        pg2: "एक प्लॅटफॉर्म जेथे बातचीत महत्त्वाची असते.",
        enterotp: "ओटीपी प्रविष्ट करा",
        resend: "ओटीपी पुन्हा पाठवा",
        alreadya: "आधीच अकाउंट आहे?",
        warning:
          "आमच्याशी रजिस्टर नाही. कृपया रजिस्टर होण्यासाठी ओटीपी प्रविष्ट करा.",
      },
      prelogin: {
        til1: "केवळ 2 चरणांची साइन अप प्रक्रिया",
        til2: "मोबाईल नंबर किंवा ईमेल आयडी प्रविष्ट करा",
        til3: "ओटीपी सत्यापन",
        til4: "ईमेल/ मोबाईल/ यूजरनेम प्रविष्ट करा",
        pleaseadd: "कृपया एक वैध नंबर प्रविष्ट करा",
        pleaseaddEmail: "कृपया एक वैध ईमेल प्रविष्ट करा",
        validem: "कृपया एक वैध ईमेल आयडी प्रविष्ट करा.",
        mtext: "टेक्सट साठी साइन इन किंवा साइन अप करा.",
        entero:
          "आपल्या खुल के खात्याशी संबंधित मोबाइल नंबर किंवा ईमेल प्रविष्ट करा",
      },
    },

    broadcast: {
      dest: "गंतव्य",
      connAccount: "आपला खाता ब्रॉडकास्ट ला कनेक्ट करा",
      noBroadcast: "कोणतेही प्रसारण गंतव्य जोडले गेले नाहीत",
      addDest: "गंतव्य जोडा",
    },

    changePass: {
      verify: "सत्यापित करा",
      change: "पासवर्ड बदला",
      enterOld: "जुना पासवर्ड प्रविष्ट करा",
      enterNew: "नवीन पासवर्ड प्रविष्ट करा",
      confirmNew: "नवीन पासवर्ड पुष्टी करा",
      bothMatch: "दोन्ही पासवर्ड संगतीत असणे आवश्यक आहे.",
    },

    mute: {
      addMuted: "म्युट केलेले शब्द जोडा",
      noMuted: "कोणतेही शब्द म्युट केलेले नाहीत!",
    },
  },
  walkthrough: {
    walkthrough_dailog_question: "आपल्याला हे पुन्हा पाहायला आवडेल का?",
    step_1_1: "खुल के वरती स्वागत आहे,",
    step_1_2: "तुम्हाला खुल के ची सफर करायला आवडेल का?",
    step_2:
      '<span>आणि इथे पोस्ट आणि नेटवर्क द्वारे <span style="color:#66b984; font-weight: bold">टाऊनहॉल</span> </span>',
    step_3:
      "<span>लाईक, प्रसार, शेअर आणि कमेंट पर्यायांचा उपयोग करून संवाद साधा</span>",
    step_4: "<span>फार वेळ नाही? लघु व्हिडिओ पहा</span>",
    step_5:
      '<span style="color:#66b984; font-weight: bold">पोस्ट </span> तयार करा',
    step_6: "पोस्टचा मजकूर, चित्रे, कागदपत्रे, व्हिडिओ, ऑडिओ आणि पोल तयार करा",
    step_7: "एक संवाद संपादित करा आणि नवे विषय शोधा",
    step_8:
      'पूर्व, आगामी, लाईव्ह आणि तुमचे <span style="color:#66b984; font-weight: bold">राउंडटेबल्स शोधा</span>',
    step_9:
      'तुमचे <span style="color:#66b984; font-weight: bold">राउंडटेबल्स तयार करा </span> तयार करा',
    step_10: "आपल्या मित्रांसोबत आणि फॉलोवर्स सोबत विवेकाने बोला",
    step_11:
      'आपल <span style="color:#66b984; font-weight: bold">प्रोफाइल</span> पहा आणि कस्टमाइज करा',
  },
  dateTime: {
    months: {
      jan: "जानेवारी",
      feb: "फेब्रुवारी",
      mar: "मार्च",
      apr: "एप्रिल",
      may: "मे",
      jun: "जून",
      jul: "जुलै",
      aug: "ऑगस्ट",
      sep: "सप्टेंबर",
      oct: "ऑक्टोबर",
      nov: "नोव्हेंबर",
      dec: "डिसेंबर",
    },
    validation: {
      dobValidation:
        "आम्हाला खात्री आहे की तुम्ही चुकीची माहिती प्रविष्ट केली आहे. कृपया सुनिश्चित करा की तुम्ही वास्तविक जन्मतारीख वापरली आहे.",
    },
    invitationPopUp: {
      youHaveBeenInvited: "तुम्हाला आमंत्रित केले गेले आहे",
    },
  },
  welcomePop: {
    welcome: "स्वागत आहे",
    createUsername: "युजर नेम तयार करा",
    updateUsername: "तुमचे युजर नेम अपडेट करा",
    addDP: "तुमचा प्रोफाइल फोटो जोडा,आपले युजर नेम अपडेट करा",
    selectLang: "भाषा निवडा",
    interested: "जर तुम्ही पासवर्ड लॉगीन मध्ये इच्छुक असाल,",
    kindly: "कृपया",
    setPass: "तुमचा पासवर्ड सेट करा",
  },
  post: {
    pollCreate: "तुमचा पोल तयार केला गेला आहे.",
  },
  rtList: {
    owner: "तुम्ही मालक असलेले राउंडटेबल",
    moderator: "जेथे तुम्ही मोडरेटर होता असे राउंडटेबल",
    panalist: "जेथे आपण पॅनलिस्ट होता असे राउंडटेबल",
    audiance: "येथे आपण दर्शक होता असे राउंडटेबल",
  },
  keyMoments: {
    addKeyMoment: "की मोमेंट्स जोडा",
    keyMoments: "की मोमेंट्स",
    rountableChapter: "राउंडटेबल चाप्टर",
    title: "शीर्षक",
    addTime: "वेळ जोडा",
    addTitle: "शीर्षक जोडा",
    rtKeyMom: "राउंडटेबलचे की मोमेंट्स",
    validation: {
      invalidFormat: "MM:SS हे एक वैध स्वरूप आहे",
      withinVideoTimeframe: "कृपया व्हिडिओच्या संदर्भात टाइमस्टॅम्प जोडा",
      addSequentially: "कृपया क्रमाने की मोमेंट्स जोडा",
      maxLimit: "प्रत्येक चाप्टर साठी जास्तीत जास्त १० मिनिट्स",
      minLimit: "प्रत्येक चाप्टर साठी किमान १५ सेकंद",
      allFieldsFilled: "कृपया सर्व जागा योग्यपणे भरल्या आहेत ते तपासा ",
      createdSuccessfully:
        "तुमचे की मोमेंट्स यशस्वीरीत्या तयार केले गेले आहेत.",
      updatedSuccessfully:
        "तुमचे की मोमेंट्स यशस्वीरीत्या अपडेट केले गेले आहेत.",
    },
    dummyKeyMom: {
      one: "होस्ट आणि वक्त्यांचा परिचय",
      two: "वक्ते पाऊसाबद्दल बोलले",
      three: "वक्ते हवामाना बद्दल बोलले",
      four: "मेट्रो प्रकल्प",
      five: "सामान्य लोकांवरील परिणाम",
      six: "जास्त पाऊसामुळे परिवहन आणि रेल्वेची आपत्ती",
      seven: "बचाव कार्यासाठी काय योजना आहे?",
      eight: "पुढील हवामान अंदाज काय आहे?",
      nine: "या स्थितीतील लोकांसाठी वक्त्यांचा सल्ला",
      ten: "विषय सारांश",
    },
    exampleHeadingKeyMom: "की मोमेंट्स चे उदाहरण",
    section1: {
      heading: "राउंडटेबल मधील की मोमेंट्स?",
      content1:
        "राउंडटेबल की मोमेंट्स आपल्या राउंडटेबल चर्चेचे ठळक मुद्दे आहेत. ते आपल्या टाइमलाइन मधील ठराविक विषयाशी संबंधित असतात किंवा ठराविक उत्तर देतात.",
      content2:
        "सामान्यतः, आपल्या व्हिडिओमधील की मोमेंट्स हे त्या टाइमलाइन मधील विभागाचे विवरण दर्शवतात.",
      content3:
        "राउंडटेबल मध्ये देखील की मोमेंट्स हे शोध परिणामत दिसत नाही.म्हणून व्हिडिओच्या वर्णनात आणि, काही अटी अधिकृत झाल्यास, आपल्या व्हिडिओच्या प्रोग्रेस बार वर ते दिसतात.",
    },
    section2: {
      heading: "राउंडटेबलमध्ये की मोमेंट्स युजर्स साठी महत्वाचे का आहे?",
      content1: `की मोमेंट्स आपल्या व्हिडिओला शोध निकालातील उच्च रॅंकिंग साठी मदत करतात, जसे की आपल्या राउंडटेबलला ट्रॅफिक देण्यास मदत होते. हे कारण आहे की अतिरिक्त माहिती खुल के ला निर्णय ठरविण्यात मदत करते की आपला राउंडटेबल हा विशिष्ट शोधांसाठी उपयुक्त आहे.`,
      content2: `तसेच, राउंडटेबल की मोमेंटस म्हणजे आपल्या दर्शकांसाठी जीवन सोपे करतात. त्यांना क्षणात कळू शकेल की आपले व्हिडिओ मधून त्यांना ते मिळेल का ज्याचा ते शोध घेत आहे. जर हो, तर एक क्लिक त्यांना त्या व्हिडिओ क्षणांन मध्ये नेण्यास सोपे करते.`,
      content3: `कारण की लोकांना माहित आहे की तुमचे व्हीडीओ पाहण्यास सुरुवात केल्यावर त्यांना काय मिळू शकेल, त्यामुळे की मोमेंट्स हे एक उत्तम साधन बनू शकते. याचाच अर्थ असा की कमी डाउनवोटस आणि जास्तीत जास्त शेअर्स असा आहे, ज्यामुळे खुल के च्या अल्गोरीदम कडून प्रथम राउंडटेबल शोधात मान्यता मिळते आणि इतरांन पर्यंत पोहचण्याची देखील मदत होते.`,
    },
    section3: {
      heading1: "आपल्या राउंडटेबलमध्ये की मोमेंट्स कुठल्या ठिकाणी जोडाव्या?",
      heading2:
        "की मोमेंट्स राउंडटेबलच्या मालक / सह-मालका द्वारे 3 प्रकारे सुरू केली जाऊ शकतात:",
      content1: "राउंडटेबल लिस्टिंग पृष्ठ.",
      content2: "राउंडटेबल वर्णन.",
      content3: "राउंडटेबल कार्ड.",
    },
    section4: {
      heading: "आपल्या राउंडटेबल व्हिडिओजमध्ये की मोमेंट्स कसे जोडायचे?",
      content1: "खुल के खात्यात लॉग इन करा.",
      content2:
        "तुम्ही तयार केलेला आणि यशस्वीरीत्या पूर्ण केलेला राउंडटेबल वर जा.",
      content3:
        "त्या राउंडटेबलच्या तपशीलांमध्ये क्लिक करा, त्यात की मोमेंट्स जोडा",
      content4:
        "की मोमेंट्स जोडा बटणावर क्लिक करा,आणि अनुक्रमिक आदेशात वेळ आणि शीर्षक जोडा",
      content5: "प्रत्येक वेळ आणि शीर्षक पुढील पंक्तीवर जोडा",
      content6:
        "की मोमेंट्स सेव्ह करा (तुम्ही ह्या की मोमेंट्स ची नंतर कधीही बदल करू शकता त्यासाठी एडीट चिन्हाचा वापर करू शकता)",
    },
    section5: {
      heading:
        "की मोमेंट्स सक्रिय करण्यासाठी, आपणास काही अतिरिक्त अटींना पूर्ण करावे लागेल:",
      content1: "वेळ क्रमानुसार असणे आवश्यक आहे.",
      content2: "की मोमेंट्स किमान 15 सेकंदांचा असणे आवश्यक आहे.",
      content3: "तुम्हाला किमान 3 की मोमेंट्स लागतात.",
      content4: "की मोमेंट्स जास्तीत जास्त 20 असू शकतात.",
    },
  },
  report: {
    details: "तपशील",
    report: "रिपोर्ट",
    embedPost: "एम्बेड पोस्ट",
    whyReport: "आपण हे पोस्ट रिपोर्ट का  करत आहात?",
    spam: "स्पॅम",
    falseInfo: "चुकीची माहिती",
    hateSpeech: "हेट स्पीच",
    scamFraud: "धोखेबाजी किंवा धोखाधडी",
    suicideSelfHarm: "आत्महत्या किंवा स्वतःला इजा करण्याबाबत",
    nuditySexualActivity: "न्यूडिटी किंवा सेक्षुअल",
    pleaseElaborate: "कृपया आपले कारण विस्तारित करा",
    submit: "सबमिट",
    thankYou: "धन्यवाद",
    yourFeedback:
      "आपला प्रतिसाद महत्त्वाचा आहे यातून खुल के समुदायाला सुरक्षित ठेवण्यात मदत होते",
    furtherSteps: "आणखी काही स्टेप्स",
    block: "ब्लॉक",
    blockDesc: "ब्लॉक करण्याचे वर्णन",
    unfollow: "अनफॉलो",
    unfollowDesc: "अनफॉलो करण्याचे वर्णन",
    mute: "म्यूट",
    muteDesc1: "म्यूट करण्याचे वर्णन 1",
    muteDesc2: "म्यूट करण्याचे वर्णन 2",
    close: "बंद करा",
    cancel: "रद्द करा",
    youBlocked: "आपण ब्लॉक केले",
    canUnblock: "आपण हे खाते कधीही अनब्लॉक करू शकता",
    youUnfollowed: "तुम्ही अनफॉलो केले",
    canFollow: "आपण हे खाते कधीही फॉलो करू शकता",
    youMuted: "तुम्ही म्यूट केले",
    canUnmute: "आपण हे खाते कधीही अनम्यूट करू शकता",
  },
  feedback: {
    feedback: "अभिप्राय",
    leavefeed: "अभिप्राय लिहा",
    add: "फोटो किंवा व्हिडिओ जोडा",
    submit: "सबमिट",
    enterfeed: "अभिप्राय प्रविष्ट करा",
    thankYou: "आपल्या अम्युल अभिप्रायासाठी धन्यवाद!",
    success: "आपला अभिप्राय यशस्वीरीत्या सबमिट केला गेला आहे",
    gotohome: "होमपेजवर जा",
  },
  showcase: {
    title: "शोकेस",
    description: "खुलके समुदाय को अपने वीडियो दिखाएं",
    success: "आपका प्रदर्शन सफलतापूर्वक प्रस्तुत कर दिया गया है",
    gotohome: "मुखपृष्ठ पर जाएँ",
    removeShowcase: "प्रदर्शन से निकालें",
    addToShowcase: "प्रदर्शन",
    comments: "टिप्पणियाँ",
    likes: "पसन्द",
    dislikes: "नापसन्द",
    circulated: "प्रसारित",
    quotes: "उल्लेख",
  },
  getApp: {
    playStore: "प्ले स्टोर",
    appStore: "ऐप स्टोर",
    getTheApp: "एप्प प्राप्त करे",
    download: "डाउनलोड करें",
  },
};

export default allWordsM;
