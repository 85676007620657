import React from "react";
import moment from "moment";
import UserProfile from "../UserProfile";
import VIPComp from "../VipComp";
import { allWords } from "../../App";

export default function ListingParticipants(props) {
  const {
    moderator,
    speakers,
    timestamp,
    user_type,
    marginTopStyle,
    hideTime,
  } = props;

  let speaker_list_one = [];
  let speaker_list_zero = [];
  if (speakers?.length > 0) {
    speakers?.forEach((speaker) => {
      if (speaker?.has_confirmed === 1 && speaker?.type === "NORMAL") {
        speaker_list_one.push(speaker);
      }
      if (speaker?.has_confirmed === 0 && speaker?.type === "NORMAL") {
        speaker_list_zero.push(speaker);
      }
    });
  }

  return (
    <>
      <div data-test="src-components-RTListingCard-ListingParticipants-0">
        <div data-test="src-components-RTListingCard-ListingParticipants-1">
          <div
            style={{ display: "flex" }}
            data-test="src-components-RTListingCard-ListingParticipants-2"
          >
            <div
              className="d-flex justify-content-between"
              style={{
                marginLeft: "-5px",
                alignItems: "center",
                marginTop: "-8px",
                color: "#f4f4f4",
              }}
              data-test="src-components-RTListingCard-ListingParticipants-3"
            >
              <UserProfile
                className="mod_img"
                username={moderator?.username}
                hidden={moderator?.has_confirmed !== 1 ? true : false}
              />
              &nbsp;
              <div data-test="src-components-RTListingCard-ListingParticipants-4">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#f4f4f4",
                  }}
                  data-test="src-components-RTListingCard-ListingParticipants-5"
                >
                  <p
                    style={
                      marginTopStyle ? { marginTop: marginTopStyle } : null
                    }
                    className="mod_tag"
                    data-test="src-components-RTListingCard-ListingParticipants-6"
                  >
                    @{moderator?.username || "Deleted User"}
                  </p>
                  <VIPComp user_type={user_type} />
                </div>

                {/* Time Div */}
                {!hideTime && (
                  <div
                    className="d-flex"
                    style={{ marginTop: "1.3rem", marginLeft: "-3px" }}
                    data-test="src-components-RTListingCard-ListingParticipants-7"
                  >
                    {/* <img className="time_img" alt="this is image"  src={timeimg} /> */}
                    <p
                      className="time_p"
                      data-test="src-components-RTListingCard-ListingParticipants-8"
                    >
                      <div
                        className="d-flex text-muted"
                        data-test="src-components-RTListingCard-ListingParticipants-9"
                      >
                        {moment(new Date(timestamp?.["start"]))
                          .local()
                          .format("DD MMM")}
                        <span data-test="src-components-RTListingCard-ListingParticipants-10">
                          &nbsp;at&nbsp;
                        </span>
                        {moment(new Date(timestamp?.["start"]))
                          .local()
                          .format("hh:mm A")}
                      </div>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
