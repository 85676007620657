import axios from "axios";
import { POST_API_BASE_URL, REACT_APP_BASE_URL, REACT_APP_BASE_URL_FOR_ROUNDTABLE } from "../constants/env";
import {
  auto_login_continue,
  device_info,
  checkUserStatus,
} from "../utils/utils";

const storePostUTM = async (data) => {
  const userStatus = checkUserStatus();
  const config = {
    headers: {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    },
  };

  const payload = { message :{...data}, device_info:{...device_info}}

  try {
    const response = await axios.post(
      `${POST_API_BASE_URL}/post-utm-analytics`,
      payload,
      config
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

const storeProfileUTM = async (data) => {
  const userStatus = checkUserStatus();
  const config = {
    headers: {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    },
  };

  const payload = { ...data, ...device_info };

  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/user/profile-view-analytics`,
      payload,
      config
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

const storeRT_UTM = async (data) => {
  const userStatus = checkUserStatus();
  const config = {
    headers: {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    },
  };

  const payload = { ...data, ...device_info };

  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL_FOR_ROUNDTABLE}/rt-utm-analytics`,
      payload,
      config
    );
    return response?.data;
  } catch (error) {
    if (error.response && error.response.status == 401) {
      return auto_login_continue(storeRT_UTM(data));
    } else {
      return error?.response?.data;
    }
  }
};

const viewerCountRT = async (data) => {
  const userStatus = checkUserStatus();
  const url = `${POST_API_BASE_URL}/round-table${userStatus?.isAnonymous ? "/anonymous" : ""}/modify_viewer_count/`;
  const config = {
    headers: {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
    },
  };

  const payload = { ...data, ...device_info };

  try {
    const response = await axios.post(url, payload, config);
    return response?.data;
  } catch (error) {
    if (error.response && error.response.status == 401) {
      return auto_login_continue(storeRT_UTM(data));
    } else {
      return error?.response?.data;
    }
  }
};

const storePageUtm = async (data) => {
  const userStatus = checkUserStatus();
  const config = {
    headers: {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    },
  };
  
  const payload = { ...data, ...device_info };

  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL_FOR_ROUNDTABLE}/page-utm-analytics`,
      payload,
      config
    );
    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
}

export { storePostUTM, storeProfileUTM, storeRT_UTM, viewerCountRT, storePageUtm };
