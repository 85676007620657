import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  pastrt: [],
  error: null,
  loading: false,
  keyMoments: [],
  showPastRTScrollEditorModal: false,
};

const pastRTReducer = (state = initialState, action) => {
  switch (action.type) {
    // ** GET INTEREST
    case actionTypes.GET_PASTRT_START:
      return {
        ...state,
        loading: true,
        leaveRt: false,
      };
    case actionTypes.GET_PASTRT_SUCCESS:
      return {
        ...state,
        pastrt: action.payload,
        loading: false,
        leaveRt: false,
      };
    case actionTypes.GET_PASTRT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        leaveRt: false,
      };
    case actionTypes.LEAVE_PAST_RT:
      return {
        ...state,
        leaveRt: true,
      };

    case actionTypes.GET_KEY_MOMENTS_SUCCESS:
      return {
        ...state,
        keyMoments: action.payload,
      };
    case actionTypes.UPDATE_PAST_RT_SCROLL_EDITOR_MODAL:
        return {
          ...state,
          showPastRTScrollEditorModal: action.payload,
        };
    case actionTypes.GET_KEY_MOMENTS_FAIL:
      return {
        ...state,
        keyMoments: [],
      }
    default:
      return state;
  }
};

export const getPastRtScrollEditorModalState =  (state) => {
  return state.pastrt.showPastRTScrollEditorModal
}

export default pastRTReducer;
