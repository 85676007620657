import * as actionTypes from "../../actions/actionTypes";

const initialState = {};

const personalizedRtCategoryListingReducer = (state = initialState, action) => {
    state = { ...state, isError: false, isLoading: false };

    switch (action.type) {
        case actionTypes.PERSONALIZED_RT_LISTING_CATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.PERSONALIZED_RT_LISTING_CATEGORY_SUCCESS:
            return {
                ...state,
                data: action.payload?.data?.data,
            };
        case actionTypes.PERSONALIZED_RT_LISTING_CATEGORY_FAIL: {
            return {
                ...state,
                error: action.payload,
                isError: true,
            };
        }
        default:
            return state;
    }
};

export default personalizedRtCategoryListingReducer;
