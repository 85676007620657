import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LeftSideBarDiv, Cross } from "./style";
import "./style.css";
import axios from "axios";
//** assets
// import LogoImg from "../../assets/icons/KhulKe_logo.svg";
import { REACT_APP_BASE_URL_FOR_USER, STATIC_TOKEN } from "../../constants/env";
// ** Components
import ListItem from "./AccountListItem";
import MemoAccountIcon from "../IconsComponents/AccountIcon";
// import MemoPrivacyIcon from "../IconsComponents/PrivacyIcon";
import MemoMuteWords from "../IconsComponents/MuteWords";
import MemoMuteAccounts from "../IconsComponents/MuteAccounts";
import MemoBlockedAccounts from "../IconsComponents/BlockedAccounts";
import MemoDocumentIcon from "../IconsComponents/DocumentIcon";
import MemoPrivacyPolicyIcon from "../IconsComponents/PrivacyPolicyIcon";
import MemoCommunityGuidlinesIcon from "../IconsComponents/CommunityGuidelinesIcon";
import MemoDisclaimerIcon from "../IconsComponents/DisclaimerIcon";
import MemoTermsAndConditionsIcon from "../IconsComponents/TermsAndConditionsIcon";
import MemoBroadCastIcon from "../IconsComponents/BroadCastIcon";
import MemoFAQIcon from "../IconsComponents/FAQIcon";
import MemoLogoutIcon from "../IconsComponents/LogoutIcon";
import MemoSupportIcon from "../IconsComponents/SupportIcon";
import MemoSitemapIcon from "../IconsComponents/SitemapIcon";
import logoutUser from "../../apis/logoutUser";
import PasswordIcon from "../IconsComponents/PasswordIcon";
import CloseIcon from "../../assets/icons/close_icon.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logger from "../../logger";
import { Divider } from "@mui/material";
import { allWords } from "../../App";

// eslint-disable-next-line react/prop-types
const AccountLeftSideBar = ({ handleClose }) => {
  //Refs
  const pass1 = useRef("");
  const navigate = useNavigate();
  const isMobile = window.screen.width < 769;
  // Effect
  useEffect(() => {
    if (
      window.location.pathname.includes("oldpass") ||
      window.location.pathname.includes("changepass") ||
      window.location.pathname.includes("createpass")
    ) {
      pass1.current.classList = ["passwordLink passwordBold"];
    }
  }, []);
  useEffect(() => {
    if (!localStorage?.current_user && localStorage?.anonymous_user)
      return navigate("/home", { replace: true });
  }, []);
  // handler
  async function passFunc() {
    let url = `${REACT_APP_BASE_URL_FOR_USER}/check_passwordpresent/`;

    const data = {
      user_data: JSON.parse(localStorage.getItem("current_user"))["username"],
    };
    const config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: STATIC_TOKEN,
      },
      data: data,
    };

    await axios(config)
      .then((res) => {
        if (res.data.data[0].pflag === false) {
          window.location.replace("/setPass");
        }
        if (res.data.data[0].pflag === true) {
          window.location.replace("/oldpass");
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  }

  return (
    <>
      <LeftSideBarDiv>
        <div
          style={{ position: "relative" }}
          data-test="src-components-AccountLeftsideBar-index-0"
        >
          
          <div data-test="src-components-AccountLeftsideBar-index-1">
            <Cross
              src={CloseIcon}
              onClick={handleClose}
              style={{
                position: "absolute",
                top: isMobile ? "32px" : "18px",
                right: "20px",
                marginTop: "0",
                height: "auto",
              }}
            />
          </div>

          <div data-test="src-components-AccountLeftsideBar-index-2" className="accountLeftSidebar-account-container">
            <div className="px-1rem">
              <ListItem
                selected={false}
                Icon={ArrowBackIcon}
                iconStyles={{marginTop: "1.2rem"}}
                title={'Settings'}
                path={isMobile ? "/profile/" + JSON.parse(localStorage.getItem("current_user"))["username"] : "/home"}
                style={{"fontSize": "36px", fontWeight: "bold", color: "#fff", marginTop: "1.2rem"}}    
                disableTitleClick={true}         
              />
            </div>
            <Divider sx={{ background: "white", marginTop: "2rem" }}/>

            <div className="px-1rem">
              <ListItem
                selected={
                  window.location.pathname === "/account_settings" ? true : false
                }
                Icon={MemoAccountIcon}
                title={allWords.leftAccounts.acc}
                path={"/account_settings"}             
              />
              {/* <ListItem
              selected={
                window.location.pathname === "/invite_friends" ? true : false
              }
              Icon={MemoInviteFriends}
              title={allWords.leftAccounts.invite}
              path={"/invite_friends"}
            /> */}
              {/* <ListItem
                selected={
                  window.location.pathname === "/privacy_settings" ? true : false
                }
                Icon={MemoPrivacyIcon}
                title={allWords.leftAccounts.privacy}
                path={"/privacy_settings"}
              /> */}
                 
              <ListItem
                selected={
                  window.location.pathname === "/broadcast" ? true : false
                }
                Icon={MemoBroadCastIcon}
                title={allWords.leftAccounts.broadcast}
                path={"/broadcast"}
              />
              <div
                ref={pass1}
                className={`passwordLink  ${
                  ["/setPass", "/oldpass"].includes(window.location.pathname)
                    ? "linkActive"
                    : "linkNotActive"
                }`}
                onClick={() => passFunc()}
                data-test="src-components-AccountLeftsideBar-index-3"
              >
                <>
                  <PasswordIcon
                    className={
                      ["/setPass", "/oldpass"].includes(window.location.pathname)
                        ? "linkActive"
                        : "linkNotActive"
                    }
                    style={{ width: "25px", height: "25px", marginRight: "1rem" }}
                  />
                  <span data-test="src-components-AccountLeftsideBar-index-4">
                    {allWords.leftAccounts.pass}
                  </span>
                </>
              </div>                   
              <ListItem
                selected={
                  window.location.pathname === "/muted_words" ? true : false
                }
                Icon={MemoMuteWords}
                title={allWords.leftAccounts.mutedWord}
                path={"/muted_words"}
              />
              <ListItem
                selected={
                  window.location.pathname === "/muted_accounts" ? true : false
                }
                Icon={MemoMuteAccounts}
                title={allWords.leftAccounts.MutedAcc}
                path={"/muted_accounts"}
              />
              <ListItem
                selected={
                  window.location.pathname === "/blocked_accounts" ? true : false
                }
                Icon={MemoBlockedAccounts}
                title={allWords.leftAccounts.blocked}
                path={"/blocked_accounts"}
              />
            </div>           
          </div>
        </div>
        <div className="px-1rem">
          <Divider sx={{ background: "white" }} />
        </div>
        <div className="px-1rem">
          <div
            className="acclist"
            style={{
              height: "19.5rem",
              overflowX: "hidden",
              overflowY: "auto",
              paddingRight: "12px",
            }}
            data-test="src-components-AccountLeftsideBar-index-5"
          >
            <ListItem
              openTab={true}
              Icon={MemoSitemapIcon}
              title={allWords.leftAccounts.sitemap}
              path={"/sitemap"}
            />
            <ListItem
              // eslint-disable-next-line no-undef
              selected={
                window.location.pathname === "/feedback" ||
                window.location.pathname === "/submit"
                  ? true
                  : false
              }
              Icon={MemoSupportIcon}
              title={allWords.leftAccounts.feedback}
              path={"/feedback"}
            />
            <ListItem
              Icon={MemoTermsAndConditionsIcon}
              title={allWords.leftAccounts.additionalResources}
              path={"/additional-resources"}
              selected={window.location.pathname === "/additional-resources"}
            />
          </div>
        </div>
        <div
          className="d-flex warn-text px-1rem accountSidebar-logout-container"
          style={{ cursor: "pointer", fontSize: "1.125rem" }}
          onClick={logoutUser}
          data-test="src-components-AccountLeftsideBar-index-6"
        >
          <MemoLogoutIcon
            className="icon selected_icon mt-1"
            style={{
              width: "25px",
              height: "25px",
              opacity: 1,
            }}
          />
          <small style={{ visibility: "hidden", userSelect: "none" }}>
            |||||
          </small>
          <p data-test="src-components-AccountLeftsideBar-index-7">
            {allWords.leftAccounts.logout}
          </p>
        </div>
      </LeftSideBarDiv>
    </>
  );
};

export default AccountLeftSideBar;
