import React from "react";
import vip_tier1 from "../assets/icons/vip_tier1.svg";
import vip_tier2 from "../assets/icons/vip_tier2.svg";
import vip_tier3 from "../assets/icons/vip_tier3.svg";
import vip_tier4 from "../assets/icons/vip_tier4.svg";
import vip_tier5 from "../assets/icons/vip_tier5.svg";
import vip_tier6 from "../assets/icons/vip_tier6.svg";

export default function VIPComp({ user_type, overrideVipStyle }) {
  const userType = user_type?.toLowerCase();

  const defStylee = overrideVipStyle
    ? {
        width: 14,
        height: 14,
        marginLeft: "4px",
        marginTop: "6px",
        ...overrideVipStyle,
      }
    : {
        width: 14,
        height: 14,
        marginLeft: "4px",
        // marginTop: "6px",
      };
  return (
    <span
      style={{
        display: "inline-flex",
        alignItems: "center",
        margin: "0 5px 0 2px ",
      }}
      data-test="src-components-VipComp-0"
    >
      {userType == "respected" && (
        <img
          src={vip_tier1}
         alt="this is image"
          style={defStylee}
          data-test="src-components-VipComp-1"
        />
      )}
      {userType == "vip" && (
        <img
          src={vip_tier2}
         alt="this is image"
          style={defStylee}
          data-test="src-components-VipComp-2"
        />
      )}
      {userType == "celebrity" && (
        <img
          src={vip_tier3}
         alt="this is image"
          style={defStylee}
          data-test="src-components-VipComp-3"
        />
      )}
      {userType == "top" && (
        <img
          src={vip_tier4}
         alt="this is image"
          style={defStylee}
          data-test="src-components-VipComp-4"
        />
      )}
      {userType == "normal" && (
        <img
          src={vip_tier5}
         alt="this is image"
          style={defStylee}
          data-test="src-components-VipComp-5"
        />
      )}
      {userType == "company" && (
        <img
          src={vip_tier6}
         alt="this is image"
          style={defStylee}
          data-test="src-components-VipComp-6"
        />
      )}
      {/* {userType == "default" && (
        <img src={vip_tier6} alt="this is image" style={defStylee} />
      )} */}
    </span>
  );
}
