import Slider from "react-slick";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ChevronLeft } from "@mui/icons-material";

export function ImageSlider({ images }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <div data-test="src-pages-Townhall-BreakingNews-0">
        <ChevronRight fontSize="large"/>
      </div>
    ),
    prevArrow: (
      <div data-test="src-pages-Townhall-BreakingNews-1">
        <ChevronLeft fontSize="large"/>
      </div>
    ),
  };
  return (
    <Slider {...settings} className="ImageSlider-slider">
      {images?.map((image, index) => (
        <div key={index} className="ImageSlider-image-container">
          <img
            src={image.url}
            alt={`Slide ${index}`}
            height={100}
            className="ImageSlider-image"
          />
        </div>
      ))}
    </Slider>
  );
}
