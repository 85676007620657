import { getShowCaseDataApi } from "../../../apis/profileApi";
import * as actionTypes from "../actionTypes";

export const fetchShowcaseRequest = () => ({
  type: actionTypes.FETCH_SHOWCASE_REQUEST,
});

export const fetchShowcaseSuccess = (data) => ({
  type: actionTypes.FETCH_SHOWCASE_SUCCESS,
  payload: data,
});

export const fetchShowcaseFailure = (error) => ({
  type: actionTypes.FETCH_SHOWCASE_FAILURE,
  payload: error,
});

export const fetchShowcaseData = (orgId) => {
  return async (dispatch) => {
    dispatch(fetchShowcaseRequest());
    try {
      const data = await getShowCaseDataApi(orgId);
      dispatch(fetchShowcaseSuccess(data));
    } catch (error) {
      dispatch(fetchShowcaseFailure(error.message));
    }
  };
};
