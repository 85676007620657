/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import UserProfile from "../UserProfile";
import ToastHandler from "../../utils/ToastHandler";
import CropIcon from "@mui/icons-material/Crop";
import CloseIcon from "@mui/icons-material/Close";
import EditImage from "../Post/AddPostDialog/EditImage";
import { allWords } from "../../App";
import { REACT_APP_BASE_URL } from "../../constants/env";
import axios from "axios";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ImagePreviewDialog } from "./ImagePreviewDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function Highlights({
  user_id,
  image,
  setImage,
  handleHighlightsImageChange,
  setOpenImageInput,
  openImageinput,
}) {
  const [HighlightsData, setHighlightsData] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [openImagePreview, setopenImagePreview] = useState(false);
  const [previewData, setpreviewData] = useState();
  const [imageSrc, setImageSrc] = useState({
    index: null,
    url: null,
    file: null,
  });
  const [imageCaption, setImageCaption] = useState("");
  const editImageRef = useRef();
  const [editImage, setEditImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editHighlight, setEditHighlight] = useState(false);
  const [removeHighlight, setRemoveHighlight] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState([]);
  const [highlightsToEdit, setHighlightsToEdit] = useState([]);
  const [yesRemove, setYesremove] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditHighlight = () => {
    setEditHighlight(() => true);
    handleMenuClose();
  };

  const handleRemoveHighlight = () => {
    setRemoveHighlight(true);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setRemoveHighlight(false);
  };

  const handleRemove = async () => {
    // console.log("selectedHighlight ==== ", selectedHighlight);
    setLoading(true);
    const highlight_id = selectedHighlight._id;
    const org_id = user_id;
    const config = {
      method: "DELETE",
      url: `${REACT_APP_BASE_URL}/user/v2/org/${org_id}/delete-highlight/${highlight_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // data: formData,
    };
    // console.log("Config of delete ==== ", config);
    try {
      let resp = await axios(config);
      if (resp.status === 200) ToastHandler("sus", "Deleted successfully");
      setopenImagePreview(() => false);
      getHighlight();
      // console.log("Resp of Delete ==== ", resp);
    } catch (error) {
      console.log("Error of handle Remove Delete ==== ", error);
      ToastHandler("warn", "something went wrong");
    } finally {
      setLoading(false);
      ToastHandler("sus", "Deleted successfully");
      setRemoveHighlight(false);
      getHighlight();
    }
  };

  const getCurrentUser = () => {
    try {
      return JSON.parse(localStorage?.current_user ?? "{}");
    } catch (error) {
      return {};
    }
  };
  const currentUser = getCurrentUser();
  const token = localStorage.getItem("access");

  const handleCrop = () => {
    editImageRef.current?.setCroppedImage();
    setEditImage(false);
  };

  const removeImage = (index) => {
    // console.log("Remove ==== ", index);
    // console.log("highlightsToEdit ==== ", highlightsToEdit);
    // console.log("image ==== ", image);
    if (editHighlight) {
      const updatedImages = [
        ...highlightsToEdit.slice(0, index),
        ...highlightsToEdit.slice(index + 1),
      ];
      // console.log("Updated ==== ", updatedImages);
      setHighlightsToEdit(() => updatedImages);
      // setImage(updatedImages);
      return;
    }
    const updatedImages = [...image.slice(0, index), ...image.slice(index + 1)];
    setImage(() => updatedImages);
    return;
  };

  const handleSubmit = async () => {
    // console.log("editHighlight ==== ", editHighlight);
    setLoading(true);
    const highlight_id = selectedHighlight._id;
    const org_id = user_id;
    const formData = new FormData();

    if (textInput === "")
      return ToastHandler("warn", allWords.profile.highlights.enterTitle);
    // console.log("Creqte highlight Image ==== ", image);
    if (image.length > 0 && textInput) {
      for (let i in image) {
        formData.append("images", image[i]?.file);
      }
      formData.append("title", textInput);
      if (imageCaption.length > 0) formData.append("caption", imageCaption);
    } else {
      return ToastHandler("warn", allWords.profile.highlights.selectImage);
    }
    setLoading(true);

    let config = {};
    config = {
      method: "post",
      url: `${REACT_APP_BASE_URL}/user/v2/org/highlight`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    await axios(config)
      .then(() => {
        setOpenImageInput(false);
        getHighlight();
        setImageCaption("");
        setImage([]);
        setTextInput("");
        setLoading(false);
      })
      .catch((err) => {
        console.log("Err handleSubmit ==== ", err);
        setLoading(false);
        ToastHandler("warn", "something went wrong");
      });
  };

  const getHighlight = async () => {
    const config = {
      method: "GET",
      url: `${REACT_APP_BASE_URL}/user/v2/org/${user_id}/highlight?limit=10&offset=0`,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-API-Anonymous": "false",
      },
    };
    await axios(config)
      .then((res) => {
        setHighlightsData(res?.data?.data);
      })
      .catch();
  };

  const handlePreviews = (index) => {
    if (!openImagePreview) {
      // Only set the preview data and open the dialog if it is currently closed
      setpreviewData(HighlightsData[index]);
      setSelectedHighlight(HighlightsData[index]);
      setHighlightsToEdit(HighlightsData[index]?.media);
      setImage(HighlightsData[index]?.media);
      setTimeout(() => setopenImagePreview(true), 10); // Delay opening slightly to avoid immediate toggle
    }
  };

  const handleHighlightImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      for (let i = 0; i < e.target.files.length; i++) {
        let size = e.target.files[i].size;
        if (Math.round(size / 1024) > 1024 * 15) {
          ToastHandler("warn", "Upload an image less than 15 MB.");
          // setIsMediaAddDialogOpen(false);
          e.target.value = null;
          return false;
        }
      }
    }
    // console.log("imageFile ==== ", imageFile);
    if (!imageFile) {
      ToastHandler("warn", "Please select image.");
      // setImgKey((prevkey) => prevkey + 1);
      setEditHighlight(false);
      // editHighlight;
      // setIsMediaAddDialogOpen(false);
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      ToastHandler("warn", allWords.misc.toastMsg.invalidImgFormat);
      // setImgKey((prevkey) => prevkey + 1);

      return false;
    }
    const images = e.target.files;

    if (images.length <= 4) {
      if (image.length === 4) {
        ToastHandler("warn", allWords.profileShowcase.only4ImageAllowed);
      } else {
        if (e.target.files) {
          const file = Array.from(e.target.files).map((file) => {
            return {
              file: file,
              url: URL.createObjectURL(file),
            };
          });

          setImage((prevImg) => prevImg.concat(file));
        } else {
          setImage([]);
        }
      }
    } else {
      ToastHandler("warn", allWords.profileShowcase.only4ImageAllowed);
    }

    e.target.value = null;
  };

  const buildShowcasePayloadFormData = ({
    type,
    title = "",
    caption = "",
    link = "",
    media = [],
  }) => {
    let formData = new FormData();
    const data = {
      type,
      title,
      caption,
      old_media: media,
    };
    const stringifieddata = JSON.stringify(data);
    // console.log("Medias ==== ", image);
    // console.log("Data ==== ", data);
    formData.append("data", stringifieddata);
    for (let i = media?.length; i < image.length; i++) {
      formData.append("new_media", image[i].file);
    }

    return formData;
  };

  const updateHighlightApi = async ({ type, title, caption, link, media }) => {
    const highlightid = selectedHighlight._id;
    const org_id = selectedHighlight.user_id;
    const token = localStorage.getItem("access");
    // console.log(" Highlight Media length ==== ", media.length);
    try {
      const payload = buildShowcasePayloadFormData({
        type,
        title,
        caption,
        link,
        media,
      });
      if (image.length > 0 && image.length < 4) {
        const response = await axios.put(
          `${REACT_APP_BASE_URL}/user/v2/org/${org_id}/update-highlight/${highlightid}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("response of highlight update ==== ", response);
        if (response.status === 200) {
          ToastHandler("sus", "Updated successfully");
          setEditHighlight(() => false);
          setTextInput("");
          setHighlightsToEdit([]);
          setImageCaption("");
          getHighlight();
        }
      } else if (image.length === 0) {
        ToastHandler(
          "warn",
          "Please select atleast one image or max 3 images."
        );
      } else {
        ToastHandler("warn", "Please select less than 4 images.");
      }
    } catch (error) {
      console.log("updateHighlightApi ==== ", error);
      ToastHandler("warn", "something went wrong");
    }
  };

  //last one image is not getting deleted from highlight ====
  console.log("SelectedHighlight ==== ", selectedHighlight);
  console.log("image Highlight ==== ", image);
  const handleMediaSave = () => {
    // let oldMedia = selectedHighlight?.media.map((item) => item.url);
    // console.log("selectedHighlight ==== ", selectedHighlight);
    // let oldMedia = selectedHighlight?.media?.map((item) => item.url);
    let oldMedia = image
      ?.map((item) => item.url)
      ?.filter((url) => !url.startsWith("blob:"));

    updateHighlightApi({
      type: "media",
      title: selectedHighlight?.title || "",
      caption: selectedHighlight?.caption || "",
      link: "",
      media: oldMedia,
    });
  };

  useEffect(() => {
    getHighlight();
  }, [user_id]);

  return (
    <>
      {HighlightsData?.length > 0 && (
        <Grid className="Highlight_container">
          <Grid className="Highlights_title_container">
            <Typography className="Highlights_title">
              {allWords.profile?.highlights.highlights}
            </Typography>
            {currentUser?._id === user_id && (
              <IconButton variant="contained" component="label">
                <AddIcon className="Highlights_AddIcon" />
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={handleHighlightsImageChange}
                />
              </IconButton>
            )}
          </Grid>
          <Grid className="Highlights_image_container">
            {HighlightsData?.map((item, index) => {
              return (
                <Grid
                  key={index}
                  className="Highlights_image_subcontainer"
                  onClick={() => {
                    handlePreviews(index);
                  }}
                >
                  <img
                    src={item?.media[0]?.url}
                    alt=""
                    className="Highlights_image"
                  />
                  <Tooltip title={item?.title}>
                    <Typography className="Highlights_image_title">
                      {item?.title}
                    </Typography>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openImageinput}
        className="Highlight_modal_container"
      >
        <Box className="Highlight_modal_subcontainer">
          {editImage ? (
            <>
              <EditImage
                imageSrc={imageSrc}
                style={{
                  width: "100%",
                }}
                setImgArray={setImage}
                ref={editImageRef}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <Button
                  primary
                  onClick={() => {
                    setEditImage(false);
                  }}
                >
                  {allWords.misc.cancel}
                </Button>
                <Button bgColor onClick={handleCrop}>
                  {allWords.misc.save}
                </Button>
              </div>
            </>
          ) : (
            <Grid className="Highlight_modal_input_container">
              <Grid className="Highlight_modal_header_container">
                <Typography className="Highlight_modal_title">
                  {allWords.profile?.highlights.highlights}
                </Typography>
                <IconButton
                  onClick={() => {
                    setOpenImageInput(false);
                    setTextInput("");
                    setImage([]);
                    setImageCaption("");
                  }}
                >
                  <CancelOutlinedIcon className="Highlight_CancelOutlinedIcon" />
                </IconButton>
              </Grid>
              <Grid className="Highlight_modal_title_container">
                <UserProfile username={currentUser?.username} />
                <input
                  type="text"
                  className="Highlight_modal_title_input"
                  placeholder={allWords.profile.highlights.WriteTitle}
                  value={textInput}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 50) {
                      setTextInput(inputText);
                    }
                  }}
                />
                <Typography className="Highlight_modal_title_input_count">
                  {`${50 - textInput.length}/50`}
                </Typography>
              </Grid>
              <Grid className="Highlight_modal_imageArray_container">
                {image?.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      className="Highlight_modal_image_container"
                      sx={{
                        width: image.length > 1 ? "48%" : "100%",
                      }}
                    >
                      <img src={item?.url} className="Highlight_modal_image" />
                      <IconButton
                        className="Highlight_modal_image_Cancel"
                        sx={{
                          top: image.length > 1 ? "12%" : "7%",
                          color: "#fff",
                        }}
                        onClick={() => removeImage(index)}
                      >
                        <CloseIcon
                          sx={{
                            fontSize: image.length > 1 ? "12px" : "20px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        className="Highlight_modal_image_Crop"
                        onClick={() => {
                          setEditImage(true);
                          setImageSrc({
                            index: index,
                            url: item.url,
                            file: item.file,
                          });
                        }}
                      >
                        <CropIcon
                          sx={{
                            fontSize: image.length > 1 ? "12px" : "20px",
                          }}
                        />
                      </IconButton>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  placeholder={allWords.profile.highlights.addCaption}
                  className="Highlight_modal_AddCaption"
                  value={imageCaption}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 50) {
                      setImageCaption(inputText);
                    }
                  }}
                />
                <Typography className="Highlight_modal_AddCaption_count">
                  {50 - imageCaption.length}
                </Typography>
              </Grid>
              <Grid className=" Highlight_modal_create_container">
                <IconButton variant="contained" component="label">
                  <AddPhotoAlternateOutlinedIcon />
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    hidden
                    onChange={handleHighlightsImageChange}
                  />
                </IconButton>
                <Button
                  className="Highlight_modal_create_Button"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#66B984", width: 20, height: 20 }}
                    />
                  ) : (
                    allWords.profile.highlights.create
                  )}
                </Button>
                <Button
                  className="Highlight_modal_cancel_Button"
                  // onClick={() => setOpenImageInput(false)}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#66B984", width: 20, height: 20 }}
                    />
                  ) : (
                    allWords.profile.highlights.cancel
                  )}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openImagePreview}
        className="Highlight_modal_container_Preview"
      >
        <Box className="Highlight_modal_subcontainer_Preview">
          <IconButton
            onClick={() => {
              setopenImagePreview(false);
            }}
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <CancelOutlinedIcon className="Highlight_CancelOutlinedIcon" />
          </IconButton>

          <Grid className="Highlight_modal_previews_container_Preview">
            {/* <div className="PreviewTitle">{previewData?.title}</div>{" "} */}
            <Grid className="Highlight_modal_previews_image_container_Preview">
              <ImagePreviewDialog
                open={openImagePreview}
                anchorEl={anchorEl}
                handleClose={() => setopenImagePreview(() => false)}
                images={previewData?.media}
                handleEditHighlight={handleEditHighlight}
                handleRemoveHighlight={handleRemoveHighlight}
                handleMenuClose={handleMenuClose}
                handleMenuOpen={handleMenuOpen}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editHighlight}
        className="Highlight_modal_container"
      >
        <Box className="Highlight_modal_subcontainer">
          {editImage ? (
            <>
              <EditImage
                imageSrc={imageSrc}
                style={{
                  width: "100%",
                }}
                setImgArray={setImage}
                ref={editImageRef}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <Button
                  primary
                  onClick={() => {
                    setEditImage(false);
                  }}
                >
                  {allWords.misc.cancel}
                </Button>
                <Button bgColor onClick={handleCrop}>
                  {allWords.misc.save}
                </Button>
              </div>
            </>
          ) : (
            <Grid className="Highlight_modal_input_container">
              <Grid className="Highlight_modal_header_container">
                <Typography className="Highlight_modal_title">
                  {allWords.profile?.highlights.editHighlights}
                </Typography>
                <IconButton
                  onClick={() => {
                    setEditHighlight(() => false);
                    setTextInput("");
                    setHighlightsToEdit([]);
                    setImageCaption("");
                  }}
                >
                  <CancelOutlinedIcon className="Highlight_CancelOutlinedIcon" />
                </IconButton>
              </Grid>
              <Grid className="Highlight_modal_title_container">
                <UserProfile username={currentUser?.username} />
                <input
                  type="text"
                  className="Highlight_modal_title_input"
                  placeholder={
                    selectedHighlight?.description
                      ? selectedHighlight.description
                      : allWords.profile.highlights.WriteTitle
                  }
                  value={textInput}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 300) {
                      setTextInput(inputText);
                    }
                  }}
                />
                <Typography className="Highlight_modal_title_input_count">
                  {`${300 - textInput.length}/300`}
                </Typography>
              </Grid>

              <Grid className="Highlight_modal_imageArray_container">
                {image?.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      className="Highlight_modal_image_container"
                      sx={{
                        width: "49%",
                      }}
                    >
                      <img src={item?.url} className="Highlight_modal_image" />
                      <IconButton
                        className="Highlight_modal_image_Cancel"
                        sx={{
                          top: image.length > 1 ? "12%" : "7%",
                          color: "#fff",
                        }}
                        // onClick={() => removeImage(index)}
                        onClick={() => {
                          image.splice(index, 1);
                          setImage([...image]);
                          // if (editHighlight) {
                          //   if (image?.length === 0) {
                          //     setEditImage(false);
                          //   }
                          // }
                        }}
                      >
                        <CloseIcon
                          sx={{
                            fontSize: image.length > 1 ? "12px" : "20px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        className="Highlight_modal_image_Crop"
                        onClick={() => {
                          setEditImage(true);
                          setImageSrc({
                            index: index,
                            url: item.url,
                            file: item.file,
                          });
                        }}
                      >
                        <CropIcon
                          sx={{
                            fontSize: image.length > 1 ? "12px" : "20px",
                          }}
                        />
                      </IconButton>
                    </Grid>
                  );
                })}
                {image.length < 4 && !editImage && (
                  <Grid
                    item
                    key={1}
                    md={6}
                    //  md={selectedItem.media.length === 1 ? 12 : 6}
                  >
                    {/* <Container> */}
                    <div className="add_media_container">
                      <IconButton
                        variant="contained"
                        component="label"
                        className="icon_img"
                        // style={{ paddingRight: "27rem" }}
                      >
                        <AddCircleOutlineIcon />
                        <input
                          id="imageInput"
                          type="file"
                          accept="image/*"
                          multiple
                          hidden
                          onChange={handleHighlightImageChange}
                        />
                      </IconButton>
                    </div>
                    {/* </Container> */}
                  </Grid>
                )}
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  placeholder={
                    selectedHighlight?.caption
                      ? selectedHighlight.caption
                      : allWords.profile.highlights.addCaption
                  }
                  className="Highlight_modal_AddCaption"
                  value={imageCaption}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length <= 50) {
                      setImageCaption(inputText);
                    }
                  }}
                />
                <Typography className="Highlight_modal_AddCaption_count">
                  {50 - imageCaption.length}
                </Typography>
              </Grid>
              <Grid className="Highlight_modal_create_container">
                {/* <IconButton variant="contained" component="label">
                  <AddPhotoAlternateOutlinedIcon />
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    hidden
                    onChange={handleHighlightsImageChange}
                  />
                </IconButton> */}
                <Button
                  className="Highlight_modal_create_Button"
                  // onClick={handleSubmit}
                  onClick={handleMediaSave}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#66B984", width: 20, height: 20 }}
                    />
                  ) : (
                    allWords.profile.highlights.save
                  )}
                </Button>
                <Button
                  className="Highlight_modal_cancel_Button"
                  onClick={() => {
                    setEditHighlight(() => false);
                    setTextInput("");
                    setHighlightsToEdit([]);
                    setImageCaption("");
                  }}
                  // onClick={() => setOpenImageInput(false)}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#66B984", width: 20, height: 20 }}
                    />
                  ) : (
                    allWords.profile.highlights.cancel
                  )}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={removeHighlight}
        onClose={handleCancel}
        className="Highlight_modal_container"
      >
        <Box className="Highlight_modal_subcontainer">
          <Grid className="Highlight_modal_input_container">
            <Grid className="Highlight_modal_header_delete_container">
              <Typography className="Highlight_modal_title">
                {allWords.profile?.highlights.removeHighlights}
              </Typography>
              <Typography className="Highlight_modal_delete_instruction">
                {allWords.profile?.highlights.removeHighlightInstruction}
              </Typography>
            </Grid>

            <Grid className="Highlight_modal_imageArray_container">
              {image?.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    className="Highlight_modal_image_container"
                    sx={{
                      width: image.length > 1 ? "48%" : "100%",
                    }}
                  >
                    <img src={item?.url} className="Highlight_modal_image" />
                  </Grid>
                );
              })}
            </Grid>
            <Grid className=" Highlight_modal_delete_container">
              <Button
                className="Highlight_modal_create_Button"
                onClick={handleRemove}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#66B984", width: 20, height: 20 }}
                  />
                ) : (
                  allWords.profile.highlights.delete
                )}
              </Button>

              <Button
                className="Highlight_modal_cancel_Button"
                onClick={handleCancel}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#66B984", width: 20, height: 20 }}
                  />
                ) : (
                  allWords.profile.highlights.cancel
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
