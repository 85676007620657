import { UPDATE_SNIPPET_MUTE_FLAG_CONTROLLER } from "../../actions/actionTypes";

const initialState = { level: 100, snippetMuteFlag: false };

const audioVolumeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "changeVol":
            return { ...state, level: action.payload };
        case UPDATE_SNIPPET_MUTE_FLAG_CONTROLLER: 
            return {
                ...state, snippetMuteFlag: action.payload
            }
        default:
            return state;
    }
};

export const getSnippetMuteFlag = (state) => state.audioVolume.snippetMuteFlag;

export default audioVolumeReducer;
