import {
  GET_SCROLLS_POSTS_SUCCESS,
  GET_SCROLLS_POSTS_FAILURE,
  LIKE_DISLIKE_SCROLLS_POSTS,
  GET_SINGLE_SCROLLS_POSTS_SUCCESS,
  GET_SINGLE_SCROLLS_POSTS_FAILURE,
  GET_SCROLL_POST_FOLLOW,
  GET_PROFILE_SCROLLS_POSTS_SUCCESS,
  GET_PROFILE_SCROLLS_POSTS_FAILURE,
  GET_USER_SCROLLS_POSTS_SUCCESS,
  GET_USER_SCROLLS_POSTS_FAILURE,
} from "../actionTypes";
import {
  getScrollsPostsAPI,
  getSingleScrollsPostAPI,
  getProfileScrollsPostAPI,
  getUserScrollsPostAPI,
} from "../../../apis/scrollsApi";

const getScrollsPostsSuccess = (ScrollsPosts) => ({
  type: GET_SCROLLS_POSTS_SUCCESS,
  payload: ScrollsPosts,
});

const getScrollsPostsFailure = (err) => ({
  type: GET_SCROLLS_POSTS_FAILURE,
  payload: err,
});

const getSingleScrollsPostsSuccess = (SingleScrollsPosts) => ({
  type: GET_SINGLE_SCROLLS_POSTS_SUCCESS,
  payload: SingleScrollsPosts,
});

const getSingleScrollsPostsFailure = (singlePostError) => ({
  type: GET_SINGLE_SCROLLS_POSTS_FAILURE,
  payload: singlePostError,
});

export const likeDislikeScrollsPosts = (data) => ({
  type: LIKE_DISLIKE_SCROLLS_POSTS,
  payload: data,
});

export const getScrollsPostsFollow = (data) => ({
  type: GET_SCROLL_POST_FOLLOW,
  payload: data,
});

const getProfileScrollsPostsSuccess = (ProfileScrollsPosts) => ({
  type: GET_PROFILE_SCROLLS_POSTS_SUCCESS,
  payload: ProfileScrollsPosts,
});

const getProfileScrollsPostsFailure = (err) => ({
  type: GET_PROFILE_SCROLLS_POSTS_FAILURE,
  payload: err,
});

const getUserScrollsPostsSuccess = (UserScrollsPosts) => ({
  type: GET_USER_SCROLLS_POSTS_SUCCESS,
  payload: UserScrollsPosts,
});

const getUserScrollsPostsFailure = (err) => ({
  type: GET_USER_SCROLLS_POSTS_FAILURE,
  payload: err,
});

export const getScrollsPosts = (
  skip,
  type,
  exclude_post = "",
  profilePath = ""
) => {
  return async (dispatch) => {
    try {
      const posts = await getScrollsPostsAPI(
        skip,
        type,
        exclude_post,
        profilePath
      );
      dispatch(getScrollsPostsSuccess(posts.data.data));
    } catch (err) {
      dispatch(getScrollsPostsFailure(err));
    }
  };
};

export const getSingleScrollsPosts = (postId) => {
  return async (dispatch) => {
    try {
      const posts = await getSingleScrollsPostAPI(postId);
      dispatch(getSingleScrollsPostsSuccess(posts.data.data));
    } catch (err) {
      dispatch(getSingleScrollsPostsFailure(err));
    }
  };
};

export const getProfileScrollsPosts = (user_id, skip, limit) => {
  return async (dispatch) => {
    try {
      const posts = await getProfileScrollsPostAPI(user_id, skip, limit);
      dispatch(getProfileScrollsPostsSuccess(posts.data.data));
    } catch (err) {
      dispatch(getProfileScrollsPostsFailure(err));
    }
  };
};

export const getUserScrollsPosts = (user_id, skip, first_post_id) => {
  return async (dispatch) => {
    try {
      const posts = await getUserScrollsPostAPI(user_id, skip, first_post_id);
      dispatch(getUserScrollsPostsSuccess(posts.data.data));
    } catch (err) {
      dispatch(getUserScrollsPostsFailure(err));
    }
  };
};
