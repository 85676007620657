import { CHANGE_PLAYING_STATE_OF_M3U8 } from "../../actions/m3u8Action";

// Used to know the playing state of video
const initialState = {
  isPlaying: false,
};

const m3u8Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PLAYING_STATE_OF_M3U8:
      return {
        ...state,
        isPlaying: action.payload,
      };

    default:
      return state;
  }
};

export default m3u8Reducer;
