// ** REGISTER USER
export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

//DEVICE INFO
export const DEVICE_INFO_SUCCESS = "DEVICE_INFO_SUCCESS";

// ** GET TOKEN
export const OBTAIN_TOKEN_START = "OBTAIN_TOKEN_START";
export const OBTAIN_TOKEN_FAIL = "OBTAIN_TOKEN_FAIL";
export const OBTAIN_TOKEN_SUCCESS = "OBTAIN_TOKEN_SUCCESS";

// ** GET POST
export const GET_POST_START = "GET_POST_START";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_FAIL = "GET_POST_FAIL";

// ** ADD POST
export const ADD_POST_START = "ADD_POST_START";
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS";
export const ADD_POST_FAIL = "ADD_POST_FAIL";

// ** GET INTEREST
export const GET_INTEREST_START = "GET_INTEREST_START";
export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS";
export const GET_INTEREST_FAIL = "GET_INTEREST_FAIL";

// ** GET DISCLAIMER
export const GET_RT_DISCLAIMER_START = "GET_RT_DISCLAIMER_START";
export const GET_RT_DISCLAIMER_SUCCESS = "GET_RT_DISCLAIMER_SUCCESS";
export const GET_RT_DISCLAIMER_FAIL = "GET_RT_DISCLAIMER_FAIL";

// ** UPDATE INTEREST
export const UPDATE_INTEREST_START = "UPDATE_INTEREST_START";
export const UPDATE_INTEREST_SUCCESS = "UPDATE_INTEREST_SUCCESS";
export const UPDATE_INTEREST_FAIL = "UPDATE_INTEREST_FAIL";

// ** LIKE
export const LIKE_START = "LIKE_START";
export const LIKE_SUCCESS = "LIKE_SUCCESS";
export const LIKE_FAIL = "LIKE_FAIL";

// ** DISLIKE
export const DISLIKE_START = "DISLIKE_START";
export const DISLIKE_SUCCESS = "DISLIKE_SUCCESS";
export const DISLIKE_FAIL = "DISLIKE_FAIL";

// ** Add Quote
export const ADD_QUOTE_START = "ADD_QUOTE_START";
export const ADD_QUOTE_SUCCESS = "ADD_QUOTE_SUCCESS";
export const ADD_QUOTE_FAIL = "ADD_QUOTE_FAIL";

// ** Add CIRCULATE
export const ADD_CIRCULATE_START = "ADD_CIRCULATE_START";
export const ADD_CIRCULATE_SUCCESS = "ADD_CIRCULATE_SUCCESS";
export const ADD_CIRCULATE_FAIL = "ADD_CIRCULATE_FAIL";

// ** Add CIRCULATE
export const ADD_REPLY_START = "ADD_REPLY_START";
export const ADD_REPLY_SUCCESS = "ADD_REPLY_SUCCESS";
export const ADD_REPLY_FAIL = "ADD_REPLY_FAIL";

// ** GET SUGGESSTIONS
export const GET_SUGGESSTION_START = "GET_SUGGESSTION_START";
export const GET_SUGGESSTION_SUCCESS = "GET_SUGGESSTION_SUCCESS";
export const GET_SUGGESSTION_FAIL = "GET_SUGGESSTION_FAIL";

//  ** FOLLOWERS
export const GET_FOLLOWERS_START = "GET_FOLLOWERS_START";
export const GET_FOLLOWERS_SUCCESS = "GET_FOLLOWERS_SUCCESS";
export const GET_FOLLOWERS_FAIL = "GET_FOLLOWERS_FAIL";

// ** Edit Details
export const EDIT_DETAILS_START = "EDIT_DETAILS_START";
export const EDIT_DETAILS_SUCCESS = "EDIT_DETAILS_SUCCESS";
export const EDIT_DETAILS_FAIL = "EDIT_DETAILS_FAIL";

// ** UNIVERSAL SEARCH
export const GET_SEARCH_START = "GET_SEARCH_START";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_FAIL = "GET_SEARCH_FAIL";

// ** PROFILE FOLLOW
export const GET_FOLLOW_START = "GET_FOLLOW_START";
export const GET_FOLLOW_SUCCESS = "GET_FOLLOW_SUCCESS";
export const GET_FOLLOW_FAIL = "GET_FOLLOW_FAIL";

// **PRIVACY SETTING
export const UPDATE_USER_NAME_START = "UPDATE_USERNAME_START";
export const UPDATE_USER_NAME_SUCCESS = "UPDATE_USERNAME_SUCCESS";
export const UPDATE_USER_NAME_FAIL = "UPDATE_USERNAME_FAIL";

// **NOTIFICATION DATA
export const NOTIFICATION_START = "NOTIFICATION_START";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL";

// **NOTIFICATION DATA 2.0
export const INTERACTION_REQUEST = "INTERACTION_REQUEST";
export const INTERACTION_SUCCESS = "INTERACTION_SUCCESS";
export const INTERACTION_FAIL = "INTERACTION_FAIL";

// **FB TOKEN DATA
export const TOKEN_START = "TOKEN_START";
export const TOKEN_SUCCESS = "TOKEN_SUCCESS";
export const TOKEN_FAIL = "TOKEN_FAIL";

// **USER ACTION DATA
export const GET_USERACTION_START = "GET_USERACTION_START";
export const GET_USERACTION_SUCCESS = "GET_USERACTION_SUCCESS";
export const GET_USERACTION_FAIL = "GET_USERACTION_FAIL";

// ** Edit USER
export const EDIT_USER_ACTION_START = "EDIT_USER_ACTION_START";
export const EDIT_USER_ACTION_SUCCESS = "EDIT_USER_ACTION_SUCCESS";
export const EDIT_USER_ACTION_FAIL = "EDIT_USER_ACTION_FAIL";

// ** GET TWITTER FOLLOWERS
export const GET_TWFOL_START = "GET_TWFOL_START";
export const GET_TWFOL_SUCCESS = "GET_TWFOL_SUCCESS";
export const GET_TWFOL_FAIL = "GET_TWFOL_FAIL";

// ** POST BOOKMARK
export const BOOKMARK_START = "BOOKMARK_START";
export const BOOKMARK_SUCCESS = "BOOKMARK_SUCCESS";
export const BOOKMARK_FAIL = "BOOKMARK_FAIL";

// ** POST DELETE
export const DELETE_START = "DELETE_START";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAIL = "DELETE_FAIL";

// ** POST MUTE
export const MUTE_START = "MUTE_START";
export const MUTE_SUCCESS = "MUTE_SUCCESS";
export const MUTE_FAIL = "MUTE_FAIL";

// ** POST MUTE
export const METADATA_START = "METADATA_START";
export const METADATA_SUCCESS = "METADATA_SUCCESS";
export const METADATA_FAIL = "METADATA_FAIL";
// ** AUDIO TRIM
export const AUDIO_TRIM_START = "AUDIO_TRIM_START";
export const AUDIO_TRIM_SUCCESS = "AUDIO_TRIM_SUCCESS";
export const AUDIO_TRIM_FAIL = "AUDIO_TRIM_FAIL";

// ** VIDEO TRIM
export const VIDEO_TRIM_START = "VIDEO_TRIM_START";
export const VIDEO_TRIM_SUCCESS = "VIDEO_TRIM_SUCCESS";
export const VIDEO_TRIM_FAIL = "VIDEO_TRIM_FAIL";

// ** ROUNDTABLE
export const GET_RT_START = "GET_RT_START";
export const GET_RT_SUCCESS = "GET_RT_SUCCESS";
export const GET_RT_FAIL = "GET_RT_FAIL";

// ** SINGLE ROUNDTABLE
export const GET_RT_SINGLE_START = "GET_RT_SINGLE_START";
export const GET_RT_SINGLE_SUCCESS = "GET_RT_SINGLE_SUCCESS";
export const GET_RT_SINGLE_FAIL = "GET_RT_SINGLE_FAIL";
export const GET_RT_SINGLE_RESET = "GET_RT_SINGLE_RESET";
export const SET_RT_DOCUMENT = "SET_RT_DOCUMENT";
export const SET_CONSENT = "SET_CONSENT";
export const SET_LIVE_USER = "SET_LIVE_USER";
export const SET_KEY_MOMENT_ID = "SET_KEY_MOMENT_ID";

// ** SET REMINDER
export const GET_RT_REMINDER_START = "GET_RT_REMINDER_START";
export const GET_RT_REMINDER_SUCCESS = "GET_RT_REMINDER_SUCCESS";
export const GET_RT_REMINDER_FAIL = "GET_RT_REMINDER_FAIL";

// ** USER SEARCH
export const GET_USERSEARCH_START = "GET_USERSEARCH_START";
export const GET_USERSEARCH_SUCCESS = "GET_USERSEARCH_SUCCESS";
export const GET_USERSEARCH_FAIL = "GET_USERSEARCH_FAIL";
// ** GET MESSAGES
export const GET_YAPP_MESSAGES_START = "GET_YAPP_MESSAGES_START";
export const GET_YAPP_MESSAGES_SUCCESS = "GET_YAPP_MESSAGES_SUCCESS";
export const GET_YAPP_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

// ** ADD MESSAGES
export const SEND_MESSAGES_START = "SEND_MESSAGES_START";
export const SEND_MESSAGES_SUCCESS = "SEND_MESSAGES_SUCCESS";
export const SEND_MESSAGES_FAIL = "SEND_MESSAGES_FAIL";

// ** DELETE MESSAGES
export const DELETE_MESSAGES_START = "DELETE_MESSAGES_START";
export const DELETE_MESSAGES_SUCCESS = "DELETE_MESSAGES_SUCCESS";
export const DELETE_MESSAGES_FAIL = "DELETE_MESSAGES_FAIL";

// ** DELETE ALL CHAT
export const DELETE_ALL_CHAT_START = "DELETE_ALL_CHAT_START";
export const DELETE_ALL_CHAT_SUCCESS = "DELETE_ALL_CHAT_SUCCESS";
export const DELETE_ALL_CHAT_FAIL = "DELETE_ALL_CHAT_FAIL";

// ** Reply MESSAGES
export const SEND_REPLY_MESSAGES_START = "SEND_REPLY_MESSAGES_START";
export const SEND_REPLY_MESSAGES_SUCCESS = "SEND_REPLY_MESSAGES_SUCCESS";
export const SEND_REPLY_MESSAGES_FAIL = "SEND_REPLY_MESSAGES_FAIL";

// ** forward MESSAGES
export const FORWARD_MESSAGES_START = "FORWARD_MESSAGES_START";
export const FORWARD_MESSAGES_SUCCESS = "FORWARD_MESSAGES_SUCCESS";
export const FORWARD_MESSAGES_FAIL = "FORWARD_MESSAGES_FAIL";

// ** GET INTEREST
export const GET_PASTRT_START = "GET_PASTRT_START";
export const GET_PASTRT_SUCCESS = "GET_PASTRT_SUCCESS";
export const GET_PASTRT_FAIL = "GET_PASTRT_FAIL";
export const LEAVE_PAST_RT = "LEAVE_PAST_RT";
export const UPDATE_PAST_RT_SCROLL_EDITOR_MODAL = "UPDATE_PAST_RT_SCROLL_EDITOR_MODAL";

// ** USER SEARCH
export const GET_VISITORSEARCH_START = "GET_VISITORSEARCH_START";
export const GET_VISITORSEARCH_SUCCESS = "GET_VISITORSEARCH_SUCCESS";
export const GET_VISITORSEARCH_FAIL = "GET_VISITORSEARCH_FAIL";

// ** HISTORICAL MESSAGES
export const GET_HIST_MESSAGES_START = "GET_HIST_MESSAGES_START";
export const GET_HIST_MESSAGES_SUCCESS = "GET_HIST_MESSAGES_SUCCESS";
export const GET_HIST_MESSAGES_FAIL = "GET_HIST_MESSAGES_FAIL";
// ** ADD VISITOR COMMENT
export const ADD_VISITOR_COMMENT_START = "ADD_VISITOR_COMMENT_START";
export const ADD_VISITOR_COMMENT_SUCCESS = "ADD_VISITOR_COMMENT_SUCCESS";
export const ADD_VISITOR_COMMENT_FAIL = "ADD_VISITOR_COMMENT_FAIL";

// ** USER INTERACTION LIKE
export const POST_LIKE_START = "POST_LIKE_START";
export const POST_LIKE_SUCCESS = "POST_LIKE_SUCCESS";
export const POST_LIKE_FAIL = "POST_LIKE_FAIL";

// ** USER INTERACTON DISLIKE
export const POST_DISLIKE_START = "POST_DISLIKE_START";
export const POST_DISLIKE_SUCCESS = "POST_DISLIKE_SUCCESS";
export const POST_DISLIKE_FAIL = "POST_DISLIKE_FAIL";

// ** User Interaction Add Quote
export const ADD_RT_POST_QUOTE_START = "ADD_RT_POST_QUOTE_START";
export const ADD_RT_POST_QUOTE_SUCCESS = "ADD_RT_POST_QUOTE_SUCCESS";
export const ADD_RT_POST_QUOTE_FAIL = "ADD_QUOTE_FAIL";

// **  User Interaction  Add reply
export const ADD_RT_POST_REPLY_START = "ADD_RT_POST_REPLY_START";
export const ADD_RT_POST_REPLY_SUCCESS = "ADD_RT_POST_REPLY_SUCCESS";
export const ADD_RT_POST_REPLY_FAIL = "ADD_RT_POST_REPLY_FAIL";

// ** Add CIRCULATE
export const ADD_RT_POST_CIRCULATE_START = "ADD_RT_POST_CIRCULATE_START";
export const ADD_RT_POST_CIRCULATE_SUCCESS = "ADD_RT_POST_CIRCULATE_SUCCESS";
export const ADD_RT_POST_CIRCULATE_FAIL = "ADD_RT_POST_CIRCULATE_FAIL";

// ** CREATE EDIT RT
export const CREATE_EDIT_RT_START = "CREATE_EDIT_RT_START";
export const CREATE_EDIT_RT_SUCCESS = "CREATE_EDIT_RT_SUCCESS";
export const CREATE_EDIT_RT_FAIL = "CREATE_EDIT_RT_FAIL";

// ** INVITE PANELIST
export const INVITE_PANELIST_START = "INVITE_PANELIST_START";
export const INVITE_PANELIST_SUCCESS = "INVITE_PANELIST_SUCCESS";
export const INVITE_PANELIST_FAIL = "INVITE_PANELIST_FAIL";

// ** INVITE VISITOR
export const INVITE_VISITOR_START = "INVITE_VISITOR_START";
export const INVITE_VISITOR_SUCCESS = "INVITE_VISITOR_SUCCESS";
export const INVITE_VISITOR_FAIL = "INVITE_VISITOR_FAIL";

// ** USER PROFILE
export const USER_PROFILE_START = "USER_PROFILE_START";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";
export const PROFILE_HEADER_SUCCESS = "PROFILE_HEADER_SUCCESS";
export const PROFILE_POINT_CALCULATION = "PROFILE_POINT_CALCULATION";
export const PROFILE_POINTS_DATA = "PROFILE_POINTS_DATA";
export const USER_PROFILE_RESPONSE_DATA = "USER_PROFILE_RESPONSE_DATA";
export const PROFILE_MUTUAL_FRIENDS = "PROFILE_MUTUAL_FRIENDS";
export const UPDATE_PROFILE_RESPONSE_LOADING = "UPDATE_PROFILE_RESPONSE_LOADING";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const UPDATE_PROFILE_BLOCK = "UPDATE_PROFILE_BLOCK";
export const UPDATE_PROFILE_NOT_FOUND = "UPDATE_PROFILE_NOT_FOUND";

// ** INVITEE LIST
export const INVITEE_LIST_START = "INVITEE_LIST_START";
export const INVITEE_LIST_SUCCESS = "INVITEE_LIST_SUCCESS";
export const INVITEE_LIST_FAIL = "INVITEE_LIST_FAIL";
export const INVITEE_LIST_LOADING_FALSE = "INVITEE_LIST_LOADING_FALSE";

// ** VIEW LIST
export const VIEW_LIST_START = "VIEW_LIST_START";
export const VIEW_LIST_SUCCESS = "VIEW_LIST_SUCCESS";
export const VIEW_LIST_FAIL = "VIEW_LIST_FAIL";

// ** ACCEPT ACCESS
export const ACCEPT_ACCESS_START = "ACCEPT_ACCESS_START";
export const ACCEPT_ACCESS_SUCCESS = "ACCEPT_ACCESS_SUCCESS";
export const ACCEPT_ACCESS_FAIL = "ACCEPT_ACCESS_FAIL";

// ** REQUEST ACCESS
export const REQUEST_ACCESS_START = "REQUEST_ACCESS_START";
export const REQUEST_ACCESS_SUCCESS = "REQUEST_ACCESS_SUCCESS";
export const REQUEST_ACCESS_FAIL = "REQUEST_ACCESS_FAIL";

// ** OWNER RIGHTS
export const OWNER_RIGHTS_START = "OWNER_RIGHTS_START";
export const OWNER_RIGHTS_SUCCESS = "OWNER_RIGHTS_SUCCESS";
export const OWNER_RIGHTS_FAIL = "OWNER_RIGHTS_FAIL";

// ** DELETE RT
export const DELETE_RT_START = "DELETE_RT_START";
export const DELETE_RT_SUCCESS = "DELETE_RT_SUCCESS";
export const DELETE_RT_FAIL = "DELETE_RT_FAIL";

// ** RESTORE USERNAME
export const RESTORE_USERNAME_START = "RESTORE_USERNAME_START";
export const RESTORE_USERNAME_SUCCESS = "RESTORE_USERNAME_SUCCESS";
export const RESTORE_USERNAME_FAIL = "RESTORE_USERNAME_FAIL";

// ** INVITE BY
export const INVITE_BY_START = "INVITE_BY_START";
export const INVITE_BY_SUCCESS = "INVITE_BY_SUCCESS";
export const INVITE_BY_FAIL = "INVITE_BY_FAIL";
export const INVITE_BY_RESET = "INVITE_BY_RESET";

//RAISE HAND
export const SHOW_RAISE_HAND = "SHOW_RAISE_HAND";

// ** POPULAR RT
export const GET_POPULAR_START = "GET_POPULAR_START";
export const GET_POPULAR_SUCCESS = "GET_POPULAR_SUCCESS";
export const GET_POPULAR_FAIL = "GET_POPULAR_FAIL";

// ** POPULAR SHOW
export const GET_POPULAR_SHOW_START = "GET_POPULAR_SHOW_START";
export const GET_POPULAR_SHOW_SUCCESS = "GET_POPULAR_SHOW_SUCCESS";
export const GET_POPULAR_SHOW_FAIL = "GET_POPULAR_SHOW_FAIL";

// WALKTHROUGH
export const WALKTHROUGH_START = "WALKTHROUGH_START";
export const WALKTHROUGH_SUCCESS = "WALKTHROUGH_SUCCESS";
export const WALKTHROUGH_FAIL = "WALKTHROUGH_FAIL";

//  **FOR WALKTHROUGH
export const TOGGLE_WALKTHROUGH_FLAG = "TOGGLE_WALKTHROUGH_FLAG";
export const SIGN_UP_FLAG = "SIGN_UP_FLAG";
export const TAB_SWITCH_FLAG = "TAB_SWITCH_FLAG";

export const DRAWER_FLAG = "DRAWER_FLAG";

// RT Actions
export const RT_ACTION_START = "RT_ACTION_START";
export const RT_ACTION_SUCCESS = "RT_ACTION_SUCCESS";
export const RT_ACTION_FAIL = "RT_ACTION_FAIL";
export const LIVE_RT_STATE = "LIVE_RT_STATE";

export const NAVIGATION_CHECK_LOADING = "NAVIGATION_CHECK_LOADING";

// CREATE RT
export const CREATE_EDIT_ROUNDTABLE_INITIALIZE =
  "CREATE_EDIT_ROUNDTABLE_INITIALIZE";
export const CREATE_EDIT_ROUNDTABLE_START = "CREATE_EDIT_ROUNDTABLE_START";
export const CREATE_EDIT_ROUNDTABLE_SUCCESS = "CREATE_EDIT_ROUNDTABLE_SUCCESS";
export const CREATE_EDIT_ROUNDTABLE_FAIL = "CREATE_EDIT_ROUNDTABLE_FAIL";

// Personal details
export const GET_PERSONALDETAIL_START = "GET_PERSONALDETAIL_START";
export const GET_PERSONALDETAIL_SUCCESS = "GET_PERSONALDETAIL_SUCCESS";
export const GET_PERSONALDETAIL_FAIL = "GET_PERSONALDETAIL_FAIL";

// CREATE REC RT
export const CREATE_REC_RT_START = "CREATE_REC_RT_START";
export const CREATE_REC_RT_SUCCESS = "CREATE_REC_RT_SUCCESS";
export const CREATE_REC_RT_FAIL = "CREATE_REC_RT_FAIL";

// LOGO VIDEOS
export const LOGO_VIDEOS_START = "LOGO_VIDEOS_START";
export const LOGO_VIDEOS_SUCCESS = "LOGO_VIDEOS_SUCCESS";
export const LOGO_VIDEOS_FAIL = "LOGO_VIDEOS_FAIL";

// DELETE LOGO VIDEOS
export const DEL_LOGO_VIDEOS_START = "DEL_LOGO_VIDEOS_START";
export const DEL_LOGO_VIDEOS_SUCCESS = "DEL_LOGO_VIDEOS_SUCCESS";
export const DEL_LOGO_VIDEOS_FAIL = "DEL_LOGO_VIDEOS_FAIL";

// IMAGE CHANGE
export const IMAGE_CHANGE_START = "IMAGE_CHANGE_START";
export const IMAGE_CHANGE_SUCCESS = "IMAGE_CHANGE_SUCCESS";
export const IMAGE_CHANGE_FAIL = "IMAGE_CHANGE_FAIL";

// DEL IMAGE CHANGE
export const DEL_IMAGE_CHANGE_START = "DEL_IMAGE_CHANGE_START";
export const DEL_IMAGE_CHANGE_SUCCESS = "DEL_IMAGE_CHANGE_SUCCESS";
export const DEL_IMAGE_CHANGE_FAIL = "DEL_IMAGE_CHANGE_FAIL";
// INVITATION POP UP
export const INVITATION_POP_START = "INVITATION_POP_START";
export const INVITATION_POP_SUCCESS = "INVITATION_POP_SUCCESS";
export const INVITATION_POP_FAIL = "INVITATION_POP_FAIL";

// REJECT ACCEPT
export const REJECT_ACCEPT_START = "REJECT_ACCEPT_START";
export const REJECT_ACCEPT_SUCCESS = "REJECT_ACCEPT_SUCCESS";
export const REJECT_ACCEPT_FAIL = "REJECT_ACCEPT_FAIL";

// JOIN RT
export const JOIN_RT_START = "JOIN_RT_START";
export const JOIN_RT_SUCCESS = "JOIN_RT_SUCCESS";
export const JOIN_RT_FAIL = "JOIN_RT_FAIL";

//LIVE RT
export const SET_LIVE_RT_TIMER = "SET_LIVE_RT_TIMER";
export const RESET_LIVE_RT_TIMER = "RESET_LIVE_RT_TIMER";
export const LIVE_RT_EXTENDED = "LIVE_RT_EXTENDED";
export const UPDATE_RT_EXTENDED_TIME = "UPDATE_RT_EXTENDED_TIME";
export const UPDATE_EXTEND_DIALOG = "UPDATE_EXTEND_DIALOG";

export const ACTIVE_WILDCARD_MESSAGE = "ACTIVE_WILDCARD_MESSAGE";
// MINE RT LIST
export const MINE_RT_LISTING_REQUEST = "MINE_RT_LISTING_REQUEST";
export const MINE_RT_LISTING_SUCCESS = "MINE_RT_LISTING_SUCCESS";
export const MINE_RT_LISTING_FAIL = "MINE_RT_LISTING_FAIL";

// MINE RT LIST
export const RT_LISTING_CATEGORY_REQUEST = "RT_LISTING_CATEGORY_REQUEST";
export const RT_LISTING_CATEGORY_SUCCESS = "RT_LISTING_CATEGORY_SUCCESS";
export const RT_LISTING_CATEGORY_FAIL = "RT_LISTING_CATEGORY_FAIL";

// PERSONALIZED ALL RT LIST
export const PERSONALIZED_RT_LISTING_ALL_REQUEST =
  "PERSONALIZED_RT_LISTING_ALL_REQUEST";
export const PERSONALIZED_RT_LISTING_ALL_SUCCESS =
  "PERSONALIZED_RT_LISTING_ALL_SUCCESS";
export const PERSONALIZED_RT_LISTING_ALL_FAIL =
  "PERSONALIZED_RT_LISTING_ALL_FAIL";

// PERSONALIZED CATEGORY RT LIST
export const PERSONALIZED_RT_LISTING_CATEGORY_REQUEST =
  "PERSONALIZED_RT_LISTING_CATEGORY_REQUEST";
export const PERSONALIZED_RT_LISTING_CATEGORY_SUCCESS =
  "PERSONALIZED_RT_LISTING_CATEGORY_SUCCESS";
export const PERSONALIZED_RT_LISTING_CATEGORY_FAIL =
  "PERSONALIZED_RT_LISTING_CATEGORY_FAIL";

//PERSONALIZED RT LISTING REQUEST
export const PERSONALIZED_RT_LISTING_REQUEST =
  "PERSONALIZED_RT_LISTING_REQUEST";
export const PERSONALIZED_RT_LISTING_SUCCESS =
  "PERSONALIZED_RT_LISTING_SUCCESS";
export const PERSONALIZED_RT_LISTING_FAIL = "PERSONALIZED_RT_LISTING_FAIL";

//K3 Posts
export const GET_K3_POSTS_START = "GET_K3_POSTS_START";
export const GET_K3_POSTS_SUCCESS = "GET_K3_POSTS_SUCCESS";
export const GET_K3_POSTS_FAILURE = "GET_K3_POSTS_FAILURE";
export const LIKE_DISLIKE_K3_POSTS = "LIKE_DISLIKE_K3_POSTS";

//SCROLLS Posts
export const GET_SCROLLS_POSTS_START = "GET_SCROLLS_POSTS_START";
export const GET_SCROLLS_POSTS_SUCCESS = "GET_SCROLLS_POSTS_SUCCESS";
export const GET_SCROLLS_POSTS_FAILURE = "GET_SCROLLS_POSTS_FAILURE";
export const GET_SINGLE_SCROLLS_POSTS_START = "GET_SINGLE_SCROLLS_POSTS_START";
export const GET_SINGLE_SCROLLS_POSTS_SUCCESS = "GET_SINGLE_SCROLLS_POSTS_SUCCESS";
export const GET_SINGLE_SCROLLS_POSTS_FAILURE = "GET_SINGLE_SCROLLS_POSTS_FAILURE";
export const LIKE_DISLIKE_SCROLLS_POSTS = "LIKE_DISLIKE_SCROLLS_POSTS";
export const GET_SCROLL_POST_FOLLOW = "GET_SCROLL_POST_FOLLOW";
export const GET_PROFILE_SCROLLS_POSTS_START = "GET_PROFILE_SCROLLS_POSTS_START";
export const GET_PROFILE_SCROLLS_POSTS_SUCCESS = "GET_PROFILE_SCROLLS_POSTS_SUCCESS";
export const GET_PROFILE_SCROLLS_POSTS_FAILURE = "GET_PROFILE_SCROLLS_POSTS_FAILURE";
export const GET_USER_SCROLLS_POSTS_START = "GET_USER_SCROLLS_POSTS_START";
export const GET_USER_SCROLLS_POSTS_SUCCESS = "GET_USER_SCROLLS_POSTS_SUCCESS";
export const GET_USER_SCROLLS_POSTS_FAILURE = "GET_USER_SCROLLS_POSTS_FAILURE";

//BKK Posts
export const GET_BKK_POSTS_START = "GET_BKK_POSTS_START";
export const GET_BKK_POSTS_SUCCESS = "GET_BKK_POSTS_SUCCESS";
export const GET_BKK_POSTS_FAILURE = "GET_BKK_POSTS_FAILURE";
export const LIKE_DISLIKE_BKK_POSTS = "LIKE_DISLIKE_BKK_POSTS";


//EBKK Posts
export const GET_EBKK_POSTS_START = "GET_EBKK_POSTS_START";
export const GET_EBKK_POSTS_SUCCESS = "GET_EBKK_POSTS_SUCCESS";
export const GET_EBKK_POSTS_FAILURE = "GET_EBKK_POSTS_FAILURE";
export const ADD_SINGLE_EBKK_POST = "ADD_SINGLE_EBKK_POST";
export const REMOVE_SINGLE_EBKK_POST = "REMOVE_SINGLE_EBKK_POST";
export const SET_NO_MORE_DATA = "SET_NO_MORE_DATA";
export const LIKE_DISLIKE_EBKK_POSTS = "LIKE_DISLIKE_EBKK_POSTS"



//shareLink popup
export const SET_SHARE_LINK = "SET_SHARE_LINK";
export const SET_DIALOG_STATUS = "SET_DIALOG_STATUS";
export const SET_PARENT_TYPE = "SET_PARENT_TYPE";

//GET KEY MOMENTS
export const GET_KEY_MOMENTS_SUCCESS = "GET_KEY_MOMENTS_SUCCESS";
export const GET_KEY_MOMENTS_FAIL = "GET_KEY_MOMENTS_FAIL";

//AUTH ACTION TYPES
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const LOAD_SDK_START = "LOAD_SDK_START";
export const LOAD_SDK_SUCCESS = "LOAD_SDK_SUCCESS";
export const LOAD_SDK_FAIL = "LOAD_SDK_FAIL";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

// TOGGLE LEFT SIDEBAR
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

// HASHTAGS AND LOCATION ACTIONS
export const GET_HASTAGS_POST_START = "GET_HASTAGS_POST_START";
export const GET_HASTAGS_POST_SUCCESS = "GET_HASTAGS_POST_SUCCESS";
export const GET_HASTAGS_POST_FAILURE = "GET_HASTAGS_POST_FAILURE";
export const SET_HASTAGS_NO_MORE_DATA = "SET_HASTAGS_NO_MORE_DATA";

export const GET_LOCATIONS_POST_START = "GET_LOCATIONS_POST_START";
export const GET_LOCATIONS_POST_SUCCESS = "GET_LOCATIONS_POST_SUCCESS";
export const GET_LOCATIONS_POST_FAILURE = "GET_LOCATIONS_POST_FAILURE";
export const SET_LOCATIONS_NO_MORE_DATA = "SET_LOCATIONS_NO_MORE_DATA";

//MEETUP TOKEN KEY GENERATION
export const MEETUP_TOKEN_KEY_GENERATION_START = "MEETUP_TOKEN_KEY_GENERATION_START";
export const MEETUP_TOKEN_KEY_GENERATION_SUCCESS = "MEETUP_TOKEN_KEY_GENERATION_SUCCESS";
export const MEETUP_TOKEN_KEY_GENERATION_FAILURE = "MEETUP_TOKEN_KEY_GENERATION_FAILURE";

// SNIPPET AUDIO CONTROLLER 
export const UPDATE_SNIPPET_MUTE_FLAG_CONTROLLER = "UPDATE_SNIPPET_MUTE_FLAG_CONTROLLER";

//SHOWCASE FETCH ACTIONS
export const FETCH_SHOWCASE_REQUEST = 'FETCH_SHOWCASE_REQUEST';
export const FETCH_SHOWCASE_SUCCESS = 'FETCH_SHOWCASE_SUCCESS';
export const FETCH_SHOWCASE_FAILURE = 'FETCH_SHOWCASE_FAILURE';
