import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  dialogPaper: {
    backgroundColor: '#212121 !important',
    border:'none !important'
  },
  imageContainer: {
    width: 450, 
    height: 200, 
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  iconButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7) !important', 
    },
  },
  leftIconButton: {
    left: 0,
  },
  rightIconButton: {
    right: 0,
  },
  imageIndex: {
    padding: '8px',
    color: '#fff',
    textAlign: 'center',
    margin: '16px auto',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
  },
  dialogContent: {
    marginBottom: '28px', 
  },
});

const ShowcaseImagesDialog = ({ open, handleClose, images }) => {

  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    if (currentImageIndex === images.length - 1) return;
    setCurrentImageIndex((prevIndex) => prevIndex + 1);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <Dialog open={open}  maxWidth="md" classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.imageIndex}>{`Image ${currentImageIndex + 1} of ${images.length}`}</Typography>
        <IconButton color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.imageContainer}>
          <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} className={classes.image} />
          <IconButton className={`${classes.iconButton} ${classes.leftIconButton}`} onClick={prevImage}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton className={`${classes.iconButton} ${classes.rightIconButton}`} onClick={nextImage}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions>

      </DialogActions>
    </Dialog>
  );
};

export default ShowcaseImagesDialog;



