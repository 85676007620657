import styled from 'styled-components';

export const closeStyle = {
  position: "absolute",
  right: "8px",
  color: "#FFFFFF",
};

export const backStyle = {
  position: "absolute",
  left: "8px",
  cursor: "pointer",
};

export const closeButtonStyle = {
  background: "#F4F4F4 0% 0% no-repeat padding-box",
  borderRadius: "10px",
  opacity: 1,
  color: "#0E0817",
  marginTop: "3rem",
  marginLeft: "25%",
  marginBottom: "1.5rem",
  width: "50%",
  fontSize: "1.3rem",
  textTransform: 'none',
}

export const submitButtonStyle = {
  background: "#F4F4F4 0% 0% no-repeat padding-box",
  borderRadius: "5px",
  opacity: 1,
  width: "50%",
  color: "#0E0817",
  textTransform: 'none',
  height: "2rem",
  marginTop: "15px",
}

export const actionButtonStyle = {
  color: "#ED4D29",
  border: "1px solid #ED4D29",
  borderRadius: "10px",
  opacity: 1,
  margin: "20px",
  textTransform: 'none',
  fontSize: "1rem",
  padding:".2rem 1.6rem"
}

export const cancelButtonStyle = {
  background: "#F4F4F4",
  borderRadius: "10px",
  opacity: 1,
  margin: "20px",
  color: "#0E0817",
  textTransform: 'none',
  fontSize: "1rem",
  padding:".2rem 1.6rem"

}

export const confirmDescStyle = { 
  textAlign: "center", 
  fontSize: "1.2rem", 
  color: "#A2A2A2"
}

export const confirmCloseStyle = {
  background: "#F4F4F4 0% 0% no-repeat padding-box",
  borderRadius: "10px",
  opacity: 1,
  color: "#0E0817",
  marginTop: "3rem",
  width: "50%",
  fontSize: "1.3rem",
  textTransform: 'none',
}

export const TextareaStyle = {
  backgroundColor: "#252525",
  fontSize: "1rem",
  padding: "0.3rem",
  width: "95%",
  color: "white",
  marginLeft: "0.9rem",
}

export const ReportOptionStyle = {
  fontSize: "1rem",
  left:  "10px",
  cursor: "pointer",
  margin: "1rem",
  color: "#A2A2A2",
  padding: "0px",
}

export const FurtherDiv = styled.div`
  position: relative;
  font-size: 1.2rem;
  left: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;


export const NameContainer = styled.div`
  background-color: #252525;
  color: #F4F4F4;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  margin: 0.5rem;
  font-size: 1.2rem;
  padding: 0.5rem;
  cursor: pointer;
`;

export const InfoDiv = styled.div`
  color: #A2A2A2;
  font-size: 1rem;
  display: flex;
  padding-left: 1rem;
  margin-bottom: 1rem;
`;

export const TextDiv = styled.div`
  text-align: left;
  font: normal normal normal 14px/20px Work Sans;
  letter-spacing: 0px;
  color: #A2A2A2;
  opacity: 0.6;
  align: center;
`;



