import axios from 'axios';
import { REACT_APP_BASE_URL_FOR_USER_V1 } from '../../../constants/env';
import { getPreparedRequestHeaderConfig } from '../utils';
import { auto_login_continue } from '../../../utils/utils';

// Create an Axios instance
const UserServiceInstanceV1 = axios.create({
  baseURL: REACT_APP_BASE_URL_FOR_USER_V1, // replace with your API's base URL
  // You can add other default settings here
});

// Add a request interceptor
UserServiceInstanceV1.interceptors.request.use(
  (config) => {
    const newConfig = getPreparedRequestHeaderConfig(config);
    return newConfig;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
UserServiceInstanceV1.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const res = error.response;
    if (!res) return;
    if (res.status === 401) {
      return auto_login_continue(() => UserServiceInstanceV1(originalRequest));
    }
    // Any status codes outside the range of 2xx cause this function to trigger
    // Do something with the response error
    return Promise.reject(error);
  }
);

export default UserServiceInstanceV1;
