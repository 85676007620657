import React, { useEffect, useState, useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";
import axios from "axios";
import CustomizedSnackbars from "../../components/Snackbar.component";
import RightSideBar from "../../components/RightSideBar";
import ProfileHeader from "../../components/ProfileHeader";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header";
import { MainDiv } from "../../pages/RoundTable/style.js";
import { CenterDiv } from "./style";
import { RightDiv } from "../../global_styles/global_style";
import "react-placeholder/lib/reactPlaceholder.css";
import {
  IS_ANDROID_OR_IOS,
  REACT_APP_BASE_URL_FOR_USER,
} from "../../constants/env";
import { auto_login_continue, getUtmParameters } from "../../utils/utils";
import { allWords } from "../../App";
import logger from "../../logger";
import ProfilePointer from "../../components/ProfilePoint";
import EarnPointBadges from "../../components/ProfileEarnPointsAndBadges";
import ProfileBadges from "../../components/ProfileBadges";
import ProfileLevels from "../../components/ProfileLevels";
import {
  userPointsCalculation,
  userProfileBlock,
} from "../../redux/actions/profileAction/userProfileAction";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { storeProfileUTM, storePageUtm } from "../../apis/utmParamsApis.js";
import ToastHandler from "../../utils/ToastHandler.js";
import ShowCaseComponent from "../../components/ShowCaseComponent/index.jsx";
import Highlights from "../../components/Highlights/Highlights.jsx";
import DiscardDialog from "../../components/Post/AddPostDialog/DiscardDialog";
import AddPostDialog from "../../components/Post/AddPostDialog";
import UserProfile from "../../components/UserProfile";
import { Hidden } from "@mui/material";
import { getIsProfileBlock, getIsProfileError, getIsProfileLoading, getIsProfileNotFound, getUserProfileData } from "../../redux/reducers/profileReducer/userProfileReducer.js";
import PropTypes from 'prop-types';

// to make it work as enum so no one modify the flag accidentally
export const ProfilePageFlag = Object.freeze({
  profile: "Profile",
  khulKeBoard: "Khul Ke Board",
  earnPointBadges: "Earn Points and badges",
  badges: "Badges",
  levels: "Levels",
});

const Profile = ({ origin = "/home" }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const url_search_params = new URL(window.location.href);
  const username_split = url_search_params.pathname.split("/")[2];
  const username =
    urlSearchParams.get("username") ||
    username_split ||
    JSON.parse(localStorage.current_user || localStorage.anonymous_user)[
      "username"
    ];

  const profileResponseData = useSelector(getUserProfileData);
  const profileLoading = useSelector(getIsProfileLoading);
  const profileError = useSelector(getIsProfileError);
  const notFound = useSelector(getIsProfileNotFound);
  const block = useSelector(getIsProfileBlock);
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [onPointScreen, setOnPointScreen] = useState(ProfilePageFlag.profile);
  const [HighlightsUser, setHighlightsUser] = useState("");
  const [HighlightsImage, setHighlightsImage] = useState([]);
  const [openHighlightsImageModal, setopenHighlightsImageModal] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [discard, setDiscard] = useState(false);
  const [addPost, setAddPost] = useState(false);
  const [isOrgMemberOfVisitedOrg, setOrgMemberOfVisitedOrg] = useState(false);


  const handleOpenChange = (newValue) => {
    setIsOpen(newValue);
  };
  const dispatch = useDispatch();
  const {
    user_activities_badge,
    user_activities_level,
    user_activities_points,
  } = useSelector((state) => state.user_profile.profilePoints);
  const { badges, points, level, next_level_points } = useSelector(
    (state) => state.user_profile.profilePointDetail
  );
  const utmObject = username && getUtmParameters(window.location.href);
  const selfState = useLocation();

  const shareProfile = useCallback(async () => {
    const config = {
      method: "post",
      url: `${REACT_APP_BASE_URL_FOR_USER}/${
        !localStorage.current_user
          ? "anonymous/share-profile-data-links"
          : "share-user-data-links"
      }`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.access || JSON.parse(localStorage.anonymous_user).token
        }`,
      },
      data: JSON.stringify({ username }),
    };

    try {
      const res = await fetchData(config);
      setRedirectUrl(res.data.data[0]["link"]);
      if (window.innerWidth < 768) {
        return setOpen(true);
      }
      setOpen(false);
    } catch (e) {
      const res = e.response;
      logger.error(res);
    }
  }, [username]);

  const getCustomOrgDetails = async (id) => {
    const anonymousUser = JSON.parse(localStorage.getItem("anonymous_user"));

    const config = {
      method: "get",
      url: `${REACT_APP_BASE_URL_FOR_USER}/v1/auth/user-custom-org-details/?limit=10&offset=0`,
      headers: {
        Authorization: `Bearer ${
          anonymousUser ? anonymousUser?.["token"] : localStorage.access
        }`,
        "Content-Type": "application/json",
      }
    };

    await axios(config)
      .then((response) => {
        setOrgMemberOfVisitedOrg(response?.data?.data.find(item => item.org_user_id === id).length !== 0)
      })

  }

  useEffect(() => {
    if(!isEmpty(profileResponseData)){
      const currentUser = JSON.parse(
        localStorage.current_user || localStorage.anonymous_user
      );
      const isSelfProfile = username === currentUser.username;
      const userOther = profileResponseData.user_other || {};
      setUserId(isSelfProfile ? profileResponseData._id : userOther._id);
      getCustomOrgDetails(profileResponseData._id);
      setHighlightsUser(profileResponseData);
    }
  }, [profileResponseData])
  
  console.log(isOrgMemberOfVisitedOrg, "shdshoidupasoid-0=-=-=")
  
  const getStaticPointCalc = async () => {
    const data = JSON.stringify({
      username: username,
    });

    const anonymousUser = JSON.parse(localStorage.getItem("anonymous_user"));

    const config = {
      method: "get",
      url: `${REACT_APP_BASE_URL_FOR_USER}${
        anonymousUser ? "/anonymous/points-static-data" : "/points-static-data"
      }`,
      headers: {
        Authorization: `Bearer ${
          anonymousUser ? anonymousUser?.["token"] : localStorage.access
        }`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then((response) => {
        dispatch(
          userPointsCalculation(
            response.data?.data || {
              user_activities_badge: {},
              user_activities_level: {},
              user_activities_points: {},
            }
          )
        );
      })
      .catch(async (e) => {
        const response = e.response;
        if (!response) return;
        if (response.status === 401) {
          return await auto_login_continue(getStaticPointCalc);
        }
      });
  };
  const handleHighlightsImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (!imageFile) {
      ToastHandler("warn", allWords.profile.highlights.selectImage);
      return false;
    }
    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      ToastHandler("warn", allWords.misc.toastMsg.invalidImgFormat);
      return false;
    }
    if (imageFile) {
      for (let i = 0; i < e.target.files.length; i++) {
        let size = e.target.files[i].size;
        if (Math.round(size / 1024) > 1024 * 5) {
          ToastHandler("warn", allWords.profile.highlights.imageLess);
          e.target.value = null;
          return false;
        }
      }
    }
    setopenHighlightsImageModal(true);
    const images = e.target.files;
    if (images.length <= 3) {
      if (HighlightsImage.length === 3) {
        ToastHandler("warn", allWords.profile.highlights.imagesAllowed);
      } else {
        if (e.target.files) {
          const file = Array.from(e.target.files).map((file) => {
            return {
              file: file,
              url: URL.createObjectURL(file),
            };
          });
          setHighlightsImage((prevImg) => prevImg.concat(file));
        }
      }
    } else {
      ToastHandler("warn",allWords.profile.highlights.imagesAllowed);
    }
    e.target.value = null;
  };
  // api calling for utm parameter store
  useEffect(() => {
    if (username && utmObject) {
      const payload = {
        username: username,
        ...utmObject,
      };
      storeProfileUTM(payload)
        .then(() => {
          return;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (utmObject) {
      const payload = { action_page: "profile", action: "view", ...utmObject };
      storePageUtm(payload)
        .then(() => {
          return;
        })
        .catch((error) => {
          ToastHandler("warn", error);
        });
    }
  }, []);

  useEffect(() => {
    getStaticPointCalc();
  }, [selfState]);

  useEffect(() => {
    if (IS_ANDROID_OR_IOS && redirectUrl === "") {
      shareProfile();
    }
  }, [shareProfile, redirectUrl]);

  const handleClose = () => {
    setOpen(false);
  };

  const getProfileComponent = () => {
    switch (onPointScreen) {
      case ProfilePageFlag.khulKeBoard:
        return (
          <ProfilePointer
            user_id={userId}
            setOnPointScreen={setOnPointScreen}
            badges={badges}
            points={points}
            level={level}
            nextLevelPoints={next_level_points}
            user_activities_badge={user_activities_badge}
            user_activities_level={user_activities_level}
            user_activities_points={user_activities_points}
          />
        );
      case ProfilePageFlag.earnPointBadges:
        return (
          <EarnPointBadges
            user_id={userId}
            setOnPointScreen={setOnPointScreen}
            badges={badges}
            points={points}
            level={level}
            nextLevelPoints={next_level_points}
            user_activities_points={user_activities_points}
          />
        );
      case ProfilePageFlag.badges:
        return (
          <ProfileBadges
            user_id={userId}
            setOnPointScreen={setOnPointScreen}
            badges={badges}
            points={points}
            level={level}
            nextLevelPoints={next_level_points}
            user_activities_badge={user_activities_badge}
          />
        );
      case ProfilePageFlag.levels:
        return (
          <ProfileLevels
            user_id={userId}
            setOnPointScreen={setOnPointScreen}
            badges={badges}
            points={points}
            level={level}
            nextLevelPoints={next_level_points}
            user_activities_level={user_activities_level}
          />
        );
      case ProfilePageFlag.profile:
      default:
        return <Outlet context={{ block, user_id: userId, setBlock: (value) => dispatch(userProfileBlock(value)) }} />;
    }
  };
  return (
    <>
      <Hidden smDown>
        <Header />
      </Hidden>
      {open && (
        <CustomizedSnackbars
          open={open}
          handleClose={handleClose}
          redirectUrl={redirectUrl}
        />
      )}
      <MainDiv className="main-div-profile-content">
        <CenterDiv className="profile-content-tabs-div profile-change-css" id="profile-header">
          {profileLoading && <Spinner />}
          {profileError && !notFound && (
            <div className="text-center" data-test="src-pages-Profile-index-0">
              <small className="warn-text">{allWords.profile.fallback}</small>
            </div>
          )}
          {!profileLoading && profileError && notFound && (
            <div className="text-center" data-test="src-pages-Profile-index-1">
              <small className="warn-text">
                User with username {username} not found! Please try another
                username.
              </small>
            </div>
          )}
          {!profileLoading && !profileError && (
            <>
              <ProfileHeader
                acc_set
                edit_btn
                follow
                origin={origin}
                follow_btn
                blockUser={block}
                p_loading={profileLoading}
                p_error={profileError}
                onPointScreen={onPointScreen}
                setOnPointScreen={setOnPointScreen}
                user_id={userId}
                HighlightsUser={HighlightsUser}
                handleHighlightsImageChange={handleHighlightsImageChange}
                handleOpenChange={handleOpenChange}
                isOpen={isOpen}
                isOrg={HighlightsUser?.user_domain === "ORG"}
              />
              {HighlightsUser?.user_domain === "ORG" && (
                <Highlights
                  user_id={HighlightsUser?._id}
                  image={HighlightsImage}
                  setImage={setHighlightsImage}
                  handleHighlightsImageChange={handleHighlightsImageChange}
                  setOpenImageInput={setopenHighlightsImageModal}
                  openImageinput ={openHighlightsImageModal}
                />
              )}
              <ShowCaseComponent 
                handleOpenChange={handleOpenChange}
                isOpen={isOpen}
                HighlightsUser={HighlightsUser}
              />
              {HighlightsUser?.user_domain === "ORG" && isOrgMemberOfVisitedOrg && (<> <div
                    style={{
                      width: "100%",
                      display: "flex",
                      borderTop: "grey solid 1px",
                      borderBottom: "grey solid 1px",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      alignItems: "center",
                    }}
                  >
                    <UserProfile
                     height={48} width={48} username={JSON.parse(localStorage.current_user || localStorage.anonymous_user)["username"]}
                     isOrg={HighlightsUser?.user_domain === "ORG"} />
                    <input type="text" 
                           style= {{
                          width: "88%",
                          marginLeft: "1rem",
                          border: "1px solid grey",
                          height: "50px",
                          borderRadius: "7px",
                          backgroundColor: "#1c1b1c"
                          }} 
                          placeholder={`Write something for ${HighlightsUser?.username} ...` } 
                          onClick={(e) => {
                            setAddPost(true)
                          }} />
                    {addPost && (
                        <AddPostDialog
                          // onClose={() => setIsDialogOpen(false)} // Close dialog
                          open={addPost}
                          setAddPost={setAddPost}
                          setDialogTitle={setDialogTitle}
                          setDiscard={setDiscard}
                          isOrg={HighlightsUser?.user_domain === "ORG"} 
                          orgName={HighlightsUser?.username}
                          orgId={HighlightsUser?._id}
                        />
                      )}
                      {discard && (
                        <DiscardDialog setDiscard={setDiscard} setAddPost={setAddPost} />
                      )}
                  </div></>)}
              {getProfileComponent()}
            </>
          )}
        </CenterDiv>
        <RightDiv className="profile-right-div">
          <RightSideBar showSearch showRoundtabaleContent />
        </RightDiv>
      </MainDiv>
    </>
  );
};

Profile.propTypes = {
  origin: PropTypes.string,
}

export default Profile;
