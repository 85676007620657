import React, { useEffect, useState } from "react";
import LeftSideBar from "./components/LeftSideBar";
import "./superparent.css";
import { useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import Welcome3 from "./pages/Welcome/Welcome3";
import SuccessDialog from "./components/SuccessDialog";
import { allWords } from "./App";
import {
  toggleSiderBarFull,
  toggleSiderBarSmall,
} from "./redux/actions/toggleSidebarAction";
const isMobile = window.screen.width < 769;

export default function SuperParent({ children }) {
  const [isPageNotFound, setIsPageNotFound] = useState(false);
  const loc = useLocation();
  const dispatch = useDispatch();
  const checkpage =
    loc.pathname.includes("roundtable") && loc.search.includes("?id=");
  const [kkpaths, setKKPaths] = useState(false);
  const fullScrc = useSelector((state) => state.fullScreen.full);
  const userDataDetails = useSelector((state) => state.user_profile);
  const navigationCheckLoading = useSelector(
    (state) => state.createEditRoundtable.navigationCheckLoading
  );

  const current_user = localStorage.current_user
    ? JSON.parse(localStorage.current_user)
    : null;

  const [userDialog, setUserDialog] = useState({
    flag: 1,
    data: [],
    updated: false,
  });
  const [is_icon_sidebar, setis_icon_sidebar] = useState(true);
  const [isIconRequired, setIsIconRequired] = useState(true);
  const [successDialog, setSuccessDialog] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // dont show welcome 2nd popup for some screens
  const location = window.location.pathname;
  let currentuser = JSON.parse(localStorage.getItem("current_user"));
  const isEmbed = loc.pathname.split("/")[1] === "embed";

  useEffect(() => {
    const checkPageStatus = async () => {
      try {
        const response = await fetch(window.location.href);
        if (response.status === 404) {
          // Check if status code is 404 or another code
          setIsPageNotFound(true);
        } else {
          setIsPageNotFound(false);
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        setIsPageNotFound(true);
      }
    };
    checkPageStatus();
  }, [window.location.href]);

  useEffect(() => {
    if (!current_user) {
      return;
    }

    if (userDataDetails) {
      setUserData(
        userDataDetails?.data?.data?.self_user ||
          userDataDetails?.data?.data?.user_other
      );
      setUserDialog({
        flag:
          userData?.username_updated === 0
            ? current_user?.hasChangedUsername
              ? 1
              : 0
            : 1,
        data: userData,
        updated: false,
      });
    }
  }, [userDataDetails, userData]);

  const yappLeftSideBar = () => {
    if (
      loc.pathname.includes("search") ||
      loc.pathname.includes("personal_details") ||
      loc.pathname.includes("create") ||
      loc.pathname.includes("edit") ||
      loc.pathname.includes("live") ||
      loc.pathname.includes("mine") ||
      loc.pathname.includes("upcoming") ||
      loc.pathname.includes("scrolls") ||
      loc.pathname.includes("home") ||
      loc.pathname.includes("roundtable") ||
      loc.pathname.includes("showcase") ||
      loc.pathname.includes("showcaselist")
    ) {
      setIsIconRequired(true);
      if (is_icon_sidebar == true) {
        setis_icon_sidebar(true);
      } else {
        setis_icon_sidebar(false);
      }
    } else {
      setIsIconRequired(false);
    }

    if (loc.pathname.includes("yapp")) {
      setIsIconRequired(true);
      setis_icon_sidebar(false);
    }
    if (
      loc.pathname.includes("account_settings") ||
      loc.pathname.includes("broadcast") || loc.pathname.includes("additional-resources") ||
      loc.pathname.includes("oldpass") ||
      loc.pathname.includes("setPass") ||
      loc.pathname.includes("muted_words") ||
      loc.pathname.includes("deactivate_info") ||
      loc.pathname.includes("muted_accounts") ||
      loc.pathname.includes("blocked_accounts") ||
      loc.pathname.includes("feedback")
    ) {
      setIsIconRequired(true);
      setis_icon_sidebar(false);
    }
    if (loc.pathname.includes("all")) {
      setIsIconRequired(true);
      setis_icon_sidebar(true);
    }
  };

  useEffect(() => {
    // used to show left sidebar for the pages
    if (
      loc.pathname.includes("home") ||
      loc.pathname.includes("snip-it") ||
      loc.pathname.includes("all") ||
      loc.pathname.includes("live") ||
      loc.pathname.includes("list") ||
      loc.pathname.includes("upcoming") ||
      loc.pathname.includes("mine") ||
      loc.pathname.includes("notifications") ||
      loc.pathname.includes("profile") ||
      loc.pathname.includes("search") ||
      loc.pathname.includes("personal_details") ||
      loc.pathname.includes("create") ||
      loc.pathname.includes("edit") ||
      loc.pathname.includes("post") ||
      loc.pathname.includes("yapp") ||
      loc.pathname.includes("k3") ||
      loc.pathname.includes("bol-khulke") ||
      loc.pathname.includes("soch-khulke") ||
      loc.pathname.includes("scrolls") ||
      loc.pathname.includes("VideosOnMap") ||
      loc.pathname.includes("tags") ||
      loc.pathname.includes("locations") ||
      loc.pathname === "/" ||
      loc.pathname.includes("roundtables") ||
      loc.pathname.includes("showcase") ||
      loc.pathname.includes("account_settings") ||
      loc.pathname.includes("broadcast") ||
      loc.pathname.includes("oldpass") ||
      loc.pathname.includes("deactivate_info") ||
      loc.pathname.includes("setPass") ||
      loc.pathname.includes("muted_words") ||
      loc.pathname.includes("muted_accounts") ||
      loc.pathname.includes("blocked_accounts") ||
      loc.pathname.includes("feedback") ||
      loc.pathname.includes("organization") ||
      loc.pathname.includes("additional-resources")
    ) {
      setIsIconRequired(true);
      setKKPaths(true);
      setis_icon_sidebar(true);
    } else setKKPaths(false);
    yappLeftSideBar();
    // For showing popup based on different screens
    let path = loc.pathname;

    if (path.includes("roundtable") && path.split("/").length < 3) {
      path = loc.search;
    }

    const condition = (el) => path.includes(el);

    // add word from the path where you dont want to show welcome popup 2nd time
    const noWelcomePaths = ["join", "recorded", "id", "profile", "post"];

    if (noWelcomePaths.some(condition)) {
      return setShowPopup(false);
    }

    setShowPopup(true);
  }, [loc]);

  const showIconMenuHandler = () => {
    setis_icon_sidebar((prev) => !prev);

    if (is_icon_sidebar) {
      dispatch(toggleSiderBarFull());
    } else if (!is_icon_sidebar) {
      dispatch(toggleSiderBarSmall());
    }
  };

  // let width = "327px";
  let width = "300px";

  if (location.split("/")[1] === "yapp") {
    width = "fit-content";
  }

  if (is_icon_sidebar && loc.pathname.includes("profile")) {
    // width="320px";
    width = "250px";
  } else if (is_icon_sidebar) {
    width = "300px";
  } else {
    width = "85px";
  }

  const style = {
    position: "relative",
    width: width,
  };

  return (
    <>
      <div
        className={isEmbed ? "" : "outerkk1"}
        id="outerkk1-id"
        style={{
          coloe: "#fff",
          width:
            loc.pathname.includes("welcome") ||
            loc.pathname.includes("recording") ||
            loc.pathname.includes("join") ||
            loc.pathname.includes("recorded") ||
            fullScrc
              ? "100%"
              : "",
          justifyContent: loc.pathname.includes("join") ? "center" : "",
          height: loc.pathname.includes("join") || isEmbed ? "100vh" : "",
          display:
            loc.pathname.includes("profile") && window.innerWidth < 400
              ? "flex"
              : null,
        }}
        data-test="src-SuperParent-0"
      >
        {!isEmbed &&
        !navigationCheckLoading &&
        ((!isMobile && kkpaths) || checkpage || isPageNotFound) ? (
          <div className="innerkk1" style={style} data-test="src-SuperParent-1">
            <LeftSideBar is_icon_sidebar={is_icon_sidebar} />
            {isIconRequired ? (
              <div
                className="icon_menu_container"
                data-test="src-SuperParent-2"
              >
                <div
                  className="icon_menu"
                  onClick={showIconMenuHandler}
                  data-test="src-SuperParent-3"
                >
                  {is_icon_sidebar ? (
                    <ChevronLeftIcon className="icon_color" />
                  ) : (
                    <ChevronRightIcon className="icon_color" />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : undefined}
        {children}
      </div>
      {userDialog?.flag !== 0 ||
      (!(
        userData &&
        userData?.username_updated &&
        userData.username_updated !== 1
      ) &&
        !showPopup &&
        !currentuser?.hasChangedUsername) ? (
        <></>
      ) : (
        <Welcome3
          welcomeScreen={true}
          fromHome={true}
          userData={userDialog?.data}
          setUserDialog={setUserDialog}
          setSuccessDialog={setSuccessDialog}
          hasUpdatedUsername={userDialog?.flag}
        />
      )}

      <SuccessDialog
        open={successDialog}
        setOpen={setSuccessDialog}
        msg={allWords.misc.pages.proupdated}
        fromHome={true}
      />
    </>
  );
}
