import { SET_SHARE_LINK, SET_DIALOG_STATUS, SET_PARENT_TYPE } from '../actionTypes'

export const setSharLink = (link) => ({
    type: SET_SHARE_LINK,
    payload: link,
});

export const setDialogStatus = (status) => ({
    type: SET_DIALOG_STATUS,
    payload: status,
});

export const setPostParentType = (type) => ({
    type: SET_PARENT_TYPE,
    payload: type,
});