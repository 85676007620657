import React, { useEffect, useState } from "react";
import { REACT_APP_BASE_URL_CLOUDFRONT } from "../../constants/env";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import WithoutImage from "../../assets/images/WithoutImage.svg";

// Style
import "./style.css";
import M3U8Player from "../M3U8Player";
import AspectRatio from "@mui/joy/AspectRatio";
import { Box } from "@mui/material";
const hoverEffect = {
  "&:hover": {
    borderRadius: "0px",
    transition: "all 0.5s ease-in-out",
  }
}

export default function VideoThumbnail(props) {
  const {
    media_recording,
    owner_details,
    c_img,
    setCImg,
    title,
    rt_id,
    videoElem,
    rt_details,
    pauseVid,
    muteFlag,
    upcoming,
    videoUrl,
    parentHeight,
  } = props;

  let current_user = null;
  current_user = JSON.parse(
    localStorage.current_user || localStorage.anonymous_user
  );

  // Local state
  const [isMuted, setIsMuted] = useState(true);
  const [videoData, setVideoData] = useState("");
  const [ext, setExt] = useState("");

  useEffect(() => {
    const media = Array.isArray(media_recording)
      ? media_recording?.find((item) =>
          item?.metadata?.ext.includes("m3u8")
            ? item?.metadata?.ext.includes("m3u8")
            : item?.metadata?.mimeType.includes("video") ||
              item?.metadata?.mimeType.includes("audio")
        )
      : media_recording;

    setVideoData(media?.metadata?.tempFilename);
    setExt(media?.metadata?.mimeType);
  }, []);

  const toggleMute = (e) => {
    e.stopPropagation();
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (ext?.includes("audio"))
      isMuted
        ? (videoElem.current.muted = true)
        : (videoElem.current.muted = false);
  }, [ext, isMuted, videoElem]);

  return (
    <>
      <div
        className={`${upcoming ? "upcoming_img" : "video_img"} ${c_img ? "" : "title_cover"}`}
        data-test="src-components-RTListingCard-VideoThumbnail-0"
      >
        {c_img ? (
          <Box
            sx={{
              width: "100%",
              borderRadius: "sm",
              p: 0,
              ...hoverEffect,
            }}
            data-test="src-components-RTListingCard-VideoThumbnail-1"
          >
            <AspectRatio
              objectFit="contain"
              sx={{
                borderRadius: "10px 10px 0px 0px",
                background: "#0E0817",
                div: {
                  backgroundColor: "transparent",
                },
                ...hoverEffect,
              }}
            >
              <img
                alt="this is image"
                src={c_img}
                onError={() => {
                  setCImg(undefined);
                }}
                data-test="src-components-RTListingCard-VideoThumbnail-2"
              />
            </AspectRatio>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              borderRadius: "10px",
              ...hoverEffect,
            }}
            data-test="src-components-RTListingCard-VideoThumbnail-3"
          >
            <AspectRatio
              objectFit="contain"
              sx={{
                borderRadius: "10px",
                background: "#0E0817",
                div: {
                  backgroundColor: "transparent",
                },
                ...hoverEffect
              }}
            >
              <div data-test="src-components-RTListingCard-VideoThumbnail-4">
                <img
                  alt="this is image"
                  src={WithoutImage}
                  onError={() => {
                    setCImg(undefined);
                  }}
                  data-test="src-components-RTListingCard-VideoThumbnail-5"
                />
                <div
                  className="rt_img_p"
                  data-test="src-components-RTListingCard-VideoThumbnail-6"
                >
                  <p data-test="src-components-RTListingCard-VideoThumbnail-7">
                    {title}
                  </p>
                </div>
              </div>
            </AspectRatio>
          </Box>
        )}
      </div>
      <div
        id="video_div"
        data-test="src-components-RTListingCard-VideoThumbnail-8"
      >
        <div
          id="video_wrappers"
          className="video-wrapper"
          style={{
            width: "97%",
            height: parentHeight,
            margin: "0 auto",
          }}
          data-test="src-components-RTListingCard-VideoThumbnail-9"
        >
          {!ext?.includes("audio") ? (
            <>
              {muteFlag == true && !upcoming && (
                <M3U8Player
                  muteFlag={muteFlag}
                  src={media_recording}
                  rt_data={rt_details}
                  current_user={current_user}
                  label="listing"
                  videoElem={videoElem}
                  pauseVid={pauseVid}
                  owner_details={rt_details?.owner}
                  open_to_all={rt_details?.open_to_all}
                  isMuted={isMuted}
                  className="videoThumbnail"
                  id={`video_thumb`}
                  fillerImg={c_img ? c_img : WithoutImage}
                />
              )}
            </>
          ) : (
            <div data-test="src-components-RTListingCard-VideoThumbnail-10">
              <div data-test="src-components-RTListingCard-VideoThumbnail-11">
                {c_img ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "10px 10px 0px 0px",
                      p: 0,
                      ...hoverEffect
                    }}
                    data-test="src-components-RTListingCard-VideoThumbnail-12"
                  >
                    <AspectRatio
                      objectFit="contain"
                      sx={{
                        borderRadius: "10px 10px 0px 0px",
                        background: "#0E0817",
                        ...hoverEffect
                      }}
                    >
                      <img
                        alt="this is image"
                        src={c_img}
                        onError={() => {
                          setCImg(undefined);
                        }}
                        data-test="src-components-RTListingCard-VideoThumbnail-13"
                      />
                    </AspectRatio>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "10px 10px 0px 0px",
                      ...hoverEffect
                    }}
                    data-test="src-components-RTListingCard-VideoThumbnail-14"
                  >
                    <AspectRatio
                      objectFit="contain"
                      sx={{
                        borderRadius: "10px 10px 0px 0px",
                        background: "#0E0817",
                        div: {
                          backgroundColor: "transparent",
                        },
                        ...hoverEffect
                      }}
                    >
                      <div data-test="src-components-RTListingCard-VideoThumbnail-15">
                        <img
                          alt="this is image"
                          src={WithoutImage}
                          onError={() => {
                            setCImg(undefined);
                          }}
                          data-test="src-components-RTListingCard-VideoThumbnail-16"
                        />
                        <div
                          className="rt_img_p"
                          data-test="src-components-RTListingCard-VideoThumbnail-17"
                        >
                          <p data-test="src-components-RTListingCard-VideoThumbnail-18">
                            {title}
                          </p>
                        </div>
                      </div>
                    </AspectRatio>
                  </Box>
                )}
              </div>
              <audio
                // preload
                loop={true}
                poster={
                  rt_details?.thumbnail_url ||
                  `${REACT_APP_BASE_URL_CLOUDFRONT}/uploads/${rt_details?.owner?.user_id}/roundtable/${rt_details?._id}/profile/${rt_details?.["media"]?.[0]?.["metadata"]?.["tempFilename"]}`
                }
                id="video_id"
                className="videoClass"
                src={
                  videoUrl ||
                  `${REACT_APP_BASE_URL_CLOUDFRONT}/uploads/${
                    owner_details?.user_id
                  }/roundtable/${rt_id}/recording/${videoData}${
                    Math.round(videoElem?.current?.duration) < 330
                      ? `#t=0,31`
                      : `#t=300,331`
                  }`
                }
                ref={videoElem}
                onLoadedMetadata={() => {
                  videoElem.current.currentTime =
                    Math.round(videoElem?.current?.duration) < 330 ? 0 : 300;
                }}
                onPlaying={() => {
                  let timer = setInterval(() => {
                    if (Math.round(videoElem?.current?.duration) < 330) {
                      if (Math.round(videoElem?.current?.currentTime) === 30)
                        videoElem.current.currentTime = 0;
                    } else {
                      if (
                        Math.round(videoElem?.current?.currentTime - 300) === 30
                      )
                        videoElem.current.currentTime = 300;
                    }
                  }, 1000);

                  return () => {
                    clearInterval(timer);
                  };
                }}
                muted
                // controls
                onEnded={() => {
                  pauseVid();
                }}
              />
            </div>
          )}
          {
            <div
              className="controls"
              id="ctrl_button"
              data-test="src-components-RTListingCard-VideoThumbnail-19"
            >
              <button
                id="mute_icon_button"
                className="mute-btn-rt"
                onClick={toggleMute}
                data-test="src-components-RTListingCard-VideoThumbnail-20"
              >
                {!isMuted ? (
                  <VolumeUpIcon className="volumeThumbIcon" id="up_button" />
                ) : (
                  <VolumeOffIcon className="volumeThumbIcon" id="down_button" />
                )}
              </button>
            </div>
          }
        </div>
      </div>
    </>
  );
}
