import React from "react";
import axios from "axios";
import JSEncrypt from "jsencrypt";
import logoutUser from "../apis/logoutUser";
import { allWords } from "../App";
import {
  APP_LINKS,
  ENCRYPTED_PASSWORD_KEY,
  LOGIN_URL,
  PUBLIC_TOKEN,
  PUBLIC_TOKEN_URL,
  REACT_APP_URL,
  STATIC_TOKEN,
  MOBILE_VIEW,
  updatePublicToken,
  REACT_APP_BASE_URL,
  REACT_APP_USER_ONBOARDING_URL,
} from "../constants/env";
import allWordsH from "../lang/hindi";
import allWordsT from "../lang/tamil";
import allWordsE from "../lang/english";
import { isEmpty, isString } from "lodash";
import logger from "../logger";
import NodeRSA from "node-rsa";

export function replaceURLs(title) {
  if (!title) return;

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return title.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return (
      '<div><a target="_blank" style="color: #009AD3;" href="' +
      hyperlink +
      '" rel="noopener noreferrer">' +
      url +
      "</a></div>"
    );
  });
}
// export function replaceURLs(title) {
//   if (!title) return;
//   var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
//   return title.replace(urlRegex, "")
// }

export function filterURLs(title) {
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return title.match(urlRegex);
}

//!Redirect to user's profile in a new tab
export const profileRedirect = (username) => {
  if (!username) {
    return;
  }
  window.open(`${window.location.origin}/profile?username=${username}`);
};

//! Identify if a document is of a valid type
export const validDocType = (docname, extensions) => {
  if (extensions.length === 0 && !docname.includes(".")) {
    return false;
  }
  let file_ext = docname.split(".").at(-1);

  let result = extensions.filter((ext) => {
    if (ext === file_ext) {
      return true;
    }
  });
  return result.length > 0;
};

//not dependent on backend to remove cross site scripts, plus treat html code messages as normal string instead of hiding it
const RE_URL = /(((https?:\/\/)|(www\.))[^\s]+)/g;

export const linkify = (str) => {
  let match;
  const results = [];
  let lastIndex = 0;
  while ((match = RE_URL.exec(str))) {
    const link = match[0];
    if (lastIndex !== match.index) {
      const text = str.substring(lastIndex, match.index);
      results.push(<span key={results.length}>{text}</span>);
    }

    let hyperlink = link;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }

    results.push(
      <a
        key={results.length}
        href={hyperlink}
        target="_blank"
        style={{
          textDecoration: "none",
          color: "blue",
        }}
        rel="noreferrer"
      >
        {link}
      </a>
    );
    lastIndex = match.index + link.length;
  }
  if (results.length === 0) {
    return str;
  }
  if (lastIndex !== str.length) {
    results.push(<span key={results.length}>{str.substring(lastIndex)}</span>);
  }
  return results;
};

const getPuToken = async () => {
  var config = {
    method: "get",
    url: PUBLIC_TOKEN_URL,
    headers: {
      Authorization: STATIC_TOKEN,
    },
  };

  const response = await axios(config);
  updatePublicToken(response.data.data[0]);
  return response.data.data[0];
};

export const get_encrypted_password = async (password) => {
  if (!PUBLIC_TOKEN) {
    await getPuToken();
  }

  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_TOKEN);
  return encrypt.encrypt(password);
};

export const auto_login_continue = async (recall) => {
  const encrypted_password = localStorage.getItem(ENCRYPTED_PASSWORD_KEY);
  const currentUser = localStorage.getItem("current_user");

  if (!encrypted_password && currentUser) {
    if (!JSON.parse(currentUser).anonymous) logoutUser();
  } else if (!currentUser && !encrypted_password) {
    return;
  } else {
    const username = JSON.parse(localStorage.getItem("current_user")).username;

    if (!username) return;

    const data = JSON.stringify({
      username,
      password: encrypted_password,
    });

    const config = {
      method: "POST",
      url: LOGIN_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: STATIC_TOKEN,
      },
      data,
    };

    const response = await axios(config);

    if (response.status !== 200) {
      logger.info("logged out user");
      return logoutUser();
    }

    const access = response.data.access;
    const refresh = response.data.refresh;

    localStorage.setItem("access", access);
    localStorage.setItem("refresh", refresh);
    localStorage.setItem("tokens", JSON.stringify({ access, refresh }));
    localStorage.setItem("current_user", JSON.stringify(response.data));
    if (recall) {
      return recall();
    } else {
      window.location.reload();
    }
  }
};

export const getCursorXY = (input, selectionPoint) => {
  if (!input) return;

  const { offsetLeft: inputX, offsetTop: inputY } = input;

  const div = document.createElement("div");

  const copyStyle = getComputedStyle(input);
  for (const prop of copyStyle) {
    div.style[prop] = copyStyle[prop];
  }

  const swap = ".";
  const inputValue =
    input.tagName === "INPUT" ? input.value.replace(/ /g, swap) : input.value;

  const textContent = inputValue.substr(0, selectionPoint);

  div.textContent = textContent;

  if (input.tagName === "TEXTAREA") div.style.height = "auto";

  if (input.tagName === "INPUT") div.style.width = "auto";

  const span = document.createElement("span");

  span.textContent = inputValue.substr(selectionPoint) || ".";

  div.appendChild(span);

  document.body.appendChild(div);

  const { offsetLeft: spanX, offsetTop: spanY } = span;

  const { offsetTop: divY } = div;

  document.body.removeChild(div);

  return {
    x: inputX + spanX,
    y: inputY + spanY - document.body.offsetHeight,
  };
};

export const getCusrorPosition = (input) => {
  if (!input) return;

  const {
    offsetLeft,
    offsetTop,
    offsetHeight,
    offsetWidth,
    scrollLeft,
    scrollTop,
    selectionEnd,
    value,
  } = input;

  const { lineHeight, paddingRight } = getComputedStyle(input);
  const text = value.slice(0, selectionEnd);
  const words = text.split(/\r?\n/).join(" ").split(" ");
  const lastword = words[words.length - 1];

  const { x, y } = getCursorXY(input, selectionEnd - lastword.length);

  const newLeft = Math.min(
    x - scrollLeft,
    offsetLeft + offsetWidth - parseInt(paddingRight, 10)
  );

  const newTop = Math.min(
    y - scrollTop + parseInt(lineHeight, 10),
    offsetTop + offsetHeight
  );

  return { left: newLeft, top: newTop };
};

export const moengageEvent = async (event_name, component, props) => {
  let data1 = "";

  if (navigator.geolocation) {
    data1 = navigator.geolocation.getCurrentPosition(
      (position) => (data1 = position)
    );
  }

  let data = {
    ID: JSON.parse(localStorage.current_user || localStorage.anonymous_user)?.[
      "_id"
    ],
    // "K Device ID" :,
    Device: "web",
    OS: navigator.platform,
    "OS Version": "v1",
    // "Model": ,
    // "Model Name" : ,
    "App Version": 1,
    "Build Version": 1,
    // IP: res?.data?.IPv4,
    // City: res?.data?.city,
    // State: res?.data?.state,
    // Country: res?.data?.country_name,
    // "Country Code": res?.data?.country_code,
    // Postcode: res?.data?.postal,
    Latitude: data1?.coords?.latitude,
    Longitude: data1?.coords?.longitude,
    Component: component,
    "Is UnReg":
      !localStorage.currentUser && localStorage.anonymous_user ? 1 : 0,
  };

  await window.Moengage.track_event(event_name, { ...data, ...props });
};

export const langFunc = () => {
  let local = JSON.parse(
    localStorage.getItem("current_user")
  )?.display_language;
  switch (local) {
    case "en":
      return allWordsE;

    case "hi":
      return allWordsH;

    case "ta":
      return allWordsT;

    case "ma":
      return allWordsM;

    default:
      return allWordsE;
  }
};

const getMatchingMonth = (d) => {
  const getMonths = Object.keys(allWordsE.dateTime.months);
  return getMonths.find((m) => m === d.toLowerCase());
};

export const filterDateTrans = (data) => {
  let newDate = data;
  let local = JSON.parse(
    localStorage.getItem("current_user")
  )?.display_language;
  if (local === "en" || isEmpty(data)) {
    return data;
  }
  const splitDate = data.split(" ");
  const monthIndex = splitDate.findIndex(
    (d) => isString(d) && getMatchingMonth(d)
  );
  if (monthIndex >= 0) {
    const Month = splitDate[monthIndex];
    const monthKey = getMatchingMonth(Month);
    splitDate[monthIndex] = allWords.dateTime.months[monthKey];
    newDate = splitDate.join(" ");
  }

  return newDate;
};

export const isEngContent = () => {
  return (
    JSON.parse(localStorage.getItem("current_user"))?.display_language === "en"
  );
};

export const isHindiContent = () => {
  return (
    JSON.parse(localStorage.getItem("current_user"))?.display_language === "hi"
  );
};
export const stopKeyboardShortcuts = () => {
  window.addEventListener("keydown", (event) => {
    switch (event.key) {
      case "S":
      case "s":
        if (
          window.location.origin !== "http://localhost:3000" &&
          event.ctrlKey
        ) {
          event.preventDefault();
        }
        break;
      case "F12":
      case "f12":
        if (window.location.origin !== "http://localhost:3000") {
          event.preventDefault();
        }
        break;
      case "I":
      case "i":
      case "J":
      case "j":
      case "K":
      case "k":
        if (
          window.location.origin !== "http://localhost:3000" &&
          event.ctrlKey &&
          event.shiftKey
        ) {
          event.preventDefault();
        }
        break;
      case "U":
      case "u":
        if (
          window.location.origin !== "http://localhost:3000" &&
          event.ctrlKey
        ) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  });
};

export function getTimeRemaining(t, n) {
  let timeout = t;
  let now = n;
  const total = timeout - now;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  return {
    total,
    hours,
    minutes,
    seconds,
  };
}

export const device_info = {
  device_name: navigator.userAgent,
  platform: navigator.platform,
  platform_version: navigator.platform,
  app_version: "0.1.0",
  latitude: 0.0,
  longitude: 0.0,
  ip_address: "",
};

// Get all utm related information from url
export const getUtmParameters = (url) => {
  if (
    !(
      url.includes("utm_source") ||
      url.includes("utm_medium") ||
      url.includes("utm_campaign") ||
      url.includes("utm_term") ||
      url.includes("utm_content")
    )
  ) {
    // None of the utm parameters are present, return false
    return false;
  }

  var urlParams = new URLSearchParams(new URL(url).search);

  var utmObject = {
    utm_source: urlParams.get("utm_source") || "",
    utm_medium: urlParams.get("utm_medium") || "",
    utm_campaign: urlParams.get("utm_campaign") || "",
    utm_term: urlParams.get("utm_term") || "",
    utm_content: urlParams.get("utm_content") || "",
  };

  return utmObject;
};

// Get the value for the string to be appended to share urls for UTM
export const getUtmAppendString = (
  utmSource,
  utmCampaign,
  utmContent = null
) => {
  let currentPath = window.location.pathname;
  let profileRegex = /\/profile\/[a-zA-Z0-9]+/;
  let utmAppendString;

  const utmMedium = MOBILE_VIEW ? "mobile_web" : "web";

  if (utmContent !== null) {
    utmAppendString = `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${utmContent}`;
    return utmAppendString;
  }

  if (currentPath === "/home") {
    utmContent = "Home";
  } else if (currentPath === "/roundtable/all") {
    utmContent = "Roundtable";
  } else if (currentPath === "/roundtables/upcoming") {
    utmContent = "Upcoming";
  } else if (profileRegex.test(currentPath) === true) {
    utmContent = "Profile";
  } else if (currentPath === "/search") {
    utmContent = "Search";
  } else if (currentPath === "/roundtable/notifications") {
    utmContent = "Rt_notification";
  } else if (currentPath === "/notifications/network") {
    utmContent = "Network_notification";
  } else if (currentPath === "/notifications/interaction") {
    utmContent = "Interaction_notification";
  } else if (currentPath === "/roundtable/join") {
    utmContent = "Live_rt";
    utmCampaign = "share_via";
  } else if (currentPath.includes("/roundtable/recorded")) {
    utmContent = "Completed_rt";
    utmCampaign = "share_via";
  } else if (currentPath.includes("/roundtable/list/category")) {
    utmContent = "Category";
  } else if (
    currentPath.includes("/roundtables/") &&
    !currentPath.includes("/upcoming")
  ) {
    utmContent = "Rt_label";
  } else if (
    currentPath.includes("/roundtable/create/success") ||
    currentPath === "/roundtable"
  ) {
    utmContent = "Awesome";
  } else if (currentPath === "/townhall") {
    utmContent = "Timeline";
    utmCampaign = "share_via";
  } else if (currentPath.includes("/post/")) {
    utmContent = "Detailed_post";
    utmCampaign = "share_via";
  } else if (currentPath === "/scrolls") {
    utmContent = "Scrolls_comment";
  } else {
    utmContent = "Home";
  }

  utmAppendString = `utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${utmContent}`;

  return utmAppendString;
};

// Will be removed once api changes the name to Popular Categories
export const POPULART_CATEGORIES = "popular-categories";

export function trimLeadingQuestionMark(str) {
  if (str.startsWith("?")) {
    return str.substr(1);
  }
  return str;
}

export function navigateToNewPage(newPageUrl) {
  // Push the new page URL to the history stack.
  window.history.pushState(null, null, newPageUrl);

  // Reload the page.
  window.location.reload();
}

export const redirectToAppStore = (navigate) => {
  const loc = window.location.href;
  const redirectionEndPoint = window.location.pathname;
  if (navigator.userAgent.match(/android/i)) {
    try {
      const testingUrls = ["dev", "stage"];

      let intentUrl;

      if (testingUrls.some((el) => loc.includes(el))) {
        intentUrl = REACT_APP_URL.replace("https://www.", "");
      } else {
        intentUrl = REACT_APP_URL.replace("https://", "");
      }

      window.location.href = `intent://${intentUrl}${redirectionEndPoint}#Intent;scheme=https;package=com.khulke.app;end`;
    } catch (error) {
      // If the app is not installed, redirect to the Play Store  window.location.href = storeUrl;
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.khulke.app";
    }
  } else if (navigator.userAgent.match(/iphone|ipad|ipod/i)) {
    navigate(`${redirectionEndPoint}`);

    setTimeout(() => {
      window.location.href = `https://apps.apple.com/in/app/khul-ke-social-networking-app/id1590836834`;
    }, 1000);
  } else {
    window.location.href = `${REACT_APP_URL}${redirectionEndPoint}`;
  }
};

export const checkUserStatus = () => {
  const current_user = JSON.parse(localStorage.getItem("current_user"));
  const anonymous_user = JSON.parse(localStorage.getItem("anonymous_user"));

  return {
    isLoggedIn: !!current_user,
    isAnonymous: !!anonymous_user,
    userDetails: current_user || anonymous_user || null,
  };
};

// return in scrolls category image according to scrolls category key and label
import Public_Opinion from "../assets/icons/public_opinion.svg";
import News from "../assets/icons/news.svg";
import Masti from "../assets/icons/masti.svg";
import Showcase from "../assets/icons/showcase.svg";
import DIY from "../assets/icons/diy.svg";
import Bhakti from "../assets/icons/bhakti.svg";
import Politics from "../assets/icons/politics.svg";
import Sports from "../assets/icons/sports.svg";
import RoundTable from "../assets/icons/roundTable.svg";
import Leadership from "../assets/icons/leadership.svg";
import Governance from "../assets/icons/governance.svg";
import Science from "../assets/icons/sci&tech.svg";
import CreateSnipIt from "../assets/icons/create_snipit.svg";
import allWordsM from "../lang/marathi";
import ToastHandler from "./ToastHandler";

const scrollsTagImage = [
  { key: "PUBLIC_OPINION", label: "Public opinion", image: Public_Opinion },
  { key: "NEWS", label: "News", image: News },
  { key: "MASTI", label: "Masti", image: Masti },
  {
    key: "SHOWCASE",
    label: "Showcase - Product, Talent & Institution",
    image: Showcase,
  },
  { key: "DIY", label: "DIY - Do It Yourself", image: DIY },
  { key: "BHAKTI", label: "Bhakti", image: Bhakti },
  { key: "POLITICS", label: "Politics", image: Politics },
  { key: "SPORTS", label: "Sports", image: Sports },
  {
    key: "ROUNDTABLE_SNIPPET",
    label: "RoundTable Snippets",
    image: RoundTable,
  },
  {
    key: "ROUNDTABLE_SNIPPETS",
    label: "RoundTable Snippets",
    image: RoundTable,
  },
  { key: "LEADERSHIP_SPEAKS", label: "Leadership Speaks", image: Leadership },
  { key: "GOVERNANCE", label: "Governance", image: Governance },
  {
    key: "SCIENCE_AND_TECHNOLOGY",
    label: "Science and technology",
    image: Science,
  },
  { key: "OTHERS", label: "Others", image: CreateSnipIt },
];

export const getImageOfScrolls = (key, label) => {
  const tag = key
    ? scrollsTagImage?.find((tag) => tag.key === key)
    : scrollsTagImage?.find((tag) => tag.label === label);

  return tag ? tag.image : null;
};

export const getLabelOfScrollsFromKey = (key) => {
  const tag = scrollsTagImage?.find((tag) => tag.key === key)

  return tag ? tag.label : null;
}
export function removeHtmlEntities(html) {
  if (html) {
    const regexEntities = /(&lt;[^&]+&gt;)|(&amp;nbsp;)/g;
    const regexBoldTags = /<b>|<\/b>/g;

    let cleanedHtml = html.replace(regexEntities, "");
    cleanedHtml = cleanedHtml.replace(regexBoldTags, "");

    return cleanedHtml;
  } else {
    return "";
  }
}

// For SHOWCASE Page...

let token = null;
try {
  token = localStorage.getItem("current_user")
    ? localStorage.getItem("access")
    : JSON.parse(localStorage.getItem("anonymous_user") || {}).token;
} catch (error) {
  console.error("Error parsing anonymous user token:", error);
}

export const fetchShowcase = async (setshowcaseData) => {
  const config = {
    url: `${REACT_APP_BASE_URL}/user/v2/org/showcase-page/listing?limit=10&offset=0`,
    //   method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const resp = await axios(config);
    if (!resp.status === 200) return;
    setshowcaseData(resp.data?.data || []);
  } catch (error) {
    console.log("Error caught ==== ", error);
  }
  // finally {
  // }
};

export const fetchShowcaseList = async () => {
  const userStatus = checkUserStatus();
  const orgId = userStatus?.userDetails?._id;
  try {
    const url = `${REACT_APP_USER_ONBOARDING_URL}/user/v2/org/${orgId}/showcase?limit=10&offset=0`;
    const headers = {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    };
    const response = await axios.get(url, { headers });
    // console.log("Response ==== ", response);
    return response?.data?.data || [];
  } catch (error) {
    console.error("Error in getShowCaseApi: ==== ", error);
    logger.error(error);
    return [];
  }
};
export const addToShowcase = async (item, setshowcaseData) => {
  const formData = new FormData();
  formData.append("type", "post");
  formData.append("content_id", item._id);
  formData.append("title", item?.text || item?.title);
  formData.append("caption", item?.media[0]?.caption || item?.caption);
  formData.append("event_date", item?.created_at || item?.event_date);
  const config = {
    url: `${REACT_APP_BASE_URL}/user/v2/org/showcase`,
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: formData,
  };
  try {
    const res = await axios(config);
    
    if (res.status !== 200) return;

    setshowcaseData((prev) => {
      return prev.map((showcase) => {
        if (showcase._id !== item._id) {
          return showcase;
        }

        return { ...showcase, showcased: 1 };
      });
    });
  } catch (error) {
    console.log("Error caught while adding to showcase ==== ", error);
    ToastHandler("dan", "Error caught while adding to showcase");
  }
};

export const deleteFromShowcase = async (id, setshowcaseData) => {
  const config = {
    url: `${REACT_APP_BASE_URL}/user/v2/org/showcase/post/${id}/remove`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios(config);
    console.log("Res ==== ", res);
    if (res.status !== 200) {
      return;
    }

    setshowcaseData((prev) => {
      return prev.map((item) => {
        if (item._id !== id) {
          return item;
        }

        return { ...item, showcased: 0 };
      });
    });
  } catch (error) {
    ToastHandler("dan", "Error caught while deleting from showcase");
    console.log("Error caught while deleting from showcase ==== ", error);
  }
};

export const getEncryptedToken = (token, encryptionKey) => {
  try {
    const rsa = new NodeRSA(encryptionKey);
    const encryptedToken = rsa.encrypt(token, "base64");
    return encodeURIComponent(encryptedToken);
  } catch (error) {
    return "";
  }
};
