// Used for the toggle of the left sidebar

import {
  MAKE_FULLSCREEN,
  MAKE_SMALLSCREEN,
} from "../../actions/toggleSidebarAction";

const initialState = { isLeftbarSmall: false };

const toggleHomeButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_FULLSCREEN:
      return { isLeftbarSmall: true };
    case MAKE_SMALLSCREEN:
      return { isLeftbarSmall: false };
    default:
      return state;
  }
};

export default toggleHomeButtonReducer;
