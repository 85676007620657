import Hls from "hls.js";
import React, { useEffect } from "react";

// Styles
import { AspectRatio, Box } from "@mui/joy";
import moment from "moment";
import { useDispatch } from "react-redux";
import { m3u8_controls, m3u8_controls_d } from "../../data";
import { changePlayingStateOfM3U8 } from "../../redux/actions/m3u8Action";
import "./style.css";

export default function M3U8Player(props) {
  const {
    muteFlag,
    src,
    increaseViewerCount,
    rt_data,
    current_user,
    label = "",
    videoElem,
    setVideoTimer,
    setLoading,
    pauseVid,
    className,
    setHideRT,
    onUploadLoadMetaData,
    owner_details,
    open_to_all,
    isMuted,
    id,
    autoPlay,
    hidden = false,
    endFunc,
    start = "",
    fillerImg,
  } = props;

  let optionFlag =
    owner_details?.username === current_user?.username ||
    (rt_data?.moderator?.username === current_user?.username &&
      rt_data?.moderator?.m_type === "co-owner");

  const dispatch = useDispatch();

  useEffect(() => {
    const video = document.getElementById(id);
    const defaultOptions = {};

    const ownPubflag =
      owner_details?.username == current_user?.username &&
      open_to_all == "public";

    const cont = label == "" ? m3u8_controls : [];
    const notListing = ownPubflag ? m3u8_controls_d : cont;

    const liveAuto = label === "live_rt" ? autoPlay : false;

    defaultOptions.controls =
      label !== "listing" && label !== "live_rt" ? notListing : [];

    defaultOptions.clickToPlay =
      label === "live_rt" || (label == "upload" && !optionFlag) ? false : true;

    defaultOptions.muted = label === "listing" ? true : false;

    defaultOptions.disableContextMenu =
      label == "upload" && !optionFlag ? true : false;

    defaultOptions.autoplay =
      label == "" || (label == "upload" && optionFlag) ? true : liveAuto;

    defaultOptions.ratio = "16:9";

    defaultOptions.loop = {
      active: label === "listing" ? true : false,
    };
    const hls = new Hls({
      maxBufferSize: 60 * 1000 * 1000, // Adjust buffer size as needed
      maxMaxBufferLength: 5, // Maximum buffer length in seconds
      maxBufferLength: 30,
      maxStarvationDelay: 4,
      capLevelToPlayerSize: true, // Cap level to player size for better performance
      autoLevelEnabled: true, // Enable automatic quality level selection
    });
    if (
      label === "" ||
      label === "live_rt" ||
      (label === "listing" && muteFlag) ||
      label === "upload"
    ) {
      if (src?.includes("m3u8")) {
        hls.loadSource(src);

        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          const availableQualities = hls.levels
            ?.map((l) => l.height)
            ?.filter((q) => q && q <= 720) // Filter out all valid qualities less then 720
            ?.sort((a, b) => a - b); // Sort from lowest to highest quality
          defaultOptions.quality = {
            default: availableQualities[0],
            options: availableQualities,
            forced: true,
            onChange: (e) => updateQuality(e),
          };
          new Plyr(video, defaultOptions);
          if (
            (window.location.href.includes("join") && label !== "listing") ||
            (window.location.href.includes("recorded") &&
              window.location.href.split("/").pop() === rt_data?._id)
          ) {
            video.muted = false;
          } else {
            video.muted = true;
          }
        });
        hls.attachMedia(video);
        window.hls = hls;
      } else {
        video.src = src;
        video.addEventListener("loadedmetadata", () => {
          video.play();
        });
        new Plyr(video, defaultOptions);
      }
    }
    video.play();
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src]);

  function updateQuality(newQuality) {
    window.hls.levels.forEach((level, levelIndex) => {
      if (level.height == newQuality) {
        window.hls.currentLevel = levelIndex;
      }
    });
  }

  function onLoadedMetadata() {
    videoElem?.current?.play();
    switch (label) {
      case "listing":
        videoElem.current.currentTime =
          Math.round(videoElem?.current?.duration) < 330 ? 0 : 300;
        break;
      case "upload":
        onUploadLoadMetaData();
        break;
      case "live_rt":
        videoElem.current.currentTime = moment(new Date()).diff(
          new Date(start),
          "seconds"
        );
        break;
      default:
        break;
    }
  }

  function onLoadedData() {
    if (label === "") {
      setLoading(false);
      setVideoTimer(videoElem?.current?.duration);
      setTimeout(increaseViewerCount, 5000);
    }
  }

  function onPlaying() {
    if (label === "listing") {
      const timer = setInterval(() => {
        if (Math.round(videoElem?.current?.duration) < 330) {
          if (Math.round(videoElem?.current?.currentTime) === 30)
            videoElem.current.currentTime = 0;
          videoElem.current.muted = true;
        } else {
          if (Math.round(videoElem?.current?.currentTime - 300) === 30)
            videoElem.current.currentTime = 300;
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    } else if (label == "") {
      setHideRT(true);
    }
  }

  function onEnded() {
    switch (label) {
      case "listing":
        pauseVid();
        break;
      case "live_rt":
        endFunc();
        break;
      case "":
        setHideRT(false);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    // To know if the video is in playing state or paused

    const el = document.getElementById(id);

    el.addEventListener("play", () => {
      dispatch(changePlayingStateOfM3U8(true));
    });

    el.addEventListener("pause", () => {
      dispatch(changePlayingStateOfM3U8(false));
    });

    return () => {
      el.removeEventListener("play", () => {
        dispatch(changePlayingStateOfM3U8(true));
      });

      el.removeEventListener("pause", () => {
        dispatch(changePlayingStateOfM3U8(false));
      });
    };
  }, []);
  const getVideo = () => (
    <div
      className="hlsDiv"
      style={{
        width:
          label === "live_rt" || className === "Embed_video_player"
            ? "100%"
            : "",
      }}
      data-test="src-components-M3U8Player-index-0"
    >
      <video
        onContextMenu={(e) => e.preventDefault()}
        disablecontextmenu="true"
        ref={videoElem}
        id={id}
        style={{ height: "100%" }}
        hidden={hidden}
        autoPlay={true}
        className={className}
        onLoadedMetadata={onLoadedMetadata}
        onLoadedData={onLoadedData}
        onPlaying={onPlaying}
        onEnded={onEnded}
        poster={fillerImg}
        crossOrigin="anonymous"
        muted={isMuted ? isMuted : false}
        onMouseUp={onPlaying}
      />
    </div>
  );
  return id === "past_video" ? (
    <>
      <Box data-test="src-components-M3U8Player-index-1">
        <AspectRatio objectFit="contain">{getVideo()}</AspectRatio>
      </Box>
    </>
  ) : (
    getVideo()
  );
}
