import { getProfile } from "../../../apis/profileApi";
import * as actionTypes from "../actionTypes";

export const userProfileStart = () => {
  return {
    type: actionTypes.USER_PROFILE_START,
  };
};

export const userProfileSuccess = (data) => {
  return {
    type: actionTypes.USER_PROFILE_SUCCESS,
    payload: data,
  };
};

export const userProfileFail = (err) => {
  return {
    type: actionTypes.USER_PROFILE_FAIL,
    payload: err,
  };
};

export const userProfileHeaderUpdate = (data) => {
  return {
    type: actionTypes.PROFILE_HEADER_SUCCESS,
    payload: data,
  };
}

export const userPointsCalculation = (data) => {
  return {
    type: actionTypes.PROFILE_POINT_CALCULATION,
    payload: data
  }
}

export const userPointsData = (data) => {
  return {
    type: actionTypes.PROFILE_POINTS_DATA,
    payload: data
  }
}

export const userProfileResponseData = (data) => {
  return {
    type: actionTypes.USER_PROFILE_RESPONSE_DATA,
    payload: data
  }
}

export const userProfileLoading = (data) => {
  return {
    type: actionTypes.UPDATE_PROFILE_RESPONSE_LOADING,
    payload: data
  }
}


export const userProfileError = (data) => {
  return {
    type: actionTypes.UPDATE_PROFILE_ERROR,
    payload: data
  }
}

export const userProfileBlock = (data) => {
  return {
    type: actionTypes.UPDATE_PROFILE_BLOCK,
    payload: data
  }
}

export const userProfileNotFound = (data) => {
  return {
    type: actionTypes.UPDATE_PROFILE_NOT_FOUND,
    payload: data
  }
}


export const userMutualFriends = (data) => { 
  return {
    type: actionTypes.PROFILE_MUTUAL_FRIENDS,
    payload: data
  }
}

export const userProfileData = (data) => {
  return (dispatch) => {
    dispatch(userProfileStart());
    getProfile(data)
      .then((res) => {
        dispatch(userProfileSuccess(res.data));
      })
      .catch((err) => {
        dispatch(userProfileFail(err));
      });
  };
};
