import axios from "axios";
import {
  REACT_APP_BASE_URL_FOR_ROUNDTABLE,
  REACT_APP_BASE_URL_FOR_ROUNDTABLE_V1,
} from "../constants/env";
import { auto_login_continue, checkUserStatus } from "../utils/utils";

const getScrollsPostsAPI = async (
  skip,
  type,
  exclude_post = "",
  profilePath = ""
) => {
  const token = localStorage.getItem("current_user")
    ? localStorage.getItem("access")
    : JSON.parse(localStorage.getItem("anonymous_user"))?.token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-API-Anonymous": localStorage.getItem("current_user") ? false : true,
    },
  };

  const params = {
    offset: skip,
    limit: 20,
    type,
  };

  if (profilePath !== "") {
    params["path"] = profilePath;
  }

  if (exclude_post !== "") {
    params["exclude_post"] = exclude_post;
  }

  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL_FOR_ROUNDTABLE}/v1/short-video/scrolls`,
      {
        headers: config.headers,
        params,
      }
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error
      return auto_login_continue(
        getScrollsPostsAPI(skip, type, (exclude_post = ""), (profilePath = ""))
      );
    } else {
      // Handle other errors
      throw error;
    }
  }
};

const addScrollsPostAPI = async (
  video,
  captionVal,
  startTime,
  endTime,
  timeDuration
) => {
  try {
    const message = {
      type: "BKK_POST",
      media_type: "VIDEO",
      text: captionVal,
      caption: [""],
      tags: [""],
      file_type: ["VIDEO"],
      start_duration: [`${startTime}`],
      end_duration: [`${endTime}`],
      duration: [`${timeDuration}`],
      trim: ["true"],
      is_snap: ["false"],
      is_recorded: ["false"],
    };

    const data = {
      video,
      message,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        "device-type": "android",
        "user-id": JSON.parse(localStorage.getItem("current_user"))._id,
      },
    };

    const response = await axios.post(
      `${POST_API_BASE_URL}/post-media/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (!error.response) return;
    if (error.response.status === 401) {
      return await auto_login_continue(addBKKPostAPI());
    }
    return error.response.data;
  }
};

const getSingleScrollsPostAPI = async (postId) => {
  const token = localStorage.getItem("current_user")
    ? localStorage.getItem("access")
    : JSON.parse(localStorage.getItem("anonymous_user"))?.token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-API-Anonymous": localStorage.getItem("current_user") ? false : true,
    },
  };
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL_FOR_ROUNDTABLE}/townhall/short-video/scroll/${postId}/share`,
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error
      return auto_login_continue(getSingleScrollsPostAPI(postId));
    } else {
      // Handle other errors
      throw error;
    }
  }
};

const getProfileScrollsPostAPI = async (user_id, skip, limit) => {
  try {
    const userStatus = checkUserStatus();
    const anonymousUser = JSON.parse(localStorage.getItem("anonymous_user"));
    const url = `${REACT_APP_BASE_URL_FOR_ROUNDTABLE_V1}/profile/scrolls/${user_id}?limit=${limit || 20}&offset=${skip}`;

    const headers = {
      Authorization: `Bearer ${anonymousUser ? anonymousUser.token : localStorage.access}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    };

    const response = await axios.get(url, { headers });
    return response;
  } catch (error) {
    console.error("Error in getProfileScrollsPostAPI:", error);
    throw error;
  }
};

const getUserScrollsPostAPI = async (user_id, skip, first_post_id) => {
  const userStatus = checkUserStatus();
  const config = {
    headers: {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    },
  };
  const data = {
    limit: 20,
    offset: skip,
    user_id,
  };

  if (first_post_id !== "") {
    data["first_post_id"] = first_post_id;
  }

  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL_FOR_ROUNDTABLE}/profile-click/scrolls`,
      data,
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error
      return auto_login_continue(getUserScrollsPostAPI(user_id, skip));
    } else {
      // Handle other errors
      throw error;
    }
  }
};

export {
  getScrollsPostsAPI,
  addScrollsPostAPI,
  getSingleScrollsPostAPI,
  getProfileScrollsPostAPI,
  getUserScrollsPostAPI,
};
