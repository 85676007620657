import React from "react";

export default function NoTables({ txt, img, styles }) {
  return (
    <div
      className="my-5 container-fluid text-center"
      data-test="src-components-ResponseData-NoTable-0"
    >
      {txt ? (
        <small
          className="warn-text"
          style={{ visibility: "hidden", userSelect: "none" }}
        >
          {txt}
        </small>
      ) : null}
      <img
        src={img}
        alt="this is image" 
        style={styles ? styles : null}
        data-test="src-components-ResponseData-NoTable-1"
      />
    </div>
  );
}
