// ** Components
import { RightSideContainer } from "./style";
import ContentCard from "../../components/ContentCard";
import PopularShows from "../PopularShows";
import HomeSearchContainer from "./HomeSearchContainer";

const RightSideBar = ({ showSearch, showRoundtabaleContent }) => {
  return (
    <RightSideContainer>
      {showSearch && <HomeSearchContainer />}
      {showRoundtabaleContent ? <ContentCard /> : null}
      {!window.location.pathname.includes("notifications") &&
        !window.location.pathname.includes("profile") &&
        !window.location.pathname.includes("personal_details") && (
          <PopularShows />
        )}
    </RightSideContainer>
  );
};

export default RightSideBar;
