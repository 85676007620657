import { STATIC_TOKEN } from "../constants/env";
import UserServiceInstanceV1 from "./instances/UserInstanceV1";

const signupOTPService = async (data) => {
  return UserServiceInstanceV1.post("/auth/send-otp", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: STATIC_TOKEN,
    },
  });
};

export { signupOTPService };
