import React, { useState, useEffect } from "react";
import { MOBILE_VIEW } from "../../constants/env";
import {
  Grid,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ArrowBack } from "@mui/icons-material";
import CloseIcon from "../../assets/icons/close_icon_white.svg";
import GreenCheck from "../../assets/icons/green_check.svg";

import { allWords } from "../../App";
import { moengageEvent } from "../../utils/utils";

import {
  closeStyle,
  backStyle,
  closeButtonStyle,
  submitButtonStyle,
  confirmCloseStyle,
  confirmDescStyle,
  actionButtonStyle,
  cancelButtonStyle,
  TextareaStyle,
  ReportOptionStyle,
  FurtherDiv,
  NameContainer,
  InfoDiv,
  TextDiv,
} from "./style";
import "./style.css";

import {
  POST_API_BASE_URL,
  REACT_APP_BASE_URL_FOR_USER,
  REACT_APP_BASE_URL_FOR_USER_V1,
} from "../../constants/env";

const ReportDialog = (props) => {
  const { reportOpen, setReportOpen, name, username, userId, contentId, type } =
    props;

  const [openReportDesc, setOpenReportDesc] = useState(false);
  const [openFurtherSteps, setOpenFurtherSteps] = useState(false);
  const [openBlockUser, setOpenBlockUser] = useState(false);
  const [openBlockConfirm, setOpenBlockConfirm] = useState(false);
  const [openUnfollowUser, setOpenUnfollowUser] = useState(false);
  const [openUnfollowConfirm, setOpenUnfollowConfirm] = useState(false);
  const [openMuteUser, setOpenMuteUser] = useState(false);
  const [openMuteConfirm, setOpenMuteConfirm] = useState(false);
  const [reportText, setReportText] = useState("");
  const [reportOption, setReportOption] = useState("");

  useEffect(() => {
    toast.configure();
  }, []);

  const customPaperProps = {
    sx: { borderRadius: "20px" },
    style: {
      backgroundColor: "#0E0817",
      color: "#FFFFFF",
      fontSize: "2rem",
      padding: "2rem 1.2rem 0rem 1.2rem",
    },
  };

  const handleBlock = () => {
    let data = JSON.stringify({
      handle: username,
      type: "block",
    });
    axios.post(`${REACT_APP_BASE_URL_FOR_USER}/user-action/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    });
  };

  const handleUnfollow = () => {
    let data = JSON.stringify({ handle: username, type: "unfollow" });
    let config = {
      method: "post",
      url: `${REACT_APP_BASE_URL_FOR_USER}/follow-friends/`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config).then().catch();
  };

  const handleMute = () => {
    const data = new FormData();
    data.append("operation_type", "MUTED");
    data.append("user", `${userId}`);

    const config = {
      method: "post",
      url: `${POST_API_BASE_URL}/post/${contentId}`,
      headers: {
        "device-type": "web",
        Authorization: `Bearer ${localStorage.access}`,
      },
      data: data,
    };

    axios(config).then().catch();
  };

  const handleReport = async () => {
    const data = JSON.stringify({
      type: type,
      content_id: contentId,
      report_text: reportText,
      report_option: reportOption,
    });

    let config = {
      method: "post",
      url: `${REACT_APP_BASE_URL_FOR_USER_V1}/report-content`,
      headers: {
        Authorization: `Bearer ${localStorage.access}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios(config);

    if (res.status === 200) {
      moengageEvent("Report", "User", {
        IdOth: userId,
        UsernameOth: username,
        NameOth: name,
      });
      toast.success("Reported successfully to the Khul Ke team", {
        position: "top-center",
      });
    } else {
      toast.error("Failed to send report", { position: "top-center" });
    }

    setReportText("");
    setReportOption("");
  };

  const handleOptionClick = (type) => {
    setReportOption(type);
    setOpenReportDesc(true);
  };

  return (
    <div data-test="src-components-ReportDialog-index-0">
      <Dialog
        open={reportOpen}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-1"
      >
        <DialogTitle align="center">
          {allWords.report.report}
          <Button
            onClick={() => {
              setReportOpen(false);
            }}
            style={closeStyle}
            data-test="src-components-ReportDialog-index-2"
          >
            <img
              src={CloseIcon}
              data-test="src-components-ReportDialog-index-3"
            ></img>
          </Button>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{ textAlign: "left", padding: "0px", overflow: "hidden" }}
        >
          <p
            style={{
              fontSize: "1.5rem",
              left: "1rem",
              top: "0.4rem",
              position: "relative",
              paddingRight: "100px",
            }}
            data-test="src-components-ReportDialog-index-4"
          >
            {allWords.report.whyReport}
          </p>
          <Divider />
          <div
            onClick={() => handleOptionClick("It's Spam")}
            style={ReportOptionStyle}
            data-test="src-components-ReportDialog-index-5"
          >
            {allWords.report.spam}
          </div>
          <Divider />
          <div
            onClick={() => handleOptionClick("False Information")}
            style={ReportOptionStyle}
            data-test="src-components-ReportDialog-index-6"
          >
            {allWords.report.falseInfo}
          </div>
          <Divider />
          <div
            onClick={() => handleOptionClick("Hate Speech")}
            style={ReportOptionStyle}
            data-test="src-components-ReportDialog-index-7"
          >
            {allWords.report.hateSpeech}
          </div>
          <Divider />
          <div
            onClick={() => handleOptionClick("Scam or Fraud")}
            style={ReportOptionStyle}
            data-test="src-components-ReportDialog-index-8"
          >
            {allWords.report.scamFraud}
          </div>
          <Divider />
          <div
            onClick={() => handleOptionClick("Suicide or self harm")}
            style={ReportOptionStyle}
            data-test="src-components-ReportDialog-index-9"
          >
            {allWords.report.suicideSelfHarm}
          </div>
          <Divider />
          <div
            onClick={() => handleOptionClick("Nudity or sexual activity")}
            style={{ ...ReportOptionStyle, marginBottom: "25px" }}
            data-test="src-components-ReportDialog-index-10"
          >
            {allWords.report.nuditySexualActivity}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openReportDesc}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-11"
      >
        <DialogTitle align="center">
          <ArrowBack
            style={backStyle}
            onClick={() => {
              setOpenReportDesc(false);
              setReportOpen(true);
            }}
          />
          <span
            style={{
              paddingLeft: MOBILE_VIEW ? "50px" : "200px",
              paddingRight: MOBILE_VIEW ? "50px" : "200px",
            }}
            data-test="src-components-ReportDialog-index-12"
          >
            {allWords.report.report}
          </span>
          <Button
            onClick={() => {
              setOpenReportDesc(false);
            }}
            style={closeStyle}
            data-test="src-components-ReportDialog-index-13"
          >
            <img
              src={CloseIcon}
              data-test="src-components-ReportDialog-index-14"
            ></img>
          </Button>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ textAlign: "center", fonSize: "2rem" }}>
          <Grid container data-test="src-components-ReportDialog-index-15">
            <Grid item xs={12} data-test="src-components-ReportDialog-index-16">
              <TextDiv>
                <Textarea
                  minRows={8}
                  placeholder={allWords.report.pleaseElaborate}
                  style={TextareaStyle}
                  onChange={(e) => {
                    setReportText(e.target.value);
                  }}
                />
              </TextDiv>
            </Grid>
            <Grid item xs={12} data-test="src-components-ReportDialog-index-17">
              <Button
                onClick={() => {
                  setOpenReportDesc(false);
                  handleReport();
                  setOpenFurtherSteps(true);
                }}
                style={submitButtonStyle}
                data-test="src-components-ReportDialog-index-18"
              >
                {allWords.report.submit}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openFurtherSteps}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-19"
      >
        <DialogTitle align="center">
          <p data-test="src-components-ReportDialog-index-20">
            <img
              src={GreenCheck}
              data-test="src-components-ReportDialog-index-21"
            ></img>
          </p>
          <p data-test="src-components-ReportDialog-index-22">
            <b>{allWords.report.thankYou}</b>
          </p>
          <p
            style={{ color: "#A2A2A2" }}
            data-test="src-components-ReportDialog-index-23"
          >
            {allWords.report.yourFeedback}
          </p>
        </DialogTitle>
        <Grid data-test="src-components-ReportDialog-index-24">
          <Grid data-test="src-components-ReportDialog-index-25">
            <Divider />
            <FurtherDiv>{allWords.report.furtherSteps}</FurtherDiv>
            <Divider />
          </Grid>

          <Grid
            style={{ marginTop: "1rem" }}
            data-test="src-components-ReportDialog-index-26"
          >
            <NameContainer
              className="blockUser"
              onClick={() => {
                setOpenBlockUser(true);
              }}
            >
              {allWords.report.block} {name}
            </NameContainer>
            <InfoDiv>
              @{username} {allWords.report.blockDesc} @{username}
            </InfoDiv>
            <Divider />
          </Grid>

          <Grid
            style={{ marginTop: "1rem" }}
            data-test="src-components-ReportDialog-index-27"
          >
            <NameContainer
              className="unfollowUser"
              onClick={() => {
                setOpenUnfollowUser(true);
              }}
            >
              {allWords.report.unfollow} {name}
            </NameContainer>
            <InfoDiv>
              {allWords.report.unfollowDesc} @{username}
            </InfoDiv>
            <Divider />
          </Grid>

          <Grid
            style={{ marginTop: "1rem" }}
            data-test="src-components-ReportDialog-index-28"
          >
            <NameContainer
              className="muteUser"
              onClick={() => {
                setOpenMuteUser(true);
              }}
            >
              {allWords.report.mute} {name}
            </NameContainer>
            <InfoDiv>
              {allWords.report.muteDesc1} @{username}{" "}
              {allWords.report.muteDesc2}
            </InfoDiv>
            <Divider />
          </Grid>
          <Button
            onClick={() => {
              setReportOpen(false);
              setOpenFurtherSteps(false);
            }}
            style={closeButtonStyle}
            data-test="src-components-ReportDialog-index-29"
          >
            {allWords.report.close}
          </Button>
        </Grid>
      </Dialog>
      <Dialog
        open={openBlockUser}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-30"
      >
        <DialogTitle
          style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "300" }}
        >
          {allWords.report.block} <b style={{ fontWeight: "600" }}>{name}</b>
        </DialogTitle>
        <DialogContent
          style={{
            textAlign: "center",
            fontSize: "1em",
            paddingRight: "3rem",
            paddingLeft: "3rem",
          }}
        >
          <Button
            onClick={() => {
              handleBlock();
              setOpenFurtherSteps(false);
              setOpenBlockUser(false);
              setOpenBlockConfirm(true);
            }}
            style={actionButtonStyle}
            data-test="src-components-ReportDialog-index-31"
          >
            {allWords.report.block}
          </Button>
          <Button
            onClick={() => {
              setReportOpen(false);
              setOpenFurtherSteps(false);
              setOpenBlockUser(false);
            }}
            style={cancelButtonStyle}
            data-test="src-components-ReportDialog-index-32"
          >
            {allWords.report.cancel}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openBlockConfirm}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-33"
      >
        <DialogTitle
          align="center"
          style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "300" }}
        >
          {allWords.report.youBlocked}{" "}
          <b style={{ fontWeight: "600" }}>{name}</b>
        </DialogTitle>
        <DialogContent style={confirmDescStyle}>
          <Grid data-test="src-components-ReportDialog-index-34">
            {allWords.report.canUnblock}
          </Grid>
          <Grid data-test="src-components-ReportDialog-index-35">
            <Button
              onClick={() => {
                setReportOpen(false);
                setOpenFurtherSteps(false);
                setOpenBlockUser(false);
                setOpenBlockConfirm(false);
              }}
              style={confirmCloseStyle}
              data-test="src-components-ReportDialog-index-36"
            >
              {allWords.report.close}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openUnfollowUser}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-37"
      >
        <DialogTitle
          align="center"
          style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "300" }}
        >
          {allWords.report.unfollow} <b style={{ fontWeight: "600" }}>{name}</b>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              setOpenFurtherSteps(false);
              setOpenUnfollowConfirm(true);
            }}
            style={actionButtonStyle}
            data-test="src-components-ReportDialog-index-38"
          >
            {allWords.report.unfollow}
          </Button>
          <Button
            onClick={() => {
              setReportOpen(false);
              setOpenFurtherSteps(false);
              setOpenBlockUser(false);
            }}
            style={cancelButtonStyle}
            data-test="src-components-ReportDialog-index-39"
          >
            {allWords.report.cancel}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openUnfollowConfirm}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-40"
      >
        <DialogTitle
          align="center"
          style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "300" }}
        >
          {allWords.report.youUnfollowed}{" "}
          <b style={{ fontWeight: "600" }}>{name}</b>
        </DialogTitle>
        <DialogContent style={confirmDescStyle}>
          <Grid data-test="src-components-ReportDialog-index-41">
            {allWords.report.canFollow}
          </Grid>
          <Grid data-test="src-components-ReportDialog-index-42">
            <Button
              onClick={() => {
                handleUnfollow();
                setOpenFurtherSteps(false);
                setOpenUnfollowUser(false);
                setOpenUnfollowConfirm(false);
              }}
              style={confirmCloseStyle}
              data-test="src-components-ReportDialog-index-43"
            >
              {allWords.report.close}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMuteUser}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-44"
      >
        <DialogTitle
          align="center"
          style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "300" }}
        >
          {allWords.report.mute} <b style={{ fontWeight: "600" }}>{name}</b>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              handleMute();
              setOpenFurtherSteps(false);
              setOpenMuteUser(false);
              setOpenMuteConfirm(true);
            }}
            style={actionButtonStyle}
            data-test="src-components-ReportDialog-index-45"
          >
            {allWords.report.mute}
          </Button>
          <Button
            onClick={() => {
              setReportOpen(false);
              setOpenFurtherSteps(false);
              setOpenMuteUser(false);
            }}
            style={cancelButtonStyle}
            data-test="src-components-ReportDialog-index-46"
          >
            {allWords.report.cancel}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMuteConfirm}
        PaperProps={customPaperProps}
        data-test="src-components-ReportDialog-index-47"
      >
        <DialogTitle
          align="center"
          style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "300" }}
        >
          {allWords.report.youMuted} <b style={{ fontWeight: "600" }}>{name}</b>
        </DialogTitle>
        <DialogContent style={confirmDescStyle}>
          <Grid data-test="src-components-ReportDialog-index-48">
            {allWords.report.canUnmute}
          </Grid>
          <Grid data-test="src-components-ReportDialog-index-49">
            <Button
              onClick={() => {
                setReportOpen(false);
                setOpenFurtherSteps(false);
                setOpenBlockUser(false);
                setOpenMuteConfirm(false);
              }}
              style={confirmCloseStyle}
              data-test="src-components-ReportDialog-index-50"
            >
              {allWords.report.close}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReportDialog;
