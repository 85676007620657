import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { allWords } from "../../App";
import "./welcome3.css";

import { Done } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import axios from "axios";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/icons/edit.svg";
import editProfile from "../../assets/icons/editProfile.svg";
// import defaultUser from "../../assets/images/default_user.png";
import {
  POST_API_BASE_URL,
  REACT_APP_BASE_URL_FOR_USER,
  STATIC_TOKEN,
} from "../../constants/env";
import logger from "../../logger";
import { toggleSignUpFlag } from "../../redux/actions/compActions";
import { userProfileData } from "../../redux/actions/profileAction/userProfileAction";
import ToastHandler from "../../utils/ToastHandler";
import {
  auto_login_continue,
  device_info,
  moengageEvent,
} from "../../utils/utils";
import EditImageWelcome from "./EditImagWelcome";
import { globalImages } from "../../assets/imagesPath/images";
import logo from "../../assets/images/logo.svg";
import signupimg from "../../assets/images/signup.svg";
import outerImg from "../../assets/images/outer border.svg";
import defaultUser from "../../assets/images/inner image.svg";
import footerImg from "../../assets/images/footer-img.svg";

export default function Welcome3({
  welcomeScreen,
  fromHome = false,
  userData,
  setUserDialog,
  setSuccessDialog,
  hasUpdatedUsername,
}) {
  const [lang, setLang] = useState("English");
  const [languages, setLanguages] = useState([
    "English",
    "Hindi",
    "Tamil",
    "Marathi",
  ]);

  const handleChange = (e) => {
    const { value } = e.target;
    setLang(value);

    const ind = languages.indexOf(value);

    const tempLangs = [...languages];

    let slicedArr = tempLangs.splice(0, ind);
    tempLangs.splice(1, 0, ...slicedArr);
    setLanguages(tempLangs);
  };

  // vars
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let us = JSON.parse(localStorage.getItem("current_user"));

  //states here
  const [editUsername, setEditUsername] = useState(false);
  const [editProfileImage, setEditProfileImage] = useState();

  let upload_image = localStorage.getItem("upload_profile");
  const [avatar, setAvatar] = useState(upload_image || defaultUser);
  const [tempImage, setTempImage] = useState([]);
  const [profileMessage, setProfileMessage] = useState({
    type: "default",
    message: "Add your best pic here",
  });

  const [usernameMessage, setUsernameMessage] = useState({
    type: "default",
    // message: "Want to change your username?",
    message: "",
  });

  const [EditNameMessage, setEditNameMessage] = useState({
    type: "",
    message: "",
  });

  const [username, setUsername] = useState(us?.username || "");
  const [userForInput, setUserForInput] = useState(username);
  const [openEditor, setOpenEditor] = useState(false);
  const [updatingUsername, setUpdatingUsername] = useState(false);
  const [updatingImage, setUpdatingImage] = useState(false);
  const [validUsername, setValidUsername] = useState(true);
  // Refs
  const imageInputRef = useRef();

  useEffect(() => {
    //todo: temporary for beta deployment, not redirect to home page;
    // if (!us) {
    //   sessionStorage.clear();

    //   navigate("/");
    // }
    // if (us && !sessionStorage.getItem("showWelcome")) {
    //   navigate("/home");
    // }

    axios
      .get(avatar)
      .then((res) => {
        if (res.status !== 200) {
          setAvatar(defaultUser);
        }
      })
      .catch((err) => {
        if (
          err.response === undefined &&
          window.location.href.includes("welcome")
        ) {
          setAvatar(defaultUser);
        }
      });
  }, []);
  const editRef = useRef(null);

  // handler
  async function passFunc() {
    let url = `${REACT_APP_BASE_URL_FOR_USER}/check_passwordpresent/`;
    const data = {
      user_data: JSON.parse(localStorage.getItem("current_user"))["username"],
    };
    var config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: STATIC_TOKEN,
      },
      data: data,
    };

    await axios(config)
      .then((res) => {
        if (res.data.data[0].pflag === false) {
          // window.location.replace("/")
          navigate("/setPass");
        }
        if (res.data.data[0].pflag === true) {
          navigate("/oldpass");
        }
        sessionStorage.removeItem("showWelcome");
      })
      .catch((err) => {
        logger.error(err);
      });
  }

  useEffect(() => {
    setUsername(us?.username);
  }, [editUsername]);

  useEffect(() => {
    imageInputRef.current.value = "";

    if (!editProfileImage) {
      return setTempImage([]);
    }

    setTempImage([
      { file: editProfileImage, url: URL.createObjectURL(editProfileImage) },
    ]);
  }, [editProfileImage]);

  //updateprofile image function
  const updateProfileImg = (file) => {
    if (!file) return;
    setUpdatingImage(true);
    var reader = new FileReader();
    reader.onloadend = function () {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("image", file, "profile.jpg");
      data.append("type", "profile_photo");
      // data.append("image_encode", reader.result.split("base64,")[1]);
      var config = {
        method: "post",
        url: `${REACT_APP_BASE_URL_FOR_USER}/upload_image/`,
        headers: {
          Authorization: `Bearer ${localStorage.access}`,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setEditProfileImage();
          imageInputRef.current.value = "";
          setUpdatingImage(false);
          if (response.status === 200) {
            ToastHandler("sus", "Your profile picture has been updated.");
            return setProfileMessage({
              message: "Your profile picture has been updated",
              type: "success",
            });
          }

          if (response.status === 252 || response.status === 256) {
            ToastHandler("dan", response.message);
          }

          setProfileMessage({
            message: "Something went wrong, please try again",
            type: "success",
          });

          ToastHandler("dan", "Something went wrong, please try again.");
          localStorage.removeItem("upload_profile");
          setAvatar(defaultUser);
          //todo: temporary change.
          // setAvatar(
          //   `${POST_API_BASE_URL}/profile-photo/${us?.["username"]}/pp`
          // );
        })
        .catch(async function (e) {
          console.log();
          setAvatar(
            `${POST_API_BASE_URL}/profile-photo/${us?.["username"]}/pp`
          );
          setUpdatingImage(false);
          imageInputRef.current.value = "";
          const res = e.response;
          setEditProfileImage();

          if (!res)
            return setProfileMessage({
              message: "Something went wrong, please try again",
              type: "error",
            });

          if (res.status === 401) {
            return await auto_login_continue(() => updateProfileImg(file));
          }

          return setProfileMessage({
            message: "Something went wrong, please try again",
            type: "error",
          });
        });
    };
    reader.readAsDataURL(file);
  };

  const updateUsernameAndProfileName = async () => {
    let username_value = username.trim();
    if (username_value === us?.username) {
      return setEditNameMessage({
        message: "Nothing changed",
        type: "error",
      });
    } else {
      const updateName = async () => {
        let data = { name: username_value };
        let config = {
          method: "put",
          url: `${REACT_APP_BASE_URL_FOR_USER}/edit_profile/`,
          headers: {
            Authorization: `Bearer ${us?.access}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        try {
          const response = await axios(config);
          if (response.status === 200) {
            localStorage.removeItem("upload_profile");
            localStorage.setItem(
              "current_user",
              JSON.stringify({
                ...us,
                username: username_value,
                name: username_value,
                hasChangedUsername: true,
              })
            );

            let join_rt = localStorage.join_rt;
            if (join_rt) {
              join_rt = JSON.parse(join_rt);
              const updatedJoinRT = {
                ...join_rt,
                uid: username_value,
              };
              localStorage.setItem("join_rt", JSON.stringify(updatedJoinRT));
            }

            setUsernameMessage({
              message: "Your Username has been updated",
              type: "success",
            });

            continueBtnHandler();
            if (fromHome == false) dispatch(toggleSignUpFlag(true));
          } else {
            setUsernameMessage({
              message: "Username updated but could not update profile name",
              type: "error",
            });
          }
          setEditUsername(false);
        } catch (e) {
          setEditUsername(false);
          const res = e.response;
          setUsernameMessage({
            message: "Username updated but could not update profile name",
            type: "error",
          });
          if (!res) return;
          if (res.status === 401) {
            auto_login_continue(() => {
              return updateName();
            });
          }
          return res;
        }
      };

      var data = JSON.stringify({
        device_info: device_info,
        new_username: username_value,
        old_user_name: us?.username,
        store_old_username: false,
        restore_flag: false,
      });

      var config = {
        method: "post",
        url: `${REACT_APP_BASE_URL_FOR_USER}/edit_username/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.access}`,
        },
        data: data,
      };

      try {
        const response = await axios(config);
        if (response.data.status === 400) {
          return setEditNameMessage({
            message: response.data.message,
            type: "error",
          });
        }
        if (response.status === 200) {
          localStorage.setItem(
            "current_user",
            JSON.stringify({
              ...us,
              username: username_value,
              hasChangedUsername: true,
            })
          );
          let join_rt = localStorage?.join_rt;
          if (join_rt) {
            join_rt = JSON.parse(join_rt);
            const updatedJoinRT = {
              ...join_rt,
              uid: username_value,
            };
            localStorage.setItem("join_rt", JSON.stringify(updatedJoinRT));
          }

          updateName();
          moengageEvent("Update Username", "User", {
            Username: username_value,
          });
          if (fromHome == false) dispatch(toggleSignUpFlag(true));
        } else {
          setUsernameMessage({
            message: "Something went wrong! Please try again",
            type: "error",
          });
        }
      } catch (e) {
        const res = e.response;
        setUsernameMessage({
          message: "Something went wrong! Please try again",
          type: "error",
        });
        if (!res) return;
        if (res.status === 401) {
          auto_login_continue(() => {
            return updateUsernameAndProfileName();
          });
        }
        return res;
      }
    }
  };

  const VerifyUsername = async (cancelToken) => {
    setValidUsername(false);
    let regexp =
      /^[A-Za-z0-9\u0900-\u097F\u0980-\u09FF\u0A00-\u0A7F\u0A80-\u0AFF\u0B00-\u0B7F\u0B80-\u0BFF\u0C00-\u0C7F\u0C80-\u0CFF\u0D00-\u0D7F\u4e00-\u9eff\u3040–\u309F\u30A0–\u30FF]*[._-]?[a-zA-Za-z0-9\u0900-\u097F\u0980-\u09FF\u0A00-\u0A7F\u0A80-\u0AFF\u0B00-\u0B7F\u0B80-\u0BFF\u0C00-\u0C7F\u0C80-\u0CFF\u0D00-\u0D7F\u4e00-\u9eff\u3040–\u309F\u30A0–\u30FF]*[._-]?[a-zA-Za-z0-9\u0900-\u097F\u0980-\u09FF\u0A00-\u0A7F\u0A80-\u0AFF\u0B00-\u0B7F\u0B80-\u0BFF\u0C00-\u0C7F\u0C80-\u0CFF\u0D00-\u0D7F\u4e00-\u9eff\u3040–\u309F\u30A0–\u30FF]*$/gu;
    let username_value = username.trim();
    if (username_value === us?.username) {
      setValidUsername(true);
      return setEditNameMessage({
        message: "",
        type: "",
      });
    }
    if (username_value === "") {
      setEditNameMessage({
        message: "Username can not be empty",
        type: "error",
      });
    } else if (username_value?.length < 3) {
      setEditNameMessage({
        message: "Username must be atlease 3 characters long",
        type: "error",
      });
    } else if (username_value?.length > 15) {
      setEditNameMessage({
        message: "Username should be less than or equal to 15 letters",
        type: "error",
      });
    } else if (!regexp.test(username_value) || username_value.includes("@")) {
      setEditNameMessage({
        message: " Use only letters, numbers, . , - and _",
        type: "error",
      });
    } else {
      var data = JSON.stringify({
        username: username_value,
      });

      var config = {
        method: "post",
        url: `${REACT_APP_BASE_URL_FOR_USER}/user-search/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: STATIC_TOKEN,
        },
        data: data,
        cancelToken: cancelToken.token,
      };

      try {
        const response = await axios(config);
        if (response.status === 200 && response.data["status"] === 200) {
          setValidUsername(true);
          return setEditNameMessage({
            message: "Username is available",
            type: "success",
          });
        }

        if (response.status === 252 && response.data["status"] === 252) {
          return setEditNameMessage({
            message: "This username already exists",
            type: "error",
          });
        }
        setEditNameMessage({
          message: "Something went wrong, Please Try Again",
          type: "error",
        });
      } catch (e) {
        setEditNameMessage({
          message: "Something went wrong, Please Try Again",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    VerifyUsername(source);
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [username]);

  const [langApiResLoading, setLangApiResLoading] = useState(false);
  async function continueBtnHandler() {
    await update_profile_lang();
    if (welcomeScreen !== true) {
      navigate("/home");
    } else {
      if (fromHome === false) {
        window.location.reload();
      } else {
        setUserDialog({ flag: 1, data: userData, updated: true });
        setSuccessDialog(true);
        dispatch(userProfileData({ username: us?.["username"] }));
      }
    }
    sessionStorage.removeItem("showWelcome");
  }

  const update_profile_lang = async () => {
    let access = localStorage.getItem("access");

    const langVal = lang.toLowerCase().slice(0, 2);

    try {
      setLangApiResLoading(true);
      var config = {
        method: "POST",
        url: `${REACT_APP_BASE_URL_FOR_USER}/update-profile-language`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        data: { display_language: langVal },
      };
      const res = await axios(config);

      if (res.status == 200) {
        let locData = JSON.parse(localStorage.getItem("current_user"));
        locData.display_language = langVal;
        localStorage.setItem("current_user", JSON.stringify(locData));
      }
    } catch (err) {
      ToastHandler("dan", allWords.misc.somethingwrong);
      setLangApiResLoading(false);
      return logger.error(err.message);
    }
  };
  useEffect(() => {
    editRef?.current?.click();
  }, []);

  const cancelImageUpload = () => {
    setEditProfileImage();
    setTempImage([]);
    //todo if username not provided
    // setAvatar(`${POST_API_BASE_URL}/profile-photo/${us?.["username"]}/pp`);
    setAvatar(upload_image || defaultUser);
  };

  return (
    <div data-test="src-pages-Welcome-Welcome3-0">
      <div
        className="bg-theme-dark"
        container
        style={{
          width: welcomeScreen === true ? "100%" : "100vw",
          height: welcomeScreen === true ? "100%" : "100vh",
          position: welcomeScreen === true ? "fixed" : "",
          top: welcomeScreen ? "0px" : "",
          left: welcomeScreen ? "0px" : "",
          zIndex: welcomeScreen === true ? "1000" : "",
        }}
        justifyContent="center"
        alignItems={"center"}
        data-test="src-pages-Welcome-Welcome3-1"
      >
        <div
          className="signup-top py-3"
          data-test="src-pages-Welcome-Welcome3-2"
        >
          <div data-test="src-pages-Welcome-Welcome3-3"></div>
          <Link to="/home">
            <img
              src={logo}
              alt="logo"
              width={70}
              data-test="src-pages-Welcome-Welcome3-4"
            />
          </Link>
          <div
            className="launguage-dropdown"
            data-test="src-pages-Welcome-Welcome3-5"
          >
            <FormControl
              className="welcome-lang-select"
              fullWidth
              size="small"
              success
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                onChange={handleChange}
                style={{ borderRadius: "10px", color: "#6A779B" }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#0e0817",
                      borderRadius: "8px",
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "#6A779B15",
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "#6A779B15",
                      },
                      "& .MuiMenuItem-root.Mui-selected:hover": {
                        backgroundColor: "#6A779B15",
                      },
                    },
                  },
                }}
                data-test="src-pages-Welcome-Welcome3-6"
              >
                {languages.map((el) => (
                  <MenuItem
                    className="language-popup-list"
                    key={el}
                    value={el}
                    itemID={el}
                    style={{
                      color: lang === el ? "#66b984" : "#fff",
                      borderBottom: lang === el ? "1px solid #00000029" : " ",
                      padding: "10px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className="signup-box row mb-4"
          data-test="src-pages-Welcome-Welcome3-7"
        >
          <div
            className="col-md-6 col-sm-12"
            data-test="src-pages-Welcome-Welcome3-8"
          >
            <Grid data-test="src-pages-Welcome-Welcome3-9">
              <Box
                style={{
                  width: "80%",
                  aspectRatio: "1",
                  margin: "auto",
                  borderRadius: "50%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                data-test="src-pages-Welcome-Welcome3-10"
              >
                <img
                  src={outerImg}
                  className="avaterImage"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  alt="edit"
                  data-test="src-pages-Welcome-Welcome3-11"
                />
                {updatingImage && (
                  <div
                    className="avaterImage"
                    style={{
                      width: "64%",
                      height: "64%",
                      backgroundColor: "#2b2734",
                    }}
                    data-test="src-pages-Welcome-Welcome3-12"
                  >
                    {" "}
                  </div>
                )}
                {updatingImage ? (
                  <CircularProgress />
                ) : (
                  <img
                    src={avatar}
                    className="avaterImage"
                    style={{
                      width: "60%",
                      height: "60%",
                    }}
                    alt="edit"
                    data-test="src-pages-Welcome-Welcome3-13"
                  />
                )}
                <input
                  type="file"
                  id="welcome-profile-edit"
                  style={{ display: "none" }}
                  ref={imageInputRef}
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    const imageFile = e.target.files[0];
                    if (!imageFile) return;
                    if (
                      !imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)
                    ) {
                      ToastHandler(
                        "warn",
                        allWords.misc.toastMsg.invalidImgFormat
                      );
                      return false;
                    }

                    if (imageFile) {
                      for (let i = 0; i < e.target.files.length; i++) {
                        let size = e.target.files[i].size;
                        if (Math.round(size / 1024) > 1024 * 15) {
                          ToastHandler(
                            "warn",
                            "Upload an image less than 15 MB."
                          );
                          e.target.value = null;
                          return false;
                        } else {
                          setEditProfileImage(imageFile);
                          setOpenEditor(true);
                        }
                      }
                    }
                  }}
                  data-test="src-pages-Welcome-Welcome3-14"
                />
                {!updatingImage && (
                  <IconButton
                    style={{
                      position: "absolute",
                      bottom: "18%",
                      right: "18%",
                    }}
                    onClick={() => {
                      imageInputRef.current.click();
                    }}
                    data-test="src-pages-Welcome-Welcome3-15"
                  >
                    <img
                      src={editProfile}
                      width="80px"
                      alt="this is image"
                      data-test="src-pages-Welcome-Welcome3-16"
                    />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </div>
          <div
            className="welcomeInput col-md-6 col-sm-12"
            data-test="src-pages-Welcome-Welcome3-17"
          >
            <Typography
              variant={"h2"}
              className="text-light pb-2"
              style={{
                textAlign: "center",
                font: "normal normal bold 40px Work Sans",
                color: "#ffffff",
              }}
              data-test="src-pages-Welcome-Welcome3-18"
            >
              {allWords.welcomePop.welcome}
            </Typography>

            {!editUsername && (
              <Grid item xs={12} data-test="src-pages-Welcome-Welcome3-19">
                <Typography
                  style={{
                    textAlign: "center",
                    font: "normal normal 600 32px Work Sans",
                    color: "#54B798",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  data-test="src-pages-Welcome-Welcome3-20"
                >
                  @{username}
                  <IconButton
                    style={{
                      marginLeft: "5px",
                      borderRadius: "0px",
                    }}
                    ref={editRef}
                    onClick={() => {
                      setEditUsername((prev) => !prev);
                      setUserForInput("");
                    }}
                    data-test="src-pages-Welcome-Welcome3-21"
                  >
                    <img
                      src={editIcon}
                      alt="this is image"
                      data-test="src-pages-Welcome-Welcome3-22"
                    />
                  </IconButton>
                </Typography>
              </Grid>
            )}
            {editUsername && (
              <Grid
                item
                xs={12}
                className="py-3"
                data-test="src-pages-Welcome-Welcome3-23"
              >
                <Box
                  className="box-style"
                  data-test="src-pages-Welcome-Welcome3-24"
                >
                  <Input
                    type="text"
                    style={{ color: "#707070" }}
                    className={`input ${
                      window.location.pathname === "/home" ? "updateInput" : ""
                    } text-light`}
                    placeholder={
                      window.location.pathname === "/home"
                        ? allWords.welcomePop.updateUsername
                        : allWords.welcomePop.createUsername
                    }
                    value={userForInput}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      setUserForInput(e.target.value);
                    }}
                    disabled={updatingUsername}
                    inputProps={{ maxLength: 15 }}
                  />
                  <LoadingButton
                    style={{
                      backgroundColor: "#f4f4f4",
                      color: "black",
                      border: "4px",
                      textTransform: "capitalize",
                      height: "fit-content",
                      font: "normal normal 600 12px Work Sans",
                      letterSpacing: "0.6px",
                      padding: "5px 30px",
                      opacity:
                        updatingImage ||
                        !validUsername ||
                        userForInput.length < 1
                          ? "0.6"
                          : "1",
                    }}
                    disabled={
                      updatingImage || !validUsername || userForInput.length < 1
                    }
                    loadingIndicator={<CircularProgress size={16} />}
                    loading={updatingUsername}
                    onClick={() => {
                      updateUsernameAndProfileName();
                    }}
                  >
                    {allWords.misc.update}
                  </LoadingButton>
                </Box>
                {EditNameMessage.message && (
                  <Typography
                    className={`errorMessage ${
                      EditNameMessage.type === "error" ? "error" : ""
                    }`}
                    data-test="src-pages-Welcome-Welcome3-25"
                  >
                    {EditNameMessage.message}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              className="text-light py-2"
              data-test="src-pages-Welcome-Welcome3-26"
            >
              <Typography
                className="profileMessage text-light"
                data-test="src-pages-Welcome-Welcome3-27"
              >
                {allWords.welcomePop.addDP}
              </Typography>
              <Typography
                className="profileMessage languageMessage text-light"
                data-test="src-pages-Welcome-Welcome3-28"
              >
                {allWords.welcomePop.selectLang}
              </Typography>
            </Grid>
            <div className="py-2" data-test="src-pages-Welcome-Welcome3-29">
              <FormControl
                className="welcome-lang-select"
                fullWidth
                size="small"
                success
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lang}
                  onChange={handleChange}
                  style={{
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "#0e0817",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#0e0817",
                        borderRadius: "8px",
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#6A779B15",
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#6A779B15",
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: "#6A779B15",
                        },
                      },
                    },
                  }}
                  data-test="src-pages-Welcome-Welcome3-30"
                >
                  {languages.map((el) => (
                    <MenuItem
                      className="language-popup-list"
                      key={el}
                      value={el}
                      itemID={el}
                      style={{
                        color: lang === el ? "#66b984" : "#fff",
                        borderBottom: lang === el ? "1px solid #00000029" : " ",
                        padding: "10px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      {el}
                      {lang === el ? (
                        <Done
                          className="done-icon-select"
                          color="#66b984"
                          style={{ marginLeft: "5px" }}
                        />
                      ) : null}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Grid item xs={12} data-test="src-pages-Welcome-Welcome3-31">
              <div
                className="loginMessage text-light"
                data-test="src-pages-Welcome-Welcome3-32"
              >
                {allWords.welcomePop.interested}
                <br /> {allWords.welcomePop.kindly}{" "}
                <a
                  to="/setPass"
                  onClick={() => passFunc()}
                  data-test="src-pages-Welcome-Welcome3-33"
                >
                  {allWords.welcomePop.setPass}
                </a>
              </div>
            </Grid>
          </div>
        </div>
        <div className=" footer-sec" data-test="src-pages-Welcome-Welcome3-34">
          <img
            src={footerImg}
            height="auto"
            width="100%"
            alt="this is image"
            data-test="src-pages-Welcome-Welcome3-35"
          />
        </div>
      </div>
      <EditImageWelcome
        editImage={editProfileImage}
        setEditImage={setEditProfileImage}
        tempImage={tempImage}
        setTempImage={setTempImage}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        setAvatar={setAvatar}
        onUploadImage={updateProfileImg}
        onCancleUpload={cancelImageUpload}
      />
    </div>
  );
}
