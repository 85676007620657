import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import UserTagIcon from "../../assets/icons/user-tag.svg";
import LocationIcon from "../../assets/icons/add-location.svg";
import HashIcon from "../../assets/icons/hashtag.svg";
import CloseIcon from "../../assets/icons/Icon-ionic-ios-close-circle-outline.svg";
import closeIconWhite from "../../assets/icons/close-icon-white.svg";
import PhoneIcon from "../../assets/icons/phone-android.svg";
import { MOBILE_VIEW } from "../../constants/env";
const current_user = localStorage?.current_user
  ? JSON.parse(localStorage?.current_user)
  : {};

export const CustomContainer = styled.div`
  height: 84px;
  background: #ffffff;
  border-radius: 10px;
  margin: 0.613rem 0.313rem;
  opacity: 1;
  display: flex;
  align-items: center;
`;
export const LeftProfile = styled.div`
  margin-left: 25px;
  @media (max-width: 768px) {
    margin-left: 15px;
  }
`;
export const MiddleContainer = styled.div`
  width: 100%;
  height: 52px;
  background: #f2f2f2;
  border-radius: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  margin: 0px 10px;
  cursor: ${current_user?.is_expert_bkk ? "pointer" : ""};
  @media (max-width: 768px) {
    width: calc(100% - 100px);
    height: 44px;
    padding-left: 10px;
  }
  @media (max-width: 480px) {
    width: calc(100% - 80px);
    height: 34px;
    padding-left: 8px;
  }
`;
export const TextInsideMiddle = styled.p`
  minwidth: 138px;
  height: 19px;
  text-align: left;
  font: normal normal normal 16px/26px "Work Sans", sans-serif;
  letter-spacing: 0px;
  color: #7a7a7a;
  opacity: 1;
  margin-top: 8px;
  @media (max-width: 768px) {
    font-size: 14px;
    width: auto;
    height: auto;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
export const RightSVG = styled.div`
  margin-right: 2%;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;
export const VideoSelectionContainer = styled.div`
  width: 35em;
  height: auto;
  padding-left: 1em;
`;
export const StepOneContainer = styled.div`
  width: ${!MOBILE_VIEW ? "512px" : "280px"};
  height: ${!MOBILE_VIEW ? "429px" : "auto"};
  padding: ${MOBILE_VIEW ? "40px 1rem" : "80px 1rem"};
  background: transparent;
  margin: 1rem;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const SvgIcon = styled.div`
  margin-bottom: 3em;
  // margin: ${!MOBILE_VIEW ? "40px auto" : "20px auto"};
  text-align: center;
`;

export const UploadText = styled.p`
  text-align: center;
  font-size: normal normal medium 22px/20px "Work Sans";
  color: #fff;
`;

export const UploadButton = styled.button`
  padding: ${MOBILE_VIEW ? "6px 35px" : "6px 55px"};
  background: #f4f4f4;
  border-radius: 8px;
  border: none;
  color: #100a1a;
  font-weight: 600;
  font-size: ${MOBILE_VIEW ? "0.8rem" : "1.1rem"};

  &:hover {
    opacity: 0.8;
  }
`;
export const PostButton = styled.button`
  padding: 0.4em 1.5em;
  border-radius: 25px;
  font-size: 0.9rem;
  border: none;
  color: #100a1a;

  &:hover {
    opacity: 0.8;
  }
`;
export const StepTwoContainer = styled.div`
  width: 100%;
  max-height: 580px;
  background: transparent;
  opacity: 1;
  display: flex;
  align-items: center;
`;
export const ScrollCatContainer = styled.div`
  background-color: transparent;
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.8em;
  column-gap: 1em;
`;
export const ScrollCatWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7em 0.5em;
  border: 1px solid #817e8680;
  border-radius: 14px;
  cursor: pointer;
`;
export const ScrollCatRadioBtn = styled.div`
  margin-right: 0.8em;

  & span {
    display: block;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid;
    background-color: transparent;
    border-radius: 50%;
  }

  & img {
    width: 1.8em;
    height: 1.8em;
    object-fit: contain;
  }
`;
export const ScrollCatWrapperRight = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
  height: 100%;

  & img {
    width: auto;
    height: 70%;
  }

  & div {
    margin-left: 1em;
    text-align: left;
  }

  & h4 {
    color: #f4f4f4;
    font: normal normal bold 16px/16px Work Sans;
    margin-bottom: 4px;
  }

  & h6 {
    font: normal normal normal 12px/14px Work Sans;
    color: #a2a2a2;
    margin-bottom: 0;
  }
`;
export const VideoPlayerContainer = styled.div`
  width: 100%;
  height: 467px;
  background: #f2f1f2;
  border-radius: 0px 0px 0px 16px;
  opacity: 1;
  margin-left: 205px;
`;
export const TrimmerContainer = styled.div`
  flex: 1;
  /* Add your styles for the trimmer container here */
`;
export const FormPartContainer = styled.div`
border-left: 1px solid #A2A2A240;
margin-left: 1em;
min-height: 500px;
max-height: 100%;
position: relative;
`;
export const FormPartWrapper = styled.div`
  flex: 1;
  margin: 0.6rem 1rem 1rem 1rem;
  width: 418px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 4rem;
    margin-bottom: 0.1rem;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-top: 0.6rem;
    margin-bottom: 0.1rem;
  }
`;
export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: visible;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin: 0.6rem;
  }
`;
export const SkkInputContainer = styled.div`
  width: 418px;
  margin-left: 0.2rem;
  border-radius: 8px;
  padding: 4px;
  text-align: left;
  font: normal normal normal 14px/20px Work Sans;
  letter-spacing: 0px;
  color: #475376;
  opacity: 0.6;
  background: transparent 0% 0% no-repeat padding-box;

  @media (max-width: 768px) {
    width: 98%;
    height: 138px;
    padding: 8px;
    margin-left: 0.4rem;
  }
`;
export const PContainer = styled.p`
  margin: 0 0 0 4px;
  opacity: 0.6;
  text-align: end;
  color: white;
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.5vh;
  cursor: pointer;
  margin-bottom: "0.8em" & img {
    width: auto;
    height: 90%;
  }
`;
export const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;

  & img {
    width: auto;
    height: 100%;
  }

  & h5 {
    font-size: 1.1rem;
    margin-bottom: 0;
    margin-left: 0.4em;
  }

  & p {
    font-size: 0.8rem;
    margin-bottom: 0;
    margin-right: 0.6em;
  }
`;
export const Asterisk = styled.span`
color: red;
font-size: 1em;
margin-left: 0.2em;
}`;
export const StyledUserTagIcon = styled.div`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-image: url(${UserTagIcon});
  background-repeat: no-repeat;
`;
export const StyledLocationIcon = styled.div`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-image: url(${LocationIcon});
  background-repeat: no-repeat;
`;
export const StyledHashIcon = styled.div`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-image: url(${HashIcon});
  background-repeat: no-repeat;
`;
export const StyledPhoneIcon = styled.div`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-image: url(${PhoneIcon});
  background-repeat: no-repeat;
`;
export const HashInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%
`;
export const StyledHashInput = styled.input`
  box-sizing: border-box;
  padding: 7px 7px 7px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  font-size: 14px; // Set the font size
  color: #000000; // Set the text color
  opacity: 1; // Set the opacit

  &:focus {
    outline: none;
    border-color: none;
  }
  &::placeholder {
    text-align: left;
    font: normal normal normal 14px/16px Work Sans;
    letter-spacing: 0px;
    color: #63779c;
  }
`;
export const SkkStyledHashInput = styled.input`
  box-sizing: border-box;
  padding: 9px 7px 9px 10px;
  border: 1px solid #a2a2a24d;
  border-radius: 5px;
  width: 100%;
  font-size: 12px;
  color: #fff;
  background-color: #1a171f;
  &:focus {
    outline: none;
    border-color: none;
  }
  &::placeholder {
    text-align: left;
    font: normal normal normal 12px/14px Work Sans;
    letter-spacing: 0px;
    color: #a3a3a3;
  }
`;
export const HashtagList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  // background-color:#5e5c60 !important;
  justify-content: center;
  margin-top: "0.8em";
`;
export const HashtagItem = styled.div`
  background-color: #e3e3e3;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const SkkHashtagItem = styled.div`
  font-size: 0.8em;
  background-color: #5e5c60 !important;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const RemoveButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px; // Adjust as needed to fit the icon
  height: 20px; // Adjust as needed to fit the icon
  background-color: transparent;
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px; // As specified
  opacity: 1;
`;
export const SkkRemoveButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px; // Adjust as needed to fit the icon
  height: 20px; // Adjust as needed to fit the icon
  background-color: transparent;
  background-image: url(${closeIconWhite});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px; // As specified
  opacity: 1;
`;
export const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 10px;
  // z-index:250;
`;
export const StyledCreatableSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  z-index: 250;
`;

export const StyledInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
`;
export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

export const StyledDialogContainer = styled.div`
  background: #212121;
  color: #E4E9F1;
  border-radius: 20px;
  box-shadow: none;
  overflow-y: auto;
`;
export const StyledDialogTitle = styled.div`
  height: 3em;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 0.8px solid #a2a2a280;
  padding: 1em 1em;
  position: sticky;
  top: 0;
  background-color: #212121;

`;
export const DialogMiddleText = styled.div`
  width: fit-container;
  height: 25px;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 25px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin-left: 20px;
`;
export const VidErrorDiv = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: normal normal medium 8px/10px "Work Sans";
  letter-spacing: 0px;
  color: #ed4d29;
  margin: auto;
  @media (max-width: 768px) {
    margin: auto;
  }
`;
export const Hr = styled.hr`
  transform: translateY(-36%);
  position: absolute;
  top: 36%;
  left: 0px;
  width: 100%;
`;
