import axios from "axios";
import { REACT_APP_LIVESTREAM_API_BASEURL } from "../constants/env";

// https://dev-livestream.khulke.com/public-resource/publicKey
export const getMeetupTokenKeyAPI = async () => {
  return await axios
    .get(`${REACT_APP_LIVESTREAM_API_BASEURL}/public-resource/publicKey`)
    .then((res) => res)
    .catch(async (e) => {
      const res = e.response;
      if (!res) return;
      return res;
    });
};
