import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { MOBILE_VIEW } from "../../constants/env";
import { CancelOutlined } from "@mui/icons-material";

export default function PreloginModal(props) {
  const {
    open,
    setOpen,
    src,
    component,
    btn_component,
    txt1,
    txt2,
    setLoginComp,
    setSignupComp,
    header,
    container,
    extraComp,
  } = props;

  const navigate = useNavigate();
  const redirectToMeet = localStorage?.getItem("redirectToMeet");

  const closePreLogin = () => {
    setOpen(false);
    setLoginComp(false);
    setSignupComp(false);
    if (extraComp) {
      navigate("/home");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        onClose={() => {
          if (extraComp || redirectToMeet === "true") return;
          setOpen(false);
        }}
        PaperProps={{
          style: {
            borderRadius: "0.8rem",
            backgroundColor: "#252525",
            color: "#fff",
            minWidth: MOBILE_VIEW
              ? "90%"
              : header !== undefined
                ? "670px"
                : "550px",
            maxWidth: MOBILE_VIEW
              ? "90%"
              : header !== undefined
                ? "670px"
                : "600px",
            height: "fit-content",
            margin: MOBILE_VIEW ? "auto" : "",
          },
        }}
        data-test="src-components-PreloginModal-index-0"
      >
        {extraComp}
        <DialogContent
          style={{
            maxWidth:
              header === undefined
                ? txt1 !== "Log in or sign up to interact."
                  ? "430px"
                  : "450px"
                : "",
            minWidth: MOBILE_VIEW
              ? 0
              : header === "RoundTable Details"
                ? 670
                : 450,
            margin: MOBILE_VIEW ? 0 : "auto",
            overflowX: "hidden",
            overflowY: MOBILE_VIEW ? "auto" : "hidden",
            width: "100%",
          }}
        >
          <div
            id="close_div_button"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            data-test="src-components-PreloginModal-index-1"
          >
            <p
              style={{ fontWeight: "bold", fontSize: "24px" }}
              data-test="src-components-PreloginModal-index-2"
            >
              {header}
            </p>
            {redirectToMeet !== "true" && (
              <IconButton
                id="close_button"
                onClick={closePreLogin}
                className={"icon_button"}
                data-test="src-components-PreloginModal-index-3"
              >
                <CancelOutlined
                  id="cross_icon_button"
                  className="cross_icon_white"
                />
              </IconButton>
            )}
          </div>
          {container}
          <div
            className="text-center container-fluid px-3 pb-3"
            data-test="src-components-PreloginModal-index-4"
          >
            <div
              style={{ textAlign: "-webkit-center" }}
              data-test="src-components-PreloginModal-index-5"
            >
              {/* {src} */}
            </div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "0rem",
                width: "100%",
              }}
              data-test="src-components-PreloginModal-index-6"
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  width: "100%",
                }}
                data-test="src-components-PreloginModal-index-7"
              >
                {txt1}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  width: "22rem",
                  paddingTop: "8px",
                }}
                data-test="src-components-PreloginModal-index-8"
              >
                {txt2}
              </Typography>

              {component}

              {btn_component}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
