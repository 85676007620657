import {
  GET_SCROLLS_POSTS_SUCCESS,
  GET_SCROLLS_POSTS_FAILURE,
  LIKE_DISLIKE_SCROLLS_POSTS,
  GET_SINGLE_SCROLLS_POSTS_SUCCESS,
  GET_SINGLE_SCROLLS_POSTS_FAILURE,
  GET_SCROLL_POST_FOLLOW,
  GET_PROFILE_SCROLLS_POSTS_SUCCESS,
  GET_PROFILE_SCROLLS_POSTS_FAILURE,
  GET_USER_SCROLLS_POSTS_SUCCESS,
  GET_USER_SCROLLS_POSTS_FAILURE,
} from "../../actions/actionTypes";

// Initial state for the SCROLLS posts
const initialState = {
  scrollsPosts: [],
  singleScrollsPosts: [],
  profileScrollsPosts: [],
  userScrollsPosts:[],
  error: null,
  singlePostError: null,
  profileScrollsPostsError: null,
};

const scrollsPostsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCROLLS_POSTS_SUCCESS:
      return {
        ...state,
        scrollsPosts: action.payload,
        error: null,
      };
    case GET_SCROLLS_POSTS_FAILURE:
      return {
        ...state,
        scrollsPosts: [],
        error: action.payload,
      };
    case GET_USER_SCROLLS_POSTS_SUCCESS:
      return {
        ...state,
        userScrollsPosts: action.payload,
        error: null,
      };
    case GET_USER_SCROLLS_POSTS_FAILURE:
      return {
        ...state,
        userScrollsPosts: [],
        error: action.payload,
      };
    case GET_SINGLE_SCROLLS_POSTS_SUCCESS:
      return {
        ...state,
        singleScrollsPosts: action.payload,
        error: null,
      };
    case GET_SINGLE_SCROLLS_POSTS_FAILURE:
      return {
        ...state,
        singleScrollsPosts: [],
        singlePostError: action.payload,
      };
    case GET_PROFILE_SCROLLS_POSTS_SUCCESS:
      return {
        ...state,
        profileScrollsPosts: action.payload,
        error: null,
      };
    case GET_PROFILE_SCROLLS_POSTS_FAILURE:
      return {
        ...state,
        profileScrollsPosts: [],
        profileScrollsPostsError: action.payload,
      };
    case LIKE_DISLIKE_SCROLLS_POSTS:
      let mergedPosts;
      if (state.singleScrollsPosts?.length != 0) {
        mergedPosts = [state.singleScrollsPosts, ...state.scrollsPosts];
      } else {
        mergedPosts = state.scrollsPosts;
      }
      return {
        ...state,
        scrollsPosts: mergedPosts.map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        }),
        singleScrollsPosts: [],
        userScrollsPosts: state.userScrollsPosts.map((item) => {
          if (item._id === action.payload._id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        }),
      };
    case GET_SCROLL_POST_FOLLOW:
      return {
        ...state,
        scrollsPosts: state.scrollsPosts.map((data) => {
          if (data?.user[0]?.username == action.payload) {
            return { ...data, isFollowing: true };
          } else {
            return data;
          }
        }),
      };
    default:
      return state;
  }
};

export default scrollsPostsReducer;
