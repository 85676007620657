import React, { useState, useEffect } from "react";
import { Cross, Div, LeftSideBarDiv } from "./style";
import ListItem from "./ListItem";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../assets/icons/close_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../global_styles/style";
import { allWords } from "../../App";
import Meet from "../../assets/icons/meet.svg";
import si_rt_menu_a from "../../assets/icons/si_rt_menu_a.svg";
import si_rt_menu from "../../assets/icons/si_rt_menu.svg";
import si_yapp_menu_a from "../../assets/icons/si_yapp_menu_a.svg";
import si_yapp_menu from "../../assets/icons/si_yapp_menu.svg";
import si_th_menu from "../../assets/icons/si_th_menu.svg";
import si_th_menu_a from "../../assets/icons/si_th_menu_a.svg";
import si_org_menu_a from "../../assets/icons/si_org_menu.svg";
import { Notifications } from "@mui/icons-material";
import Button from "../common/Button";
import logo from "../../assets/images/logo.svg";
import UserCard from "./UserCard";
import { getPostData } from "../../redux/actions/postAction";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus_icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings_icon.svg";
import {
  APPLE_LOGO,
  GOOGLEPLAY_LOGO,
  DOWNLOAD_ANDRIOD_APP_REDIRECTION,
  DOWNLOAD_IOS_APP_REDIRECTION,
  MEET_UP_BASE_URL,
} from "../../constants/env";
import "./style.css";
import { getMeetupEncryptedTokenForRedirection } from "../../redux/reducers/meetupReducer/meetupReducer";
import { getEncryptedToken } from "../../utils/utils";
import "./CommonLeftbarItem.css";

const CommonLeftbarItem = ({
  condition,
  isNotItemTtitle,
  handleClose,
  rtInvite,
  rt_count,
  setRTCount,
  current_user,
  notification_count,
  setNotificationCount,
  expanded,
  windowWidth,
  setModalOpen,
  hasClickedNotification,
  setCreateContent,
  is_icon_sidebar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const encryptionkey = useSelector(getMeetupEncryptedTokenForRedirection);
  const location = window.location.pathname;
  const [anonymous_user, setAnonymous_user] = useState(
    JSON.parse(localStorage.getItem("anonymous_user")) || null
  );

  const handleMeetupClick = () => {
    const accessToken = localStorage.access;
    const encryptedAccessToken = getEncryptedToken(accessToken, encryptionkey);
    return window.open(`${MEET_UP_BASE_URL}?token=${encryptedAccessToken}`);
  };

  useEffect(() => {
    if (!anonymous_user) {
      setTimeout(function () {
        const temp = JSON.parse(localStorage.getItem("anonymous_user"));
        if (temp) setAnonymous_user(temp);
      }, 50);
    }
  }, [localStorage.getItem("anonymous_user")]);

  return (
    <LeftSideBarDiv id="leftSide_div">
      <div data-test="src-components-LeftSideBar-CommonLeftbarItem-0">
        <div
          style={{ position: "relative" }}
          className="head-icon khulkeLogoHome"
          data-test="src-components-LeftSideBar-CommonLeftbarItem-1"
        >
          <div
            style={{
              display: "flex",
              justifyContent:
                windowWidth < 969 || condition ? "flex-end" : "flex-start",
            }}
            data-test="src-components-LeftSideBar-CommonLeftbarItem-2"
          >
            <Link to={"/home"}>
              <Logo
                className="logo-khulke-img"
                src={logo}
                style={{ marginTop: "1rem", marginLeft: "0.5rem" }}
                loading="lazy"
                alt="logo-img"
              />
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "0",
            }}
            data-test="src-components-LeftSideBar-CommonLeftbarItem-3"
          >
            {
              //TODO work on second part of RTListing
            }
            {/* {isNotItemTtitle &&
              <IconButton onClick= {() => setExpanded(false)}>
              <ArrowBackIosIcon  sx={{width: 42, height: 42, color: "black"}}/>
              </IconButton>
    } */}
          </div>
          <div data-test="src-components-LeftSideBar-CommonLeftbarItem-4">
            <Cross src={CloseIcon} onClick={handleClose} />
          </div>
        </div>
        <Div style={{ marginTop: ".563rem" }}>
          {location.split("/")[1] === "home" || location === "/" ? (
            <ListItem
              selected
              title={isNotItemTtitle ? "" : allWords.th.home}
              notifications={rt_count !== 0 ? rt_count : ""}
              setNotificationCount={setRTCount}
              Icon={si_rt_menu_a}
              path={"/home"}
              onClick={current_user?.is_invited ? rtInvite : null}
              is_icon_sidebar={is_icon_sidebar}
            />
          ) : (
            <ListItem
              title={isNotItemTtitle ? "" : allWords.th.home}
              notifications={rt_count !== 0 ? rt_count : ""}
              setNotificationCount={setRTCount}
              Icon={si_rt_menu}
              path={"/home"}
              className="roundtableStep"
              onClick={current_user?.is_invited ? rtInvite : null}
              is_icon_sidebar={is_icon_sidebar}
            />
          )}
          {location === "/townhall" ? (
            <ListItem
              selected
              title={isNotItemTtitle ? "" : allWords.th.latest}
              Icon={si_th_menu_a}
              path={"/townhall"}
              onClick={() => {
                dispatch(getPostData(20));
                navigate("/townhall");
              }}
              className="townhallStep"
              is_icon_sidebar={is_icon_sidebar}
            />
          ) : (
            <ListItem
              title={isNotItemTtitle ? "" : allWords.th.latest}
              Icon={si_th_menu}
              path={"/townhall"}
              onClick={() => {
                dispatch(getPostData(20));
                navigate("/townhall");
              }}
              is_icon_sidebar={is_icon_sidebar}
            />
          )}
          {current_user?.org_page ? (location === "/organization/home" ? (
            <ListItem
              selected
              title={isNotItemTtitle ? "" : allWords.th.organization}
              Icon={si_org_menu_a}
              path={"/organization/home"}
              onClick={() => {
                dispatch(getPostData(20));
                navigate("/organization/home");
              }}
              className="townhallStep"
              is_icon_sidebar={is_icon_sidebar}
            />
          ) : (
            <ListItem
              title={isNotItemTtitle ? "" : allWords.th.organization}
              Icon={si_org_menu_a}
              path={"/organization/home"}
              onClick={() => {
                dispatch(getPostData(20));
                navigate("/organization/home");
              }}
              is_icon_sidebar={is_icon_sidebar}
            />
          )) : <></>}
          <ListItem
            selected={window.location.pathname.includes("meet") ? true : false}
            title={isNotItemTtitle ? "" : allWords.th.meet}
            Icon={Meet}
            onClick={handleMeetupClick}
            is_icon_sidebar={is_icon_sidebar}
          />
          {localStorage.current_user && !anonymous_user && (
            <>
              {location.split("/")[1] === "yapp" ? (
                <ListItem
                  selected
                  title={isNotItemTtitle ? "" : allWords.th.yapp}
                  Icon={si_yapp_menu_a}
                  path={"/yapp"}
                  onClick={() => {
                    navigate("/yapp");
                  }}
                  is_icon_sidebar={is_icon_sidebar}
                />
              ) : (
                <ListItem
                  title={isNotItemTtitle ? "" : allWords.th.yapp}
                  Icon={si_yapp_menu}
                  path={"/yapp"}
                  className="yappStep"
                  onClick={() => {
                    navigate("/yapp");
                  }}
                  is_icon_sidebar={is_icon_sidebar}
                />
              )}
            </>
          )}

          {localStorage.current_user && !anonymous_user && (
            <>
              {location.split("/")[1] === "notifications" ? (
                <ListItem
                  selected
                  comp
                  Icon={Notifications}
                  title={isNotItemTtitle ? "" : allWords.th.noti}
                  path={"/notifications/interaction"}
                  notifications={
                    notification_count !== 0 ? notification_count : ""
                  }
                  setNotificationCount={setNotificationCount}
                  style={{ color: "#f4f4f4" }}
                  onClick={() => {
                    navigate("/notifications/interaction");
                    localStorage.setItem(
                      "current_user",
                      JSON.stringify({
                        ...hasClickedNotification,
                        clicked_notification: true,
                        notification_count: notification_count,
                      })
                    );
                  }}
                  is_icon_sidebar={is_icon_sidebar}
                />
              ) : (
                <ListItem
                  comp
                  Icon={Notifications}
                  title={isNotItemTtitle ? "" : allWords.th.noti}
                  path={"/notifications/interaction"}
                  notifications={
                    notification_count !== 0 ? notification_count : ""
                  }
                  setNotificationCount={setNotificationCount}
                  style={{ color: "#a2a2a2" }}
                  onClick={() => {
                    navigate("/notifications/interaction");
                    localStorage.setItem(
                      "current_user",
                      JSON.stringify({
                        ...hasClickedNotification,
                        clicked_notification: true,
                        notification_count: notification_count,
                      })
                    );
                  }}
                  is_icon_sidebar={is_icon_sidebar}
                />
              )}
              {/* )} */}
            </>
          )}

          {localStorage.current_user && !anonymous_user && (
            <>
              {location.split("/")[1] === "profile" ? (
                <ListItem
                  selected
                  title={isNotItemTtitle ? "" : allWords.th.profile}
                  username={current_user?.["username"]}
                  Icon
                  path={`/profile/${current_user?.["username"]}`}
                  txt="profile"
                  onClick={() => {
                    navigate(`/profile/${current_user?.["username"]}`);
                  }}
                  is_icon_sidebar={is_icon_sidebar}
                />
              ) : (
                <ListItem
                  title={isNotItemTtitle ? "" : allWords.th.profile}
                  username={current_user?.["username"]}
                  Icon
                  path={`/profile/${current_user?.["username"]}`}
                  txt="profile"
                  onClick={() => {
                    navigate(`/profile/${current_user?.["username"]}`);
                  }}
                  is_icon_sidebar={is_icon_sidebar}
                />
              )}
            </>
          )}
        </Div>
        {/* )} */}
        <div
          className="createContentDiv"
          style={{
            marginTop: "2rem",
            marginLeft: "0.1rem",
            marginRight: "0.1rem",
            display: windowWidth <= 968 || condition ? "flex" : "",
            justifyContent: windowWidth <= 968 || condition ? "center" : "",
            marginBottom: "1rem",
          }}
          data-test="src-components-LeftSideBar-CommonLeftbarItem-5"
        >
          {(windowWidth <= 968 || condition) && !expanded ? (
            <div
              onClick={() => {
                if (!localStorage.current_user && localStorage.anonymous_user)
                  return setModalOpen(true);
                setCreateContent(true);
              }}
              data-test="src-components-LeftSideBar-CommonLeftbarItem-6"
            >
              <PlusIcon className="side-action-icon" />
            </div>
          ) : is_icon_sidebar ? (
            <Button
              theme={{ color: { primary: "#dddddd" } }}
              onClick={() => {
                if (!localStorage.current_user && localStorage.anonymous_user)
                  return setModalOpen(true);
                setCreateContent(true);
              }}
              className="commonLeftbarItem-create-button"
              style={{
                minWidth: "0px",
                transition: "all 80ms linear",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              data-test="src-components-LeftSideBar-CommonLeftbarItem-7"
            >
              {allWords.th.create}
            </Button>
          ) : (
            <div
              onClick={() => {
                if (!localStorage.current_user && localStorage.anonymous_user)
                  return setModalOpen(true);
                setCreateContent(true);
              }}
              data-test="src-components-LeftSideBar-CommonLeftbarItem-8"
            >
              <PlusIcon className="side-action-icon" />
            </div>
          )}
        </div>
        {!localStorage.current_user && (
          <div
            style={{
              display: "contents",
              // justifyContent: "center",
              // flexWrap: "wrap",
            }}
            data-test="src-components-LeftSideBar-CommonLeftbarItem-9"
          >
            <p
              className="commonLeftbarItem-login-text"
              style={{
                margin: "30px 0px 10px -10px",
                font: "normal normal bold 16px/19px Work Sans",
                textAlign: "center",
              }}
              data-test="src-components-LeftSideBar-CommonLeftbarItem-10"
            >
              {allWords.th.loginCTA}
            </p>
            <Button
              onClick={() => {
                navigate("/login");
              }}
              variant={"contained"}
              className="commonLeftbarItem-login-button"
              style={{
                backgroundColor: "#66b984",
                minWidth: "0px",
                textTransform: "capitalize",
              }}
              data-test="src-components-LeftSideBar-CommonLeftbarItem-11"
            >
              {allWords.th.loginBtn}
            </Button>

            <div className="get-app">
              <p>{allWords.getApp.getTheApp}!</p>
              <Link
                // className="footerLink"
                to={DOWNLOAD_ANDRIOD_APP_REDIRECTION}
                target="_blank"
                rel="noreferrer"
              >
                <div className="dwd-box">
                  <img
                    src={GOOGLEPLAY_LOGO}
                    width={18}
                    height={22}
                    loading="lazy"
                    // alt="img"
                  />
                  <p>{allWords.getApp.playStore}</p>
                </div>
              </Link>
              <Link
                // className="footerLink"
                to={DOWNLOAD_IOS_APP_REDIRECTION}
                target="_blank"
                rel="noreferrer"
              >
                <div className="dwd-box">
                  <img
                    src={APPLE_LOGO}
                    width={18}
                    height={22}
                    loading="lazy"
                    // alt="img"
                  />
                  <p>{allWords.getApp.appStore}</p>
                </div>
              </Link>
            </div>
          </div>
        )}
        {/* {windowWidth > 968 && (
          <SuggestedToYou is_icon_sidebar={is_icon_sidebar} />
        )} */}
      </div>
      {localStorage.current_user &&
        !anonymous_user &&
        !location.includes("yapp") &&
        !location.includes("account_settings") &&
        !location.includes("broadcast") &&
        !location.includes("oldpass") &&
        !location.includes("setPass") &&
        !location.includes("muted_words") &&
        !location.includes("deactivate_info") &&
        !location.includes("muted_accounts") &&
        !location.includes("blocked_accounts") &&
        !location.includes("feedback") && (
          <>
            {is_icon_sidebar ? (
              <UserCard windowWidth={windowWidth} />
            ) : (
              <div
                style={{
                  marginBottom: "1.5rem",
                }}
                onClick={() => navigate("/account_settings")}
                data-test="src-components-LeftSideBar-CommonLeftbarItem-12"
              >
                <SettingsIcon className="side-action-icon" />
              </div>
            )}
          </>
        )}
    </LeftSideBarDiv>
  );
};

export default CommonLeftbarItem;
