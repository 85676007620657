/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { allWords } from "../../App";
import {Avatar, AvatarGroup,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import VIPComp from "../VipComp";
import { REACT_APP_BASE_URL_FOR_USER } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { userMutualFriends } from "../../redux/actions/profileAction/userProfileAction";
import {  } from "react-redux";
import {searchProfileMutualFollowers, searchHandleMutualFollowers} from "../../utils/SearchUtils"

export const MutualFollowersList = ({
    username,
    setMutualFollowers,
    setOpenMutualUserModal,
    setIsFollowing
}) => {
  const dispatch = useDispatch();
  const mutual_friends = useSelector((state) => state.user_profile.mutualFriends);

  useEffect(() => {
    const fetchMutualFollowers = async () => {
      try {

        let config = {
          method: "get",
          url: `${REACT_APP_BASE_URL_FOR_USER}/v2/mutual-followers?username=${username}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios(config);
        if(setIsFollowing) {
          setIsFollowing(response?.data?.data?.is_following)
        }
        dispatch(userMutualFriends(response?.data?.data || {}));
      } catch (error) {
        console.error("Error fetching mutual followers:", error);
      }
    };

    fetchMutualFollowers();
  }, [username]);

  const handleMutualUserList = async () => {
    const anonymous_user = JSON.parse(localStorage.getItem("anonymous_user"));
    if (anonymous_user) return;

    let config = {
      method: "get",
      url: `${REACT_APP_BASE_URL_FOR_USER}/v2/mutual-followers-list/?username=${username}&limit=20&skip=0`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios(config);
      setMutualFollowers(response.data.data || []);
      setOpenMutualUserModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "12px",
          }}
        >
          <AvatarGroup max={2} style={{ cursor: "pointer" }}>
            {mutual_friends?.username_1 && (
              <Avatar
                alt={mutual_friends?.username_1}
                src={mutual_friends?.profile_pic1}
                onClick={() => {
                  if (window.location.pathname !== "/friends") {
                    window.open(
                      `${window.location.origin}/profile/${mutual_friends?.username_1}`
                    );
                  }
                }}
              />
            )}
            {mutual_friends?.username_2 && (
              <Avatar
                alt={mutual_friends?.username_2}
                src={mutual_friends?.profile_pic2}
                onClick={() => {
                  if (window.location.pathname !== "/friends") {
                    window.open(
                      `${window.location.origin}/profile/${mutual_friends?.username_2}`
                    );
                  }
                }}
              />
            )}
          </AvatarGroup>
          {(mutual_friends?.username_1 || mutual_friends?.username_2) && (
            <div style={{ marginLeft: "14px" }}>
              Followed by{" "}
              <span
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (window.location.pathname !== "/friends") {
                    window.open(
                      `${window.location.origin}/profile/${mutual_friends?.username_1}`
                    );
                  }
                }}
              >
                {mutual_friends?.username_1 && mutual_friends?.username_1}
                {mutual_friends?.username_2 && <>,</>}
              </span>
              &nbsp;
              <span
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (window.location.pathname !== "/friends") {
                    window.open(
                      `${window.location.origin}/profile/${mutual_friends?.username_2}`
                    );
                  }
                }}
              >
                {mutual_friends?.username_2 && mutual_friends?.username_2}{" "}
              </span>
              &nbsp;
              {mutual_friends.mut_foll !== 0 && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={handleMutualUserList}
                >
                  and {mutual_friends.mut_foll} others
                </span>
              )}
            </div>
          )}
        </div>
      }
    </div>
  );
};

export const ProfileSectionSearchMutualFollowersList = ({
  username,
  setMutualFollowers,
  setOpenMutualUserModal,
}) => {

const [mutual_friends, setMutualFriends] = useState({});

useEffect(() => {
  const fetchMutualFollowers = async () => {
    try {
      const response = await searchProfileMutualFollowers(username);
      setMutualFriends(response);
    } catch (error) {
      console.error("Error fetching mutual followers:", error);
    }
  };

  fetchMutualFollowers();
}, [username]);


const handleMutualUserList = async () => {
  const anonymous_user = JSON.parse(localStorage.getItem("anonymous_user"));
  if (anonymous_user) return;
  try {
    const response = await searchHandleMutualFollowers(username);
    setMutualFollowers(response);
    setOpenMutualUserModal(true);
  } catch (error) {
    console.error(error);
  }
};

return (
  <div>
    {
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "2px",
        }}
      >
        {(mutual_friends?.username_1 || mutual_friends?.username_2) && (
          <div style={{ marginLeft: "0px" }}>
            Followed by{" "}
            <span
              style={{
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                if (window.location.pathname !== "/friends") {
                  window.open(
                    `${window.location.origin}/profile/${mutual_friends?.username_1}`
                  );
                }
              }}
            >
              {mutual_friends?.username_1 && mutual_friends?.username_1}
              {mutual_friends?.username_2 && <>,</>}
            </span>
            &nbsp;
            <span
              style={{
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                if (window.location.pathname !== "/friends") {
                  window.open(
                    `${window.location.origin}/profile/${mutual_friends?.username_2}`
                  );
                }
              }}
            >
              {mutual_friends?.username_2 && mutual_friends?.username_2}{" "}
            </span>
            &nbsp;
            {mutual_friends.mut_foll !== 0 && (
              <span
                style={{ cursor: "pointer" }}
                onClick={handleMutualUserList}
              >
                and {mutual_friends.mut_foll} others
              </span>
            )}
          </div>
        )}
      </div>
    }
  </div>
);
};

export const MutualFollowersDialogBox = ({
  openMutualUserModal,
  setOpenMutualUserModal,
  mutualFollowers,
  follow_unfollow_driver,
  username,
  is_following,
  setProfilehOpen,
  follows,
}) => {
  return (
    <Dialog
      onClose={() => setOpenMutualUserModal(false)}
      open={openMutualUserModal}
      sx={{
        "div.MuiPaper-root": {
          width: "100%",
          background: "#2c2f3e",
          color: "#fff",
        },
      }}
    >
      <DialogTitle>Mutual Followers</DialogTitle>
      <List>
        {mutualFollowers.map((follower, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar
                alt={follower?.username}
                src={follower?.profile_pic}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (window.location.pathname !== "/friends") {
                    window.open(
                      `${window.location.origin}/profile/${follower?.username}`
                    );
                  }
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  {follower.name}{" "}
                  <span>
                    {follower?.user_type ? (
                      <VIPComp user_type={follower?.user_type} />
                    ) : null}
                  </span>
                </>
              }
              style={{ cursor: "pointer" }}
              secondary={follower.username}
              primaryTypographyProps={{
                color: "#ffff",
              }}
              secondaryTypographyProps={{
                color: "darkgrey",
              }}
              onClick={() => {
                if (window.location.pathname !== "/friends") {
                  window.open(
                    `${window.location.origin}/profile/${follower?.username}`
                  );
                }
              }}
            />
            <button
              style={
                is_following
                  ? {
                      fontSize: "0.9rem",
                      padding: "0rem 0.8rem",
                      height: "35px",
                      marginTop: "0",
                      marginLeft: "0",
                      background: "transparent",
                      color: "#f4f4f4",
                      border: "1px solid #f4f4f4",
                    }
                  : {
                      fontSize: "0.9rem",
                      padding: "0rem 0.8rem",
                      height: "35px",
                      marginTop: "0",
                      marginLeft: "auto",
                    }
              }
              className={
                !follower?.is_following
                  ? `following-button-small`
                  : `follow-button-small`
              }
              ref={follows}
              onClick={() => {
                if (!localStorage.current_user && localStorage.anonymous_user)
                  return setProfilehOpen(true);
                follow_unfollow_driver(username, !follower?.is_following);
              }}
            >
              {!follower?.is_following
                ? `Following`
                : allWords.misc.livert.follow}
            </button>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};