import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import { ImageSlider } from "./ImageSlider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { allWords } from "../../App";

export function ImagePreviewDialog({
  open,
  handleClose,
  images,
  anchorEl,
  handleRemoveHighlight,
  handleEditHighlight,
  handleMenuClose,
  handleMenuOpen,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="ImagePreviewDialog_modal_container"
    >
      <Box   className="ImagePreviewDialog_modal_subcontainer">
        <div>
          <IconButton
            aria-label="menu"
            aria-controls="menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <MoreVertIcon sx={{color:"#fff"}}/>
          </IconButton>

          {/* Menu */}
          <Menu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                backgroundColor: "#212121",
                borderRadius: "6px",
                opacity: 1,
                color: "#F4F4F4",
                font: "normal normal medium 16px/36px Work Sans",
              },
            }}
          >
            <MenuItem onClick={handleEditHighlight}>
              <EditIcon fontSize="small" sx={{ marginRight: "10px" }} />
              {allWords.profile.highlights.edit}
            </MenuItem>
            <MenuItem onClick={handleRemoveHighlight}>
              <DeleteIcon fontSize="small" sx={{ marginRight: "10px" }} />
              {allWords.profile.highlights.delete}
            </MenuItem>
          </Menu>
        </div>
        <ImageSlider images={images} />
      </Box>
    </Modal>
  );
}
