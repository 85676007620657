/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import showcasePreviewFallbackImg from "../../assets/images/showcasePreviewFallback.png";
import ShowcaseImagesDialog from "./ShowcaseImagesDialog";

const ShowCaseMediaCard = ({
  title = "Alt Text",
  summary,
  imageUrl,
  handleShowCaseDialogOpen,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "#18161C",
        color: "#fff",
        borderRadius: "8px",
        border: "1px solid #A2A2A280",
        width: "100%",
      }}
      onClick={handleShowCaseDialogOpen}
    >
      <CardContent
        sx={{
          minHeight: "70px",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#F4F4F4",
            font: "normal normal normal 13px/17px Work Sans",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            lineClamp: 2,
          }}
        >
          {summary}
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        height="240"
        sx={{ objectFit: "contain", background: "#000000" }}
        image={imageUrl}
        alt={title}
      />
    </Card>
  );
};

const ShowCasePreviewCard = ({
  title = "Alt Text",
  desc = "",
  summary,
  imageUrl,
  linkUrl = "",
  handleShowCaseDialogOpen,
}) => {
  // console.log("Image URL ==== ", imageUrl);
  return (
    <Card
      sx={{
        backgroundColor: "#18161C",
        color: "#fff",
        borderRadius: "8px",
        border: "1px solid #A2A2A280",
        width: "100%",
      }}
      onClick={handleShowCaseDialogOpen}
    >
      <CardMedia
        component="img"
        height="200"
        image={imageUrl || showcasePreviewFallbackImg}
        alt={title}
        sx={{ background: "#000000" }}
      />

      <CardContent
        sx={{
          minHeight: "110px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <div>
          <Typography
            variant="body2"
            sx={{
              color: "#F4F4F4",
              font: "normal normal 600 15px/18px Work Sans",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              lineClamp: 1,
            }}
          >
            {linkUrl}
          </Typography>
        </div>
        <div>
          <Typography
            variant="body2"
            sx={{
              color: "#F4F4F4",
              font: "normal normal normal 14px/17px Work Sans",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              lineClamp: 2,
              marginTop: "10px",
            }}
          >
            {summary}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

const ShowCaseCardComponent = ({ data }) => {
  const [isShowCaseDialogopen, setIsShowCaseDialogopen] = useState(false);

  const handleShowCaseDialogOpen = () => {
    setIsShowCaseDialogopen(true);
  };

  const handleShowCaseDialogClose = () => {
    setIsShowCaseDialogopen(false);
  };
  // console.log("DATA ==== ", data);
  return (
    <>
      {data?.type === "media" ? (
        <>
          <ShowCaseMediaCard
            summary={data?.title}
            imageUrl={data?.media?.[0]?.url}
            handleShowCaseDialogOpen={handleShowCaseDialogOpen}
          />
          <ShowcaseImagesDialog
            open={isShowCaseDialogopen}
            handleClose={handleShowCaseDialogClose}
            images={data?.media?.map((item) => item.url)}
          />
        </>
      ) : data?.type === "post" ? (
        <>
          <ShowCaseMediaCard
            summary={data?.title}
            imageUrl={data?.media?.[0]?.thumbnail_url}
          />
          <ShowcaseImagesDialog
            open={isShowCaseDialogopen}
            handleClose={handleShowCaseDialogClose}
            images={data?.media?.map((item) => item.thumbnail_url)}
          />
        </>
      ) : (
        <ShowCasePreviewCard
          summary={data?.title}
          desc={data?.description}
          linkUrl={data?.link}
          imageUrl={data?.media?.[0]?.url}
        />
      )}
    </>
  );
};

export default ShowCaseCardComponent;
