import styled from "styled-components";
import { Button } from "@mui/material";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'
import UserTagIcon from "../../../assets/icons/Icon-awesome-user-tag.svg";
import LocationIcon from "../../../assets/icons/Icon-material-add-location.svg";
import HashIcon from "../../../assets/icons/Icon-awesome-hashtag.svg";
import CloseIcon from "../../../assets/icons/Icon-ionic-ios-close-circle-outline.svg";
import PhoneIcon from '../../../assets/icons/material-phone-android.svg'

export const MainDiv = styled.div`
  width: 100%;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  .input_container {
    width: 100%;
    display: flex;
    align-items: start;
    margin-bottom: 0.5rem;
  }
  .main_container {
    flex: 1 !important;
  }
`;

export const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 0.5rem;
`;

export const Input = styled.input`
  flex: 1;
  outline: none;
  border: none;
  padding: 0.8rem;

  ::placeholder {
    color: ${(props) => props.theme.color.lynch};
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const AddPostBtn = styled(Button)`
  min-width: 120px !important;
  border-radius: 50px !important;
  background-color: ${(props) => props.theme.color.white} !important;
  padding: 1rem !important;
  text-transform: capitalize !important;
  color: ${(props) => props.theme.color.black} !important;
  min-width: 80px;
  outline: none;
  border: none;
  height: 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
`;

export const VidErrorDiv = styled.div`
  width: 320px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: normal normal medium 8px/10px "Work Sans";
  letter-spacing: 0px;
  color: #ed4d29;
  opacity: 1;
  margin: 2% auto auto auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: visible;
  margin-top: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const StyledUserTagIcon = styled.div`
  width: 24px;
  height: 20px;
  background-image: url(${UserTagIcon});
  background-size: cover;
  background-repeat: no-repeat;
`;
export const StyledPhoneIcon = styled.div`
  width: 22px;
  height: 32px;
  background-image: url(${PhoneIcon});
  background-size: cover;
  background-repeat: no-repeat;
`;
export const StyledLocationIcon = styled.div`
  width: 24px;
  height: 32px;
  background-image: url(${LocationIcon});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledHashIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${HashIcon});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const HashInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const StyledHashInput = styled.input`
  box-sizing: border-box;
  padding: 7px 7px 7px 10px;
  border: 1px solid #7070704D;
  border-radius: 5px;
  width: 100%;
  font-size: 14px; // Set the font size
  background: #1A171F;
  color:#fff;
  opacity: 1; // Set the opacit

   
  &:focus {
    outline: none;
    border-color: none;
  }

  &::placeholder {
    text-align: left;
    font: normal normal normal 14px/16px Work Sans;
    letter-spacing: 0px;
    color: #63779C;
  }
`;
export const HashtagList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center; // This will center the HashtagItems
`;

export const HashtagItem = styled.div`
  background-color: #e3e3e3;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const RemoveButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px; // Adjust as needed to fit the icon
  height: 20px; // Adjust as needed to fit the icon
  background-color: transparent;
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px; // As specified
  opacity: 1;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 10px;
  z-index: 250;
  &::placeholder {
    color: red; /* Change to your desired color */
  }
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  z-index:250;
`;

export const StyledInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
