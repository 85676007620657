import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// importing material UI components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Avatar, Drawer, Grid } from "@mui/material";
import MainMenu from "../../assets/icons/main_menu.svg";

// Components
import LeftSideBar from "../LeftSideBar";
import PreloginComp from "../PreLoginComp";
import Ak from "../AccountLeftsideBar/index";

// Style
import "./style.css";

// Assets
import RoundTableIconActive from "../../assets/icons/RoundTable_icon_active.svg";

// Utils
import { allWords } from "../../App";

// Redux
import { drawerFlag } from "../../redux/actions/compActions";
import ButtonComponent from "../ButtonComponent";
import HomeSearchContainer from "../RightSideBar/HomeSearchContainer";
import { redirectToAppStore } from "../../utils/utils";

export default function Header({ isRoundTable, redirectUrl }) {
  const headerStyle = {
    backgroundColor: "#0E0817",
    boxShadow: "none",
    // borderBottom: "1px solid #E4E9F0",
    padding: "0 0 5px 0",
    marginTop: "5px",
  };

  const open = useSelector((state) => state.drawerSwitchRed.drawFlag);
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const toggleSlider = () => {
    dispatch(drawerFlag(!open));
  };
  let arrURLs = [
    "/account_settings",
    "/invite_friends",
    "/privacy_settings",
    "/setPass",
    "/changepass",
    "/oldpass",
    "/muted_words",
    "/muted_accounts",
    "/blocked_accounts",
    "/feedback",
  ];

  const showSearchOpenApp =
    window.location.pathname.includes("recorded") ||
    window.location.pathname.includes("scrolls");
  //todo comment for scrolls implementation
  // window.location.pathname.includes("bol-khulke") ||
  // window.location.pathname.includes("soch-khulke") ||
  // window.location.pathname.includes("snip-it") ||
  // window.location.pathname.includes("k3");

  return (
    <div className="mobile-header" data-test="src-components-Header-index-0">
      <AppBar
        className="mobile-header"
        position="static"
        sx={headerStyle}
        data-test="src-components-Header-index-1"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          data-test="src-components-Header-index-2"
        >
          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="space-between"
            data-test="src-components-Header-index-3"
          >
            <Toolbar>
              <IconButton
                size="medium"
                edge="start"
                color="default"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleSlider}
                data-test="src-components-Header-index-4"
              >
                <Avatar src={MainMenu} style={{ width: 30, height: 30 }} />
              </IconButton>
              <Drawer
                open={open}
                anchor="left"
                onClose={toggleSlider}
                PaperProps={{ sx: { backgroundColor: "#0E0817" } }}
              >
                {arrURLs.includes(window.location.pathname) ? (
                  <Ak expanded={open} handleClose={toggleSlider} />
                ) : (
                  <LeftSideBar
                    expanded={open}
                    handleClose={toggleSlider}
                    is_icon_sidebar={true}
                  />
                )}
              </Drawer>
            </Toolbar>
          </Grid>
          {!showSearchOpenApp ? (
            <Grid
              className="searchRoundtableButtonContainer"
              data-test="src-components-Header-index-5"
            >
              <HomeSearchContainer />
            </Grid>
          ) : null}
          {showSearchOpenApp ? (
            <div
              className="d-flex align-items-center open-app-div"
              data-test="src-components-Header-index-6"
            >
              <div
                className="img-search-div"
                data-test="src-components-Header-index-7"
              >
                <img
                  src="/assets/icons/search.svg"
                  alt="search"
                  width={35}
                  height={35}
                  onClick={() => navigate("/rt/search")}
                  style={{ cursor: "pointer" }}
                  data-test="src-components-Header-index-8"
                />
              </div>
              <ButtonComponent
                btnText="Open App"
                propStyles={{ borderRadius: "10px", margin: "0 1rem" }}
                btnFunction={() => redirectToAppStore(navigate)}
              />
            </div>
          ) : null}
          {/* <Grid
            item
            xs={isRoundTable ? 5 : 2}
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ flexWrap: "nowrap" }}
          ></Grid> */}
        </Grid>
        <PreloginComp
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          icon={
            <img
              src={RoundTableIconActive}
              alt="this is image"
              width={40}
              height={40}
              data-test="src-components-Header-index-9"
            />
          }
          title={allWords.misc.prelog}
          description={""}
        />
      </AppBar>
    </div>
  );
}
