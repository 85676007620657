import axios from "axios";
import { REACT_APP_BASE_URL_FOR_USER, REACT_APP_VAPID_ID } from "../constants/env";
import { messaging } from "../push_firebase";
import { auto_login_continue, device_info } from "../utils/utils";
import logger from "../logger";
import _ from "lodash";
const retry = 3;

export const updateDeviceInfo = async (access_token) => {
  const deviceId = Math.floor(1000000 + Math.random() * 9000000);  
  let firebaseToken = "default";
  try {
    firebaseToken = await messaging?.getToken({ vapidKey: REACT_APP_VAPID_ID });
  }
  catch(e) {
    console.log(e);
  }

  if (access_token) {
    return await axios
      .get(
        `${REACT_APP_BASE_URL_FOR_USER}/v2/update-device-info-on-app-launch`,
        {
          params: {
            deviceinfo: device_info,
            "device-id": deviceId,
            "device-type": "web",
            "firebase-token" : firebaseToken,
          },
          headers: {
            Authorization: `Bearer ${access_token}`,
            "X-API-Anonymous": access_token ? false : true,
          },
        }
      )
      .then((data) => {
        return _.get(data, "data.data[0]", null);
      })
      .catch((err) => {
        if (err?.response?.status === 401 && retry) {
          retry--;
          return auto_login_continue(() => checkIfImagesNeedsToBeChanged());
        } else {
          logger.error(err.messege);
          return null;
        }
      });
  }
};

export const checkIfImagesNeedsToBeChanged = async () => {
  const anonymous_user = _.get(localStorage, "anonymous_user", {});
  const access_token = localStorage.getItem("access");
  const token = access_token ? access_token : JSON.parse(anonymous_user)?.token;

  const deviceId = Math.floor(1000000 + Math.random() * 9000000);  
  let firebaseToken = "default";
  let cur_user = {};
  try {
    firebaseToken = await messaging?.getToken({ vapidKey: REACT_APP_VAPID_ID });
  }
  catch(e) {
    console.log(e);
  }

  if (anonymous_user && token) {
    return await axios
      .get(
        `${REACT_APP_BASE_URL_FOR_USER}/v2/update-device-info-on-app-launch`,
        {
          params: {
            deviceinfo: device_info,
            "device-id": deviceId,
            "device-type": "web",
            "firebase-token" : firebaseToken,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "X-API-Anonymous": access_token ? false : true,
          },
        }
      )
     
      .then((data) => {
        if(access_token){ 
          cur_user = {
          ...JSON.parse(localStorage.getItem("current_user")),
          org_page: data?.data?.data?.[0]?.org_page,
        };
        localStorage.setItem("current_user", JSON.stringify(cur_user));
      }
        return data;
      })
      .catch((err) => {
        if (err?.response?.status === 401 && retry) {
          retry--;
          return auto_login_continue(() => checkIfImagesNeedsToBeChanged());
        } else {
          logger.error(err.messege);
        }
      });
  }
};

export const fetchNewImages = async (
  url = "https://khulkedev-public-cdn.s3.ap-south-1.amazonaws.com/static/updated/files/si_v1.json"
) => {
  return await axios
    .get(url)
    .then()
    .catch((err) => {
      if (err?.response?.status === 401) {
        return auto_login_continue(() => fetchNewImages());
      } else {
        logger.error(err);
      }
    });
};
