import React from "react";
import styled from "styled-components";
import DownloadSVG from "../../../assets/icons/download.svg";
import PdfSVG from "../../../assets/images/PDF_file_icon.svg";
import ExcelSVG from "../../../assets/images/xls_doc.png";
import PptVG from "../../../assets/images/ppt_doc.png";
import WordSVG from "../../../assets/images/word_doc.png";
import { IconButton } from "@mui/material";
import ExcelBgIcon from "../../../assets/images/excel_bg_icon.png";
import PptBgIcon from "../../../assets/images/powerpoint-icon.png";
import WordBgIcon from "../../../assets/images/word_bg_icon.png";
import DeleteSVG from "../../../assets/icons/delete.svg";
import "../style.css";
import axios from "axios";

const Container = styled.div`
  /* margin-top: 1rem; */
  /* margin-bottom: 1.2rem; */
  width: 100%;
  .docs_container {
    .img {
      width: 100%;
      /* height: 250px; */
      border-radius: 5px;
    }
    .icon_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* margin-top: -55px; */
    }
  }
`;

const DocsContainer = ({
  excelDoc,
  docsFile,
  docsFilePath,
  handle,
  pptDoc,
  wordDoc,
  orignalFilename,
  thumbnail,
  setPptFile,
  setDocsFile,
  setWordFile,
  setXlFile,
  setDocsFilePath,
}) => {
  const fetchFile = (url, filename) => {
    let messingFileName = excelDoc || pptDoc || wordDoc || docsFile;
    axios({
      url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename || messingFileName}`);
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };
  return (
    <>
      <Container>
        {handle ? (
          <div
            className="docs_container"
            data-test="src-components-Post-AddPostDialog-DocsContainer-0"
          >
            {(docsFilePath || docsFile || excelDoc || pptDoc || wordDoc) && <IconButton
              style={{
                position: "absolute",
                right: "20px",
              }}
              className="icon"
              onClick={() => {
                setPptFile(null)
                setDocsFile(null)
                setWordFile(null)
                setXlFile(null)
                setDocsFilePath(null)
              }}
              data-test="src-components-DocumentAttachment-4"
            >
              <img
                src={DeleteSVG}
                alt="delete_icon"
                data-test="src-components-DocumentAttachment-5"
              />
            </IconButton>}
            {excelDoc && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-1"
              >
                <img
                  className="img"
                  // style={
                  //   thumbnail
                  //     ? { maxWidth: "100%", maxHeight: "100%" }
                  //     : { width: 300, height: 200 }
                  // }
                  src={thumbnail ? thumbnail : ExcelBgIcon}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-2"
                />
              </div>
            )}
            {docsFile && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-3"
              >
                <img
                  className="img"
                  src={thumbnail ? thumbnail : PdfSVG}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-4"
                />
              </div>
            )}
            {wordDoc && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-5"
              >
                <img
                  className="img"
                  src={thumbnail ? thumbnail : WordBgIcon}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-6"
                />
              </div>
            )}
            {pptDoc && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-7"
              >
                <img
                  className="img"
                  src={thumbnail ? thumbnail : PptBgIcon}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-8"
                />
              </div>
            )}

            <div
              className="icon_container"
              data-test="src-components-Post-AddPostDialog-DocsContainer-9"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-10"
              >
                <IconButton
                  className="icon"
                  style={{
                    width: 40,
                    height: 40,
                    marginLeft: "0.5rem",
                    padding: "1rem",
                    marginRight: "2rem",
                  }}
                  data-test="src-components-Post-AddPostDialog-DocsContainer-11"
                >
                  {pptDoc && (
                    <img
                      src={PptVG}
                      alt="this is image"
                      style={{ width: 38, height: 38 }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-12"
                    />
                  )}
                  {wordDoc && (
                    <img
                      src={WordSVG}
                      alt="this is image"
                      style={{ width: 38, height: 38 }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-13"
                    />
                  )}
                  {excelDoc && (
                    <img
                      src={ExcelSVG}
                      alt="this is image"
                      style={{ width: 38, height: 38 }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-14"
                    />
                  )}
                  {docsFile && (
                    <img
                      src={PdfSVG}
                      alt="this is image"
                      style={{ width: 26, height: 26 }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-15"
                    />
                  )}
                </IconButton>
                {
                  <p
                    className="doc_text_post"
                    style={{
                      maxWidth: "300px",
                    }}
                    data-test="src-components-Post-AddPostDialog-DocsContainer-16"
                  >
                    {excelDoc || docsFile || wordDoc || pptDoc}
                  </p>
                }
              </div>

              {(docsFilePath || docsFile || excelDoc || pptDoc || wordDoc) && <IconButton
                className="icon"
                component="a"
                href={docsFilePath}
                target="_blank"
                download={orignalFilename}
                data-test="src-components-Post-AddPostDialog-DocsContainer-17"
              >
                <img
                  src={DownloadSVG}
                  alt="download"
                  style={{ width: 38, height: 38 }}
                  data-test="src-components-Post-AddPostDialog-DocsContainer-18"
                />
              </IconButton>}
            </div>
          </div>
        ) : (
          <div
            className="docs_container"
            data-test="src-components-Post-AddPostDialog-DocsContainer-19"
          >
            {excelDoc && (
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-20"
              >
                <img
                  className="img"
                  // style={
                  //   thumbnail
                  //     ? { maxWidth: "100%", maxHeight: "100%" }
                  //     : { width: "auto", height: 220 }
                  // }
                  src={thumbnail ? thumbnail : ExcelBgIcon}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-21"
                />
              </div>
            )}
            {docsFile && (
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-22"
              >
                <img
                  className="img"
                  src={thumbnail ? thumbnail : PdfSVG}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-23"
                />
              </div>
            )}
            {wordDoc && (
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-24"
              >
                <img
                  className="img"
                  src={thumbnail ? thumbnail : WordBgIcon}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-25"
                />
              </div>
            )}
            {pptDoc && (
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-26"
              >
                <img
                  className="img"
                  src={thumbnail ? thumbnail : PptBgIcon}
                  alt="this is image"
                  data-test="src-components-Post-AddPostDialog-DocsContainer-27"
                />
              </div>
            )}
            <div
              className="icon_container"
              data-test="src-components-Post-AddPostDialog-DocsContainer-28"
            >
              <div
                style={{ display: "flex", alignItems: "center" }}
                data-test="src-components-Post-AddPostDialog-DocsContainer-29"
              >
                <IconButton
                  className="icon"
                  style={{
                    width: 40,
                    height: 40,
                    marginLeft: "0.5rem",
                    marginRight: "2rem",
                  }}
                  data-test="src-components-Post-AddPostDialog-DocsContainer-30"
                >
                  {pptDoc && (
                    <img
                      src={PptVG}
                      alt="this is image"
                      style={{ width: 38, height: 38 }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-31"
                    />
                  )}
                  {wordDoc && (
                    <img
                      src={WordSVG}
                      alt="this is image"
                      style={{ width: 38, height: 38 }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-32"
                    />
                  )}
                  {excelDoc && (
                    <img
                      src={ExcelSVG}
                      alt="this is image"
                      style={{ width: 38, height: 38 }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-33"
                    />
                  )}
                  {docsFile && (
                    <img
                      src={PdfSVG}
                      alt="this is image"
                      style={{
                        width: 26,
                        height: 26,
                      }}
                      data-test="src-components-Post-AddPostDialog-DocsContainer-34"
                    />
                  )}
                </IconButton>
                {excelDoc && (
                  <p
                    className="doc_text_post"
                    data-test="src-components-Post-AddPostDialog-DocsContainer-35"
                  >
                    {excelDoc}
                  </p>
                )}
                {docsFile && (
                  <p
                    className="doc_text_post"
                    data-test="src-components-Post-AddPostDialog-DocsContainer-36"
                  >
                    {docsFile}
                  </p>
                )}
                {wordDoc && (
                  <p
                    className="doc_text_post"
                    data-test="src-components-Post-AddPostDialog-DocsContainer-37"
                  >
                    {wordDoc}
                  </p>
                )}
                {pptDoc && (
                  <p
                    className="doc_text_post"
                    data-test="src-components-Post-AddPostDialog-DocsContainer-38"
                  >
                    {pptDoc}
                  </p>
                )}
              </div>

              <IconButton
                className="icon"
                component="a"
                onClick={() => fetchFile(docsFilePath, orignalFilename)}
                data-test="src-components-Post-AddPostDialog-DocsContainer-39"
              >
                <img
                  src={DownloadSVG}
                  alt="download"
                  style={{ width: 38, height: 38 }}
                  data-test="src-components-Post-AddPostDialog-DocsContainer-40"
                />
              </IconButton>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default DocsContainer;
