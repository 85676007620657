import * as React from "react";

function PrivacyPolicyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 29 29"
      {...props}
    >
      <g id="noun-policy-3324936" transform="translate(-8.752 -4.645)">
        <g id="Policy" transform="translate(8.752 4.644)">
          <path
            id="Path_100833"
            data-name="Path 100833"
            d="M29.676,8.37a.452.452,0,0,0-.52-.425c-.054,0-5.633.778-9.564-3.167a.452.452,0,0,0-.642,0c-3.932,3.954-9.5,3.167-9.564,3.167a.452.452,0,0,0-.52.425C8.62,13.225,8.57,20.052,10.932,23.3A21.979,21.979,0,0,0,19,29.363a.9.9,0,0,0,.534,0A21.756,21.756,0,0,0,27.608,23.3C29.97,20.052,29.92,13.225,29.676,8.37Zm-2.8,14.392c-2.3,3.167-6.954,5.533-7.61,5.737-.656-.2-5.307-2.579-7.61-5.737C9.543,19.853,9.529,13.569,9.769,8.877A12.857,12.857,0,0,0,19.293,5.71a12.769,12.769,0,0,0,9.5,3.167C29.011,13.569,29,19.853,26.88,22.762Zm-3.814-8.6a.452.452,0,0,1,.027.642l-4.606,5.045a.475.475,0,0,1-.326.149h0a.452.452,0,0,1-.326-.14l-2.371-2.425A.452.452,0,1,1,16.1,16.8l2.036,2.086,4.3-4.7a.452.452,0,0,1,.629-.032Z"
            transform="translate(-8.752 -4.644)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoPrivacyPolicyIcon = React.memo(PrivacyPolicyIcon);
export default MemoPrivacyPolicyIcon;
