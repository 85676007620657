import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import DisableBadge from "../../assets/images/disable-trophy.svg";
import Trophy from "../../assets/images/trophy.svg";

import BeginnerCreator from "../../assets/SVG/Creator/Creator - Beginner.svg";
import BeginnerModerator from "../../assets/SVG/Moderator/Moderator - Beginner.svg";
import BeginnerPanelist from "../../assets/SVG/Panelist/Panelist  - Beginner.svg";
import BeginnerAudience from "../../assets/SVG/Audience/Audience - Beginner.svg";
import BeginnerSnipItCreator from "../../assets/SVG/Creator/Creator - Beginner.svg";
import BeginnerViewer from "../../assets/SVG/Viewer/Viewer - Beginner.svg";
import BeginnerSnipItViewer from "../../assets/SVG/Viewer/Viewer - Beginner.svg"


import CompetentCreator from "../../assets/SVG/Creator/Creator - Competent.svg";
import CompetentModerator from "../../assets/SVG/Moderator/Moderator - Competent.svg";
import CompetentPanelist from "../../assets/SVG/Panelist/Panelist  - Competent.svg";
import CompetentAudience from "../../assets/SVG/Audience/Audience - Competent.svg";
import CompetentSnipItCreator from "../../assets/SVG/Creator/Creator - Competent.svg";
import CompetentViewer from "../../assets/SVG/Viewer/Viewer - Competent.svg";
import CompetentSnipItViewer from "../../assets/SVG/Viewer/Viewer - Competent.svg"

function ProfileBadges({ user_activities_badge = {}, badges = [] }) {
  const imageStyle = {
    width: "45px",
    height: "45px",
    borderRadius: "0.5rem",
    cursor: "pointer",
  };

  const getBadgeImage = (badgename) => {
    switch (badgename) {
      case "Creator":
        return badges.includes(badgename) ? CompetentCreator : BeginnerCreator;
      case "Moderator":
        return badges.includes(badgename) ? CompetentModerator : BeginnerModerator;
      case "Panelist":
        return badges.includes(badgename) ? CompetentPanelist : BeginnerPanelist;
      case "Audience":
        return badges.includes(badgename) ? CompetentAudience : BeginnerAudience;
      case "Scrolls Creator":
        return badges.includes(badgename) ? CompetentSnipItCreator : BeginnerSnipItCreator;
      case "Scrolls Viewer":
        return badges.includes(badgename) ? CompetentSnipItViewer : BeginnerSnipItViewer;
      case "Viewer":
        return badges.includes(badgename) ? CompetentViewer : BeginnerViewer;
      default:
        return badges.includes(badgename) ? Trophy : DisableBadge;
    }
  };

  return (
    <List dense={false}>
      {Object.entries(user_activities_badge).map(([key, value]) => (
        <React.Fragment key={key}>
          <ListItem>
            <ListItemAvatar sx={{ marginRight: "16px" }}>
              {/* <Avatar> */}
              <div class="circular-progress-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  aria-labelledby="title"
                  role="graphic"
                >
                  <title id="title">circular progress bar</title>
                  <circle cx="50" cy="50" r="40"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    id="pct-ind"
                    style={{
                      strokeDashoffset: `${
                        badges.includes(value.name) ? 0 : 360
                      }`,
                    }}
                  ></circle>
                </svg>
                <img
                  id={"pp_img"}
                  alt={""}
                  className={`circular-profile-pointer`}
                  src={getBadgeImage(value.name)}
                  style={imageStyle}
                />
              </div>
              {/* </Avatar> */}
            </ListItemAvatar>
            <ListItemText
              primary={value.name}
              sx={{
                // font: "normal normal medium 16px/16px Work Sans",
                color: "#F4F4F4",
              }}
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}

export default ProfileBadges;
