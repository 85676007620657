import * as React from "react";

function SitemapIcon(props) {
  const [hovered, setHovered] = React.useState(false);

  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <g id="Sitemap_disabled" data-name="Sitemap disabled" transform="translate(0.323 0.322)">
        <rect id="Rectangle_5611" data-name="Rectangle 5611" width="24" height="24" transform="translate(-0.323 -0.323)" fill="none" opacity="0"/>
        <path id="mdi--sitemap_1_" data-name="mdi--sitemap (1)" d="M8.273,2V6.8h1.818V9.2H4.636a1.714,1.714,0,0,0-1.818,1.6v2.4H1V18H6.455V13.2H4.636V10.8h5.455v2.4H8.273V18h5.455V13.2H11.909V10.8h5.455v2.4H15.545V18H21V13.2H19.182V10.8a1.714,1.714,0,0,0-1.818-1.6H11.909V6.8h1.818V2Z" transform="translate(0.677 1.678)" fill={`${hovered ?   '#f4f4f4':'#a2a2a2'}`}/>
      </g>
    </svg>
  );
}

const MemoSitemapIcon = React.memo(SitemapIcon);
export default MemoSitemapIcon;
