import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputBase from "@material-ui/core/InputBase";
import { allWords } from '../../App'
import {makeStyles} from "@mui/styles";
import showCaseLinkError from "../../assets/images/showcaseLinkError.png";
import showcasePreviewFallbackImg from "../../assets/images/showcasePreviewFallback.png";
import styled from "styled-components";

const useStyles = makeStyles({
  dialogPaper: {
    borderRadius: "0.8rem",
    width: 600,
    color: "#fff",
    background: "#242424",
  },
  input: {
    borderBottom: "1px solid #F4F4F4",
    width: "100%",
    height: "19px",
    textAlign: "left",
    font: "normal normal normal 16px/20px Work Sans",
    letterSpacing: "0px",
    color: "#F4F4F4",
    opacity: 0.5,
    "&:focus": {
      borderBottom: "1px solid #F4F4F4",
    },
  },
  invalidLinkContainer: {
    width: "100%",
    height: "240px",
    border: "1px solid #A2A2A280",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    marginTop: "1rem",
  },
  invalidLinkImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    background: "transparent",
    opacity: "1",
  },
  invalidLinkText: {
    width: "191px",
    height: "19px",
    textAlign: "left",
    font: "normal normal normal 16px/20px Work Sans",
    letterSpacing: "0px",
    color: "#F4F4F4",
    opacity: "1",
    marginTop: "10px",
  },
  customButton: {
    width: "108px",
    height: "32px",
    background: "#F4F4F4",
    textAlign: "center",
    font: "normal normal medium 14px / 36px",
    letterSpacing: "0px",
    color: "#0E0817",
    borderRadius: "60px",
    opacity: "1",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  ogImageContainer: {
    position: "relative",
    width: "100%",
    height: "300px",
    border: "1px solid #A2A2A280",
    borderRadius: "12px",
    opacity: "1",
    marginTop: "1rem",
  },
  loaderContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  ogImage: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    background: "transparent",
    borderRadius: "12px",
    opacity: "1",
  },
});

const Button = styled.button`
  width: 200px;
  height: 50px;
  outline: none;
  border-radius: 5px;
  color: ${(props) => (props.primary ? props.theme.color.primary : "white")};
  background-color: ${(props) => (props.bgColor ? props.theme.color.secondary : "white")};
  border: 2px solid
    ${(props) => (props.primary ? props.theme.color.primary : props.theme.color.secondary)};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

function AddLinkDialog({
  isOpen,
  onClose,
  link,
  handleLinkChange,
  isValid,
  createShowcase,
  ogImage,
  isOgImageLoading,
}) {
  const classes = useStyles();
  return (
    <Dialog PaperProps={{className: classes.dialogPaper}} open={isOpen}>
      <DialogTitle>
        {allWords.profileShowcase.addShowcase}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{position: "absolute", right: 0, top: 0}}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container>
          <InputBase
            classes={{input: classes.input}}
            placeholder="Paste or type a link to a post, roundtable, or short video."
            value={link}
            onChange={handleLinkChange}
          />
          {!isValid && link !== "" && (
            <div className={classes.invalidLinkContainer}>
              <img
                src={showCaseLinkError}
                alt="Invalid link"
                className={classes.invalidLinkImage}
              />
              <div className={classes.invalidLinkText}>Please enter a valid link.</div>
            </div>
          )}
          {isValid && link && ogImage && (
            <div className={classes.ogImageContainer}>
              {isOgImageLoading ? (
                <div className={classes.loaderContainer}>
                  <CircularProgress size={40} />
                </div>
              ) : (
                <img src={ogImage} className={classes.ogImage} alt="OG Image" />
              )}
            </div>
          )}
          {isValid && link && ogImage === null && !isOgImageLoading && (
            <div className={classes.ogImageContainer}>
              <img src={showcasePreviewFallbackImg} className={classes.ogImage} alt="OG Image" />
            </div>
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid}
          className={classes.customButton}
          onClick={() => createShowcase({type: "link", link})}
        >
          {allWords.th.create}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddLinkDialog;
