import React, { useState } from "react";
import { CardContainer, SuggestionTitle } from "./style";
import Moment from "react-moment";
import PreloginComp from "../PreLoginComp";
import PersonIcon from "@mui/icons-material/Person";
import { allWords } from "../../App";
import FollowBtn from "../../assets/icons/followBtn.svg";
import UnfollowBtn from "../../assets/icons/unfollowBtn.svg";
import "./style.css";
import UserProfile from "../UserProfile";
import VIPComp from "../VipComp";
import { Stack } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SuggesstionListComponent = ({
  render_points,
  btn_fucntion,
  is_icon_sidebar,
  disable_btn_fucntion = () => {},
}) => {
  let safe_zone_css = "";
  let status = disable_btn_fucntion();
  if (status) {
    safe_zone_css = "disabled-button";
  } else {
    safe_zone_css = "";
  }
  let current_user = null;
  try {
    current_user = JSON.parse(
      localStorage.current_user || localStorage.anonymous_user
    );
  } catch (err) {
    current_user = { username: null };
  }

  const [profileOpen, setProfileOpen] = useState(false);

  return (
    <>
      <div
        className="container"
        data-test="src-components-SuggestionCard-index-0"
      >
        {render_points?.length > 0 &&
          typeof render_points !== "string" &&
          render_points.map((render_point, index) => {
            try {
              if (render_point["username"]) {
                return (
                  <div
                    key={render_point["username"]}
                    className="d-flex justify-content-between mb-3 mt-2"
                    style={
                      is_icon_sidebar ? { width: "14rem" } : { width: "45px" }
                    }
                    data-test="src-components-SuggestionCard-index-1"
                  >
                    <div
                      style={{ cursor: "pointer", paddingLeft: "0px" }}
                      className="d-flex justify-content-start col-sm-12 col-lg-7 col-md-7"
                      onClick={() => {
                        if (window.location.pathname !== "/friends") {
                          window.open(
                            `${window.location.origin}/profile/${render_point["username"]}`
                          );
                        }
                      }}
                      data-test="src-components-SuggestionCard-index-2"
                    >
                      <UserProfile username={render_point["username"]} />
                      &nbsp;&nbsp;
                      {is_icon_sidebar && (
                        <div
                          style={{ marginTop: "0px" }}
                          data-test="src-components-SuggestionCard-index-3"
                        >
                          <p
                            style={{
                              cursor: "pointer",
                              width:
                                render_point?.["name"]?.length > 14
                                  ? "8rem"
                                  : "100%",
                              fontSize: "14px",
                            }}
                            className="friend-name mb-0"
                            data-test="src-components-SuggestionCard-index-4"
                          >
                            {render_point?.["name"]?.length > 14
                              ? render_point["name"]?.slice(0, 14) + "..."
                              : render_point["name"].split(" ")}
                            {render_point?.user_type ? (
                              <VIPComp user_type={render_point?.user_type} />
                            ) : null}
                          </p>
                          {render_point?.formatted_created_at ? (
                            <small className="small-one text-muted">
                              <Moment fromNow>
                                {render_point["formatted_created_at"]}
                              </Moment>
                            </small>
                          ) : (
                            <p
                              className="small-one text-muted friend-name"
                              style={{
                                cursor: "pointer",
                                width:
                                  render_point?.["username"]?.length > 9
                                    ? "5rem"
                                    : "100%",
                                fontSize: "12px",
                              }}
                              data-test="src-components-SuggestionCard-index-5"
                            >
                              @{render_point["username"]}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    {current_user["username"] !== render_point["username"] && (
                      <div
                        className="col-sm-12 col-lg-5 col-md-5 follow-suggestion-button"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          cursor: "pointer",
                        }}
                        data-test="src-components-SuggestionCard-index-6"
                      >
                        {render_point["is_following"] ? (
                          <img
                            src={FollowBtn}
                            onClick={() => {
                              btn_fucntion(
                                render_point["username"],
                                "unfollow"
                              );
                            }}
                            alt="this is img"
                            style={
                              is_icon_sidebar
                                ? { width: "40px", height: "40px" }
                                : {
                                    width: "28px",
                                    height: "28px",
                                    marginTop: "1em",
                                  }
                            }
                            data-test="src-components-SuggestionCard-index-7"
                          />
                        ) : (
                          <img
                            src={UnfollowBtn}
                            onClick={() => {
                              if (
                                !localStorage.current_user &&
                                localStorage.anonymous_user
                              ) {
                                return setProfileOpen(true);
                              }
                              btn_fucntion(
                                render_point["username"],
                                "follow",
                                index
                              );
                            }}
                            alt="this is img"
                            style={
                              is_icon_sidebar
                                ? { width: "40px", height: "40px" }
                                : {
                                    width: "28px",
                                    height: "28px",
                                    marginTop: "1em",
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                  }
                            }
                            data-test="src-components-SuggestionCard-index-8"
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              }
            } catch (err) {
              return;
            }
          })}
        {render_points?.length === 0 ||
          (typeof render_points === "string" && (
            <div
              className="text-center"
              data-test="src-components-SuggestionCard-index-9"
            >
              <p
                className="warn-text"
                data-test="src-components-SuggestionCard-index-10"
              >
                {allWords.th.suggested.noResult}
              </p>
            </div>
          ))}
      </div>
      <PreloginComp
        modalOpen={profileOpen}
        setModalOpen={setProfileOpen}
        icon={
          <PersonIcon style={{ color: "#66B984" }} width={40} height={40} />
        }
        title={allWords.th.suggested.pre}
        description={""}
      />
    </>
  );
};

const ProfileCard = ({ friend, index, handleRemoveFriend, btn_fucntion }) => {
  return (
    <Box
      style={{
        width: 220,
        minHeight: "300px",
        maxHeight: "300px",
        backgroundColor: "#2C2F3E",
        borderRadius: "20px",
        position: "relative",
        color: "#FFF",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        gap: 2,
        cursor: "pointer",
        marginRight: "12px",
      }}
      data-test="src-components-SuggestionCard-index-11"
    >
      <IconButton
        size="small"
        style={{
          position: "absolute",
          top: "8px",
          right: "8px",
          color: "#FFF",
        }}
        onClick={() => handleRemoveFriend(friend)}
        data-test="src-components-SuggestionCard-index-12"
      >
        <CloseIcon />
      </IconButton>
      <Avatar
        src={friend.profile_pic}
        alt={friend.name}
        style={{
          width: "64px",
          height: "64px",
          marginTop: "12px",
        }}
        onClick={() => {
          if (window.location.pathname !== "/friends") {
            window.open(
              `${window.location.origin}/profile/${friend["username"]}`
            );
          }
        }}
      />
      <Typography
        variant="h6"
        component="div"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginTop: "8px",
        }}
        onClick={() => {
          if (window.location.pathname !== "/friends") {
            window.open(
              `${window.location.origin}/profile/${friend["username"]}`
            );
          }
        }}
        data-test="src-components-SuggestionCard-index-13"
      >
        {friend.name}
      </Typography>
      <Typography
        variant="caption"
        component="p"
        style={{
          color: "#B0B3C2",
          marginTop: "4px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        onClick={() => {
          if (window.location.pathname !== "/friends") {
            window.open(
              `${window.location.origin}/profile/${friend["username"]}`
            );
          }
        }}
        data-test="src-components-SuggestionCard-index-14"
      >
        @{friend.username}
        <span data-test="src-components-SuggestionCard-index-15">
          {friend?.user_type ? <VIPComp user_type={friend?.user_type} /> : null}
        </span>
      </Typography>
      <Typography
        variant="body2"
        component="p"
        style={{
          textAlign: "center",
          marginTop: "8px",
          maxHeight: "60px",
          overflow: "hidden",
          padding: "0 4px",
        }}
        data-test="src-components-SuggestionCard-index-16"
      >
        {friend.bio}
      </Typography>
      {friend.is_following ? (
        <Button
          variant="contained"
          style={{
            marginTop: "auto",
            marginBottom: "12px",
            padding: "8px 14px",
            width: "90%",
            borderRadius: "20px",
            background: "transparent",
            color: "#f4f4f4",
            border: "1px solid #f4f4f4",
            textTransform: "capitalize",
          }}
          onClick={() => {
            btn_fucntion(friend["username"], "unfollow");
          }}
          data-test="src-components-SuggestionCard-index-17"
        >
          Following
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#F4F4F4",
            color: "#000000",
            marginTop: "auto",
            marginBottom: "12px",
            padding: "8px 14px",
            width: "90%",
            borderRadius: "20px",
            textTransform: "capitalize",
          }}
          onClick={() => {
            if (!localStorage.current_user && localStorage.anonymous_user) {
              return setProfileOpen(true);
            }
            btn_fucntion(friend["username"], "follow", index);
          }}
          data-test="src-components-SuggestionCard-index-18"
        >
          Follow
        </Button>
      )}
    </Box>
  );
};

// export default ProfileCard;

// TODO: need to refactor this whole code
const ProfileSuggesstionListComponent = ({
  render_points,
  btn_fucntion,
  disable_btn_fucntion = () => {},
  handleRemoveFriend = () => {},
}) => {
  let safe_zone_css = "";
  let status = disable_btn_fucntion();
  if (status) {
    safe_zone_css = "disabled-button";
  } else {
    safe_zone_css = "";
  }
  let current_user = null;
  try {
    current_user = JSON.parse(
      localStorage.current_user || localStorage.anonymous_user
    );
  } catch (err) {
    current_user = { username: null };
  }

  const [profileOpen, setProfileOpen] = useState(false);

  return (
    <>
      <div
        className="container"
        style={{ overflowX: "scroll", padding: "8px" }}
        data-test="src-components-SuggestionCard-index-19"
      >
        {render_points?.length > 0 && typeof render_points !== "string" && (
          <>
            <Stack
              sx={{ display: "inline-flex" }}
              direction={"row"}
              justifyContent={"space-between"}
            >
              {render_points.map((render_point, index) => {
                try {
                  console.log(render_point);
                  if (render_point["username"]) {
                    return (
                      <ProfileCard
                        key={render_point["username"]}
                        friend={render_point}
                        index={index}
                        handleRemoveFriend={handleRemoveFriend}
                        btn_fucntion={btn_fucntion}
                      />
                    );
                  }
                } catch (err) {
                  return;
                }
              })}
            </Stack>
          </>
        )}
        {render_points?.length === 0 ||
          (typeof render_points === "string" && (
            <div
              className="text-center"
              data-test="src-components-SuggestionCard-index-20"
            >
              <p
                className="warn-text"
                data-test="src-components-SuggestionCard-index-21"
              >
                {allWords.th.suggested.noResult}
              </p>
            </div>
          ))}
      </div>
      <PreloginComp
        modalOpen={profileOpen}
        setModalOpen={setProfileOpen}
        icon={
          <PersonIcon style={{ color: "#66B984" }} width={40} height={40} />
        }
        title={allWords.th.suggested.pre}
        description={""}
      />
    </>
  );
};

const SuggestionCard = ({
  render_points,
  btn_fucntion,
  type,
  disable_btn_fucntion = () => {},
  need_badge,
  is_icon_sidebar,
  is_profile_section,
  handleRemoveFriend = () => {},
}) => {
  if (!render_points.length) return null;

  return (
    <CardContainer
      className="me-1"
      style={
        is_icon_sidebar
          ? { padding: "12px 0px 12px 12px", height: "30vh" }
          : {
              padding: "0px",
              height: "fit-content",
              borderBottom: "1px solid #e4e9f0",
              borderTop: "1px solid #e4e9f0",
              borderLeft: "none",
              borderRight: "none",
              borderRadius: "0",
            }
      }
    >
      {(is_icon_sidebar || is_profile_section) && (
        <SuggestionTitle
          style={{
            lineHeight: "3rem",
            fontSize: "1.2rem",
          }}
        >
          {allWords.th.suggested.sugTitle}
        </SuggestionTitle>
      )}
      {is_profile_section ? (
        <ProfileSuggesstionListComponent
          render_points={render_points}
          btn_fucntion={btn_fucntion}
          type={type}
          need_badge={need_badge}
          is_icon_sidebar={is_icon_sidebar}
          is_profile_section={is_profile_section}
          handleRemoveFriend={handleRemoveFriend}
        />
      ) : (
        <div
          className="suggestedList mt-1 "
          data-test="src-components-SuggestionCard-index-22"
        >
          <SuggesstionListComponent
            render_points={render_points}
            btn_fucntion={btn_fucntion}
            type={type}
            need_badge={need_badge}
            is_icon_sidebar={is_icon_sidebar}
          />
        </div>
      )}
    </CardContainer>
  );
};

const MemoSuggesstion = React.memo(SuggestionCard);
export default MemoSuggesstion;
