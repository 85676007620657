import axios from "axios";
import { REACT_APP_BASE_URL_FOR_USER, REACT_APP_BASE_URL_FOR_USER_V2, REACT_APP_BASE_URL, REACT_APP_USER_ONBOARDING_URL } from "../constants/env";
import { auto_login_continue, checkUserStatus } from "../utils/utils";
import logger from '../logger'

const userStatus = checkUserStatus();

export const getProfile = async ({ username }) => {
  try {
    const anonymous_user = JSON.parse(localStorage.getItem("anonymous_user"));
    const current_user = JSON.parse(localStorage.getItem("current_user"));
    const isSelfProfile = username === current_user.username;
    const config = {
      method: "get",
      url: isSelfProfile
      ? `${REACT_APP_BASE_URL_FOR_USER_V2}/get-user-profile`
      : `${REACT_APP_BASE_URL_FOR_USER}/${anonymous_user ? "anonymous" : "v2.1"}/other-user-profile?username=${username}`,
      headers: {
        Authorization: `Bearer ${
          current_user ? localStorage.access : anonymous_user?.["token"]
        }`,
        "Content-Type": "application/json",
      },
    };

    return await axios(config);
  } catch (error) {
    console.error("Error in getProfile:", error);
    
    logger.error(error);
    
    const res = error.response;
    if (res && res.status === 401) {
      await auto_login_continue(() => getProfile({ username }));
    }
    return res;
  }
};

export const getOrgId = async (username) => {
  try {
        const config = {
      method: "get",
      url: `${REACT_APP_BASE_URL_FOR_USER}/v2.1/other-user-profile?username=${username}`,
      headers: {
        Authorization: `Bearer ${localStorage.access}`,
        "Content-Type": "application/json",
      },
    };
    const resp = await axios(config);
    return resp?.data?.data?._id;
  } catch (error) {
    console.error("Error in getProfile:", error);
    
    logger.error(error);
    
    const res = error.response;
    if (res && res.status === 401) {
      await auto_login_continue(() => getProfile({ username }));
    }
    return res;
  }
};

export async function profilePhoto({ username, size }) {
  const anonymous_user = JSON.parse(localStorage.getItem("anonymous_user"));
  const current_user = JSON.parse(localStorage.getItem("current_user"));

  const config = {
    method: "get",
    url: `${REACT_APP_BASE_URL_FOR_USER}/profile-photo/${username}/${size}/`,
    headers: {
      Authorization: `Bearer ${
        current_user ? localStorage.access : anonymous_user?.["token"]
      }`,
    },
    responseType: "blob",
  };

    return await axios(config).then().catch(async(e) => {
    const res = e.response;
    if (!res)
      return;
    if (res.status === 401) {
      await auto_login_continue(() => profilePhoto({ username }));
    }
    return await res;
});
}

const buildShowcasePayloadFormData = ({ type, title = "", caption = "", link, media = [] }) => {
  const currentDate = new Date().toISOString().replace('Z', '+00:00');
  let formData = new FormData();

  formData.append('type', type);
  formData.append('title', title);
  formData.append('caption', caption);
  formData.append('event_date', currentDate);

  if (type === 'link') {
    formData.append('link', link);
  } else if (type === 'media') {
    media.forEach((file) => {
      formData.append('media', file?.file);
    });
  } else {
    logger.error('Invalid type:', type);
  }

  return formData;
}

export const createShowcaseApi = async({ type, title, caption, link, media })=>{

  try {
    const payload = buildShowcasePayloadFormData({ type, title, caption, link, media });
    const response = await axios.post(`${REACT_APP_BASE_URL}/user/v2/org/showcase`, payload, {
      headers: {
        'Authorization': `Bearer ${userStatus?.userDetails?.access}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    logger.error(error);
  }
}

export async function uploadImage(file, type) {
  if (!file) return;
  var FormData = require("form-data");
  var data = new FormData();
  data.append("image", file);
  data.append("type", type);
  var config = {
    method: "post",
    url: `${REACT_APP_BASE_URL_FOR_USER}/upload_image/`,
    headers: {
      Authorization: `Bearer ${localStorage.access}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  axios(config).then((res) => {
    if(res.status === 200) {
      return true;
    }
    else {
      return false;
    }
  }).catch((res) => {
      return false;
  });
};

export const getShowCaseDataApi = async (orgId) => {
  try {
    const url = `${REACT_APP_USER_ONBOARDING_URL}/user/v2/org/${orgId}/showcase?limit=10&offset=0`;
    const headers = {
      Authorization: `Bearer ${userStatus?.userDetails?.access || userStatus?.userDetails?.token}`,
      "X-API-Anonymous": userStatus?.isLoggedIn ? "false" : "true",
    };

    const response = await axios.get(url, { headers });
    return response?.data?.data;
  } catch (error) {
    console.error("Error in getShowCaseApi:", error);
    logger.error(error);
  
  }
};
