import * as actionTypes from "../../actions/actionTypes";

const initialState = {
    data: [],
    isError: false,
    isLoading: false,
    pageLoading: false
};

const personalizedRtListingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PERSONALIZED_RT_LISTING_REQUEST:
            if (action.categoryType) {
                const key = action.categoryType;
                if (key !== null) {
                    const index = state.data.findIndex((item) => item.grouping_key === key);
                    if (index !== -1) {
                        state.data[index].isLoading = true;
                    } else {
                        state.data.push({
                            grouping_key: key,
                            label: action?.data?.label,
                            roundtables: [],
                            isLoading: true
                        });
                    }
                }
            } else {
                // Whole page request
                return {
                    ...state,
                    pageLoading: true
                };
            }
            return { ...state };
        case actionTypes.PERSONALIZED_RT_LISTING_SUCCESS:
            if (action.categoryType) {
                const key = action.categoryType;
                if (key !== null) {
                    const index = state.data.findIndex((item) => item.grouping_key === key);
                    if (index !== -1) {
                        state.data[index].roundtables = [...state.data[index].roundtables, ...action.payload?.data?.data];
                        state.data[index].isLoading = false;
                    } else {

                        state.data.push({
                            grouping_key: key,
                            label: action.data.label,
                            roundtables: action.payload.data
                        });
                    }
                }
            } else {
                return {
                    ...state,
                    data: action.payload,
                    pageLoading: false
                };
            }
            return { ...state };
        case actionTypes.PERSONALIZED_RT_LISTING_FAIL: {
            if (action.categoryType) {
                const key = action.categoryType;
                if (key !== null) {
                    const index = state.data.findIndex((item) => item.grouping_key === key);
                    if (index !== -1) {
                        state.data[index].isLoading = false;
                    }
                }
            } else {
                return {
                    ...state,
                    error: action.payload,
                    isError: true,
                    pageLoading: false
                };
            }
            return { ...state };
        }
        default:
            return state;
    }
};

export default personalizedRtListingReducer;