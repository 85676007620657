const getIsAnonymousUser = () => {
  const current_user = JSON.parse(localStorage.getItem("current_user"));
  return current_user ? false : true;
};

const getUserJWTToken = () => {
  try {
    const anonymous_user = JSON.parse(localStorage.getItem("anonymous_user"));
    const current_user = JSON.parse(localStorage.getItem("current_user"));
    return `Bearer ${
      current_user ? localStorage.access : anonymous_user.token
    }`;
  } catch (error) {
    return null;
  }
};

const getPreparedRequestHeaderConfig = (config) => {
  try {
    const clonedConfig = _.cloneDeep(config);
    const { authorizationTokenFlag = true, anonymousKeyFlag = false } =
      clonedConfig.customHeaders;

    clonedConfig.headers = {
      ...(authorizationTokenFlag && { Authorization: getUserJWTToken() }),
      ...(anonymousKeyFlag && { ["X-API-Anonymous"]: getIsAnonymousUser() }),
      ...clonedConfig.headers,
    };

    return clonedConfig;
  } catch (error) {
    return config;
  }
};

export { getIsAnonymousUser, getUserJWTToken, getPreparedRequestHeaderConfig };
