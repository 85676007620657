import ClearIcon from "@mui/icons-material/Clear";
import React, { Suspense } from "react";
// import ReactDOM from 'react-dom/client';
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider as SCThemeProvider } from "styled-components"; // Renamed for styled-components
import App from "./App";
import FallBackSpinner from "./components/FallBackSpinner";
import PostSuccessDialog from "./components/PostSuccessDialog";
import "./global_styles/khulke-global.css";
import { theme as scTheme } from "./theme"; // Styled-components theme

// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

const rootElement = document.getElementById("root");
// const rootElement = ReactDOM.createRoot(document.getElementById('root'));

const CloseButton = ({ closeToast }) => (
  <ClearIcon onClick={closeToast} style={{ alignSelf: "center" }} />
);

render(
  <Provider store={store}>
    <Suspense fallback={<FallBackSpinner />}>
      <BrowserRouter>
        <SCThemeProvider theme={scTheme}>
            <App />
        </SCThemeProvider>
        <PostSuccessDialog />
        <ToastContainer
          hideProgressBar
          newestOnTop={true}
          autoClose={3000}
          closeButton={CloseButton}
          style={{
            width: "fit-content",
            alignItems: "center",
          }}
        />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  rootElement
);
