import styled from "styled-components";

export const ButtonStyle = {
  textTransform: "none",
  fontSize: "16px",
  color: "#FFFFFF"
};

export const CloseStyle = {
  position: "absolute",
  right: "8px",
  color: "#FFFFFF",
};

export const TitleStyle = {
  fontSize: "18px",
  color: "#FFFFFF",
  fontWeight: "semi-bold",
};

export const IconContainer = styled.div`
  position: relative;
  border-radius: 50%;
  display: flex;
  background-color: #373737;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
`;
