/* eslint-disable react/prop-types */
import React, { useEffect} from "react";
import {
  useNavigate,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Slider from "react-slick";
import ShowCaseCard from "./Cards";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {checkUserStatus} from "../../utils/utils";
import {fetchShowcaseData} from "../../redux/actions/profileAction/showcasAction";
import "./styles.css";
import "../SliderComponent/style.css";
import { allWords } from "../../App";

const ShowCaseComponent = ({
  showDots = false,
  slideSpeed = 500,
  slidesToShow = 3,
  slidesToScroll = 1,
  showArrows = false,
  infiniteScroll = false,
  handleOpenChange,
  HighlightsUser = {},
}) => {
  const userStatus = checkUserStatus();
  const dispatch = useDispatch();
  const {showcaseData} = useSelector((state) => state.showCase);

  const navigate = useNavigate();
  let settings = {
    dots: showDots,
    infinite: infiniteScroll,
    speed: slideSpeed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    arrows: showArrows,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (HighlightsUser?._id) {
      dispatch(fetchShowcaseData(HighlightsUser._id));
    }
  }, [HighlightsUser]);

  const handleEditShowcase = () => {
    // navigate('/showcase');
    navigate('/showcaselist');
  }

  return (
    <>
      <div className={showcaseData?.length > 0 ? "header-main-slider-div" : ""}>
        {showcaseData?.length > 0 ? (
          <div className="header-main-slider">
            <h2 className="header-slider-showcase"> {allWords.profileShowcase.showcase}</h2>
            {HighlightsUser?.user_domain === "ORG" &&
              userStatus?.userDetails?._id === HighlightsUser?._id && (
                <span className="d-flex align-items-center header-details-showcase ">
                  <AddIcon
                    onClick={() => handleOpenChange(true)}
                    style={{marginLeft: "2px"}}
                  />
                  <EditIcon onClick={handleEditShowcase} style={{marginLeft: "2px"}} />
                </span>
              )}
          </div>
        ) : null}
        {showcaseData?.length > 0 ? (
          <Slider className="slick-slider-div-main custom-slider" {...settings}>
            {showcaseData?.map((el, ind) => (
              <div className="single-slide-div-showcase" key={ind}>
                <div
                  className="slider-single"
                  style={{display: "flex", justifyContent: "center"}}
                >
                  <ShowCaseCard data={el} index={ind} />
                </div>
              </div>
            ))}
          </Slider>
        ) : null}
      </div>
    </>
  );
};

export default ShowCaseComponent;
