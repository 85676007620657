import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
// import "./styles.css";
import Trophy from "../../assets/images/level-trophy.svg";
import DisableTrophy from "../../assets/images/disable-trophy.svg";
import CheckMark from "../../assets/images/level-completed.svg";
import { Typography } from "@mui/material";

function ProfileLevels({
  level = 0,
  points = 0,
  nextLevelPoints = 0,
  user_activities_level = {},
}) {
  const imageStyle = {
    width: "45px",
    height: "45px",
    borderRadius: "0.5rem",
    cursor: "pointer",
  };

  const userLevelsOverview = Object.entries(user_activities_level).map(
    ([key, value]) => {
      return {
        level: key,
        points: value,
      };
    }
  );

  const getCurrentLevelPercentageCompleted = () => {
    if (points === 0 || nextLevelPoints === 0) return 0;
    return (points / nextLevelPoints) * 100;
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {userLevelsOverview.map((item, index, array) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <div
              class="circular-progress-pointer"
              data-test="src-components-ProfileLevels-index-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                aria-labelledby="title"
                role="graphic"
              >
                <title id="title">circular progress bar</title>
                <circle cx="50" cy="50" r="40"></circle>
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  id="pct-ind"
                  style={{
                    strokeDashoffset: `${points > item.points ? 0 : 360}`,
                  }}
                ></circle>
              </svg>
              <img
                id={"pp_img"}
                alt={""}
                className={`circular-profile-pointer`}
                src={points > item.points ? Trophy : DisableTrophy}
                style={imageStyle}
                data-test="src-components-ProfileLevels-index-1"
              />
            </div>
            {index !== array.length - 1 && (
              <TimelineConnector
                sx={{
                  height: "15px",
                  backgroundColor: `${
                    points > item.points ? "#66b984" : "#DEDFE0"
                  }`,
                }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="content"
              data-test="src-components-ProfileLevels-index-2"
            >
              <Typography
                variant="body1"
                data-test="src-components-ProfileLevels-index-3"
              >
                Level {item.level}
              </Typography>
              <Typography
                variant="caption"
                data-test="src-components-ProfileLevels-index-4"
              >
                After collecting {item.points} points
              </Typography>
            </div>
            {points > item.points ? (
              <img
                src={CheckMark}
                alt="check-mark"
                style={{ width: "24px", height: "24px" }}
                data-test="src-components-ProfileLevels-index-5"
              />
            ) : (
              <div
                class="circular-progress-pointer-level"
                style={{ width: "24px", height: "24px" }}
                data-test="src-components-ProfileLevels-index-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  aria-labelledby="title"
                  role="graphic"
                >
                  <title id="title">circular progress bar</title>
                  <circle cx="50" cy="50" r="40"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    id="pct-ind"
                    style={{
                      strokeDashoffset: `${
                        level + 1 === +item.level
                          ? 360 - getCurrentLevelPercentageCompleted()
                          : 360
                      }`,
                    }}
                  ></circle>
                </svg>
              </div>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

export default ProfileLevels;
