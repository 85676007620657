const allWordsE = {
  // Townhall
  th: {
    // header
    townhall: "TownHall",
    latest: "Latest",
    organization: "Organization",
    news: "ANI News",
    expertKhulke: "Soch Khul Ke",
    timeline: "Timeline",
    meet: "MeetUp",
    snipit: "Snip-It",
    scrolls: "Scrolls",
    home: "Home",
    // left sidebar
    rt: "RoundTable",
    yapp: "Yapp",
    noti: "Notifications",
    profile: "Profile",
    post: "Post",
    newpost: "New Post",
    create: "Create",
    k3: "D2M",
    bkk: "Bol Khul Ke",
    follow: "Follow",
    unfollow: "Unfollow",
    copyLinkToPost: "Copy link to post",
    hide: "Hide",
    showcaseOnTopProfile: "Showcase on top profile",
    // left sidebar suggestions (<SuggestedToYou/>)
    suggested: {
      sugTitle: "Suggested for you",
      follow: "Follow",
      error: "Something went wrong! Try again later!",
      noUser: "No users found with similar interest",
      noResult: "No results found",
      pre: "For follow, Log in or Sign up to Khul Ke",
    },
    // left bar for anonymous
    loginCTA: "Log in to interact",
    loginBtn: "Login",
    // popup menu
    profile: "Profile",
    accSettings: "Account Settings",
    logout: "Logout",
    // create post box - new post component
    prelogMessagePost: "For creating Post, Login or sign up to Khul Ke",
    placeholder: "Write something...",
    post_placeholder: "Write your post here...",
    characters: "Characters",
    popupTitle: "New Post",
    video: "Video",
    image: "Image",
    document: "Document",
    audio: "Audio",
    poll: "Poll",
    // polling
    btnText: "Add Option",
    lenTitle: "Poll Length",
    // discard popup
    disTitle: "Discard Post",
    disText: "Do you want to discard this post?",
    disbtnOptionY: "DISCARD",
    disbtnOptionN: "CANCEL",
    disbtnOption1: "Yes, I'm sure",
    disbtnOption2: "No, Keep it",
    exitPopupTitle: "Do You want to exit?",
    // posting image
    imgplcHolder: "Add Caption",
    // right panel
    popularHeading: "Popular Shows",
    // search bar
    searPlaceholder: "Search",
    //Footer for anonymous <PreLoginFooter/>
    opt1: "FAQ",
    opt2: "Community Guidelines",
    opt3: "Support",
    opt4: "Privacy Policy",
    opt5: "Disclaimers",
    opt6: "Terms & Conditions",
    opt7: "Take Down Policy",
    opt8: "Site Map",
    //Post Interaction
    commented: "commented on this",
    //Complete your Profile
    completeYourProfile: "Complete your profile for better discoverability",
    completeYourProfileText:
      "Complete your profile and join our vibrant community. Share your interests, connect with like-minded individuals, and spark meaningful conversations. Let’s make this digital space trulyyours.",
    profComplete: "of your profile is complete",
  },
  // ---------------------------------------------------------------------------
  createNew: {
    createPost: "Create a Post",
    createScrolls: "Create Scrolls",
    createRT: "Create a RoundTable",
    scheduleMeetup: "Schedule a Meetup",
  },
  // Roundtable listing page
  rt: {
    notificationMessage: "Check status of invitations sent",
    rt: "RoundTable",
    rtNewBtn: "New",
    label1: "All",
    label2: "Live",
    label3: "Upcoming",
    label4: "Mine",
    categories: "Categories",
    // rt type popup
    popupTitle: "Select RoundTable Type",
    opt1: "Video",
    opt2: "Audio",
    opt3: "Recorded",
    // on click of bell icon -> /roundtable/notifications
    notiTitle: "RoundTable Notifications",
    // right panel tabs
    live: "live",
    upcoming: "upcoming",
    noItem: "Nothing to Show",
    noRt: "No RoundTable to display",
    noRTFound: "No RoundTable Found",
    scrolledBottom: "No More Roundtables, you have reached bottom",
    upcomingRtInfoToast:
      "This RoundTable is not started yet. We will notify you once this RoundTable is ready to join.",
    accepted_invite: "You have accepted the invite.",
    rejected_invite: "You have rejected the invite.",
    invite_follow: "Invite those you follow",
    and: "and",
  },
  // path -> /notifications/interaction
  noti: {
    // tabs title
    interaction: "Interaction",
    Net: "Network",
    noNoti: "You have no new interactions!",
    started_following: " started following you",
  },
  // path => /search (search pages)
  search: {
    top: "Top",
    latest: "Latest",
    videos: "Videos",
    profile: "Profiles",
    news: "News",
    post: "Posts",
    people: "People",
    rt: "RoundTable",
    // fallback under roundtable tab
    noRT: "No RoundTables to display",
    trendingNews: "Trending News",
    breakingNews: "Breaking News",
    newsHighlights: "News Highlights",
    shortVideos: "Scrolls Videos",
    scrolls: "Scrolls",
    rts: "RoundTables",
  },
  // login pages
  login: {
    headingleft: "A platform where conversations matter.",
    // form
    headingOfForm: "Login",
    lineAboveInput: "Enter Email/ Mobile/ Username",
    placeHolder: "abcd@example.com",
    radioTitle: "Login with",
    opt1: "OTP",
    opt2: "Password",
    btn: "Login",
    filterCap: "FILTER",
    linkToSignup: "New User? Create an Account",
    privacyPolicyLine:
      "By continuing, you agree to accept our Privacy Policy & Terms of Service.",
    formBottomLine: "For any support, you can reach us at",
    // password page
    titleAboveBar: "Enter Password",
    passPlaceholder: "Your password here",
    passwordWarning: "This is very weak password. Kindly set strong password.",
    forgotpass: "Forgot Password?",
    btnPass: "LOGIN",
    khulkeMail: "support@khulke.com",
  },

  profile: {
    bio: "Bio",
    Enterwbsiteplace: "Write website URL",
    perso4: "Personal Details",
    fallback: "Something Went Wrong! Try again later.",
    pageTitle: "Profile",
    tabopt1: "Posts",
    tabopt2: "RoundTable",
    tabopt3: "Scrolls",
    tabopt4: "Saved",
    tabopt5: "Interaction",
    // btns
    btnEdit: "Edit Profile",
    btnFollowers: "Followers",
    btnFollowing: "Following",
    // noData
    noSnips: "You have not created any Scrolls yet.",
    otherNoSnips: "Not created any scrolls yet",
    noSave: "You have not saved any post yet.",
    noRt: "No RoundTable created or attended yet",
    noPost: "No posts yet",
    withUsername: "User with username",
    notFound: "not found! Please try another username.",
    contentCardRight: {
      opt1: "Live",
      opt2: "Upcoming",
      noRT: "No RoundTables to display",
    },
    female: "Female",
    male: "Male",
    other: "other",
    highlights: {
      highlights: "Highlights",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
      edit: "Edit",
      WriteTitle: "Write title for the highlight",
      addCaption: "Add Caption",
      create: "Create",
      enterTitle: "Please enter title.",
      selectImage: "Please select image.",
      imageLess: "Upload an image less than 5 MB.",
      imagesAllowed: "Only 3 images allowed!",
      addHighlights: "Add Highlights",
      editHighlights: "Edit Highlights",
      removeHighlights: "Delete this Highlight?",
      removeHighlightInstruction:
        "You can add this to highlight again from the highlight section.",
    },
    
    roundTable: {
      CreateRoundTable: "Create RoundTable",
      AttendModerator: "Attend as Moderator",
      AttendPanelist: 'Attend as Panelist',
      AttendAsInvitedAudience: "Attend as Invited Audience",
      RandomAudience: "Attend as Random Audience",
      OwnerandModerator: "Attend as Owner and Moderator",
      OwnerAndPanelist: "Attend as Owner and Panelist",
      WatchTheRounTable: "Watch the RoundTable",
      AutoPlayRoundTable: "Auto-Play RoundTable",
      WatchRoundTable: "Watch RoundTable"
    },
    tabs:{
      RoundTable: "RoundTable",
      Latest: "Latest",
      MeetUp: "MeetUp",
      Others: "Others"
    }
    
  },
  profileShowcase: {
    showcase: "Showcase",
    addShowcase: "Add Showcase",
    only3ImageAllowed: "Only 3 images allowed!",
    only4ImageAllowed: "Only 4 images allowed!",
    save: "Save",
    remove: "Remove",
    cancel: "Cancel",
  },

  profilePoint: {
    khulKeboard: {
      title: "Khul Ke Board",
    },
    earnPointBadges: {
      title: "How to Earn Points and Badges",
    },
    levels: {
      title: "Levels",
    },
    badges: {
      title: "Badges",
    },
    points: {
      title: "Points"
    },
    question: "What is the importance of points?",
    answer:"The Duration of Roundtables attended by the user must be more than 15 mins to earn the points.",
    earnMorePoints: {
      title: "Earn More Points",
      createPost : 'Create Post',
      createRoundTable : "Create RoundTable",
      createSnipIt : "Create Snip-It",
      likePosts : "Like Posts",
      Comment: "Comment"
    },
  },

  // "/notifications/network"
  notiMain: {
    noInteraction: "You have no new interactions!",
    tab1: "Interaction",
    tab2: "Network",
  },

  // accountPages -> AccountLeftSideBar/index
  // leftbar
  leftAccounts: {
    acc: "Account",
    invite: "Invite Friends",
    privacy: "Privacy",
    pass: "Password",
    mutedWord: "Muted Words",
    MutedAcc: "Muted Accounts",
    blocked: "Blocked Accounts",
    feedback: "Feedback",
    faq: "FAQ",
    privacyPoli: "Privacy Policy",
    communiGuide: "Community Guidelines",
    disclaim: "Disclaimers",
    support: "Support",
    TnC: "Terms & Conditions",
    logout: "Logout",
    takeDown: "Take Down Policy",
    broadcast: "Broadcast",
    sitemap: "Sitemap",
    additionalResources: "Additional Resources",
  },
  // settings pages
  setting: {
    // "/account_settings"
    settingHeader: "Settings",
    usernameLegend: "Email",
    EmailLegend: "Email",
    mobileLegend: "Mobile",
    deactivateHeading: "Do you want to deactivate your account?",
    deactivateInfo:
      " You can revive your account by logging in within 30 days. After 30 days, the account will be permanently deleted along with all the posts, and media.",
    deactiveBtn: "Deactivate account",
  },

  // create rt -> "/roundtable/create"
  createRT: {
    pgTitle: "RoundTable",
    access: "Create For",
    //steps below found in <RoundTableTImeline/>
    step1: "New RoundTable",
    step2: "Speakers",
    step3: "Categories",
    step4: "Invite",
    create_your_rt: "Create your own RoundTable",
    maximum_2_gb: "Maximum video size should be 2gb only",
    download: "Download",

    type: "Type of RoundTable",
    iBtn: {
      line1: "You can select audio or video RoundTable",
      line2: "Audio – Speaker’s voice will be available for everyone",
      line3:
        "Video – Speaker’s voice and visuals both will be available for everyone",
    },
    videoBtn: "Video",
    audioBtn: "Audio",
    // title
    addTitle: "Add Title",
    pvtRTpopUp:
      "For joining Private RoundTable, send request to owner of RoundTable. Do you want to send request?",
    reqSentAlready: "Request already sent!!!",
    addTitleIbtn: {
      firstLine: "Title should catchier and more attractive for the users.",
      secondLine:
        "It has limit of 50 characters only so be specific with your discussion topic.",
    },
    addTitlePlaceholder: "Add Title",

    // description
    descTitle: "Description",
    descIBtn: {
      one: "Description should be more detailed about topics and speakers to provide visualization and trust factors to users to attend this conversation.",
      two: "It has limit of 250 words so you can put short article as well.",
    },
    // autoRecording
    recTitle: "Auto start recording",
    recIBtn: {
      one: "Auto-recording is starting your recording of RoundTable automatically once moderator is joined the RoundTable.",
      two: "“ON” selection – Starting your recording of RoundTable automatically once moderator is joined the RoundTable",
      three:
        "“OFF” selection – Recording of RoundTable will not start until moderator/owner of RoundTable has not starting recording from Live RoundTable",
    },
    natureOfRt: "Nature of RoundTable",
    optPub: "Public",
    optPriv: "Private",
    optTrial: "Trial",
    optConfi: "Confidential",
    category: "Select Category",
    categoryOption: "Select category",
    categoryData: [
      "Arts & culture",
      "Astrology",
      "Books",
      "Business",
      "ChatShows",
      "Crime",
      "Defense",
      "Education",
      "Entertainment",
      "Fashion",
      "Finance & Economy",
      "Fitness",
      "Food",
      "Games",
      "Government",
      "Health",
      "Legal",
      "Lifestyle",
      "News",
      "Pets & Animals",
      "Philosophy",
      "Poetry",
      "Politics",
      "Recipes",
      "Religion",
      "Science & Technology",
      "Society",
      "Spiritual",
      "Sports",
      "Theatre",
      "Travel",
      "Wellness",
    ],
    natureIBtn: {
      one: "There are 3 kinds of RoundTable available for you as",
      two: "“Public” – This RoundTable will be visible to everyone, and they can join this anytime during the RoundTable.",
      three:
        "“Private” – This RoundTable will be visible to everyone, but for joining this, they must request to join which only owner can approve.",
      four: "“Confidential” – This RoundTable will be visible to invitees only and only they can join this.",
    },

    natureRtBtn: {
      one: "There are 2 kinds of RoundTable available for you as",
      two: "“Public” – This RoundTable will be visible to everyone, and they can join this anytime during the RoundTable.",
      three:
        "“Private” – This RoundTable will be visible to everyone, but for joining this, they must request to join which only owner can approve.",
    },

    keepAnonTitle: "Keep Me Anonymous",
    keepAnonI:
      "This is a secret RoundTable where owner name of the RoundTable is not visible to anyone",
  },
  // Create RT page 2
  moderatorLabel: "Moderator",
  panelistLabel: "Panelist",
  transferRights: "Transfer Owner Rights",
  addPanelistBtn: "ADD PANELIST",
  editPanelistBtn: "EDIT PANELIST",
  nopanelistError: "No panelists added!",
  //Scrolls
  scrolls: {
    add: "Add Scrolls",
    create: "Create Scrolls",
    edit: "Edit Scroll",
    uploadDes: "Add video file to upload.",
    uploadBtn: "Select from Device.",
    PostBtn: "Post",
    DoneBtn: "Done",
    description: "Write Description",
    tagUsers: "Tag Respondent",
    addLocation: "Add Location",
    addHashtags: "Add Hashtags",
    addPhoneNo: "Add Respondent’s Mobile",
    //Scrolls Select Category
    categories: "Select Category",
    scrollsCat: [
      {
        title: "Public Opinion",
        subTitle:
          "Express your insights on current affairs through brief video clips.",
      },
      {
        title: "Sports",
        subTitle:
          "Celebrate the excitement by sharing your favorite sports moments!",
      },
      {
        title: "News",
        subTitle:
          "Embrace citizen journalism! Your perspective is significant, and your footage might become the next major story.",
      },
      {
        title: "RoundTable Snippets",
        subTitle:
          "Condense your thoughts! Utilize the trim feature to share your concise opinion.",
      },
      {
        title: "Masti",
        subTitle:
          "Share your moments of Masti! Upload your cheerful experiences and let's spread the joy!",
      },
      {
        title: "Leadership Speaks",
        subTitle:
          "Watch experts from various fields share their knowledge, experience, and wisdom. Learn from their insights and upgrade your skills.",
      },
      {
        title: "Showcase - Product, Talent & Institution",
        subTitle:
          "Highlight your product, talent, or institution in the spotlight. Allow the world to uncover your excellence!",
      },
      {
        title: "Governance",
        subTitle:
          "Watch content on law, judiciary, government bills, and governance. Stay informed and updated.",
      },
      {
        title: "DIY - Do It Yourselfs",
        subTitle:
          "Share your most recent DIY project with us. Upload your masterpiece and be an inspiration to others.",
      },
      {
        title: "Science and Technology",
        subTitle:
          "Watch videos related to Science & Tech. Right from experiments and hacks to tips and inventions, explore, learn and get inspired. ",
      },

      {
        title: "Bhakti",
        subTitle:
          "Share your vibes of Bhakti! Upload your moments of spiritual connection.",
      },
      {
        title: "Others",
        subTitle: "Watch content across categories. Enjoy, Learn & Share.",
      },
      {
        title: "Politics",
        subTitle: "Share your political perspectives!",
      },
    ],
    //Toaster msg
    catNotSelected: "Please Select Scroll's Tag.",
    shareScrolls: "Your Scroll has been successfully shared.",
  },
  // k3 page
  K3: {
    create: "Create",
    description: "Add news description here",
    btnadd: "Upload a D2M video",
  },
  // bkk page
  bkk: {
    create: "Create Views",
    shareViews: "Share your valuable views here",
    description: "Add your valuable views here",
    btnadd: "Add Views",
    tagUsers: "Tag Users",
    addLocation: "Add Location",
    addhasgtags: "Add hashtags",
    addVideoFile: "Add Video File to upload",
    selectFromDevice: "Select from Device",
    addPhoneNo: "Add Phone Number",
    post: "Post",
    next: "Next",
  },
  // snippit page
  snip: {
    btnadd: "Add",
    dislike: "Dislike",
    likes: "Likes",
    interactions: "Interactions",
    share: "Share",
    shares: "Shares",
    like: "Like",
    block: "Block",
    follow: "Follow",
    unfollow: "Unfollow",
    copyLinkToPost: "Copy link to post ",
    hide: "Hide",
    showcaseOnTopProfile: "Showcase on top profile",
    muteThisPerson: "Mute this person",
    reportPost: "Report post",
  },
  ekk: {
    description: "Write Description",
    shareViews: "Share your views",
  },
  yapp: {
    writeMessageHere: "Write your message here...",
    createKlub: "Create Klub",
    selectMembers: "Select Members",
    newKlub: "New Klub",
    klubName: "Klub Name",
  },
  addpanelist: "Add",
  addedalready: "Added",
  editpanelist: "Edit",
  panelist: "Panelist",
  misc: {
    pollBefore: "You have selected the",
    pollAfter: ". Once you confirm, you cannot undo this selection.",
    confirm: "Confirm",
    textError: {
      more_than_100: "You cannot type more than 100 words.",
      more_than_250: "You cannot type more than 250 words.",
    },
    invite: {
      notModPanInvite: "You cannot invite moderator or panelist.",
    },
    timer: {
      selectTime: "Select Time",
    },
    toastMsg: {
      only_two_poll: "There will be only two polls.",
      duration_lesser_than_RT:
        "Duration cannot be greater than RoundTable Duration.",
      duration_empty: "Duration cannot be empty.",
      fill_option: "Kindly fill the option or delete that.",
      minimum_options: "Minimum 2 options should be there for Poll.",
      provied_question: "Kindly provide the question for the Poll.",
      file_size: "Upload a file less than 15 MB.",
      select_document: "Please select documents.",
      invalid_file_format:
        "Invalid file format. Please upload pdf, doc, ppt, xlx files.",
      image_size: "Upload an image less than 15 MB.",
      select_image: "Please select image",
      only4ImageAllowed: "Only 4 images allowed!",
      audioLengthValidation:
        "Max. duration for Audio should be 2min 30sec only.",
      postUploadingWait:
        "File uploading is still in progress. Will Notify you once uploaded",
      invalidAudioFormat: "Invalid audio file format. Please upload mp3 file.",
      max_video_duration: "Max. duration should be 15sec only.",
      select_video: "Please select video.",
      less_than_15mb_file: "Upload a file less than 15mb",
      invalidImgFormat:
        "Invalid image format. Please upload jpg, jpeg, png, gif images.",
      invalidVideoFormat: "Invalid video file format. Please upload mp4 file.",
      invalidVidAudFormat:
        "Invalid video file format. Please upload mp4/mp3 file.",
      multipleLoginError: "You have been joined from some other device",
      sessionExpired: "Session Expired",
      feedbackImageMissing:
        "Kindly share the screenshot of screen-recording along with Feedback",
      videoLength3min:
        "Your video size is more than 3 min. Kindly trim the video and post it.",
    },
    image_less_size: "Upload less than 15 MB Image",
    addPoste: "Add Post",
    savechanges: "SAVE CHANGES",
    updateuname: "UPDATE USERNAME",
    changessaved: "Your changes have been saved!",
    oldview: "Viewers",
    allfollower: "All Followers",
    allfollowing: "All Following",
    minfo1:
      "Moderator is mandatory speaker for the RoundTable. There are few conditions with moderator as",
    minfor2:
      "Till he/she is not accepting the invitation, no one can join the RoundTable",
    minfor3:
      "Till he/she is not joining the RoundTable, RoundTable will be in lock state after start also",
    minfor4:
      "If he/she is not available within 10min after start of RoundTable, RoundTable will get cancelled",
    minfor5: "In one RoundTable, only 1 moderator can be invited",
    minfor6: "Owner can make moderator as co-owner",
    minfor7: "You can add 100 words as description for moderator",
    ttlcantEmpty: "Title can't be empty",
    minago: "min ago",
    owner: "Owner",
    vlist: " View List",
    notifywhenready:
      "This RoundTable is not started yet. We will notify you once this RoundTable is ready to join.",
    maxvidoel:
      " Max. duration for video is 2min 30sec only. Kindly trim the video and post it.",
    unsave: "unsave",
    repply: "Reply",
    send: "Send",
    exploresnip: "Explore in Snip-It",
    watchsnipit: "Watch more in Snip-It",
    explorekhabar: "Explore in Khabar Khul Ke",
    watchkhabar: "Watch more in Khabar Khul Ke",
    explorebkk: "Explore in Bol Khul Ke",
    watchbkk: "Watch more in Bol Khul Ke",
    exploreSkk: "Explore in Soch Khul Ke",
    watchSKk: "Watch more in Soch Khul Ke",
    succcopied: "Successfully Copied",
    succsaved: "Successfully Saved",
    entersomeval: "Enter some value",
    readmore: "Read more",
    readless: "Read less",
    stoprec: "Stop Recording",
    endrt: "End RoundTable",
    muteda: " Muted Audiences",
    finalr: "Final Result",
    uncirc: "Uncirculate",
    receivenotiq:
      "Would you like to receive a notification when we upload the recording of this RoundTable?",
    greatline: "Great, We will notify you once the recording is available.",
    recrte: "Recorded RoundTable",
    uploddw: "Upload",
    sche1: "There is 2 option to schedule your RoundTable as follows",
    sche2:
      "“Now” – this will start your RoundTable immediately after your RoundTable creation",
    sche3:
      "“Later” – this will provide you facility to select start date and time for your RoundTable at least 15min from current time",
    recording: "Recording",
    recline1: "Recorded video is max. 3hrs",
    recline2: "Ideal ratio for outro video is 16:9 ratio",
    recline3: "It only supports .mp4 and .mp3",
    addwm: " Add as Wildcard Message",
    coowner: "Co-owner",
    past: "past",
    new: "new",
    prelog: "For creating RoundTable, Login or sign up to Khul Ke",
    avionceconfirm: "Available once RoundTable is confirmed",
    nocategoryinc: "No categories included!",
    notagsinc: "No tags included!",
    selectSche: "Select Schedule",
    uploadl: "Upload logo",
    now: "Now",
    later: "Later",
    dateTime: "Date & Time",
    duration: "Duration",
    addDescplaceholder: "Add Description",
    recOn: "On",
    recOff: "Off",
    restore: "Restore Username",
    update: "Update",
    mob: "Enter Mobile Number",
    user: "Username",
    enterusname: "Enter Username",
    mail: "Email",
    mob: "Mobile",
    share: "Share",
    copy: "Copy Link",
    postit: "Post it",
    attending: "Attending",
    watch: "Watching",
    liste: "Listening",
    views: "Views",
    rtDetails: "RoundTable Details",
    cawaited: "Confirmation Awaited",
    jnow: "Join Now",
    sreminder: "Set Reminder",
    rset: "Reminder Set",
    has: "has",
    hassecond: "has",
    invitedu: "invited you",
    somethingwrong: "Something went wrong",
    char: "Char",
    words: "Words",
    topic: "Topic",
    done: "Done",
    invitation_sent_successfully: "Invitation sent successfully",
    you_can_edit_rt_manage_your_invitations_anytime:
      "You can edit your RoundTable and manage your invitations any time.",
    manage_invitations: "Manage Invitations",
    done_for_now: "Done for now",
    intro_video: "Intro Video",
    outro_video: "Outro Video",
    invite_audience: "Invite Audience",
    invite_your_audience: "Invite your audience",
    advance_option: "Advance Options",
    advance_option_info:
      "This is optional details for making your RoundTable more engaging and intelligible.",
    multiple_logos: "Multiple Logos",
    example_text: "Ex. Topic | Speaker name | Owner name | Hashtags",
    preview: "Preview",
    your_rt_has_update: "Your roundtable has been updated.",
    add_introduction_for_panelist: "Add introduction of the panelist",
    add_introduction_for_moderator: "Add introduction of the moderator",
    update_your_rt: "Update your RoundTable",
    edit_rt: "Edit RoundTable",
    join: "Join",
    INVITE_POP_DESC: "Do you wish to continue or skip all?",
    rt_live_time_left: "Your RoundTable is live with time left",
    mod_note_1:
      "Note: If you do not accept the invitation, then RoundTable will not start",
    mod_note_10_1:
      "Note: If you do not Join the RoundTable, then RoundTable will get cancelled after",
    mod_note_10_2: "min",
    rt_about_start_in: "And your RoundTable is about to start in:",
    for_the_rt: "for the RoundTable",
    you_have_been_invited: "You have been invited as a",
    you_have_been_invited_1: "You have been invited as a",
    you_have_been_invited_2: "",
    your_rt_is_live_with_time_left: "Your RoundTable is live with time left",
    more_inv_1: "You have",
    more_inv_2: "more invitations of RoundTables",
    see_you_there: "See you there!",
    continue: "Continue",
    continue_to_create: "Continue to create",
    you_have_not_uploaded_image:
      "You have not uploaded image which can attract more audience for your RoundTable",
    skip_all: "Skip All",
    user_already_added: "User already added !!!",
    add_more: "Add more",
    remove: "Remove",
    thumbnail: "Thumbnail",
    thumbnail_preview: "Thumbnail Preview",
    description_placeholder:
      "Add description about the topic and links of previous RoundTables",
    // Create RT page 3
    pg3: {
      uplImg: "Upload Image",
      Recommendation: "Recommendation",
      l1: "Make a cover image of RoundTable with 16:9 ratio",
      l2: "It only supports PNG, JPEG, JPG",
      l3: "It has max. limit as 15mb",

      logo: "Upload Logos (Max 3)",
      ll1: "You can upload max. 3 logos",
      ll2: "Any aspect ratio for logo is allowed",
      ll3: "Any aspect ratio for logo is allowed",
      ll4: "It has max. limit as 1mb only",

      uplintro: {
        uplintro: "Upload Intro Video",
        uploutro: "Upload Outro Video",

        introl1: "Intro video is coming on start of RoundTable for max. 15sec",
        introl2: "Ideal ratio for intro video is 16:9 ratio",
        introl3: "It only supports .mp4",
        introl4: "It has max. limit as 100mb",
        uplplace: "Upload Intro Video",

        outro1: "Outro video is coming after end of RoundTable for max. 15sec",
        outro2: "Ideal ratio for outro video is 16:9 ratio",
        outro3: "It only supports .mp4",
        outro4: "It has max. limit as 100mb",
      },
      attachdoc: "Attach Document",
      docplaceholder: "Upload Document",
      docRecommendation: "Recommendation",
      docl1:
        "1. Upload a document for making users more understandable or give them glimpse of conversation",
      docl2: "It only supports doc, xls, ppt, pdf",
      docl3: "It has max. limit as 15mb",

      category: "Categories",
      catergoryplace: "Search and add",
      tags: "Tags",
      addbtn: "Add",
    },
    pg4: {
      invite: "Invite",
      iinvite:
        "You can invite your all followers, your followings, or attendees of previous RoundTables",
      viewer: "Viewers",
      invitemore: "Invite More",
      iinvite:
        "You can invite any user who is present on Khul Ke by searching their name. Also, you can invite users who are not present on Khul Ke by entering their mobile number or email id",
      searchuser: "Search a user",
      addMobile: "Add Mobile or Email ID to invite",
    },
    review: {
      title: "Review RoundTable",
      date: "Date",
      Starttm: "Start Time",
      Endttm: "End Time",
      Duration: "Duration",
      invalid: "Invalid date",
      logos: "Logos",
      Attach: "Attachment",
      nodoc: "No documents added!",
      categories: "Categories",
      ppants: "Participants",

      discard: {
        dtitle: "Discard RoundTable",
        text: "Are you sure you want to discard this information?",
        y: "Yes, I'm Sure",
        n: "No, Keep It",
      },
    },
    livert: {
      mMODERATOR: "MODERATOR",
      mPANELIST: "PANELIST",
      mWIDLCARD: "WILDCARD",
      Audience: "Audience",
      admin: "Admin",
      moderator: "Moderator",
      owner: "Owner",
      panelists: "Panelists",
      panelist: "Panelist",
      audiences: "Audiences",
      interac: "Interactions",
      time: "Time",
      joinNow: "Join Now",
      watchNow: "Watch Now",
      live: "Live",
      upcomming: "Upcomming",
      leaveRtDesc: "Are you sure you want to leave this roundtable?",
      rtStartSoon: "RoundTable will be starting soon",
      modNotJoined: "Moderator has not joined yet, waiting for Moderator",
      nodNotPres: "Moderator is not present, so this Round Table is cancelled.",
      Nodata: "No Data Found",
      msgplace: "Write what you feel about this…",
      send: "SEND",
      follow: "Follow",
      unfollow: "Unfollow",
      following: "Following",
      followers: "Followers",
      recc: "Recommended RoundTable with same moderator",
      comment: "Comment",
      writeplace: "Write your content here...",
      leave: "Leave RoundTable",
      dis: "Disclaimer",
      end: "RoundTable is about to end",
      ext: " RoundTable time is extended by",
      extent: "Extend",
      extentTime: "Extend Time",
      sureendthis: "Are you sure you want to end the RoundTable ?",
      extendbymin:
        "Kindly select the minutes from below to extend this RoundTable",
      min: "mins",
      minutes: "Minutes",
      tleft: "Time Left: ",
      over: "This Roundtable is already over.",
      rt: "RoundTable",
      rec: "Recording of roundtable is going on",
      starredPost: "Your post has been starred by the Moderator.",
      unstarredPost: "Your post has been unstarred by the Moderator.",
      del: "Delete",
      copy: "Copy link to post",
      poll: "poll",
      vote: "Vote",
      fpoll: "First poll",
      Participants: "Participants",
      mod: "Moderator",
      panelist: "Panelist",
      raisehand: "Raise Hand Chat",
      rhp: "Send a message to get the attention of the moderator.",
      wques: "Write your question here...",
      mini: "Minimize Roundtable",
      disclaimer: "Disclaimer",
      endRt: "End Roundtable",
      full: "Fullscreen",
      notifyMe: "Notify me",
      noopt: "No options",
      intro: "Introduction",
      editp: "Edit panelist",
      padd: "Please add a moderator",
      pro: "PROCEED",
      dis: "DISCARD",
      rtcreated: "RoundTable Created Successfully",
      rt_updated: "RoundTable Updated Successfully",
      aw: "Awesome",
      nolog: "No logo added!",
      novid: "No video added!",
      nodoc: "No documents added!",
      invitecount: "Invitees count ",
      c: "Copy link",
      p: "Post it",
      you: "You",
      circ: "Circulate",
      circed: "Circulated",
      quote: "Quote",
      srec: "Start Recording",
      endrec: "End Recording",
      muted: "Muted Audience",
      nomuted: "There are no muted participants!",
      pchat: "Panelist Chat",
      am: "AM",
      pm: "PM",
      addopt: "Add Option",
      option: "Option",
      polldur: "Poll Duration",
      d: "Days",
      h: "Hours",
      m: "Mins",
      fewago: "few secs ago",
      view: "view",
      wm: "Wildcard Message",
      wildc: "Wildcard",
      // writqu: "Write what you feel about this...",
      writqu: "write your post here...",
      reply: "replying to",
      repliedto: "replied to",
      past: "past",
      nothingToShow: "Nothing to show!",
      userAlreadyAdded: "This user is already added!",
      uploadRecRT: "Upload Recorded RoundTable",
      enterValidTitle: "Kindly enter the title before uploading the image.",
      enterRecordedRT: "Please upload recorded audio/video file.",
      startRT: "Do you want us to remind you when the RoundTable starts?",
    },
    reqacc: "Request Access",
    skip: "Skip",
    next: "Next",
    created_post: "Your post has been created.",
    created_post_success: "Your post is posted successfully.",
    created_snip_success: "Your snip-it  has been successfully posted!",
    created_khabar_success: "Your D2M Video has been successfully posted!",
    created_bol_success: "Your View has been successfully posted!",
    deleted_post: "This post has been deleted",
    deleted_post_success: "Post has been deleted successfully",
    deleted_snip_success: "Snip-It has been deleted successfully",
    deleted_khabar_success: "D2M has been deleted successfully.",
    deleted_Bol_success: "View has been deleted successfully.",
    deleted_rountable_success: "RoundTable has been deleted successfully",
    raised_hand: "Raised Hand",
    liked_post: "liked your post",
    disliked_post: "disliked your post",
    circulated_post: "circulated your post",
    quoted_post: "quoted your post",
    commented_post: "commented your post",
    mention_post: "mentioned you",
    replied_post: "replied on your post",
    write: "write",
    create: "Create",
    close: "Close",
    file: "File",
    btn_cancel: "Cancel",
    allow: "Allow",
    reject: "Reject",
    text_wilduser: "Add as a wildcard panelist",
    DOB: "DOB",
    website: "Website",
    fullname: "Full Name",
    difficulty: "We are facing some difficulty, please try again",
    for_any_support_you_can_reach_us_at: "For any support, you can reach us at",
    add: "Add",
    goback: "Go back",
    backlisting: "Back To Listing",
    yes: "YES",
    no: "NO",
    bycont: "By continuing, I accept",
    termsof: "Terms of Use",
    privacy: "Privacy Policy",
    upload: "Upload Now",
    reqs: "Request Sent",
    sht: "Share on Townhall",
    rsh: "Recent Searches",
    avionceconfirm: "Available once RoundTable is confirmed",
    nomoreinvitee: "No More Invitees to show, you reached bottom",
    norest: "No results found",
    beawp: "Be a Wildcard Panelist",
    bewp2:
      "Be a Wildcard Panelist. Moderator wants you to be a wildcard panelist",
    ipr: "Invite By Previous RoundTable",
    rtNameDate: "RoundTable Name and Date",
    attendees: "Attendees",
    searchByRt: "Search By RoundTable Name",
    searchByMod: "Search By Moderator Name",
    searchByPan: "Search By Panelist Name",
    searchByCategory: "Search By Category",
    searchByTags: "Search By Tags",
    rmfromwild: "Remove from wildcard",
    nopast: "There are no past RoundTables",
    advanceSearch: "Advanced Search",
    wsomethin: "Write something...",
    save: "Save ",
    rotate: "Rotate image",
    maintainar: "Maintain Aspect Ratio",
    customar: "Custom Aspect Ratio",
    ays: "Are you sure?",
    yas: "Yes, I'm sure",
    news: "News",
    showallrep: "Show all replies",
    recom: "Recommended RoundTable with same",
    recomOwn: "Recommended RoundTable with same Owner",
    recomMod: "Recommended RoundTable with same Moderator",
    confDel: "Confirm roundtable deletion",
    proceed: "PROCEED",
    conf: "Are you sure you want to delete this roundtable?",
    confdiscard: "Are you sure you want to discard this RoundTable",
    stover: "Start time is already done.",
    kindlywait: " Kindly update the start time.",
    edit: "Edit",
    leaveQ: "YES, LEAVE QUIETLY",
    nostay: "NO, I WILL STAY",
    inviteFollow: "Invite your Followers",
    yhs: " You have selected the",
    oyc: "Once you confirm, you cannot undo this selection.",
    wyltr:
      " Would you like to receive a notification when we upload the recording of this RoundTable ?",
    cont: "Congratulations!",
    ycanytomerestore: "You can anytime restore previous username  Within this",
    days: "days",
    savedraft: "Save to draft",
    editaudio: "Edit Audio",
    cancel: "Cancel",
    cancelled: "Cancelled",
    sendforRaisehand: "Send a message to get the attention of the moderator.",
    viewm: "View Messages",
    dismiss: "Dismiss",
    nouserRaised: "No users has raised hands yet",
    raisehanWmessage: "User has only raised hand without any message",
    removeuser:
      "Are you sure you want to remove this user from raise Hand list?",
    star: "Mark as star",
    unstar: "Mark as unstar",
    showthispoll: "Show this poll",
    joinreq: "Join Request",
    youdonthavemsg: "You dont have a message selected.",
    accept: "Accept",
    reject: "Reject",
    nonoti: "No new notifications!",
    youarenotflw: "You are not following anyone",
    youhavenofollowers: "You have no followers",
    listofRT: "List Of Your RoundTables",
    pindiscussion: "  Participate in Live RoundTable Discussions",
    comingrt: '"Coming Soon" - Exciting Upcoming RoundTables',
    sevenday: "7 days",
    thirtyday: "30 days",
    continueonBrowser: "Continue on browser or redirect on app!",
    openinApp: "Open in app",
    stayinbroweser: " Stay on browser",
    email: "Email",
    update: "Update",
    mob: "Mobile",

    deactiveModal: {
      deactivateAcc: "Deactivate your account",
      desuccess: "Your account has been deactivated successfully.",
      areyousure: " Are you sure you want to deactivate your account?",
      yes: "Yes",
      no: "No",
      note: " Note before deactivating your account",
      p1: "You can revive your account by logging in within 30 days.After 30  days, the account will be permanently deleted along with all theposts, and media.",
      p2: "Some more information",
      p3: "You can restore your Khul Ke account if it was accidentally or wrongfully deactivated for up to 30 days after deactivation.",
      p4: " Some account information may still be available in search engines, such as Google or Bing.",
      p5: "Learn more",
      p6a: "  If you just want to change your",
      p6b: ", you don't need to deactivate your account — edit it in your",
      p7a: "If you just want to change your",
      p7b: "profile name",
      p7c: ", you don't need to deactivate your account — edit it in your",
      p8a: " If you just want to change your",
      p8b: "profile image",
      p8c: ", you don't need to deactivate your account — edit it in your",
      p9a: "  If you just want to change your",
      p9b: "password",
      p9c: ", you don't need to deactivate your account — edit it in your",
      p10: "If you want to download your Khul Ke data, you'll need to complete both the request and download process before deactivating your account.Links to download your data cannot be sent to deactivated accounts.",
      settings: "settings",
    },

    pages: {
      mute: "Mute",
      beaw: "Be a Wildcard Panelist",
      beawild:
        "Be a Wildcard Panelist. Moderator wants you to be a wildcard panelist",
      removeme: "Remove me from wildcard",
      removewild: " Remove Wildcard",
      unmute: "Unmute",
      belowhavehands: "Below users have raised hand",
      addwild: "Add Wildcard",
      waitingforscreen: "Waiting for Shared Screen",
      screenshared: "Your Screen is being Shared",
      loading: "Loading...",
      facingDiffi: "We are facing some difficulty. Please try again.",
      consentsent: "Wildcard consent is already sent.",
      cantaddw: "User is offline, can not add as wildcard.",
      fivespeaker:
        "There are already 5 panelist. So you cannot add any wildcard panelist",
      logintoview: "To view profile , Login or sign up to Khul Ke",
      camerataken:
        "Your camera is currently being used by another application. Turn off your camera access and close the other app to use camera for Khul Ke",
      cantgetcam: "Can not get your camera and mic track",
      distitle: "Disclaimer",
      disclaimer:
        'The information, views, opinions, and arguments (collectively "Views") showcased and presented by the panelist, or the interviewees solely belong to the individual who has expressed it and Loktantra Mediatech Private Limited("Company") does not agree, condone, endorse, or subscribe to the views expressed in the duration of the interview and makes no representation of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any view or opinion expressed by any panelist or the interviewee.The content showcased here is for informative purposes only and should not be construed for anything other than this.Any malice or hurting of sentiments is unintentional.Under no circumstance shall the Company have any liability for the Views of any panelist or interviewee, nor shall the Company have any liability for any loss or damage of any kind incurred because of Views expressed by the panelist. Your use of this platform is solely at your own risk.',

      blocked: {
        Unblock: "Unblock",
        nouserblock: "No users blocked!",
      },
      pagenotfound: "Oops...Can't find this page.",
      gotohome: "Go to Homepage",
      nomute: "No users muted!",
      other: "others",
      page: "Page",
      of: "Of",
      gender: "Gender",
      male: "Male",
      female: "Female",
      other: "Other",
      interest: "Interest",
      selectprivateinfo:
        "Select the information that will be visible on your Account Info page.",
      privacySettings: "Privacy Settings",
      update: "UPDATE",
      acc: "Account",
      dob: "Date of Birth",
      loc: "Location",
      int: "Interest",
      lang: "Language",
      site: "Website",
      usernotfound:
        "User with username not found! Please try another username.",
      makecoowner: "Make Co-owner",
      l1: "Panelists are the speakers for the RoundTable.",
      l2: "Minimum zero and max. 5 panelists can be added in single RoundTable.",
      l2a: "Minimum zero and max. 1 panelist can be added in single RoundTable.",
      l3: "You can add 100 words as description for each panelist",
      back2rt: "BACK TO ROUNDTABLE",
      thread: "Thread",
      prelog: "This will be available only for registered users.",
      redir: "Redirecting",
      dis: "Discard",
      proupdated: "Your profile is updated",
      // forgot password page
      fortitle: "Forgot Password?",
      for1: "Enter the phone number or email associated with",
      for2: "your Khul Ke account",
      rempass: "Remember Password?",
      login: {
        loginl: "Login To Your Account",
        logintitleleft: "Enter the world of conversations",
        login: "Log In",
        newuser: " New User?",
        cra: "Create an account",
        infotext: " For any support, you can reach us at",
        intro: "Khul Ke - Social Networking Platform",
        bycont: "By continuing, you agree to accept our",
        pp21: "A platform where conversations matter.",
        pp22: "Enter OTP",
        pp23: "OTP sent to",
        pp24: "Resend OTP",
        pp25: "Didn't receive the OTP?Resend in",
        pp26: "sec",
        pp27: "Enter Password",
        pp28: "Go back",
        pp29: "Get OTP",
        restpass: "Reset your password",
        enternewpass: "Enter New Password",
        retypepass: "Retype your password.",
        conto: "Continue To",
        warning:
          "You are already registered with us. Kindly enter OTP to log in.",
      },
      signup: {
        siuptitle: "Sign Up",
        sup: "Sign Up",
        enteradd: "Enter Email Address",
        usephone: "Use phone instead",
        ph1: "Enter Phone No.",
        usemail: "Use email Id instead",
        pg2: "A platform where conversations matter.",
        enterotp: "Enter OTP",
        resend: "Resend OTP",
        alreadya: "Already have an account?",
        warning:
          "You are not registered with us. Kindly enter OTP for registration.",
      },
      prelogin: {
        til1: "Only 2 steps sign up process",
        til2: "Enter mobile number or email id",
        til3: "OTP verification",
        til4: "Enter Email/ Mobile/ Username",
        pleaseadd: "Please enter a valid number",
        pleaseaddEmail: "Please enter a valid email",
        validem: "Please enter a valid Email Id.",
        mtext: "Log in or sign up to interact.",
        entero:
          "Enter the phone number or email associated with your Khul Ke account",
      },
    },

    broadcast: {
      dest: "Destination",
      connAccount: "Connect your account to Broadcast",
      noBroadcast: "No broadcast destination has been added",
      addDest: "Add a destination",
    },

    changePass: {
      verify: "Verify",
      change: "Change Password",
      enterOld: "Enter Old Password",
      enterNew: "Enter New Password",
      confirmNew: "Confirm New Password",
      bothMatch: "Both passwords must match.",
    },

    mute: {
      addMuted: "Add Muted Words",
      noMuted: "No words muted!",
    },
  },
  walkthrough: {
    walkthrough_dailog_question: "Do you want to see this again?",
    step_1_1: "Welcome to KhulKe,",
    step_1_2: "would you like to take a tour?",
    step_2:
      '<span>Scroll through posts and network here at <span style="color:#66b984; font-weight: bold">TownHall</span> </span>',
    step_3:
      "<span>Interact with posts using the like, circulate, share and comment options</span>",
    step_4: "<span>No time? Watch short videos</span>",
    step_5:
      '<span style="color:#66b984; font-weight: bold">Create </span>a post',
    step_6:
      "Post text, images, documents, videos, audio and also create polls.",
    step_7: "Conduct a conversation and explore hot topics",
    step_8:
      'Find past, upcoming, live and your <span style="color:#66b984; font-weight: bold">RoundTables </span>here ',
    step_9:
      'Create your own <span style="color:#66b984; font-weight: bold">RoundTable </span> ',
    step_10: "Talk to your friends and followers discreetly",
    step_11:
      'View and customize your <span style="color:#66b984; font-weight: bold">Profile</span>',
  },
  dateTime: {
    months: {
      jan: "Jan",
      feb: "Feb",
      mar: "Mar",
      apr: "Apr",
      may: "May",
      jun: "Jun",
      jul: "Jul",
      aug: "Aug",
      sep: "Sep",
      oct: "Oct",
      nov: "Nov",
      dec: "Dec",
    },
    validation: {
      dobValidation:
        "It look's like you've entered the wrong info. Please make sure that you use real date of birth.",
    },
    invitationPopUp: {
      youHaveBeenInvited: "You have been invited as a",
    },
  },
  welcomePop: {
    welcome: "Welcome",
    createUsername: "Create username",
    updateUsername: "Update your username",
    addDP: "Add your profile picture, update your username",
    selectLang: "and choose language",
    interested: "If you are interested in password login,",
    kindly: "then kindly",
    setPass: "set your password",
  },
  post: {
    pollCreate: "Your poll has been created.",
  },
  rtList: {
    owner: "RoundTables as owner",
    moderator: "RoundTables where you were a Moderator",
    panalist: "Roundtables where you were a Panelist",
    audiance: "Roundtables where you were an Audience",
  },
  keyMoments: {
    addKeyMoment: "Add Key Moments",
    keyMoments: "Key Moments",
    rountableChapter: "RoundTable Chapters",
    title: "Title",
    addTime: "Add time",
    addTitle: "Add title",
    rtKeyMom: "RoundTable Key Moments",
    validation: {
      invalidFormat: "MM:SS is a valid format",
      withinVideoTimeframe: "Please add timestamp within the video timeframe",
      addSequentially: "Please add key moments sequentially",
      maxLimit: "Each chapter should be max. 10min",
      minLimit: "Each chapter should be min. 15sec",
      allFieldsFilled: "Please check if all the fields are properly field.",
      createdSuccessfully: "Your Key Moments are created successfully.",
      updatedSuccessfully: "Your Key Moments are updated successfully.",
    },
    dummyKeyMom: {
      one: "Introduction of host and guest",
      two: "The guest talks about the rain",
      three: "The guest talks about climate change",
      four: "Metro Project",
      five: "Impact on Ordinary People",
      six: "Transport and train problems due to heavy rain",
      seven: "What is the plan for the rescue operation?",
      eight: "What's the next weather forecast?",
      nine: "Guest tips for people in this situation",
      ten: "Conclusion for the topic",
    },
    exampleHeadingKeyMom: "Example for Key Moments",
    section1: {
      heading: "What are Key Moments in RoundTable?",
      content1: `RoundTable Key Moments are the "highlights" of your RoundTable discussion. They mark points in your timeline that relate to particular topics or answer particular questions.`,
      content2: `Typically, key moments in your video appear as the timing and title to describe that section.`,
      content3: `On the RoundTable itself, Key Moments don't show up in search results. However, they will appear in your video description and, if you meet certain conditions, then in your video's progress bar.`,
    },
    section2: {
      heading: "Why are RoundTable key moments important to users?",
      content1: `Key Moments help your video rank higher in search results, thus driving traffic to your RoundTable. This is because the additional information helps Khul Ke to decide whether your RoundTable is a good fit for certain searches.`,
      content2: `Likewise, RoundTable Key Moments make life easier for your audience. In an instant, they can see whether or not your video can give them what they're looking for. If so, one click takes them directly to those moments in the video.`,
      content3: `Because people know what they're getting into when they start watching your video, key moments lead to better engagement scores. This means fewer downvotes and more shares, which Khul Ke's algorithm rewards with higher ranks in RoundTable searches and suggestions.`,
    },
    section3: {
      heading1: "Where to add key moments from your RoundTable?",
      heading2: `Key moments can be accessed through 3 ways by the owner/co-owner of the
      RoundTable:`,
      content1: `RoundTable listing page.`,
      content2: `RoundTable description.`,
      content3: `RoundTable card.`,
    },
    section4: {
      heading: "How to add Key Moments to your RoundTable videos?",
      content1: `Log in to the Khul Ke account.`,
      content2: `Go to the RoundTable which you have created and that completed successfully`,
      content3: `Click on the details of that RoundTable, you will find the button "Add Key Moments"`,
      content4: `Click on "Add Key Moment" button, and add the time and title in chronological order`,
      content5: `Add each time and title to the next line`,
      content6: `Save key moments (you can change these key moments later at any time by using edit icon)`,
    },
    section5: {
      heading:
        "To enable Key Moments, you must meet a few additional conditions:",
      content1: `Time should be in chronological order.`,
      content2: `The key moment must be at least 15 seconds long.`,
      content3: `You need at least 3 key moments.`,
      content4: `Key moments can be a maximum 20.`,
    },
  },
  report: {
    details: "Details",
    report: "Report",
    embedPost: "Embed Post",
    whyReport: "Why are you reporting this post?",
    spam: "It's Spam",
    falseInfo: "False Information",
    hateSpeech: "Hate Speech",
    scamFraud: "Scam or Fraud",
    suicideSelfHarm: "Suicide or self harm",
    nuditySexualActivity: "Nudity or sexual activity",
    pleaseElaborate: "Please elaborate your reason for reporting",
    submit: "Submit",
    thankYou: "Thank You for letting us know",
    yourFeedback:
      "Your feedback is important in helping us keep the KhulKe community safe",
    furtherSteps: "Further Steps to be Taken",
    block: "Block",
    blockDesc:
      "will not be able to follow you or view your posts and you will not be able to view posts or get notifications from",
    unfollow: "Unfollow",
    unfollowDesc:
      "You will no longer be able to view posts or get notifications from",
    mute: "Mute",
    muteDesc1: "Posts from",
    muteDesc2: "will be hidden for you but you will still get notifications.",
    close: "Close",
    cancel: "Cancel",
    youBlocked: "You blocked",
    canUnblock:
      "You can unblock this account anytime from your account settings.",
    youUnfollowed: "You unfollowed",
    canFollow: "You can follow this account anytime from their profile.",
    youMuted: "You muted",
    canUnmute:
      "You can unmute this account anytime from from your account settings.",
  },
  feedback: {
    feedback: "Feedback",
    leavefeed: "Leave Feedback",
    add: "Add Photos or Videos",
    submit: "Submit",
    enterfeed: "Enter Feedback",
    thankYou: "Thank you for your valuable feedback!",
    success: "Your feedback has been submitted successfully",
    gotohome: "Go To Home",
  },
  showcase: {
    title: "Showcase",
    description: "Showcase your videos to the KhulKe community",
    success: "Your showcase has been submitted successfully",
    gotohome: "Go To Home",
    removeShowcase: "Remove from Showcase",
    addToShowcase: "Showcase",
    comments: "Comments",
    likes: "Likes",
    dislikes: "Dislikes",
    circulated: "Circulates",
    quotes: "Quotes",
  },
  getApp: {
    playStore: "Play Store",
    appStore: "App Store",
    getTheApp: "Get The App",
    download: "Download",
  },
};

export default allWordsE;
