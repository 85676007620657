import React, { useState, useEffect } from "react";
import { CancelOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setSharLink,
  setDialogStatus,
  setPostParentType,
} from "../../redux/actions/PostSuccessAction";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { allWords } from "../../App";
import ToastHandler from "../../utils/ToastHandler";
import "./style.css";

// Using MUI v5's sx prop for styling
const PostSuccessDialog = () => {
  const dispatch = useDispatch();
  const { dialogStatus, shareLink, postParentType } = useSelector(
    (state) => state.postSuccessDialogData
  );
  const [isToastVisible, setIsToastVisible] = useState(false);

  const handleCloseToast = () => {
    setIsToastVisible(false);
    dispatch(setSharLink(""));
    dispatch(setDialogStatus(false));
    dispatch(setPostParentType(""));
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);
      ToastHandler("sus", allWords.misc.succcopied);
    } catch (error) {
      ToastHandler("dan", "Failed. try again!");
    }
  };

  useEffect(() => {
    if (dialogStatus) {
      setIsToastVisible(true);
    }
  }, [dialogStatus]);

  return (
    <Dialog
      open={isToastVisible}
      onClose={handleCloseToast}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          overflowY: "unset",
          width: {
            xs: "97%", // For small screens
            sm: "80%", // For medium screens and above
          },
          maxWidth: {
            sm: "580px", // For medium screens and above
          },
          margin: "0 auto",
        },
      }}
      data-test="src-components-PostSuccessDialog-index-0"
    >
      <DialogTitle
        sx={{
          backgroundColor: ["SKK", "BKK"].includes(postParentType)
            ? "#100A1A"
            : "",
        }}
      >
        <IconButton
          onClick={handleCloseToast}
          sx={{ position: "absolute", right: 8, top: 8 }}
          data-test="src-components-PostSuccessDialog-index-1"
        >
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={
          ["SKK", "BKK"].includes(postParentType)
            ? "skk-modal-content"
            : "modal-content"
        }
      >
        <Box
          sx={{ textAlign: "center", mb: 2 }}
          data-test="src-components-PostSuccessDialog-index-2"
        >
          {/* Success message */}
          {postParentType === "SNIPPET"
            ? allWords.misc.created_snip_success
            : postParentType === "K3"
              ? allWords.misc.created_khabar_success
              : ["SKK", "BKK"].includes(postParentType)
                ? allWords.misc.created_bol_success
                : allWords.misc.created_post_success}
        </Box>
        <Box
          sx={{ textAlign: "center", mb: 2 }}
          data-test="src-components-PostSuccessDialog-index-3"
        >
          {allWords.misc.share}
        </Box>
        <OutlinedInput
          sx={{
            backgroundColor: "#222224",
            color: "#E4E9F1",
            border: "1px solid #70707040",
            borderRadius: "10px",
            width: "100%",
            maxWidth: "529px",
            height: "48px",
            padding: "8px",
            mb: 2,
          }}
          readOnly
          placeholder="Enter website link"
          value={shareLink}
          endAdornment={
            <InputAdornment position="end">
              <Button
                variant="contained"
                onClick={handleCopyLink}
                sx={{
                  padding: "1rem",
                  width: "fit-content",
                  height: "32px",
                  background: "#7AB788",
                  borderRadius: "60px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  "&:hover": {
                    background: "#7AB788",
                  },
                }}
                data-test="src-components-PostSuccessDialog-index-4"
              >
                {allWords.misc.livert.c}
              </Button>
            </InputAdornment>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default PostSuccessDialog;
