import React from "react";
import CaptionInput from "./CaptionInput";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import DeleteSVG from "../../../assets/icons/delete.svg";
import CropTrimSVG from "../../../assets/icons/image crop.svg";
import { allWords } from "../../../App";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .imgSingle {
    border-radius: 1rem;
    margin-left: 155px;
    /* border: 1px solid #A2A2A280; */
    padding: 2px;
    /* width: 100% !important; */
    height: 145px !important;
    object-fit: contain !important;
  }
  .add_post_container {
    position: relative;
    .img {
      width: 100% !important;
      border-radius: 0.5rem !important;
      padding: 2px;
      height: 230px !important;
      /* object-fit: cover; */
    }

    .icon_container {
      position: absolute;
      top: 10px; /* Move to the top */
      right: 10px;
      display: flex;
      flex-direction: column; /* Stack icons vertically */
      align-items: flex-end;

      .icon {
        width: 45px;
        height: 45px;
        margin-right: 0.5rem;
      }
    }
    .croptrim_icon_container {
      position: absolute;
      bottom: 10px; /* Move to the bottom */
      right: 10px;

      .icon {
        width: 45px;
        height: 45px;
        margin-right: 0.5rem;
      }
    }
  }
`;

const ImageContainer = ({
  editImage,
  setEditImage,
  imgArray,
  setImgArray,
  imgSrc,
  index,
  setImageSrc,
  imageSrc,
  setCaption,
  setState,
  onCaptionChange,
  captionLength,
  imageCaption,
  onDeleteCaption,
  isShowCaption = true,
}) => {
  {
    /* TODO: need to confirm for this ternary check as no array handling done in this component */
  }

  const isImage = imgSrc.file && imgSrc.file.type.startsWith("image/");
  const isVideo = imgSrc.file && imgSrc.file.type.startsWith("video/");
  return (
    <Container>
      <div
        className="add_post_container"
        data-test="src-components-Post-AddPostDialog-ImageContainer-0"
      >
        {isImage && (
          <img
            className="img"
            src={imgSrc.url}
            alt="Uploaded content"
            data-test="src-components-Post-AddPostDialog-ImageContainer-1"
          />
        )}
        {isVideo && (
          <video width="320" height="240" controls>
            <source src={imgSrc.url} type={imgSrc.file.type} />
            Your browser does not support the video tag.
          </video>
        )}
        {imgSrc && (
          <div
            className="croptrim_icon_container"
            data-test="src-components-Post-AddPostDialog-ImageContainer-2"
          >
            <IconButton
              className="icon"
              onClick={() => {
                setEditImage(true);
                setImageSrc({
                  index: index,
                  url: imgSrc.url,
                  file: imgSrc.file,
                });
              }}
              data-test="src-components-Post-AddPostDialog-ImageContainer-3"
            >
              <img
                src={CropTrimSVG}
                alt="Crop or trim icon"
                data-test="src-components-Post-AddPostDialog-ImageContainer-4"
              />
            </IconButton>
          </div>
        )}
        {imgSrc && (
          <div
            className="icon_container"
            data-test="src-components-Post-AddPostDialog-ImageContainer-5"
          >
            <IconButton
              className="icon"
              onClick={() => {
                imgArray.splice(index, 1);
                if (isShowCaption) onDeleteCaption(index);
                setImgArray([...imgArray]);
                if (setState && imgArray.length === 0) {
                  setState(false);
                }
              }}
              data-test="src-components-Post-AddPostDialog-ImageContainer-6"
            >
              <img
                src={DeleteSVG}
                alt="Delete icon"
                data-test="src-components-Post-AddPostDialog-ImageContainer-7"
              />
            </IconButton>
          </div>
        )}
      </div>

      {/* <div
        className="add_post_container"
        data-test="src-components-Post-AddPostDialog-ImageContainer-0"
      >
        <img
          className="img"
          src={imgSrc.url}
          alt="images"
          data-test="src-components-Post-AddPostDialog-ImageContainer-1"
        />
        {imgSrc && (
          <div
            className="croptrim_icon_container"
            data-test="src-components-Post-AddPostDialog-ImageContainer-2"
          >
            <IconButton
              className="icon"
              onClick={() => {
                setEditImage(true);
                setImageSrc({
                  index: index,
                  url: imgSrc.url,
                  file: imgSrc.file,
                });
              }}
              data-test="src-components-Post-AddPostDialog-ImageContainer-3"
            >
              <img
                src={CropTrimSVG}
                alt="delete_icon"
                data-test="src-components-Post-AddPostDialog-ImageContainer-4"
              />
            </IconButton>
          </div>
        )}
        {imgSrc && (
          <div
            className="icon_container"
            data-test="src-components-Post-AddPostDialog-ImageContainer-5"
          >
            <IconButton
              className="icon"
              onClick={() => {
                imgArray.splice(index, 1);
                isShowCaption ? onDeleteCaption(index) : null;
                setImgArray([...imgArray]);
                if (setState) {
                  if (imgArray?.length === 0) {
                    setState(false);
                  }
                }
              }}
              data-test="src-components-Post-AddPostDialog-ImageContainer-6"
            >
              <img
                src={DeleteSVG}
                alt="delete_icon"
                data-test="src-components-Post-AddPostDialog-ImageContainer-7"
              />
            </IconButton>
          </div>
        )}
      </div> */}

      {imgSrc &&
        window.location.pathname !== "/roundtable/join" &&
        isShowCaption && (
          <>
            <div
              style={{ display: "flex", alignItems: "center" }}
              data-test="src-components-Post-AddPostDialog-ImageContainer-8"
            >
              <CaptionInput
                fullWidth
                placeholder={allWords.th.imgplcHolder}
                onChangeCaption={onCaptionChange}
                maxLength={50}
                imageCaption={imageCaption}
              />
            </div>
            {/* <CaptionInput
            fullWidth
            placeholder="Add tags"
            onChange={(e) => {
              // setCaption(e.target.value);
            }}
          /> */}
          </>
        )}
    </Container>
  );
};

export default ImageContainer;
