import { Typography } from "@mui/material";
import React from "react";
import "./style.css";
import LevelContainer from "./LevelContianer";
import Townhall from "../../assets/images/townhall.svg";
import Roundtable from "../../assets/images/roundtable.svg";
import SnipIt from "../../assets/images/snip-it.svg";
import Like from "../../assets/images/like.svg";
import Comments from "../../assets/images/comment.svg";
import ArrowRight from "../../assets/images/feather-arrow-right.svg";
import { ProfilePageFlag } from "../../pages/Profile";
import * as _ from "lodash";

import DisableBadge from "../../assets/images/disable-trophy.svg";
import Trophy from "../../assets/images/trophy.svg";
import BeginnerCreator from "../../assets/SVG/Creator/Creator - Beginner.svg";
import BeginnerModerator from "../../assets/SVG/Moderator/Moderator - Beginner.svg";
import BeginnerPanelist from "../../assets/SVG/Panelist/Panelist  - Beginner.svg";
import BeginnerAudience from "../../assets/SVG/Audience/Audience - Beginner.svg";
import BeginnerSnipItCreator from "../../assets/SVG/Creator/Creator - Beginner.svg";
import BeginnerViewer from "../../assets/SVG/Viewer/Viewer - Beginner.svg";
import BeginnerSnipItViewer from "../../assets/SVG/Viewer/Viewer - Beginner.svg";

import CompetentCreator from "../../assets/SVG/Creator/Creator - Competent.svg";
import CompetentModerator from "../../assets/SVG/Moderator/Moderator - Competent.svg";
import CompetentPanelist from "../../assets/SVG/Panelist/Panelist  - Competent.svg";
import CompetentAudience from "../../assets/SVG/Audience/Audience - Competent.svg";
import CompetentSnipItCreator from "../../assets/SVG/Creator/Creator - Competent.svg";
import CompetentViewer from "../../assets/SVG/Viewer/Viewer - Competent.svg";
import CompetentSnipItViewer from "../../assets/SVG/Viewer/Viewer - Competent.svg";
import { allWords } from "../../App";

function ProfilePointer({
  width = 45,
  height = 45,
  borderRadius = "0.5rem",
  alt = "",
  id = "pp_img",
  boxShadow = "0px",
  hidden,
  setOnPointScreen = () => {},
  user_activities_badge = {},
  user_activities_level = {},
  user_activities_points = {},
  badges = [],
  level = 0,
  points = 0,
  nextLevelPoints = 0,
}) {
  const imageStyle = {
    width,
    height,
    borderRadius,
    cursor: "pointer",
    boxShadow,
  };

  const getPointImage = (category) => {
    switch (category) {
      case "RoundTable":
        return Roundtable;
      case "Snip-It":
        return SnipIt;
      case "MeetUp":
      case "Others":
      default:
        return Townhall;
    }
  };
  const earnMorePoints = [];
  _.shuffle(Object.entries(user_activities_points)).forEach(([key, value]) => {
    if (earnMorePoints.length >= 5) return;
    if (value?.name) {
      earnMorePoints.push({
        key,
        ...value,
        img: getPointImage(value.category),
      });
    }
  });

  const getBadgeImage = (badgename) => {
    switch (badgename) {
      case "Creator":
        return badges.includes(badgename) ? CompetentCreator : BeginnerCreator;
      case "Moderator":
        return badges.includes(badgename)
          ? CompetentModerator
          : BeginnerModerator;
      case "Panelist":
        return badges.includes(badgename)
          ? CompetentPanelist
          : BeginnerPanelist;
      case "Audience":
        return badges.includes(badgename)
          ? CompetentAudience
          : BeginnerAudience;
      case "Snip-It Creator":
        return badges.includes(badgename)
          ? CompetentSnipItCreator
          : BeginnerSnipItCreator;
      case "Snip-It Viewer":
        return badges.includes(badgename)
          ? CompetentSnipItViewer
          : BeginnerSnipItViewer;
      case "Viewer":
        return badges.includes(badgename) ? CompetentViewer : BeginnerViewer;
      default:
        return badges.includes(badgename) ? Trophy : DisableBadge;
    }
  };
  return (
    <div className="section" data-test="src-components-ProfilePoint-index-0">
      <div
        className="info-wrapper"
        data-test="src-components-ProfilePoint-index-1"
      >
        <Typography
          sx={{ fontSize: "16px" }}
          className="info-for-interact-profile"
          data-test="src-components-ProfilePoint-index-2"
        >
          As you interact with people, and create content on Khul Ke, you can
          earn points for each interaction and get closer to the next level.
        </Typography>

        <Typography
          sx={{
            fontSize: "18px",
            color: "#66B984",
            cursor: "pointer",
          }}
          className="learn-more-points"
          onClick={() => setOnPointScreen(ProfilePageFlag.earnPointBadges)}
          data-test="src-components-ProfilePoint-index-3"
        >
          LEARN MORE
        </Typography>
      </div>
      {/* badges container */}
      <div
        className="bedges-wrapper"
        data-test="src-components-ProfilePoint-index-4"
      >
        <div className="title" data-test="src-components-ProfilePoint-index-5">
          <Typography
            sx={{
              font: "normal normal 600 20px/24px Work Sans",
              padding: "12px 20px",
            }}
            data-test="src-components-ProfilePoint-index-6"
          >
            {allWords.profilePoint.badges.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              padding: "12px 20px",
              color: "#66B984",
              cursor: "pointer",
            }}
            onClick={() => setOnPointScreen(ProfilePageFlag.badges)}
            data-test="src-components-ProfilePoint-index-7"
          >
            See All
          </Typography>
        </div>
        <div
          className="wrapper wrapper-badges"
          data-test="src-components-ProfilePoint-index-8"
        >
          {Object.entries(user_activities_badge).map(([key, value]) => (
            <div
              className="badges"
              key={key}
              data-test="src-components-ProfilePoint-index-9"
            >
              <div
                class="circular-progress-pointer"
                data-test="src-components-ProfilePoint-index-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  aria-labelledby="title"
                  role="graphic"
                >
                  <title id="title">circular progress bar</title>
                  <circle cx="50" cy="50" r="40"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    id="pct-ind"
                    style={{
                      strokeDashoffset: `${
                        badges.includes(value.name) ? 0 : 360
                      }`,
                    }}
                  ></circle>
                </svg>
                <img
                  id={id}
                  alt={alt}
                  className={`circular-profile-pointer`}
                  src={getBadgeImage(value.name)}
                  style={imageStyle}
                  hidden={hidden}
                  data-test="src-components-ProfilePoint-index-11"
                />
                <Typography
                  sx={{
                    // font: "normal normal medium 12px/16px Work Sans",
                    color: "#F4F4F4",
                    textAlign: "center",
                  }}
                  data-test="src-components-ProfilePoint-index-12"
                >
                  {value.name}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* level container */}
      <div
        className="levels-wrapper"
        data-test="src-components-ProfilePoint-index-13"
      >
        <div className="title" data-test="src-components-ProfilePoint-index-14">
          <Typography
            sx={{
              font: "normal normal 600 20px/24px Work Sans",
              padding: "12px 1.5rem",
            }}
            data-test="src-components-ProfilePoint-index-15"
          >
            {allWords.profilePoint.levels.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              padding: "12px 1.5rem",
              color: "#66B984",
              cursor: "pointer",
            }}
            onClick={() => setOnPointScreen(ProfilePageFlag.levels)}
            data-test="src-components-ProfilePoint-index-16"
          >
            See All
          </Typography>
        </div>
        <div
          className="wrapper"
          data-test="src-components-ProfilePoint-index-17"
        >
          <LevelContainer
            user_activities_level={user_activities_level}
            level={level}
            points={points}
            nextLevelPoints={nextLevelPoints}
          />
        </div>
      </div>
      {/* Earn more points */}
      <div
        className="earn-point-wrapper"
        data-test="src-components-ProfilePoint-index-18"
      >
        <div className="title" data-test="src-components-ProfilePoint-index-19">
          <Typography
            sx={{
              font: "normal normal 600 20px/24px Work Sans",
              padding: "12px 1.5rem",
            }}
            data-test="src-components-ProfilePoint-index-20"
          >
            {allWords.profilePoint.earnMorePoints.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              padding: "12px 1.5rem",
              color: "#66B984",
              cursor: "pointer",
            }}
            onClick={() => setOnPointScreen(ProfilePageFlag.earnPointBadges)}
            data-test="src-components-ProfilePoint-index-21"
          >
            See All
          </Typography>
        </div>
        <div
          className="wrapper earn-points-wrapper"
          data-test="src-components-ProfilePoint-index-22"
        >
          {[
            { img: Townhall, name: allWords.profilePoint.earnMorePoints.createPost },
            { img: Roundtable, name: allWords.profilePoint.earnMorePoints.createRoundTable },
            { img: SnipIt, name: allWords.profilePoint.earnMorePoints.createSnipIt },
            { img: Like, name: allWords.profilePoint.earnMorePoints.likePosts },
            { img: Comments, name: allWords.profilePoint.earnMorePoints.Comment },
          ].map((item, index) => (
            <div
              className="content"
              key={index}
              data-test="src-components-ProfilePoint-index-23"
            >
              <img
                src={item.img}
                alt="townhall"
                style={{ marginRight: "15px" }}
                data-test="src-components-ProfilePoint-index-24"
              />
              <Typography
                sx={{
                  // font: "normal normal normal 14px/16px Work Sans",
                  color: "#fff",
                }}
                data-test="src-components-ProfilePoint-index-25"
              >
                {item?.name}
              </Typography>
              <Typography
                sx={{
                  // font: "normal normal bold 16px/19px Work Sans",
                  marginLeft: "auto",
                  marginRight: "16px",
                }}
                data-test="src-components-ProfilePoint-index-26"
              >
                +5
              </Typography>
              <img
                src={ArrowRight}
                alt="arrow-right"
                data-test="src-components-ProfilePoint-index-27"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProfilePointer;
