import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="Group_19707"
      data-name="Group 19707"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_2949"
        data-name="Rectangle 2949"
        width="24"
        height="24"
        fill="none"
      />
      <rect
        id="Rectangle_2950"
        data-name="Rectangle 2950"
        width="18"
        height="15"
        rx="2"
        transform="translate(3 4.5)"
        fill="none"
        stroke="#f4f4f4"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_15348"
        data-name="Path 15348"
        d="M32,120.253l4.72-4.72a.75.75,0,0,1,1.061,0l4.189,4.189a.75.75,0,0,0,1.061,0l1.939-1.939a.75.75,0,0,1,1.061,0l3.97,3.97"
        transform="translate(-29 -104.503)"
        fill="none"
        stroke="#f4f4f4"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <circle
        id="Ellipse_998"
        data-name="Ellipse 998"
        cx="1.125"
        cy="1.125"
        r="1.125"
        transform="translate(13.5 8.25)"
        fill="#f4f4f4"
      />
    </svg>
  );
}

export default SvgComponent;
