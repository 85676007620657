import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClickAwayListener, Grid, IconButton } from "@mui/material";
import { moengageEvent } from "../../utils/utils";
import recentIcon from "../../assets/icons/Group 1053.svg";
import SearchInput from "../common/SearchInput";
import { allWords } from "../../App";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";

const HomeSearchContainer = ({ colorText }) => {
  const navigate = useNavigate();
  const [recent_searches, setRecentSearches] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleSearch = (e) => {
    let activeTab = "people";
    if (e.target.value[0] === "#") {
      activeTab = "posts";
    }

    if (e.key === "Enter") {
      if (!localStorage.getItem("khulke_recents")) {
        localStorage.setItem(
          "khulke_recents",
          JSON.stringify([e.target.value])
        );
      } else {
        let recents = JSON.parse(localStorage.getItem("khulke_recents"));
        recents.push(e.target.value);
        localStorage.setItem("khulke_recents", JSON.stringify(recents));
      }

      let comp = "";
      if (window.location.pathname.includes("profile")) {
        comp = "User";
      } else if (window.location.pathname.includes("roundtable")) {
        comp = "RoundTable";
      } else {
        comp = "Snip-It";
      }

      moengageEvent("Search", comp, { "Search Text": e.target.value });

      navigate(
        `/search?search_term=${e.target.value.trim().replace(/#/g, "%23")}&active_tab=${activeTab}`
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("khulke_recents")) {
      let temp_search = JSON.parse(localStorage.getItem("khulke_recents"));
      setRecentSearches(
        temp_search.filter((item) => !/^\s*$/.test(item)).reverse()
      );
    }
  }, []);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
      }}
    >
      <div
        className="custom_pos"
        data-test="src-components-RightSideBar-HomeSearchContainer-0"
      >
        <SearchInput
          className="barSearch"
          onKeyDown={(e) => {
            handleSearch(e);
          }}
          onChange={(e) => handleChange(e)}
          onClick={() => setIsOpen(true)}
          color={colorText}
          searchText={searchText}
        />
        {isOpen && (
          <>
            {!window.location.pathname.includes("VideosOnMap") &&
              recent_searches.length > 0 && (
                <Grid
                  data-test="src-components-RightSideBar-HomeSearchContainer-1"
                  className="homeSearchContainer_Container"
                >
                  <Grid className="homeSearchContainer_recentSearch">
                    <p className="homeSearchContainer_recentSearchText">
                      {allWords.misc.rsh}
                    </p>
                    <IconButton
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <CloseIcon
                        className="homeSearchContainer_closeIcon"
                        sx={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </Grid>
                  {recent_searches.map((item) => {
                    return (
                      <Grid
                        key={item}
                        onClick={() => {
                          navigate(
                            `/search?search_term=${item.replace(/#/g, "%23")}&active_tab=${item[0] === "#" ? "posts" : "people"}`
                          );
                        }}
                        className="homeSearchContainer_searchItems"
                      >
                        <img
                          className="homeSearchContainer_recentIcon"
                          src={recentIcon}
                          alt="this is image"
                          data-test="src-components-RightSideBar-HomeSearchContainer-2"
                        />
                        <span
                          style={{ visibility: "hidden", userSelect: "none" }}
                          data-test="src-components-RightSideBar-HomeSearchContainer-3"
                        >
                          a
                        </span>
                        <small className="homeSearchContainer_searchItemText">
                          {item}
                        </small>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default HomeSearchContainer;
