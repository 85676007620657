import React, { useEffect, useMemo, useRef, useState } from "react";
import DeleteSVG from "../../assets/icons/dustbin_icon.svg";
import styledC from "styled-components";
import "./styles.css";
import CropTrimSVG from "../../assets/icons/video trim.svg";
import { Grid, Slider, Typography, Box, IconButton } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  returnTimeString,
} from "../../utils/timeutils";
import { percentToNum, ReturnEndTime } from "../../utils/mathutils";
import ToastHandler from "../../utils/ToastHandler";

const MainDiv = styledC.div`
  position: relative;
  .icon_container {
    top: 0;
    right: 0;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .icon {
      width: 45px;
      height: 45px;
      margin-right: 0.5rem;
    }
  }
`;

const VideoContainer = ({
  videoDuration,
  setVideoDuration,
  videoFilePath,
  setVideoFilePath,
  removeVideo,
  setStep,
  setStartVideoTime,
  setEndVideoTime,
  videoFile,
  editVideo,
  setEditVideo,
  hideEdit,
  parentType,
  setFakeDiv,
  setSelectedScrollsCat,
  scrollsCat,
  ...props
}) => {
  //* using this session storage for video player re-render after selecting catagory
  const storedValueString = sessionStorage.getItem("storedVideoProperty");
  const storedBeforString = sessionStorage.getItem("storedBeforRender");
  const storedVideoProperty = storedValueString
    ? JSON.parse(storedValueString)
    : null;
  const storedBeforRender = storedBeforString
    ? JSON.parse(storedBeforString)
    : null;
  const [currentTime, setCurrentTime] = useState(0);
  const [value, setValue] = useState([0, 0]);
  const [duration, setDuration] = useState(0);
  const [show, setShow] = useState(editVideo);
  const videoRef = useRef();
  const startTimeInputSecondRef = useRef();
  const startTimeInputMinRef = useRef();
  const endTimeInputSecondRef = useRef();
  const endTimeInputMinRef = useRef();
  const [error, setError] = useState(false);
  const canvascontainerref = useRef();
  const canvasvideoref = useRef();
  const [canvasCounter, setCanvasCounter] = useState(0);

  function handleShow() {
    videoRef.current.currentTime = 0;
    setValue([
      storedVideoProperty?._start || 0,
      storedVideoProperty?._end ||
        ReturnEndTime(videoRef.current?.duration, parentType),
    ]);
    setShow(true);
    setFakeDiv(false);
    setEditVideo(true);
    props.setLengthError(false);
    if (show) {
      if (videoDuration == 0) {
        return ToastHandler("warn", "Duration of the clip cannot be 0sec.");
      }
      sessionStorage.setItem(
        "storedVideoProperty",
        JSON.stringify({
          _start: value[0],
          _end: value[1],
          _duration: value[1] - value[0],
        })
      );
      setShow(false);
      setFakeDiv(true);
      setEditVideo(false);
    }
  }

  useEffect(() => {
    if (!show) {
      return;
    }

    if (value[1] < value[0]) {
      setError(true);
      let temp = [0, ReturnEndTime(videoRef.current?.duration, parentType)];
      setValue(temp);
      return;
    }

    if (value[0] > value[1]) {
      setError(true);
      let temp = [0, ReturnEndTime(videoRef.current?.duration, parentType)];
      setValue(temp);
      return;
    }

    if (videoRef.current && videoRef.current.duration < value[1]) {
      setError(true);
      let temp = [0, ReturnEndTime(videoRef.current?.duration, parentType)];
      setValue(temp);
      return ToastHandler(
        "warn",
        "End time cannot exceed the complete video duration."
      );
    }

    if (videoRef.current && videoRef.current.duration < value[0]) {
      setError(true);
      let temp = [0, ReturnEndTime(videoRef.current?.duration, parentType)];
      setValue(temp);
      return ToastHandler(
        "warn",
        "Start time cannot exceed the complete video duration."
      );
    }

    if (parentType === "SCROLLS" && value[1] - value[0] > 180) {
      setError(true);
      let temp = [0, ReturnEndTime(videoRef.current?.duration, parentType)];
      setValue(temp);
      // return ToastHandler(
      //   "warn",
      //   "The trimmed clip cannot exceed 3min duration."
      // );
    }

    if (value[1] - value[0] === -1) {
      setError(true);
      let temp = [0, ReturnEndTime(videoRef.current?.duration, parentType)];
      setValue(temp);
      // return ToastHandler("warn", "Duration of the clip cannot be 0sec.");
    }

    setStartVideoTime(value[0]);
    setEndVideoTime(value[1]);
    setVideoDuration(value[1] - value[0]);
    sessionStorage.setItem(
      "storedVideoProperty",
      JSON.stringify({
        _start: value[0],
        _end: value[1],
        _duration: value[1] - value[0],
      })
    );
  }, [parentType, value[0], value[1]]);

  useEffect(() => {
    if (storedVideoProperty?._duration && !scrollsCat) {
      sessionStorage.setItem(
        "storedBeforRender",
        JSON.stringify({
          _start: storedVideoProperty?._start,
          _end: storedVideoProperty?._end,
          _duration: storedVideoProperty?._duration,
        })
      );
    }
  }, [scrollsCat]);

  useEffect(() => {
    if (value[0] > value[1]) {
      return ToastHandler("warn", "Start time cannot exceed the end time.");
    }
  }, [value[0]]);

  useEffect(() => {
    if (value[1] < value[0]) {
      return ToastHandler(
        "warn",
        "End time cannot be less than the start time."
      );
    }
  }, [value[1]]);

  useEffect(() => {
    //todo: default its re-render;
    // if (!show) {
    //   setStartVideoTime(0);
    //   setEndVideoTime(videoRef.current?.duration);
    //   setVideoDuration(videoRef.current?.duration);
    // }
    if (show) {
      setStartVideoTime(0);
      setEndVideoTime(value[1]);
      setVideoDuration(value[1]);
    }
  }, [show]);

  useEffect(() => {
    if (value.length > 1) {
      videoRef.current.currentTime = value[0];
    }
  }, [duration, value, videoRef]);

  const CanvasBlueprint = useMemo(() => {
    let temp = [];
    for (let i = 0; i < 5; i++) {
      temp.push(
        <img
          style={{
            height: "100%",
            width: "20%",
            position: "absolute",
            left: `${i * 20}%`,
            top: "0%",
          }}
          alt="this is image" />
      );
    }
    return temp;
  }, []);

  const removeVideoHandler = () => {
    if (removeVideo > 1) {
      setStep(removeVideo - 1);
      setVideoFilePath("");
      setFakeDiv(true);
      setEditVideo(false);
      setSelectedScrollsCat(null);
      sessionStorage.removeItem("storedVideoProperty");
      sessionStorage.removeItem("storedBeforRender");
    }
  };

  return (
    <MainDiv>
      <video
        id="my_video_player"
        onTimeUpdate={(e) => {
          if (e.target.currentTime > value[1]) {
            e.target.currentTime = value[1];
            setCurrentTime(e.target.currentTime);
            e.target.pause();
            return;
          }
          if (e.target.currentTime < value[0]) {
            e.target.currentTime = value[0];
            setCurrentTime(e.target.currentTime);
            e.target.pause();
            return;
          }
          setCurrentTime(e.target.currentTime);
        }}
        ref={videoRef}
        onLoadedMetadata={(event) => {
          if (setVideoDuration) {
            if (show) {
              setVideoDuration(
                ReturnEndTime(videoRef.current?.duration, parentType)
              );
              setEndVideoTime(
                ReturnEndTime(videoRef.current?.duration, parentType)
              );
            } else {
              setVideoDuration(videoRef.current?.duration);
              setEndVideoTime(videoRef.current?.duration);
            }
            let temp = [...value];
            if (storedBeforRender?._start) {
              temp[0] = storedBeforRender?._start;
            }
            temp[1] = ReturnEndTime(
              storedBeforRender?._end || videoRef.current?.duration,
              parentType
            );
            setValue(temp);
          }
        }}
        {...props}
        style={{
          width: "100%",
          height: 300,
          borderRadius: 10,
          // marginTop: "-6em",
          marginTop: ".5rem",
          border: "1px solid #a2a2a280",
          padding: "2px",
        }}
        controlsList="nodownload"
      />
      {show && (
        <video
          ref={canvasvideoref}
          {...props}
          style={{
            display: "none",
          }}
          onLoadedData={() => {
            setCanvasCounter(0);
            let videoelem = canvasvideoref.current;
            videoelem.muted = true;
            videoelem.play();
            videoelem.currentTime = 0;
          }}
          onSeeked={() => {
            let videoelem = canvasvideoref.current;
            let canvases = canvascontainerref.current.childNodes;
            let img = canvases[canvasCounter];
            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            context.drawImage(videoelem, 0, 0, canvas.width, canvas.height);
            img.src = canvas.toDataURL("image/jpeg");
            if (canvasCounter < 4) {
              videoelem.currentTime =
                ((canvasCounter + 1) * videoelem.duration) / 5;
            }
            setCanvasCounter(canvasCounter + 1);
          }}
        />
      )}
      <Grid
        container
        justifyContent={"space-between"}
        width={"100%"}
        style={{ padding: "0 .5em", marginTop: ".5em" }}
        sx={{
          m: "auto",
        }}
      >
        <Grid
          item
          xs={3}
          md={3}
          sx={{
            font: "normal normal normal 12px/14px Work Sans",
            color: "#fff",
          }}
        >
          {Math.round(videoFile.size / 10 ** 6) === 0
            ? Math.round(videoFile.size / 10 ** 3) + " Kb"
            : Math.round(videoFile.size / 10 ** 6) + " Mb"}
        </Grid>
        <Grid
          item
          xs={3}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            font: "normal normal normal 12px/14px Work Sans",
            color: "#fff",
          }}
        >
          {returnTimeString(storedVideoProperty?._duration || videoDuration)}
        </Grid>
      </Grid>
      {show && (
        <React.Fragment>
          <Grid
            className="trimGridContScroll"
            container
            sx={{ height: "100px" }}
            style={{ marginTop: "0" }}
          >
            <Grid item xs={12} md={4}>
              <Grid container spacing={3} sx={{ height: "80%" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    alignItems: "center",
                    paddingLeft: "0px",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                ></Grid>
              </Grid>
            </Grid>
            <Grid className="trimSlider" item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "80%",
                  position: "absolute",
                  left: "10%",
                  height: "100%",
                  padding: "20px 0px",
                  top: "8px",
                }}
                component="span"
                ref={canvascontainerref}
              >
                {CanvasBlueprint}
              </Box>
              <Slider
                step={1}
                min={0}
                max={videoRef.current?.duration}
                componentsProps={{
                  thumb: {
                    onClick: () => {
                      setCurrentTime(videoRef.current?.currentTime);
                      if (!videoRef.current?.paused) {
                        return videoRef.current?.pause();
                      }
                      return videoRef.current?.play();
                    },
                  },
                }}
                sx={{
                  position: "absolute",
                  width: "80%",
                  left: "10%",
                  top: "3%",
                  height: "80%",
                  "& .MuiSlider-thumb": {
                    height: "150%",
                    zIndex: "200",
                    borderRadius: "0px",
                    width: "3px",
                    backgroundColor: "#FFFFFF",
                  },
                  "& .MuiSlider-thumb::after": {
                    content: videoRef.current?.paused
                      ? `"play_arrow"`
                      : `"paused"`,
                    fontFamily: "Material Icons",
                    textAlign: "center",
                    display: "flex",
                    position: "absolute",
                    justifyContent: "center",
                    top: "115%",
                    backgroundColor: "transparent",
                    color: "transparent",
                    borderRadius: "4px",
                    width: videoRef.current?.paused ? "28px" : "26px",
                    height: videoRef.current?.paused ? "24px" : "24px",
                    paddingLeft: videoRef.current?.paused ? "0px" : "15px",
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: "transparent",
                    border: "none",
                  },
                }}
                value={[currentTime]}
                onChange={(e) => {
                  let val = e.target.value[0];
                  if (val < value[0] || val > value[1]) {
                    return;
                  }
                  setCurrentTime(val);
                  videoRef.current.currentTime = val;
                }}
                track={false}
              />
              <Slider
                step={1}
                min={0}
                classes="videoslider"
                max={videoRef.current?.duration}
                marks={(() => {
                  let temp = [];
                  for (let i = 0; i <= 100; i += 10) {
                    temp.push({
                      value: Math.floor(
                        percentToNum(i, videoRef.current?.duration)
                      ),
                      label: (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "fit-content",
                            justifyContent: "center",
                            color: "white",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              width: "100%",
                              font: "normal normal normal 10px/11px Work Sans",
                              textAlign: "center",
                              color: "#AEBDD3",
                            }}
                          >
                            {Math.floor(
                              percentToNum(i, videoRef.current?.duration)
                            )}
                            s
                          </Typography>
                          <FiberManualRecordIcon
                            style={{
                              width: "8px",
                              height: "8px",
                              marginTop: "4px",
                            }}
                          />
                        </div>
                      ),
                    });
                  }
                  return temp;
                })()}
                componentsProps={{
                  markLabel: {
                    style: {
                      bottom: "-20%",
                    },
                  },
                }}
                sx={{
                  width: "80%",
                  margin: "auto",
                  height: "80%",
                  zIndex: "5",
                  "& .MuiSlider-thumb": {
                    borderRadius: "5px",
                    height: "80%",
                    width: "20px",
                    backgroundColor: "#FFFFFF",
                  },
                  "& .MuiSlider-thumb::after": {
                    content: `""`,
                    backgroundColor: "white",
                    height: "50%",

                    width: "3px",
                    borderRadius: "3px",
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: "transparent",
                    border: "2px solid #FFFFFF",
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: "transparent",
                    border: "1px solid black",
                  },
                  "& .MuiSlider-mark": {
                    display: "none",
                  },
                }}
                getAriaLabel={() => "Minimum distance"}
                onChange={(e, val, activeThumb) => {
                  // Set the default maximum range to 180
                  if (val[1] - val[0] > 180) {
                    if (activeThumb === 0) {
                      return setValue([val[0], val[0] + 180]);
                    }
                    return setValue([val[1] - 180, val[1]]);
                  }
                  setValue(val);
                }}
                value={value}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  return returnTimeString(value);
                }}
                disableSwap
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      <div className="icon_container">
        {!hideEdit && (
          <IconButton
            style={{
              position: "absolute",
              top: "16px",
              right: "50px",
            }}
            className="trimIcon"
            onClick={() => {
              if (setVideoFilePath) {
                setVideoFilePath(videoFilePath);
              }
              setDuration(videoRef.current.duration);
              handleShow();
            }}
          >
            <img src={CropTrimSVG} alt="crop_icon" />
          </IconButton>
        )}
        <IconButton
          style={{
            position: "absolute",
            top: "16px",
            right: "10px",
          }}
          className="trimIcon"
          onClick={removeVideoHandler}
        >
          <img src={DeleteSVG} alt="delete_icon" />
        </IconButton>
      </div>
    </MainDiv>
  );
};

export default VideoContainer;
