import { Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { allWords } from "../../App";

export default function ConfirmationDialog({
  open,
  setOpen,
  msg,
  custom_yes,
  custom_no,
  label = "",
  labelYes = "",
  labelNo = "",
  customClassname = "",
  customDivClassname = "",
}) {
  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={() => {
          setOpen();
          setOpen(false);
        }}
        data-test="src-components-ConfirmationDialog-index-0"
      >
        <DialogContent
          className={`${customClassname}`}
          style={{
            minWidth: window.screen.width <= 768 ? "100%" : 550,
            color: "#fff",
            backgroundColor: "#212121",
          }}
        >
          <div
            className={`text-center confirmation-dialog-div ${customDivClassname}`}
            data-test="src-components-ConfirmationDialog-index-1"
          >
            <Typography
              sx={{
                fontSize: "1.5rem",
              }}
              data-test="src-components-ConfirmationDialog-index-2"
            >
              {msg}
            </Typography>
            <div
              className="d-flex justify-content-between mt-5"
              data-test="src-components-ConfirmationDialog-index-3"
            >
              <button
                className="confirmation-dialog-button "
                size="medium"
                onClick={custom_yes}
                style={{
                  backgroundColor: "#54b798",
                }}
                data-test="src-components-ConfirmationDialog-index-4"
              >
                {label !== "create_dialog" ? allWords.misc.yes : labelYes}
              </button>
              <button
                className="confirmation-dialog-button "
                size="medium"
                style={{
                  backgroundColor: "#F15B29",
                }}
                onClick={custom_no}
                data-test="src-components-ConfirmationDialog-index-5"
              >
                {label !== "create_dialog" ? allWords.misc.no : labelNo}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
