import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="Group_19706"
      data-name="Group 19706"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_2948"
        data-name="Rectangle 2948"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="Path_15346"
        data-name="Path 15346"
        d="M16.75,60h12a3,3,0,0,1,3,3v9a.75.75,0,0,1-.75.75H19a3,3,0,0,1-3-3v-9A.75.75,0,0,1,16.75,60Z"
        transform="translate(-14.5 -54.375)"
        fill="none"
        stroke="#f4f4f4"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_15347"
        data-name="Path 15347"
        d="M184,83l5.25-3v9L184,86"
        transform="translate(-166.75 -72.5)"
        fill="none"
        stroke="#f4f4f4"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  );
}

export default SvgComponent;
