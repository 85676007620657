import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PreloginComp from "../components/PreLoginComp";
import PersonIcon from "@mui/icons-material/Person";

export const PrivateRouteComponent = (props) => {
  const currentUser = localStorage.getItem("current_user");
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!currentUser &&
      localStorage.anonymous_user && window.location.pathname === '/deactivate_info')
    { 
      return setModalOpen(true);
    }
    else if (!currentUser) navigate("/home");
  }, []);
  // if (!currentUser)
  //   return (
  //     <Route path={path} element={<Navigate replace to="/roundtable/all" />} />
  //   );

  // if (localStorage.getItem("anonymous_user"))
  //   return (
  //     <Route path={path} element={<Navigate replace to="/roundtable/all" />} />
  //   );

  return <> {!modalOpen && props.children}
      {modalOpen && <PreloginComp
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          icon={<PersonIcon style={{ color: "#66B984" }} width={40} height={40} />}
          title={"Please Signup/login to deactivate account"}
          description={""}
      />} </>;
};
