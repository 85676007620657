import { REACT_APP_BASE_URL_FOR_USER, REACT_APP_BASE_URL_FOR_ROUNDTABLE_V1, REACT_APP_URL } from "../constants/env";
import axios from "axios";


export const searchProfileMutualFollowers = async(username) => {
    try {

        let config = {
          method: "get",
          url: `${REACT_APP_BASE_URL_FOR_USER}/v2/mutual-followers?username=${username}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios(config);
        return response?.data?.data || {};
      } catch (error) {
        console.error("Error fetching mutual followers:", error);
        throw error;
      }
}

export const searchHandleMutualFollowers = async (username) =>{
    let config = {
        method: "get",
        url: `${REACT_APP_BASE_URL_FOR_USER}/v2/mutual-followers-list/?username=${username}&limit=20&skip=0`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios(config);
        return response?.data?.data || [];
      } catch (error) {
        console.error(error);
        throw error;
      }
}

//shows list based on serach. need to update based on api
export const fakeData = [
    {
      src: `${REACT_APP_URL}/assets/tvShowStaticImages/tvShow1.png`,
    },
    {
      src: `${REACT_APP_URL}/assets/tvShowStaticImages/tvShow2.png`,
    },
    {
      src: `${REACT_APP_URL}/assets/tvShowStaticImages/tvShow3.png`,
    },
    {
      src: `${REACT_APP_URL}/assets/tvShowStaticImages/tvShow4.png`,
    },
    {
      src: `${REACT_APP_URL}/assets/tvShowStaticImages/tvShow5.png`,
    },
    {
      src: `${REACT_APP_URL}/assets/tvShowStaticImages/tvShow6.png`,
    }
];

export const getSearchProfileSection = async (search_debounce, access) => {
    let config = {
        method: "get",
        url: `${REACT_APP_BASE_URL_FOR_ROUNDTABLE_V1}/search/profile-section?query=${search_debounce.trim()}&limit=10&offset=0`,
        headers: {
          Authorization: `Bearer ${
            localStorage.current_user && !localStorage.anonymous_user
              ? access
              : JSON.parse(localStorage.getItem("anonymous_user"))["token"]
          }`,
          "Content-Type": "application/json",
          "X-API-Anonymous": localStorage.getItem("current_user") ? false : true,
        },
      };
      try {
        const response = await axios(config);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
}

export const getSearchTopProfileList = async (search_debounce, access) => {
    let config = {
        method: "get",
        url: `${REACT_APP_BASE_URL_FOR_ROUNDTABLE_V1}/search/top/profile?query=${search_debounce.trim()}&limit=10&offset=0`,
        headers: {
          Authorization: `Bearer ${
            localStorage.current_user && !localStorage.anonymous_user
              ? access
              : JSON.parse(localStorage.getItem("anonymous_user"))["token"]
          }`,
          "Content-Type": "application/json",
          "X-API-Anonymous": localStorage.getItem("current_user") ? false : true,
        },
      };
      try {
        const response = await axios(config);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
}