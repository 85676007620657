import { SET_SHARE_LINK, SET_DIALOG_STATUS, SET_PARENT_TYPE } from '../../actions/actionTypes'

const initialState = {
    shareLink: '',
    dialogStatus: false,
    postParentType: ""
};

const postSuccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHARE_LINK:
            return { ...state, shareLink: action.payload };
        case SET_DIALOG_STATUS:
            return { ...state, dialogStatus: action.payload };
        case SET_PARENT_TYPE:
            return { ...state, postParentType: action.payload };
        default:
            return state;
    }
};

export default postSuccessReducer;