import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REACT_APP_BASE_URL_CLOUDFRONT } from "../../constants/env";
import { getPopularShowData } from "../../redux/actions/popularAction/popularShows";
// Assets
import "../PopularUI/style.css";
// Comps
import { allWords } from "../../App";
import PopularUI from "../PopularUI";
import UserProfile from "../UserProfile";
import VipComp from "../VipComp";
import Slider from "react-slick";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ChevronLeft } from "@mui/icons-material";

export default function PopularShows() {
  const popularRtData = useSelector((state) => state.popularShows.popular);
  const dispatch = useDispatch();
  const [popularRT, setPopularRT] = useState(null);

  useEffect(() => {
    dispatch(getPopularShowData());
  }, []);

  useEffect(() => {
    if (popularRtData && popularRtData?.status === 200) {
      setPopularRT(popularRtData?.data);
    }
  }, [popularRtData]);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    autoplay: true,
    arrows: true,
    nextArrow: (
      <div>
        <ChevronRight />
      </div>
    ),
    prevArrow: (
      <div>
        <ChevronLeft />
      </div>
    ),
    className: "myCustomCarousel",
  };

  return (
    <div
      style={{
        borderRadius: "10px",
        background: "#1a171e",
        border: "1px solid #A2A2A280",
        paddingLeft: "10px",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        height: "79vh"
      }}
      className="custom_popular"
      data-test="src-components-PopularShows-index-0"
    >
      <Typography
        style={{
          fontSize: "20px",
          color: "#f4f4f4",
          marginLeft: "12px",
          marginBottom: "14px",
          paddingTop: "12px",
          fontWeight: "600px",
        }}
        data-test="src-components-PopularShows-index-1"
      >
        {allWords.th.popularHeading}
      </Typography>
      <div
        className="popularShow"
        data-test="src-components-PopularShows-index-2"
      >
        {popularRT?.map((item, index) => (
          <div
            key={item?.username}
            data-test="src-components-PopularShows-index-3"
          >
            <div
              className="d-flex"
              style={{ paddingLeft: "12px" }}
              data-test="src-components-PopularShows-index-4"
            >
              <UserProfile
                username={item?.username}
                className="avatar"
                width="2rem"
                height="2rem"
                borderRadius="25%"
              />
              &emsp;
              <div data-test="src-components-PopularShows-index-5">
                <div
                  className="d-flex"
                  style={{
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/profile/${item?.username}`
                    );
                  }}
                  data-test="src-components-PopularShows-index-6"
                >
                  <h6
                    style={{
                      fontWeight: "600",
                      fontSize: "0.75rem",
                      color: "var(--secondary-heading-color)",
                      cursor: "pointer",
                      marginTop: "1px",
                    }}
                  >
                    {item?.name}
                  </h6>
                  <VipComp
                    user_type={item?.user_type}
                    overrideVipStyle={{
                      width: "18px",
                      height: "18px",
                      marginTop: "-10px",
                      marginLeft: "3px",
                    }}
                  />
                </div>
                <h6
                  style={{
                    fontSize: "0.625rem",
                    cursor: "pointer",
                    marginTop: "-4px",
                  }}
                  className="text-muted"
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/profile/${item?.username}`
                    );
                  }}
                >
                  @{item?.username}
                </h6>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                zIndex: 4,
              }}
              data-test="src-components-PopularShows-index-7"
              // remove this hover effect accoring to new ui, after hovering card target slider come in center
              // onMouseEnter={(e) => {
              //   e.target.scrollIntoView({
              //     behavior: "smooth",
              //     inline: "center",
              //     block: "nearest",
              //   });
              // }}
            >
              <div
                className="rightSlider"
                data-test="src-components-PopularShows-index-8"
              >
                <Slider {...settings}>
                  {item?.popular_rts?.map((item) => (
                    <div
                      key={item?._id}
                      data-test="src-components-PopularShows-index-9"
                    >
                      <PopularUI
                        showParticipants={false}
                        cover_img={
                          item?.media?.length > 0 &&
                          `${REACT_APP_BASE_URL_CLOUDFRONT}/uploads/${item?.owner?.user_id}/roundtable/${item?.["_id"]}/profile/${item?.["media"]?.[0]?.["metadata"]?.["tempFilename"]}`
                        }
                        title={item?.name}
                        media_recording={
                          item?.media_recording?.length > 0
                            ? item?.media_recording
                            : null
                        }
                        live={item?.active_flag}
                        past={item?.happened_flag}
                        is_cancelled={item?.is_cancelled}
                        timestamp={item.time}
                        rec_owner_flag={
                          item?.recording?.[0]?.owner_flag === 1 ? true : false
                        }
                        rec_start_flag={
                          item?.recording?.[0]?.start_recording === 1
                            ? true
                            : false
                        }
                        rec_end_flag={
                          item?.recording?.[0]?.soft_end_recording === 0
                            ? true
                            : false
                        }
                        owner_details={item?.owner}
                        rt_type={item.r_type}
                        rt_nature={item.open_to_all}
                        rt_id={item?._id}
                        item={item}
                        moderator={item?.moderator}
                        speakers={item?.speakers}
                        join_count={
                          item?.happened_flag === true
                            ? item?.["join_count"]
                            : 0
                        }
                        viewer_count={
                          item?.active_flag === true
                            ? item?.["viewer_count"]
                            : ""
                        }
                        invitees_count={
                          item?.upcoming_flag === true
                            ? item?.["invite_count"] - item?.["rejected_count"]
                            : ""
                        }
                        user_views_count={item?.user_views_count}
                        ext={
                          item?.media_recording?.length > 0
                            ? item?.media_recording?.[0]?.["metadata"]?.["ext"]
                            : null
                        }
                        rt_details={item}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              {index < popularRT.length - 1 && (
                <hr style={{ color: "#6c757d", height: "3px" }} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
