import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="Group_19708"
      data-name="Group 19708"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_2951"
        data-name="Rectangle 2951"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="Path_15349"
        data-name="Path 15349"
        d="M51.249,36.5l-7.81,7.94a1.5,1.5,0,0,0,2.121,2.121l9.31-9.44a3,3,0,0,0-4.243-4.243l-9.31,9.44a4.5,4.5,0,0,0,6.364,6.364L55.374,41"
        transform="translate(-36.25 -28.996)"
        fill="none"
        stroke="#f4f4f4"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  );
}

export default SvgComponent;
