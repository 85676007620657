import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="Group_19709"
      data-name="Group 19709"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_2952"
        data-name="Rectangle 2952"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="Path_15350"
        data-name="Path 15350"
        d="M50.136,49h-3a1.5,1.5,0,0,0-1.5,1.5v3.75a1.5,1.5,0,0,0,1.5,1.5h1.5a1.5,1.5,0,0,0,1.5-1.5V49A9.068,9.068,0,0,0,32,49v5.25a1.5,1.5,0,0,0,1.5,1.5H35a1.5,1.5,0,0,0,1.5-1.5V50.5A1.5,1.5,0,0,0,35,49H32"
        transform="translate(-29 -36.25)"
        fill="none"
        stroke="#f4f4f4"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </svg>
  );
}

export default SvgComponent;
