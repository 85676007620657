import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Assets
import Back from "../../assets/icons/back.svg";
import cake from "../../assets/icons/cake.svg";
import locationIcon from "../../assets/icons/locationIcon.svg";
import MenuSVG from "../../assets/icons/menu.svg";
import EditCoverIcon from "../../assets/icons/edit_cover.svg";

// Components
import Spinner from "../../components/Spinner";
import MainMenu from "../../assets/icons/main_menu.svg";
import EditImage from "../Post/AddPostDialog/EditImage";
import PreloginComp from "../PreLoginComp";
import VIPComp from "../VipComp";
import LevelBadge1 from "../../assets/images/level-path-1.svg";
import LevelBadge3 from "../../assets/images/level-path-3.svg";

import ReportDialog from "../ReportDialog";
import {MenuContainer} from "../Post/style";
import {Menu as CustomMenu, Drawer, Hidden, IconButton, MenuItem} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { uploadImage } from "../../apis/profileApi";
import { drawerFlag } from "../../redux/actions/compActions";

// Styles
import {
  EditBtn,
  FollowerCount,
  FollowerDiv,
  FollowerTag,
  Title,
} from "./style";
import "./style.css";

// Constants
import {
  MOBILE_VIEW,
  POST_API_BASE_URL,
  REACT_APP_BASE_URL_FOR_USER,
} from "../../constants/env";

// Material UI
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  AvatarGroup,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Paper,
} from "@mui/material";

// Utils
import { MetaTagsGenerator } from "../../utils/MetaTagsGenerator";
import ToastHandler from "../../utils/ToastHandler";
import {
  auto_login_continue,
  moengageEvent,
  replaceURLs,
  getUtmAppendString,
} from "../../utils/utils";

// Lang
import { allWords } from "../../App";
import UserProfile from "../UserProfile";
import { globalImages } from "../../assets/imagesPath/images";
import FullScreenDialog from "../common/FullSizeDialobox";
import { useDispatch, useSelector } from "react-redux";
import { userProfileHeaderUpdate } from "../../redux/actions/profileAction/userProfileAction";
import _, { isEmpty } from "lodash";
import { ProfilePageFlag } from "../../pages/Profile";
import { ShareOutlined } from "@mui/icons-material";
import SuggestedToYou from "../LeftSideBar/SuggestedToYou";
import AddShowcaseModal from "./AddShowcaseModal";
import {
  MutualFollowersList,
  MutualFollowersDialogBox,
} from "./MutualFollowersList";
import LeftSideBar from "../LeftSideBar";
import { getUserProfileData } from "../../redux/reducers/profileReducer/userProfileReducer";

const ProfileHeader = ({
  edit_btn,
  follow,
  follow_btn,
  blockUser = false,
  onImageChange,
  p_loading = false,
  p_error = false,
  loadingimg,
  setImageUrl,
  onPointScreen = ProfilePageFlag.profile,
  setOnPointScreen = () => {},
  user_id: visitor_user_id,
  checkForFollowerFollowing = false,
  HighlightsUser = {},
  handleHighlightsImageChange,
  handleOpenChange,
  isOpen,
  isOrg,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    followers,
    following,
    location,
    bio,
    name,
    user_name,
    dob,
    user_id,
    userType,
    privacy_settings,
  } = useSelector((state) => state.user_profile.profileHeader);

  const {
    user_activities_badge,
    user_activities_level,
    user_activities_points,
  } = useSelector((state) => state.user_profile.profilePoints);
  const { badges, points, level, next_level_points } = useSelector(
    (state) => state.user_profile.profilePointDetail
  );
  const userProfileData = useSelector(getUserProfileData)

  const [web, setWebsite] = useState("");
  const [interest, setInterest] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState([]);
  const follows = useRef(false);
  const [is_following, setIsFollowing] = useState(false);
  const [privacy_temp, setPrivacyTemp] = useState([]);
  const [profilehOpen, setProfilehOpen] = useState(false);
  const [openMutualUserModal, setOpenMutualUserModal] = useState(false);
  const [mutualFollowers, setMutualFollowers] = useState([]);
  const [reportOpen, setReportOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  //states and refs regarding edit image
  const [tempImage, setTempImage] = useState([]);
  const [editImage, setEditImage] = useState(false);

  const getCurrentUser = () => {
    try {
      return JSON.parse(localStorage?.current_user ?? "{}");
    } catch (error) {
      return {};
    }
  };
  let arrURLs = [
    "/account_settings",
    "/invite_friends",
    "/privacy_settings",
    "/setPass",
    "/changepass",
    "/oldpass",
    "/muted_words",
    "/muted_accounts",
    "/blocked_accounts",
  ]; 
  const currentUser = getCurrentUser();
  const imageEditorRef = useRef();
  // URL Params
  const url_search_params = new URL(window.location.href);
  let username =
    url_search_params.pathname.split("/")[2] ||
    JSON.parse(localStorage.current_user || localStorage.anonymous_user)[
      "username"
    ];

  const updateHeaderProfileData = (response) => {
    const isSelfProfile =
      username ===
      JSON.parse(localStorage.current_user || localStorage.anonymous_user)[
        "username"
      ];

    const dispatchPayload = {
      followers: _.get(
        response,
        isSelfProfile ? "followers" : "followers",
        0
      ),
      following: _.get(
        response,
        isSelfProfile ? "following" : "following",
        0
      ),
      location: _.get(
        response,
        isSelfProfile
          ? "location"
          : "user_other.location",
        ""
      ),
      bio: _.get(
        response,
        isSelfProfile ? "one_liner" : "bio",
        ""
      ),
      name: _.get(
        response,
        isSelfProfile ? "name" : "name",
        ""
      ),
      user_name: _.get(
        response,
        isSelfProfile ? "username" : "username",
        ""
      ),
      dob: _.get(
        response,
        isSelfProfile
          ? "date_of_birth"
          : "user_other.date_of_birth",
        ""
      ),
      user_id: _.get(
        response,
        isSelfProfile ? "_id" : "user_id",
        ""
      ),
      userType: _.get(
        response,
        isSelfProfile ? "user_type" : "user_type",
        ""
      ),
      privacy_settings: _.get(
        response,
        isSelfProfile
          ? "privacy_settings"
          : "user_other.privacy_settings",
        []
      ),
    };
    dispatch(userProfileHeaderUpdate(dispatchPayload));
    moengageEvent("View Page", "ALL", {
      URL: `${window.location.origin}/${window.location.pathname}`,
    });

    try {
      //Returns blank dict when empty
      if (
        Array.isArray(
          isSelfProfile
            ? response["website"]
            : response["user_other"]["website_info"]
        )
      ) {
        setWebsite(
          isSelfProfile
            ? response["website"][0]
            : response["user_other"]["website_info"][0]
        );
      } else {
        setWebsite("");
      }
    } catch (err) {
      setWebsite("");
    }
    setInterest(response?.["interest"] || []);
    setCoverImage(response?.["cover_photo"] || "");
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      if (file.size > 2 * 1024 * 1024) {
        ToastHandler("dan", "Cover picture must be less than 2 MB");
        return;
      }
    }

    reader.onloadend = () => {
      setCoverImage(reader.result);
      try {
        const hasUploaded = uploadImage(file, "cover_photo");
        if (hasUploaded) {
          ToastHandler("sus", "Cover picture updated successfully");
        } else {
          if (hasUploaded) {
            ToastHandler("dan", "Cover picture failed to upload");
          }
        }
      } catch (err) {
        ToastHandler("dan", "Cover picture failed to upload");
      }
    };
  };

  useEffect(() => {
    if(!isEmpty(userProfileData)){
      updateHeaderProfileData(userProfileData)
    }
  }, [userProfileData]);

  useEffect(() => {
    let interest_array = [];
    interest?.map((item) => interest_array.push(item.sub_category));
    let sub_interest_array = [];
    interest_array?.map((item) =>
      item.map((i) => sub_interest_array.push(i.sub_category_name))
    );
    setSubCategoryName(sub_interest_array);
  }, [interest]);

  useEffect(() => {
    let temp = [];
    if (!privacy_settings) return;
    if (privacy_settings?.includes("dob")) {
      temp.push("dob");
    } else if (privacy_settings?.includes("location")) {
      temp.push("location");
    } else if (privacy_settings?.includes("website")) {
      temp.push("website");
    } else if (privacy_settings?.includes("interest")) {
      temp.push("interest");
    }
    setPrivacyTemp(temp);
  }, [privacy_settings]);
  const uploadDP = useRef("");

  // profile pic open
  const [imgOpen, setImgOpen] = useState(false);
  const editDP = () => {
    if (window.location.pathname.includes("personal_details")) {
      uploadDP.current.click();
    } else {
      increaseWidth();
      setOnPointScreen(ProfilePageFlag.khulKeBoard);
    }
  };

  const follow_unfollow_driver = async (handle, type) => {
    if (!handle) {
      return;
    }
    let temp1 = follows.current.innerHTML;
    let temp = "";
    if (temp1 === "Follow") {
      temp = "follow";
    } else {
      temp = "unfollow";
    }
    let data = JSON.stringify({ handle, type: temp });
    let config = {
      method: "post",
      url: `${REACT_APP_BASE_URL_FOR_USER}/follow-friends/`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async (res) => {
        if (res.status === 200) {
          moengageEvent(type === "follow" ? "Follow" : "UnFollow", "User", {
            IdOth: user_id,
            UsernameOth: handle,
          });

          if (res.data.message == "User added successfully") {
            let followingConcat = localStorage
              .getItem("followings")
              .concat(",", handle);
            localStorage.setItem("followings", followingConcat);
          }
          if (res.data.message == "User unfollowed") {
            let followingFromLS4arr2 = Array.from(
              localStorage.getItem("followings").split(",")
            );
            localStorage.setItem(
              "followings",
              followingFromLS4arr2.filter((i) => i !== handle)
            );
          }
          if (temp === "unfollow") {
            follows.current.classList = ["follow-button-small"];
            follows.current.innerHTML = "Follow";
            setIsFollowing(false);
          } else {
            follows.current.classList = ["following-button-small"];
            follows.current.innerHTML = "Following";
            setIsFollowing(true);
          }
        }
      })
      .catch(async (e) => {
        const res = e.response;
        if (!res) return;
        if (res.status === 401) {
          return await auto_login_continue(() =>
            follow_unfollow_driver(handle, type)
          );
        }
      });
  };

  //image editing effects
  useEffect(() => {
    if (!tempImage[0]?.cropped) return;
    onImageChange({ target: { files: [tempImage[0].file] } });
    setEditImage(false);
    uploadDP.current.value = "";
    setTempImage([]);
    setImageUrl(tempImage[0].url);
  }, [tempImage]);

  const utmAppendText = getUtmAppendString("share", "share_via", "Profile");

  async function shareProfile() {
    const data = JSON.stringify({
      username: username,
    });

    const config = {
      method: "post",
      url: `${REACT_APP_BASE_URL_FOR_USER}/${
        !localStorage.current_user
          ? "anonymous/share-profile-data-links"
          : "share-user-data-links"
      }`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.access || JSON.parse(localStorage.anonymous_user).token
        }`,
      },
      data: data,
    };

    return axios(config)
      .then((res) => {
        ToastHandler("sus", allWords.misc.succcopied);
        console.log(`${res?.data?.data?.[0]?.["link"]}${utmAppendText}`);
        navigator.clipboard.writeText(
          `${res?.data?.data?.[0]?.["link"]}${utmAppendText}`
        );
        moengageEvent("Share", "User", {
          IdOth: user_id,
          UsernameOth: username,
        });
      })
      .catch(async (e) => {
        const res = e.response;
        if (!res) return;
        if (res.status === 401) {
          await auto_login_continue(() => {
            return shareProfile({
              username,
            });
          });
        }
        return res;
      });
  }
  // observer
  const myStickyRef = useRef();
  let options = {
    root: myStickyRef.current,
    rooMargin: "0px",
    threshold: 0.25,
  };
  const [yesOut, setYesOut] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setYesOut(entry.isIntersecting);
    }, options);
    observer.observe(myStickyRef.current);
  }, []);

  const getPageTitle = () => {
    switch (onPointScreen) {
      case ProfilePageFlag.earnPointBadges:
        return allWords.profilePoint.earnPointBadges.title;
      case ProfilePageFlag.levels:
        return allWords.profilePoint.levels.title;
      case ProfilePageFlag.badges:
        return allWords.profilePoint.badges.title;
      case ProfilePageFlag.khulKeBoard:
        return allWords.profilePoint.khulKeboard.title;
      case ProfilePageFlag.profile:
      default:
        return allWords.profile.pageTitle;
    }
  };

  const handleBackButtonNavigation = () => {
    decreaseWidth();
    switch (onPointScreen) {
      case ProfilePageFlag.earnPointBadges:
      case ProfilePageFlag.levels:
      case ProfilePageFlag.badges:
        setOnPointScreen(ProfilePageFlag.khulKeBoard);
        break;
      case ProfilePageFlag.khulKeBoard:
        setOnPointScreen(ProfilePageFlag.profile);
        break;
      case ProfilePageFlag.profile:
        navigate(-1);
        console.log("👀 [index.js:512]: onPointScreen progile", onPointScreen); // let it be there for debuggin purpose as in local it is working
        break;
      default:
        navigate(-1);
        console.log("👀 [index.js:515]: onPointScreen default", onPointScreen); // let it be there for debuggin purpose as in local it is working
        break;
    }
  };

  const increaseWidth = () => {
    const el = document.getElementsByClassName("profile-content-tabs-div")?.[0];
    el?.classList.add("increase-width-for-ele");
  };
  const decreaseWidth = () => {
    const el = document.getElementsByClassName("profile-content-tabs-div")?.[0];
    el?.classList.remove("increase-width-for-ele");
  };
  const handleReport = (e) => {
    handleMenuClose();
    e.preventDefault();
    const anonymous_user = JSON.parse(localStorage.getItem("anonymous_user"));

    if (anonymous_user) {
      setProfilehOpen(true);
      return;
    }
    setReportOpen(true);
  };

  const open = useSelector((state) => state.drawerSwitchRed.drawFlag);

  const toggleSlider = () => {
    dispatch(drawerFlag(!open));
  };

  return (
    <>
      {p_loading && <Spinner />}
      {p_error && (
        <div
          className="text-center"
          data-test="src-components-ProfileHeader-index-0"
        >
          <small className="warn-text">
            Something went wrong! Try again later.
          </small>
        </div>
      )}
      {/* <MetaTagsGenerator
      metaTags={{
        title: `${name} | @${username} | Khul ke`,
        description: `${username}, khul ke, social media platform`,
        keywords: `profiles, networks, notification`,
        image: `${POST_API_BASE_URL}/profile-photo/${username}/pp`,
      }}
    /> */}
      {!p_loading && !p_error && (
        <>
          {onPointScreen === ProfilePageFlag.khulKeBoard ||
          onPointScreen === ProfilePageFlag.profile ? (
            <>
              <div
                className="headiheadi"
                data-test="src-components-ProfileHeader-index-1"
              >
                {/* seo stuff */}
                {/* ------------------- */}
                <section className="containerstick">
                  <h1 id="page-title" style={{ display: "none" }}>
                    {`${username} -Khul Ke - Connect With Your Network`}
                  </h1>

                  <div className="leftck" data-test="src-components-ProfileHeader-index-2">
                    <Hidden smDown>
                      <ArrowBackIcon
                        onClick={handleBackButtonNavigation} 
                        alt="Go Back" 
                        sx={{ height: "35px", width: "35px", cursor: "pointer" }}
                      />
                    </Hidden>
                    <Hidden mdUp>
                    <Drawer
                        open={open}
                        anchor="left"
                        onClose={toggleSlider}
                        PaperProps={{ sx: { backgroundColor: "#0E0817" } }}
                    >
                    {arrURLs.includes(window.location.pathname) ? (
                        <Ak expanded={open} handleClose={toggleSlider} />
                    ) : (
                        <LeftSideBar  
                        expanded={open}
                        handleClose={toggleSlider}
                        is_icon_sidebar={true}
                        />
                    )}
                    </Drawer>
                    <IconButton
                        size="medium"
                        edge="start"
                        color="default"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleSlider}
                        data-test="src-components-Header-index-4"
                    >
                        <Avatar src={MainMenu} style={{ width: 30, height: 30 }} />
                    </IconButton>
                    </Hidden>
                    <Title className="usernBesidesArrow">
                      {blockUser === true ? (
                        getPageTitle()
                      ) : (
                        <>
                          {yesOut ? (
                            getPageTitle()
                          ) : (
                            <div
                              style={{ display: "flex" }}
                              data-test="src-components-ProfileHeader-index-4"
                            >
                              {`${
                                onPointScreen !== ProfilePageFlag.profile
                                  ? getPageTitle()
                                  : `@${user_name}`
                              }`}
                              {onPointScreen !==
                              ProfilePageFlag.profile ? null : (
                                <VIPComp user_type={userType} />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </Title>
                  </div>

                  <div
                    className="profileHeader-header-right"
                    data-test="src-components-ProfileHeader-index-5"
                  >
                    {!(
                      username ===
                      JSON.parse(
                        localStorage.current_user || localStorage.anonymous_user
                      )["username"]
                    ) && (
                      <div data-test="src-components-ProfileHeader-index-6">
                        <MenuContainer
                          id="basic-button"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleMenuClick}
                        >
                          <img
                            style={{
                              paddingBottom: "0.5rem",
                              paddingRight: "1rem",
                            }}
                            alt="this is img"
                            src={MenuSVG}
                            data-test="src-components-ProfileHeader-index-7"
                          />
                        </MenuContainer>
                      </div>
                    )}

                    {(onPointScreen === ProfilePageFlag.profile ||
                      onPointScreen === ProfilePageFlag.khulKeBoard) &&
                      window.location.pathname.split("/")[1] === "profile" && (
                        <>
                          {onPointScreen === ProfilePageFlag.khulKeBoard ? (
                            <lottie-player
                              src={globalImages.profile_khulke}
                              id="profile_achievemnt"
                              background="transparent"
                              speed="1"
                              style={{
                                width: "91px",
                                marginLeft: "auto",
                                marginRight: "12px",
                              }}
                              loop
                              autoplay
                              key={onPointScreen}
                            />
                          ) : (
                            <lottie-player
                              src={globalImages.profile_achievemnt}
                              id="profile_khulke"
                              background="transparent"
                              speed="1"
                              style={{
                                width: "25px",
                                marginLeft: "auto",
                                marginRight: "12px",
                                cursor: "pointer",
                              }}
                              loop
                              autoplay
                              key={onPointScreen}
                              onClick={() =>
                                setOnPointScreen(ProfilePageFlag.khulKeBoard)
                              }
                            />
                          )}

                          {onPointScreen === ProfilePageFlag.profile && (
                            <button
                              id="share_button"
                              onClick={shareProfile}
                              className="sharebtnn"
                              hidden={blockUser}
                              data-test="src-components-ProfileHeader-index-8"
                            >
                              <ShareOutlined
                                id="share_icon_button"
                                className="icon"
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  color: "white",
                                  marginBottom: "1rem",
                                }}
                              />
                            </button>
                          )}
                        </>
                      )}
                  </div>
                </section>
              </div>

              <CustomMenu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    bgcolor: "#212121",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "#212121",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div
                  style={{
                    width: "100px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  data-test="src-components-ProfileHeader-index-9"
                >
                  <>
                    {!(
                      username ===
                      JSON.parse(
                        localStorage.current_user || localStorage.anonymous_user
                      )["username"]
                    ) && (
                      <MenuItem
                        style={{
                          width: "100%",
                          margin: "0",
                          color: "#ED4D29",
                        }}
                        onClick={handleReport}
                      >
                        {allWords.report.report}
                      </MenuItem>
                    )}
                  </>
                </div>
              </CustomMenu>
              <input
                type="file"
                accept="image/*"
                hidden
                style={{ display: "none" }}
                ref={uploadDP}
                onChange={(e) => {
                  const imageFile = e.target.files[0];
                  if (!imageFile) return;

                  if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
                    ToastHandler(
                      "warn",
                      allWords.misc.toastMsg.invalidImgFormat
                    );
                    return false;
                  }

                  if (imageFile) {
                    for (let i = 0; i < e.target.files.length; i++) {
                      let size = e.target.files[i].size;
                      if (Math.round(size / 1024) > 1024 * 15) {
                        ToastHandler(
                          "warn",
                          "Upload an image less than 15 MB."
                        );
                        e.target.value = null;
                        return false;
                      } else {
                        setTempImage([
                          {
                            file: e.target.files[0],
                            url: URL.createObjectURL(e.target.files[0]),
                            name: imageFile.name,
                          },
                        ]);
                        setEditImage(true);
                      }
                    }
                  }
                }}
                data-test="src-components-ProfileHeader-index-10"
              />
              {blockUser === true ? (
                <div
                  className="text-center"
                  data-test="src-components-ProfileHeader-index-11"
                >
                  <small className="warn-text">
                    {allWords.profile.withUsername} {username}{" "}
                    {allWords.profile.notFound}
                  </small>
                </div>
              ) : (
                <section
                  className={
                    HighlightsUser.user_domain === "ORG"
                      ? "newBottomWithCover"
                      : "newBottomWithoutCover"
                  }
                >
                  {HighlightsUser.user_domain === "ORG" && (
                    <div
                      style={{
                        width: "100%",
                        height: MOBILE_VIEW ? "150px" : "275px",
                      }}
                    >
                      {coverImage && (
                        <img
                          src={coverImage}
                          alt="this is img"
                          className="cover-image"
                        />
                      )}
                      {currentUser._id === user_id && (
                        <>
                          <label
                            htmlFor="image-upload"
                            className="custom-file-upload"
                          >
                            <img alt="this is img" src={EditCoverIcon} />
                          </label>
                          <input
                            id="image-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: "none" }}
                          />
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className="imgNStats"
                    style={{
                      display: `${onPointScreen === ProfilePageFlag.profile ? "flex" : ""}`,
                    }}
                    data-test="src-components-ProfileHeader-index-12"
                  >
                    {loadingimg ? (
                      <Spinner />
                    ) : (
                      <div className="profileContainer">
                        <UserProfile
                          username={username}
                          className="profileImg"
                          onClick={editDP}
                          size="large"
                          circularProgress={true}
                          onPointScreen={onPointScreen}
                          badges={badges}
                          points={points}
                          level={level}
                          nextLevelPoints={next_level_points}
                          user_activities_badge={user_activities_badge}
                          user_activities_level={user_activities_level}
                          user_activities_points={user_activities_points}
                          name={name}
                          user_name={user_name}
                        />
                        {onPointScreen === ProfilePageFlag.khulKeBoard && (
                          <div
                            className="user-level-path"
                            data-test="src-components-ProfileHeader-index-13"
                          >
                            <div
                              className="level-path"
                              data-test="src-components-ProfileHeader-index-14"
                            >
                              {Object.entries(user_activities_level).map(
                                ([key, value]) => (
                                  <div
                                    key={key}
                                    className={
                                      points > value ? "last-badge-div" : null
                                    }
                                    data-test="src-components-ProfileHeader-index-15"
                                  >
                                    {points > value ? (
                                      <>
                                        <img
                                          className={`d-block`}
                                          alt="this is img"
                                          src={LevelBadge1}
                                          data-test="src-components-ProfileHeader-index-16"
                                        />
                                        <span
                                          style={{ color: "#ffffff" }}
                                          data-test="src-components-ProfileHeader-index-17"
                                        >
                                          L{key}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={LevelBadge3}
                                          alt="this is img"
                                          data-test="src-components-ProfileHeader-index-18"
                                        />
                                        L{key}
                                      </>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* user stats */}
                    <div
                      ref={myStickyRef}
                      className="divForStatsTop"
                      data-test="src-components-ProfileHeader-index-19"
                    >
                      {blockUser === true ? (
                        <>
                          <div
                            className="text-center warn-text"
                            data-test="src-components-ProfileHeader-index-20"
                          ></div>
                        </>
                      ) : (
                        <>
                          {onPointScreen === ProfilePageFlag.profile && (
                            <>
                              {follow && (
                                <div
                                  style={{ display: "flex" }}
                                  data-test="src-components-ProfileHeader-index-21"
                                >
                                  <div
                                    className="statparent"
                                    onClick={() => {
                                      if (
                                        !localStorage.current_user &&
                                        localStorage.anonymous_user
                                      )
                                        return setProfilehOpen(true);
                                      navigate(isOrg ? `/profile/${username}/org_members` :
                                        `/profile/${username}/followings`
                                      );
                                    }}
                                    data-test="src-components-ProfileHeader-index-22"
                                  >
                                    <FollowerDiv style={{width: isOrg?"160px":"9rem"}}>
                                      <FollowerTag to="#">
                                        {" "}
                                        {isOrg ? "Members" : allWords.profile.btnFollowing}
                                      </FollowerTag>
                                      <FollowerCount>{isOrg ? HighlightsUser.member_count : following}</FollowerCount>
                                    </FollowerDiv>
                                  </div>
                                  <div
                                    className="statparent"
                                    onClick={() => {
                                      if (
                                        !localStorage.current_user &&
                                        localStorage.anonymous_user
                                      )
                                        return setProfilehOpen(true);
                                      navigate(isOrg ? `/profile/${username}/org_followings` :
                                        `/profile/${username}/followers`
                                      );
                                    }}
                                    data-test="src-components-ProfileHeader-index-23"
                                  >
                                    <FollowerDiv style={{marginRight: isOrg ? "1.5rem" : undefined, width: isOrg?"160px":"9rem"}}>
                                      <FollowerTag to="#">
                                        {isOrg ? allWords.profile.btnFollowing : allWords.profile.btnFollowers}
                                      </FollowerTag>
                                      <FollowerCount>{followers}</FollowerCount>
                                    </FollowerDiv>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {/* modal that opens when clicked on image thumbnail */}
                    <FullScreenDialog open={imgOpen} setOpen={setImgOpen}>
                      <UserProfile
                        username={username}
                        alt="profile"
                        id="img01"
                        width="auto"
                        height="40vh"
                        className="modal-content mcImg"
                        size="large"
                      />
                    </FullScreenDialog>
                  </div>
                  {onPointScreen === ProfilePageFlag.profile && (
                    <>
                      <div
                        className="newDataDiv"
                        data-test="src-components-ProfileHeader-index-24"
                      >
                        <div
                          className="divNamenBtn"
                          data-test="src-components-ProfileHeader-index-25"
                        >
                          <div
                            className="nameDiv3"
                            data-test="src-components-ProfileHeader-index-26"
                          >
                            <p
                              className="nameIn3"
                              data-test="src-components-ProfileHeader-index-27"
                            >
                              {name}
                            </p>
                            <div
                              className="usernamenVip"
                              data-test="src-components-ProfileHeader-index-28"
                            >
                              <p
                                className="usernameIn3"
                                data-test="src-components-ProfileHeader-index-29"
                              >
                                @{user_name}
                              </p>
                              <VIPComp user_type={userType} />
                            </div>
                          </div>

                          {edit_btn &&
                            username ===
                              JSON.parse(
                                localStorage.current_user ||
                                  localStorage.anonymous_user
                              )["username"] &&
                            window.location.pathname !==
                              "/personal_details" && (
                              <Button
                                onClick={() => navigate("/personal_details")}
                                className={isOrg ? "profile_org_join_button": "profile_edit_button"}
                                style={{right: isOrg ? "1rem" : undefined,
                                  width: isOrg ? "168px" : undefined}}
                              >
                                {isOrg ? "Join" : allWords.profile.btnEdit}
                              </Button>
                            )}
                          {HighlightsUser?.user_domain === "ORG" &&
                            currentUser._id === HighlightsUser?._id && (
                              <>
                                <Button
                                  className="profile_edit_button"
                                  sx={{
                                    marginLeft: ".5rem !important",
                                  }}
                                >
                                  <label
                                    htmlFor="file-input"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {allWords.profile.highlights.addHighlights}
                                  </label>
                                  <input
                                    id="file-input"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    hidden
                                    onChange={handleHighlightsImageChange}
                                  />
                                </Button>
                                <Button
                                  className="profile_edit_button"
                                  sx={{
                                    marginLeft: ".5rem !important",
                                  }}
                                  onClick={() => handleOpenChange(true)}
                                >
                                  <label style={{ cursor: "pointer" }}>
                                    {allWords.profileShowcase.addShowcase}
                                  </label>
                                </Button>
                              </>
                            )}
                          {isOpen && (
                            <AddShowcaseModal
                              HighlightsUser={HighlightsUser}
                              isOpen={isOpen}
                              onOpenChange={handleOpenChange}
                            />
                          )}
                          {blockUser === true ? (
                            ""
                          ) : (
                            <>
                              {is_following &&
                                !(
                                  edit_btn &&
                                  username ===
                                    JSON.parse(
                                      localStorage.current_user ||
                                        localStorage.anonymous_user
                                    )["username"] &&
                                  window.location.pathname !==
                                    "/personal_details"
                                ) && (
                                  <button
                                    style={{
                                      fontSize: "0.9rem",
                                      padding: "0rem 0.8rem",
                                      height: "35px",
                                      marginTop: "0",
                                      width: isOrg ? "168px" : undefined,
                                      borderRadius: isOrg ? "6px" : undefined,
                                      background: isOrg ? "#252525" : "transparent",
                                      border: isOrg ? "1px solid grey" : "none",
                                      marginLeft:
                                        edit_btn &&
                                        username ===
                                          JSON.parse(
                                            localStorage.current_user ||
                                              localStorage.anonymous_user
                                          )["username"] &&
                                        window.location.pathname !==
                                          "/personal_details"
                                          ? "12px"
                                          : "auto",
                                      marginRight: "20px",
                                    }}
                                    className={`follow-button-small`}
                                    onClick={() => {
                                      localStorage.setItem(
                                        "yapp_visitor_user_id",
                                        visitor_user_id
                                      );
                                      window.open(
                                        `${window.location.origin}/yapp`
                                      );
                                    }}
                                    data-test="src-components-ProfileHeader-index-30"
                                  >
                                    Yapp
                                  </button>
                                )}

                              {follow_btn &&
                                username !==
                                  JSON.parse(
                                    localStorage.current_user ||
                                      localStorage.anonymous_user
                                  )["username"] && (
                                  <button
                                    style={
                                      is_following
                                        ? {
                                            fontSize: "0.9rem",
                                            padding: "0rem 0.8rem",
                                            height: "35px",
                                            marginTop: "0",
                                            marginLeft: "0",
                                            background: isOrg ? "#252525" : "transparent",
                                            color: "#f4f4f4",
                                            border: isOrg ? "1px solid grey" : "1px solid #f4f4f4",
                                            marginRight: isOrg ? "1rem" : undefined,
                                            width: isOrg ? "168px" : undefined,
                                            borderRadius: isOrg ? "6px" : undefined
                                          }
                                        : {
                                            fontSize: "0.9rem",
                                            padding: "0rem 0.8rem",
                                            height: "35px",
                                            marginTop: "0",
                                            marginLeft: "auto",
                                          }
                                        
                                    }
                                    className={
                                      is_following
                                        ? `following-button-small`
                                        : `follow-button-small`
                                    }
                                    ref={follows}
                                    onClick={() => {
                                      if (
                                        !localStorage.current_user &&
                                        localStorage.anonymous_user
                                      )
                                        return setProfilehOpen(true);
                                      follow_unfollow_driver(
                                        username,
                                        is_following
                                      );
                                    }}
                                    data-test="src-components-ProfileHeader-index-31"
                                  >
                                    {is_following
                                      ? `Following`
                                      : allWords.misc.livert.follow}
                                  </button>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="dataDivbelow"
                        data-test="src-components-ProfileHeader-index-32"
                      >
                        {(!bio?.trim() &&
                          !location &&
                          !dob &&
                          !web &&
                          !!subCategoryName) ||
                          (username !== user_name && (
                            <div
                              className="nodataGifDiv"
                              data-test="src-components-ProfileHeader-index-33"
                            >
                              <lottie-player
                                src={globalImages.si_pr_incomplete}
                                background="transparent"
                                speed="1"
                                style={{
                                  width: "300px",
                                }}
                                loop
                                autoplay
                              />
                            </div>
                          ))}
                        {bio && (
                          <div
                            className="biol"
                            data-test="src-components-ProfileHeader-index-34"
                          >
                            <p
                              className="bioP"
                              data-test="src-components-ProfileHeader-index-35"
                            >
                              {bio}
                            </p>
                          </div>
                        )}
                        {location && (
                          <div
                            hidden={
                              user_name ===
                              JSON.parse(
                                localStorage.current_user ||
                                  localStorage.anonymous_user
                              )?.["username"]
                                ? false
                                : privacy_settings?.includes("location_radio")
                                  ? false
                                  : true
                            }
                            data-test="src-components-ProfileHeader-index-36"
                          >
                            {location && (
                              <img
                                alt="this is img"
                                src={locationIcon}
                                data-test="src-components-ProfileHeader-index-37"
                              />
                            )}
                            <small style={{ marginLeft: "8px" }}>
                              {location}
                            </small>
                          </div>
                        )}
                        <div
                          className="dobDiv"
                          hidden={
                            user_name ===
                            JSON.parse(
                              localStorage.current_user ||
                                localStorage.anonymous_user
                            )?.["username"]
                              ? false
                              : privacy_settings?.includes("dob")
                                ? false
                                : true
                          }
                          data-test="src-components-ProfileHeader-index-38"
                        >
                          {dob && (
                            <img
                              alt="this is img"
                              src={cake}
                              data-test="src-components-ProfileHeader-index-39"
                            />
                          )}
                          {dob &&
                            dob.length > 1 &&
                            moment(dob).format("DD-MM-YY") !==
                              "Invalid date" && (
                              <small style={{ marginLeft: "8px" }}>
                                {moment(dob).format("DD-MMM-YYYY")}
                              </small>
                            )}
                        </div>
                        <>
                          <div
                            className="websiteDiv"
                            dangerouslySetInnerHTML={{
                              __html: replaceURLs(web),
                            }}
                            hidden={
                              user_name ===
                              JSON.parse(
                                localStorage.current_user ||
                                  localStorage.anonymous_user
                              )?.["username"]
                                ? false
                                : privacy_settings?.includes("website_radio")
                                  ? false
                                  : true
                            }
                            data-test="src-components-ProfileHeader-index-40"
                          />
                        </>
                        <div
                          className="interestNwDiv"
                          hidden={
                            user_name ===
                            JSON.parse(
                              localStorage.current_user ||
                                localStorage.anonymous_user
                            )?.["username"]
                              ? false
                              : privacy_settings?.includes("interest_radio")
                                ? false
                                : true
                          }
                          data-test="src-components-ProfileHeader-index-41"
                        >
                          {subCategoryName?.map((i, v) =>
                            v == subCategoryName.length - 1 ? (
                              <small key={i}>{`#${i}`}</small>
                            ) : (
                              <small key={i}>{`#${i}, `}</small>
                            )
                          )}
                        </div>

                        {/*  mutual followers */}
                        <MutualFollowersList
                          setMutualFollowers={setMutualFollowers}
                          username={username}
                          setOpenMutualUserModal={setOpenMutualUserModal}
                          setIsFollowing={setIsFollowing}
                        />

                        {/* suggested for you */}
                        {is_following &&
                          !(
                            edit_btn &&
                            username ===
                              JSON.parse(
                                localStorage.current_user ||
                                  localStorage.anonymous_user
                              )["username"] &&
                            window.location.pathname !== "/personal_details"
                          ) && <SuggestedToYou is_profile_section={true} />}
                      </div>
                    </>
                  )}
                </section>
              )}

              {/* new design ends here */}
            </>
          ) : (
            <>
              <div
                className="headiheadi"
                data-test="src-components-ProfileHeader-index-47"
              >
                {/* seo stuff */}
                {/* ------------------- */}
                <section className="containerstick">
                  <h1 id="page-title" style={{ display: "none" }}>
                    {`${username} -Khul Ke - Connect With Your Network`}
                  </h1>

                  <div
                    className="leftck"
                    data-test="src-components-ProfileHeader-index-48"
                  >
                    <img
                      style={{ display: "inline-block", marginTop: "0.1rem" }}
                      src={Back}
                      alt="back button"
                      onClick={() => {
                        switch (onPointScreen) {
                          case ProfilePageFlag.earnPointBadges:
                          case ProfilePageFlag.levels:
                          case ProfilePageFlag.badges:
                            setOnPointScreen(ProfilePageFlag.khulKeBoard);
                            break;
                          case ProfilePageFlag.khulKeBoard:
                            setOnPointScreen(ProfilePageFlag.profile);
                            break;
                          case ProfilePageFlag.profile:
                          default:
                            navigate(-1);
                        }
                      }}
                      data-test="src-components-ProfileHeader-index-49"
                    />
                    <Title className="usernBesidesArrow">
                      {blockUser === true ? (
                        getPageTitle()
                      ) : (
                        <>
                          {yesOut ? (
                            getPageTitle()
                          ) : (
                            <div
                              style={{ display: "flex" }}
                              data-test="src-components-ProfileHeader-index-50"
                            >
                              {`${
                                onPointScreen !== ProfilePageFlag.profile
                                  ? getPageTitle()
                                  : `@${user_name}d`
                              }`}
                              {onPointScreen !==
                              ProfilePageFlag.profile ? null : (
                                <VIPComp user_type={userType} />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </Title>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
      {reportOpen && (
        <ReportDialog
          reportOpen={reportOpen}
          setReportOpen={setReportOpen}
          name={name}
          username={user_name}
          userId={user_id}
          contentId={user_id}
          type={"PROFILE"}
        />
      )}
      <Modal
        onClose={() => {
          setEditImage(false);
          uploadDP.current.value = "";
        }}
        open={editImage}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={4}
          style={{
            padding: "20px",
            minWidth: "600px",
            maxWidth: "1000px",
          }}
        >
          <EditImage
            imageSrc={{
              index: 0,
              file: tempImage[0]?.file,
              url: tempImage[0]?.url,
            }}
            setImgArray={setTempImage}
            imgArray={tempImage}
            ref={imageEditorRef}
            fixedRatio={1 / 1}
          />
          <Grid
            container
            justifyContent="space-between"
            data-test="src-components-ProfileHeader-index-51"
          >
            <Grid item data-test="src-components-ProfileHeader-index-52">
              <Button
                onClick={() => {
                  imageEditorRef.current.setCroppedImage();
                }}
                variant="contained"
                style={{
                  padding: "10px 30px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
                data-test="src-components-ProfileHeader-index-53"
              >
                Save
              </Button>
            </Grid>
            <Grid item data-test="src-components-ProfileHeader-index-54">
              <Button
                onClick={() => {
                  setEditImage(false);
                  setTempImage([]);
                  uploadDP.current.value = "";
                }}
                variant="contained"
                style={{
                  padding: "10px 30px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
                data-test="src-components-ProfileHeader-index-55"
              >
                {allWords.misc.livert.dis}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      <MutualFollowersDialogBox
        openMutualUserModal={openMutualUserModal}
        setOpenMutualUserModal={setOpenMutualUserModal}
        mutualFollowers={mutualFollowers}
        follow_unfollow_driver={follow_unfollow_driver}
        username={username}
        is_following={is_following}
        setProfilehOpen={setProfilehOpen}
        follows={follows}
      />
      <PreloginComp
        modalOpen={profilehOpen}
        setModalOpen={setProfilehOpen}
        icon={
          <PersonIcon style={{ color: "#66B984" }} width={40} height={40} />
        }
        title={"For follow, Login or sign up to Khul Ke"}
        description={""}
      />
    </>
  );
};

export default ProfileHeader;
