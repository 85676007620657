import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const IconContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  // Add styles for selected item
  ${(props) =>
    props.selected &&
    `
    .icon_container {
      .selected_icon {
        stroke: ${props.theme.color.white} !important;
        stroke-width: 2;
      }
      .selected_title {
        font-weight: bold;
        color: ${props.theme.color.white} !important;
      }
    }
  `}

  .icon_container {
    .selected_icon {
      stroke: ${(props) => props.theme.color.white} !important;
      stroke-width: 2;
    }
    .selected_title {
      font-weight: bold;
      color: ${(props) => props.theme.color.white} !important;
    }

    .icon {
      width: 25px;
      height: 25px;
      margin-right: 1rem;
      stroke: #a2a2a2;
    }
    .nav_title {
      color: #a2a2a2;
      transition: 0.3s;
    }
    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.color.primary};
    }
  }
`;

export const Title = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.color.white};
  font-size: 1.125rem;
  font-family: "Work Sans";
  line-height: 0;
`;

export const Notification = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  background-color: ${(props) => props.theme.color.flamingo};
  color: ${(props) => props.theme.color.whiteBackground};
  font-family: "Work Sans";
  font-size: 14px;
`;

const AccountListItem = ({ Icon, iconStyles, title, path, selected, style, openTab, disableTitleClick=false }) => {
  const navigate = useNavigate();
  return (
    <IconContainer selected={selected}>
      <div
        className={`icon_container`}
        data-test="src-components-AccountLeftsideBar-AccountListItem-0"
        style={{display:"flex", alignItems:"center"}}
      >
        {Icon && <Icon className={`icon  ${selected && "selected_icon"}`} onClick={() => navigate(path)} style={{...iconStyles}}/>}
        {openTab ? (
          <Title
            style={style}
            to={!disableTitleClick ? path : "#"}
            className={`nav_title ${selected && "selected_title"}`}
            activeclassname="active"
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </Title>
        ) : (
          <Title
            style={style}
            to={!disableTitleClick ? path : "#"}
            className={`nav_title ${selected && "selected_title"}`}
            activeclassname="active"
          >
            {title}
          </Title>
        )}
      </div>
    </IconContainer>
  );
};

export default AccountListItem;
