import {
  SET_NO_MORE_DATA,
  GET_HASTAGS_POST_START,
  GET_HASTAGS_POST_SUCCESS,
  GET_HASTAGS_POST_FAILURE,
  GET_LOCATIONS_POST_START,
  GET_LOCATIONS_POST_SUCCESS,
  GET_LOCATIONS_POST_FAILURE,
  SET_LOCATIONS_NO_MORE_DATA,
} from "../../actions/actionTypes";

const initialState = {
  hashtagPosts: [],
  hashtagError: null,
  hashtagLoading: false,
  hashtagNoMoreData: false,
  locationPosts: [],
  locationError: null,
  locationLoading: false,
  locationNoMoreData: false,
};

const hashtagLocationPostsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HASTAGS_POST_START:
      return { ...state, hashtagLoading: true };

    case GET_HASTAGS_POST_SUCCESS:
      return {
        ...state,
        hashtagPosts: [...state.hashtagPosts, ...action.payload],
        hashtagError: null,
        hashtagLoading: false,
      };
    case GET_HASTAGS_POST_FAILURE:
      return {
        ...state,
        hashtagPosts: [],
        hashtagError: action.payload,
        hashtagLoading: false,
      };

    case SET_NO_MORE_DATA:
      return {
        ...state,
        hashtagNoMoreData: true, // Set the flag to true
        hashtagLoading: false,
      };

    case GET_LOCATIONS_POST_START:
      return { ...state, locationLoading: true };

    case GET_LOCATIONS_POST_SUCCESS:
      console.log('========== api call response', action.payload)
      return {
        ...state,
        locationPosts: [...state.locationPosts, ...action.payload],
        locationError: null,
        locationLoading: false,
      };
    case GET_LOCATIONS_POST_FAILURE:
      return {
        ...state,
        locationPosts: [],
        locationError: action.payload,
        locationLoading: false,
      };

    case SET_LOCATIONS_NO_MORE_DATA:
      return {
        ...state,
        locationNoMoreData: true, // Set the flag to true
        locationLoading: false,
      };

    default:
      return state;
  }
};

export const getHashTagsPostInformation = (state) => ({
  posts: state.hashtagLocation.hashtagPosts,
  loading: state.hashtagLocation.hashtagLoading,
  error: state.hashtagLocation.hashtagError,
  noMoreData: state.hashtagLocation.hashtagNoMoreData,
});

export const getLocationPostInformation = (state) => ({
  posts: state.hashtagLocation.locationPosts,
  loading: state.hashtagLocation.locationLoading,
  error: state.hashtagLocation.locationError,
  noMoreData: state.hashtagLocation.locationNoMoreData,
});

export default hashtagLocationPostsReducer;
