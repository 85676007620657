import React, { useEffect, useState } from "react";
import { profilePhoto } from "../../apis/profileApi";
import UserDefault from "../../assets/images/default_user.png";
import worldIcon from "../../assets/images/world-icon.png";
import Trophy from "../../assets/images/trophy.svg";

import "./index.css";
import { ProfilePageFlag } from "../../pages/Profile";

const fetchProfilePhoto = async (username, size, setDp) => {
  try {
    const res = await profilePhoto({ username, size });
    const { status, data } = res;
    if (status !== 253) {
      setDp(URL.createObjectURL(data));
    } else {
      setDp(UserDefault);
    }
  } catch (error) {
    setDp(UserDefault);
  }
};

const UserProfile = ({
  username,
  onClick,
  className,
  hidden,
  width = 45,
  height = 45,
  borderRadius = "50%",
  alt = "",
  id = "pp_img",
  boxShadow = "0px",
  size = "medium",
  marginLeft = "-10px",
  circularProgress = false,
  onPointScreen = ProfilePageFlag.profile,
  badges = [],
  points = 0,
  level = 0,
  nextLevelPoints = 10,
  name = "",
  user_name = "",
  isOrgMemberList = false,
  isOrg = false
}) => {
  const [dp, setDp] = useState(UserDefault);

  useEffect(() => {
    if (username) {
      if(username==='Everyone'){
        setDp(worldIcon)
      }
      else{
        fetchProfilePhoto(username, size, setDp);
      }
    }
  }, [username]);

  const imageStyle = {
    width,
    height,
    borderRadius: isOrgMemberList ? "25%" : borderRadius,
    cursor: "pointer",
    boxShadow,
    marginLeft: isOrg ? "15px" : undefined
  };

  const getCurrentLevelPercentageCompleted = () => {
    if (points === 0 || nextLevelPoints === 0) return 0;
    return (((points / nextLevelPoints) * 100) / 100) * 360;
  };

  return (
    <>
      {circularProgress ? (
        <div
          className="circular-progress"
          data-test="src-components-UserProfile-index-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            aria-labelledby="title"
            role="graphic"
          >
            <title id="title">circular progress bar</title>
            <circle cx="50" cy="50" r="40"></circle>
            <circle
              cx="50"
              cy="50"
              r="40"
              id="pct-ind"
              style={{
                strokeDashoffset: `${
                  460 - getCurrentLevelPercentageCompleted()
                }`,
              }}
            ></circle>
          </svg>
          {onPointScreen !== ProfilePageFlag.profile ? (
            <>
              <img
                id={id}
                alt={alt}
                className={`circular-profile trophy-profile`}
                src={Trophy}
                style={imageStyle}
                onClick={onClick}
                hidden={hidden}
                data-test="src-components-UserProfile-index-1"
              />
              <img
                src={dp}
                alt="trophy"
                className="trophy bottom-profile"
                data-test="src-components-UserProfile-index-2"
              />
              <div
                className="level-tag"
                data-test="src-components-UserProfile-index-3"
              >
                Lvl {level}
              </div>
              <div
                className="user-info"
                data-test="src-components-UserProfile-index-4"
              >
                <p data-test="src-components-UserProfile-index-5">
                  {name} @{user_name} Level {level} {points}/{nextLevelPoints}
                </p>
              </div>
            </>
          ) : (
            <>
              <img
                id={id}
                alt={alt}
                className={`${className} circular-profile`}
                src={dp}
                style={imageStyle}
                onClick={onClick}
                hidden={hidden}
                data-test="src-components-UserProfile-index-6"
              />
              <img
                src={Trophy}
                alt="trophy"
                className="trophy"
                onClick={onClick}
                style={{ cursor: "pointer" }}
                data-test="src-components-UserProfile-index-7"
              />
              <div
                className="level-tag"
                onClick={onClick}
                data-test="src-components-UserProfile-index-8"
              >
                Lvl {level}
              </div>
            </>
          )}
        </div>
      ) : (
        <img
          id={id}
          alt={alt}
          className={className}
          src={dp}
          style={imageStyle}
          onClick={onClick}
          hidden={hidden}
          data-test="src-components-UserProfile-index-9"
        />
      )}
    </>
  );
};

export default UserProfile;
